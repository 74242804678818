import React, { useState, Fragment } from "react";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import FooterLandingPage from "../LandingPage/FooterLandingPage";
import record from "../../../assets/img/records.png";
import stop from "../../../assets/img/stop.png";
import replay from "../../../assets/img/record-replay.png";
import next from "../../../assets/img/icon-next.png";
import iconReplay from "../../../assets/img/icon-replay.png";
import replayQuestion from "../../../assets/img/replay-question.png";
import VideoAssessment from "../VideoAssesment";
import { translate } from "react-switch-lang";

export default translate(function GuideVideoAssesment(props) {
  const [checkBox, setCheckBox] = useState(true);
  const [goToVideo, setGoToVideo] = useState(false);
  const handleCheckBox = (e) => {
    if (e.target.checked === true) {
      setCheckBox(false);
    } else {
      setCheckBox(true);
    }
  };
  const handleGoToVideo = () => {
    setGoToVideo(true);
  };
  const {t} = props

  return (
    <Fragment>
      {goToVideo ? (
        <VideoAssessment
          applied={props.applied}
          support={props.support}
          spmExist={props.spmExist}
        />
      ) : (
        <div className="navtext" style={{ padding: "0" }}>
          <div className="panduan-assesmen">
            <Row
              style={{
                justifyContent: "center",
                margin: "0",
                marginBottom: "30px",
              }}
            >
              <Col lg="2"></Col>
              <Col lg="8">
                <Row>
                  <Col sm="12">
                    <h1>{t('Interview.CVVideoGuide')}</h1>
                    <h5>
                      {t('Interview.beforeCreateVideo')}
                    </h5>
                    <div className="box-panduan">
                      <ul
                        style={{ listStyle: "upper-alpha" }}
                        className="ml-2 upper-list"
                      >
                        <li>
                        {t('Interview.beforeCreateVideoPoint1')}
                        </li>
                        <li>
                        {t('Interview.beforeCreateVideoPoint2')}{" "}
                        </li>
                        <li>
                        {t('Interview.beforeCreateVideoPoint3')}
                        </li>
                        <li>{t('Interview.beforeCreateVideoPoint4')}</li>
                        <li>
                        {t('Interview.beforeCreateVideoPoint5')}
                        </li>
                        <ul
                          style={{ listStyle: "decimal" }}
                          className="down-list"
                        >
                          <li>
                            {t('Interview.button')}{" "}
                            <span>
                              <img
                                src={record}
                                alt="rekam"
                                style={{ width: "6%" }}
                              />
                            </span>
                            {t('Interview.record')}
                          </li>
                          <li>
                            {t('Interview.button')}{" "}
                            <span>
                              <img
                                src={stop}
                                alt="stop"
                                style={{ width: "6%" }}
                              />
                            </span>
                            {t('Interview.stop')}{" "}
                          </li>
                          <li>
                            {t('Interview.button')}{" "}
                            <span>
                              <img
                                src={iconReplay}
                                alt="putar hasil"
                                style={{ width: "6%" }}
                              />
                            </span>
                            {t('Interview.playback')}{" "}
                          </li>
                          <li>
                            {t('Interview.button')}{" "}
                            <span>
                              <img
                                src={replay}
                                alt="rekam ulang"
                                style={{ width: "6%" }}
                              />
                            </span>
                            {t('Interview.reRecord')}
                          </li>
                          <li>
                            {t('Interview.button')}{" "}
                            <span>
                              <img
                                src={next}
                                alt="next"
                                style={{ width: "6%" }}
                              />
                            </span>
                            {t('Interview.continue')}
                          </li>
                          <li>
                            {t('Interview.button')}{" "}
                            <span>
                              <img
                                src={replayQuestion}
                                alt="replay question"
                                style={{ width: "6%" }}
                              />
                            </span>
                            {t('Interview.reQuestion')}
                          </li>
                        </ul>
                        <li>
                        {t('Interview.beforeCreateVideoPoint6')}
                        </li>
                        <li>
                        {t('Interview.beforeCreateVideoPoint7')}
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col sm="8">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="checkbox"
                          onChange={(e) => handleCheckBox(e)}
                        />{" "}
                        {t('Interview.beforeCreateVideoAgreement')}
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col sm="4" className="text-right">
                    <Button
                      type="submit"
                      color="netis-color"
                      disabled={checkBox}
                      onClick={handleGoToVideo}
                    >
                      {t('Interview.btnContinue')}<i className="fa fa-arrow-right ml-2"></i>
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col lg="2"></Col>
            </Row>
          </div>
          <FooterLandingPage />
        </div>
      )}
    </Fragment>
  );
})
