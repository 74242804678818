import React from 'react'
import {t, translate} from 'react-switch-lang';
import { Container } from 'reactstrap';
import { Link } from "react-router-dom";
import IphoneImg1 from "../../../../assets/img/iphone-1.png";
import IphoneImg2 from "../../../../assets/img/iphone-2.png";
import IphoneImg2en from "../../../../assets/img/iphone-2-en.png"
import IphoneImg3 from "../../../../assets/img/iphone-3.png";
import AppsStoreImg from "../../../../assets/img/apps store.png";
import PlayStoreImg from "../../../../assets/img/playstore.png";

function ContentPromotionVideoCV() {
  const currentLang = window.localStorage.language;

  const goToLink = (url) => {
    // window.location.replace(url);
    window.open(url, "_blank");
  };
  return (
    <section
      className="mb-5"
      style={{ 
        backgroundColor: "white", 
        paddingTop:"20px" 
        }}
      >
      {/* Gambar 1 */}
      <section className="content-promotion-1-wrapper">
        <Container 
          style={{
            paddingTop:"80px"
          }}
        >
          <h2
            className="promotion-heading text-center"
            style={{ 
              fontWeight: "600",
              
              }}
          >
            {t("LandingPage.promotionVideoHeading1")}<br/>
            {t("LandingPage.promotionVideoHeading2")}
          </h2>

          <div className="row  content-promotion-1 align-items-center">
              <div className="text-desc-left text-md-right col-md-4 order-2 order-md-1">
                 <div className="mb-md-5">
                    <h5 className="promotion-text-heading">
                      {t("LandingPage.promotionTextHeading1")}
                    </h5>
                    <p>
                     {t("LandingPage.promotionText1")}
                    </p> 
                 </div>
                <div>
                  <h5 className="promotion-text-heading">
                    {t("LandingPage.promotionTextHeading2")}
                  </h5>
                  <p>
                    {t("LandingPage.promotionText2")}
                  </p>
                </div>
              </div>
              <div className="iphoneImg-center col-12 col-md-4 order-1 order-md-2">
                <img width="100%"  src={IphoneImg1} alt="iphone-1"/>
              </div>
            <div className="text-desc-right text-md-left col-12 col-md-4 order-3">
              <div className="mb-md-5">
                <h5 className="promotion-text-heading">
                  {t("LandingPage.promotionTextHeading3")}
                </h5>
                <p>
                  {t("LandingPage.promotionText3")}
                </p>
              </div>
              <div>
                <h5 className="promotion-text-heading">
                  {t("LandingPage.promotionTextHeading4")}
                  
                </h5>
                <p>
                  {t("LandingPage.promotionText4")}
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      {/* Gambar 2 */}
      <section className="content-promotion-2-wrapper mb-5">
       <Container>
         <div className="row content-promotion-2 align-items-center">
           <div className="col-12 col-md-5 text-desc-left text-md-right order-2 order-md-1">
              <h5 className="promotion-text-heading">
                {t("LandingPage.promotionTextHeading5")}
                
              </h5>
              <p>
                {t("LandingPage.promotionText5")}
              </p>
           </div>
           <div className="col-0 col-md-1"></div>
           <div className="col-12 col-md-6 iphone-right order-1 order-md-2">
             <img 
              width="100%" 
              src={
               currentLang === "ID" 
               ? IphoneImg2
               : IphoneImg2en 
              } alt="iphone img" 
            />
           </div>
         </div>
       </Container>
      </section>
      {/* Gambar 3 */}
      <section className="content-promotion-3-wrapper mb-5">
       <Container>
         <div className="row content-promotion-3 align-items-center">
           <div className="col-12 col-md-6">
             <img width="100%" src={IphoneImg3} alt="iphone img"/>
           </div>
           <div className="col-0 col-md-1"></div>
           <div className="col-12 col-md-4 text-desc-left text-left order-2 order-md-1">
              <h5 className="promotion-text-heading">
                {t("LandingPage.promotionTextHeading6")}
              </h5>
              <p>
                {t("LandingPage.promotionText6")}
              </p>
              <div className="text-center text-md-left">
                <Link
                  onClick={() =>
                    goToLink(
                      "https://play.google.com/store/apps/details?id=com.widyaskilloka.skillana"
                    )
                  }
                >
                  <img
                    src={PlayStoreImg}
                    className="img-ps"
                    alt="skillana-playstore"
                    style={{ width: "140px" }}
                  />
                </Link>
                <Link
                  onClick={() =>
                    goToLink("https://apps.apple.com/id/app/skillana/id1546184278")
                  }
                >
                  <img
                    src={AppsStoreImg}
                    className="img-ps ml-3"
                    alt="skillana-playstore"
                    style={{ width: "140px" }}
                  />
                </Link>
              </div>
           </div>
         </div>
       </Container>
      </section>
      <div className="text-center">
        <Link
          to="/video-cv"
          className="py-3 px-5 text-white"
          style={{
            border:"none",
            borderRadius:"12px",
            backgroundColor: "#FFA833"
          }}
        >
          {t("LandingPage.createCVVideo")}
        </Link>
      </div>
    </section>
  )
}

export default translate(ContentPromotionVideoCV)
