import React, { useState, Fragment } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import Color from "../../../../utils/Colors";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { translate } from "react-switch-lang";
const ContentTypeAsesmen = (props) => {
  const {t} = props
  const [redirect, setRedirect] = useState(false);
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <Fragment>
      <div className="header-landing-page-old d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none">
        <Container className="wrap-content">
          <Row className="back-asesmen">
            <Col sm="12">
              <img
                src={require("../../../../assets/img/circle.svg")}
                className="mt-2 mr-4"
                style={{ width: "80px" }}
                alt="icon1"
              />
            </Col>

            {/* Tes Kepribadian */}

            <Col sm="6">
              <center>
                <img
                  src={require("../../../../assets/img/G-1.svg")}
                  style={{ width: "60%" }}
                  alt="disc"
                />
              </center>
            </Col>
            <Col sm="6" className="mb-3 pr-3">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>
                {t('LandingPage.personalityTest')}
                </span>
                <br />
              </h2>
              <br />
              <p className="text-justify">
                <b>{t('LandingPage.personalityTest')}</b> {" "}
                {t('LandingPage.personalityTestDesc2')}
              </p>
            </Col>

            {/* Tes Gaya Kerja */}
            <Col sm="6" className="mt-5">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>
                {t('LandingPage.workStyleTest')}
                </span>
                <br />
              </h2>
              <br />
              <p className="text-justify">
                <b>{t('LandingPage.workStyleTest')}</b> {" "}
                {t('LandingPage.workStyleTestDesc2')}
              </p>
            </Col>
            <Col sm="6" className="mt-3">
              <center>
                <img
                  src={require("../../../../assets/img/G-gayakerja.svg")}
                  style={{ width: "60%" }}
                  alt="papikostik"
                />
                <img
                  src={require("../../../../assets/img/G-4.svg")}
                  style={{ width: "10%" }}
                  className="ml-5"
                  alt="icon2"
                />
              </center>
            </Col>

            {/* Tes Minat dan Bakat */}
            <Col sm="6" className="mt-5">
              <center>
                <img
                  src={require("../../../../assets/img/G-minatbakat.svg")}
                  style={{ width: "60%" }}
                  alt="mbti"
                />
              </center>
            </Col>
            <Col sm="6" className="mt-5">
              <h2 className="title-header">
                <span style={{ color: Color.primaryColor }}>
                  {t('LandingPage.aptitudeTest')}
                </span>
                <br />
              </h2>
              <br />
              <p className="text-justify">
                <b>{t('LandingPage.aptitudeTest')}</b> {" "}
                {t('LandingPage.aptitudeTestDesc2')}{" "}
                <Link to="/type-personality" className="ml-3">
                  {" "}
                  <b>{t('LandingPage.see16PersonalityTypes')}</b>{" "}
                </Link>
              </p>
            </Col>

            {/* Tes Biometrik */}
            <Col sm="6" className="mt-5">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>{t('LandingPage.biometricTest')}</span>
                <br />
              </h2>
              <br />
              <p className="text-justify">
                <b>{t('LandingPage.biometricTest')}</b> {t('LandingPage.biometricTestDesc2')}
              </p>
            </Col>
            <Col sm="6">
              <center>
                <img
                  src={require("../../../../assets/img/finger-landing.png")}
                  style={{ width: "50%" }}
                  alt="biometric"
                />
              </center>
            </Col>

            {/* Tes Kepemimpinan */}
            <Col sm="6" className="mt-3">
              <center>
                <img
                  src={require("../../../../assets/img/msdt-landing.png")}
                  style={{ width: "50%" }}
                  alt="msdt"
                />
                <img
                  src={require("../../../../assets/img/G-4.svg")}
                  style={{ width: "10%" }}
                  className="ml-5"
                  alt="icon2"
                />
              </center>
            </Col>
            <Col sm="6" className="mt-4 mb-3 pr-3">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>
                  {t('LandingPage.leadershipTest')}
                </span>
                <br />
              </h2>
              <br />
              <p className="text-justify">
                <b>{t('LandingPage.leadershipTest')}</b> {" "}
                {t('LandingPage.leadershipTestDesc2')}
              </p>
            </Col>

            {/* Tes Gaya Bisnis */}
            <Col sm="6" className="mt-5">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>{t('LandingPage.businessInsightTest')}</span>
                <br />
              </h2>
              <br />
              <p className="text-justify">
                <b>{t('LandingPage.businessInsightTest')}</b> {t('LandingPage.businessInsightTestDesc2')}
              </p>
            </Col>
            <Col sm="6">
              <center>
                <img
                  src={require("../../../../assets/img/business-insight-landing.png")}
                  style={{ width: "70%" }}
                  alt="business insight"
                />
              </center>
            </Col>

            {/* Tes Agility */}
            <Col sm="6" className="mt-3">
              <center>
                <img
                  src={require("../../../../assets/img/agility-landing.png")}
                  style={{ width: "50%" }}
                  alt="agility"
                />
              </center>
            </Col>
            <Col sm="6" className="mt-4 mb-3 pr-3">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>
                  {t('LandingPage.agilityTest')}
                </span>
                <br />
              </h2>
              <br />
              <p className="text-justify">
                <b>{t('LandingPage.agilityTest')}</b> {" "}
                {t('LandingPage.agilityTestDesc')}
              </p>
            </Col>


            {props.user?.id ? (
              <Button
                size="sm"
                className="btn-tipe-asesmen mr-auto ml-auto"
                onClick={() => setRedirect("/assessment")}
              >
                <b>{t('LandingPage.seeAssessmentResult')}</b>
              </Button>
            ) : (
              <Button
                size="sm"
                className="btn-tipe-asesmen mr-auto ml-auto"
                onClick={() => setRedirect("/login")}
              >
                <b>{t('LandingPage.loginSeeAssessment')}</b>
              </Button>
            )}
            <Col sm="12" className="mt-5">
              <img
                src={require("../../../../assets/img/G-4.svg")}
                className="mb-4 ml-3"
                style={{ width: "50px" }}
                alt="icon2"
              />
            </Col>
          </Row>
        </Container>
      </div>

      {/* TAMPILAN MOBILE WEB */}
      <div className="header-landing-page-old d-block d-sm-none || d-none d-sm-block d-md-none">
        <Container className="wrap-content">
          <Row className="back-asesmen">
            <Col sm="12">
              <img
                src={require("../../../../assets/img/circle.svg")}
                className="mt-2 mr-4"
                style={{ width: "80px" }}
                alt="icon1"
              />
            </Col>

            {/* Tes Kepribadian */}
            <Col sm="6">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>
                {t('LandingPage.personalityTest')}
                </span>
                <br />
              </h2>
              <br />
              <center>
                <img
                  src={require("../../../../assets/img/G-1.svg")}
                  style={{ width: "60%" }}
                  alt="disc"
                />
              </center>
            </Col>
            <Col sm="6" className="mb-3 pr-3">
              <p className="text-justify">
                <b>{t('LandingPage.personalityTest')}</b> {" "}
                {t('LandingPage.personalityTestDesc2')}
              </p>
            </Col>

            {/* Tes Gaya Kerja */}
            <Col sm="6" className="mt-3">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>
                {t('LandingPage.workStyleTest')}
                </span>
                <br />
              </h2>
              <br />
              <center>
                <img
                  src={require("../../../../assets/img/G-gayakerja.svg")}
                  style={{ width: "60%" }}
                  alt="papikostik"
                />
                <img
                  src={require("../../../../assets/img/G-4.svg")}
                  style={{ width: "10%" }}
                  className="ml-5"
                  alt="icon2"
                />
              </center>
            </Col>
            <Col sm="6" className="mt-5">
              <p className="text-justify">
                <b> {t('LandingPage.workStyleTest')}</b> {" "}
                {t('LandingPage.workStyleTestDesc2')}
              </p>
            </Col>

            {/* Tes Minat dan Bakat */}
            <Col sm="6" className="mt-5">
              <h2 className="title-header">
                <span style={{ color: Color.primaryColor }}>
                {t('LandingPage.aptitudeTest')}
                </span>
                <br />
              </h2>
              <br />
              <center>
                <img
                  src={require("../../../../assets/img/G-minatbakat.svg")}
                  style={{ width: "60%" }}
                  alt="mbti"
                />
              </center>
            </Col>
            <Col sm="6" className="mt-5">
              <p className="text-justify">
                <b>{t('LandingPage.aptitudeTest')}</b> {" "}
                {t('LandingPage.aptitudeTestDesc2')}
                {" "}
                <Link to="/type-personality">
                  <b>{t('LandingPage.see16PersonalityTypes')}</b>
                </Link>
              </p>
            </Col>

            {/* Tes Biometrik */}
            <Col sm="6" className="mt-3">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>
                  {t('LandingPage.biometricTest')}
                  </span>
                <br />
              </h2>
              <br />
              <center>
                <img
                  src={require("../../../../assets/img/finger-landing.png")}
                  style={{ width: "60%" }}
                  alt="biometric"
                />
              </center>
            </Col>
            <Col sm="6" className="mt-5">
              <p className="text-justify">
                <b>{t('LandingPage.biometricTest')}</b> {" "}
                {t('LandingPage.biometricTestDesc2')}
              </p>
            </Col>

            {/* Tes Kepemimpinan */}
            <Col sm="6" className="mt-3">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>
                {t('LandingPage.leadershipTest')}
                </span>
                <br />
              </h2>
              <br />
              <center>
                <img
                  src={require("../../../../assets/img/msdt-landing.png")}
                  style={{ width: "60%" }}
                  alt="msdt"
                />
                <img
                  src={require("../../../../assets/img/G-4.svg")}
                  style={{ width: "10%" }}
                  className="ml-5"
                  alt="icon2"
                />
              </center>
            </Col>
            <Col sm="6" className="mt-5">
              <p className="text-justify">
                <b>{t('LandingPage.leadershipTest')}</b>{" "}
                {t('LandingPage.leadershipTestDesc2')}
              </p>
            </Col>

            {/* Tes Gaya Bisnis */}
            <Col sm="6" className="mt-3">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>
                  {t('LandingPage.businessInsightTest')}
                  </span>
                <br />
              </h2>
              <br />
              <center>
                <img
                  src={require("../../../../assets/img/business-insight-landing.png")}
                  style={{ width: "80%" }}
                  alt="business insight"
                />
              </center>
            </Col>
            <Col sm="6" className="mt-5">
              <p className="text-justify">
                <b>{t('LandingPage.businessInsightTest')}</b> {" "}
                {t('LandingPage.businessInsightTestDesc2')}
              </p>
            </Col>

            {/* Tes Agility */}
            <Col sm="6" className="mt-3">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>
                  {t('LandingPage.agilityTest')}
                  </span>
                <br />
              </h2>
              <br />
              <center>
                <img
                  src={require("../../../../assets/img/agility-landing.png")}
                  style={{ width: "60%" }}
                  alt="agility"
                />
              </center>
            </Col>
            <Col sm="6" className="mt-5">
              <p className="text-justify">
                <b>{t('LandingPage.agilityTest')}</b> {" "}
                {t('LandingPage.agilityTestDesc')}
              </p>
            </Col>
            {/* Tes IQ */}
            {/* <Col sm="6" className="mt-3">
              <h2 className="title-header ">
                <span style={{ color: Color.primaryColor }}>Tes IQ</span>
                <br />
              </h2>
              <br />
              <center>
                <img
                  src={require("../../../../assets/img/Tes-IQ.png")}
                  style={{ width: "60%" }}
                  alt="IQ"
                />
              </center>
            </Col>
           <Col sm="6" className="mt-5">
              <p className="text-justify">
                <b>Tes IQ</b> mmerupakan salah satu tes nonverbal yang
                dikembangkan oleh John C. Raven pada tahun 1936. Tes ini
                biasanya digunakan dalam mengukur kecerdasan manusia secara umum
                dan penalaran abstrak dari pemecahan masalah individu.
                Kecerdasan umum yang diukur dalam tes ini terdapat dua komponen
                antara lain kemampuan untuk berpikir mengenai ide-ide kompleks
                dan kemampuan untuk menyimpan dan mengingat informasi.
              </p>
            </Col> */}

            {props.user?.id ? (
              <Button
                size="sm"
                className="btn-tipe-asesmen mr-auto ml-auto"
                onClick={() => setRedirect("/assessment")}
              >
                <b>{t('LandingPage.seeAssessmentResult')}</b>
              </Button>
            ) : (
              <Button
                size="sm"
                className="btn-tipe-asesmen mr-auto ml-auto"
                onClick={() => setRedirect("/assessment")}
              >
                <b>{t('LandingPage.loginSeeAssessment')}</b>
              </Button>
            )}
            <Col sm="12" className="mt-5">
              <img
                src={require("../../../../assets/img/G-4.svg")}
                className="mb-4 ml-3"
                style={{ width: "50px" }}
                alt="icon2"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default connect(({ user }) => ({ user }))(translate(ContentTypeAsesmen));
