import React, { useState } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  CardText,
  CardTitle,
  Card,
} from "reactstrap";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import {translate} from 'react-switch-lang';

function QuoteLandingPage(props) {
  const {t} = props
  const currentLang = window.localStorage.language; 

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // optional, default to 1.
      partialVisibilityGutter: 50,
    },
  };

  const [redirect, setRedirect] = useState(false);
  if (redirect) {
    return <Redirect to={redirect} />;
  }


  return (
    <div>
      <section>
        <div className="wrap-content">
          <p className="title-landingpage">{t('LandingPage.whySkillana')}</p>
          <hr
            className="line-horizontal"
            style={{ marginBottom: "10%" }}
          />{" "}
          <br />
          <Row style={{ marginBottom: "10%" }}>
            <Col lg={1} />
            <Col lg={10}>
              <div className="icon-path1 d-none d-xl-block">
                <img
                  src={require("../../../../assets/img/Path1.png")}
                  alt="logo"
                  className="path1"
                />
              </div>
              <p className="text-headerlp">
                {t('LandingPage.whySkillanaPoint1sub1')}<br />
                {t('LandingPage.whySkillanaPoint1sub2')}<br />
                {t('LandingPage.whySkillanaPoint1sub3')}
              </p>
              <div className="icon-path2 float-right d-none d-xl-block">
                <img
                  src={require("../../../../assets/img/Path2.png")}
                  alt="logo"
                  className="path2"
                />
              </div>
            </Col>
            <Col lg={1} />
          </Row>
        </div>
      </section>

      {/* GAMBAR 1 */}
      <section>
        <div
          className="d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none"
          style={{ minHeight: "75vh" }}
        >
          <Row className="align-items-md-center align-items-lg-start" style={{ margin: "0" }}>
            <Col md="6">
              <ScrollAnimation animateIn="fadeIn">
              <img
                  src={ 
                    currentLang === "ID"
                    ? require("../../../../assets/img/Bg-iphone1.png")
                    : require("../../../../assets/img/Bg-iphone1-en.png")
                }
                  style={{ width: "130%" }}
                  alt="logo"
                />
              </ScrollAnimation>
            </Col>
            <Col md="6">
              <div style={{ marginTop: "20%", float: "left" }}>
                <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                  {t('LandingPage.whySkillanaPoint2sub1')} <br />
                  {t('LandingPage.whySkillanaPoint2sub2')} <br />
                  {t('LandingPage.whySkillanaPoint2sub3')}
                </p>
                <p style={{ fontSize: "14px" }}>
                  {t('LandingPage.whySkillanaPoint3')}<br/>
                  {t('LandingPage.whySkillanaPoint3sub1')}
                </p>
                <Link
                  to="/type-asesmen"
                  className="py-2 px-3 text-white"
                  style={{
                    backgroundColor: "#FFA833",
                    border: "none",
                    borderRadius: "5px",
                    textDecoration: "none"

                  }}

                >
                  {t("General.more")}
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </section>
                
      <section>
        <div
          className="canvas-Lpmobile d-block d-sm-none || d-none d-sm-block d-md-none"
          style={{ minHeight: "28vh", marginTop: "5%" }}
        >
          <div>
            <Row>
              <Col>
                <ScrollAnimation animateIn="fadeIn">
                <img
                  src={ 
                    currentLang === "ID"
                    ? require("../../../../assets/img/iphone-1-landing-mobile.png")
                    : require("../../../../assets/img/iphone-1-landing-mobile-en.png")
                }
                  style={{width:"150%" ,marginLeft:"-30px" }}
                  alt="logo"
                />
                </ScrollAnimation>
              </Col>
              <Col xs="7">
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {t('LandingPage.whySkillanaPoint2sub1')} {" "}
                  {t('LandingPage.whySkillanaPoint2sub2')} {" "}
                  {t('LandingPage.whySkillanaPoint2sub3')}
                </p>
                <p className="text-landingPage4" style={{ fontSize: "8px" }}>
                  {t('LandingPage.whySkillanaPoint3Mobile')}
                </p>
                <Link
                  to="/type-asesmen"
                  className="py-2 px-3 text-white"
                  style={{
                    fontSize: "10px",
                    backgroundColor: "#FFA833",
                    border: "none",
                    borderRadius: "5px",
                    textDecoration: "none"
                  }}
                >
                  {t("General.more")}
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      {/* GAMBAR 2  */}
      <section>
        <div
          className="backGroundLp d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none"
          style={{ minHeight: "90vh" }}
        >
          <Container>
            <Row>
              <Col style={{ marginTop: "13%" }}>
                <p
                  style={{
                    color: "#ffdb13",
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                >
                  {t('LandingPage.whySkillanaPoint4sub1')} <br /> 
                  {t('LandingPage.whySkillanaPoint4sub2')}
                </p>
                <p style={{ fontSize: "14px", color: "white" }}>
                  {t('LandingPage.whySkillanaPoint5sub1')}
                  <br />
                  {t('LandingPage.whySkillanaPoint5sub2')}
                </p>
                <Link
                  to="/promotion-video-cv"
                  className="py-2 px-3 text-white"
                  style={{
                    backgroundColor: "#FFA833",
                    border: "none",
                    borderRadius: "5px",
                    textDecoration: "none"
                  }}

                >
                  {t("General.more")}
                </Link>
              </Col>
              <Col>
                <ScrollAnimation animateIn="fadeIn">
                  <img
                    width="100%"
                    src={
                      currentLang === "ID" 
                      ? require("../../../../assets/img/bg-iphone-2.png")
                      : require("../../../../assets/img/bg-iphone-2-en.png")
                    }
                    className="Bg-iphone2"
                    alt="bg-2"
                  />
                </ScrollAnimation>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section>
        <div
          className="backGroundLp-mobile d-block d-sm-none || d-none d-sm-block d-md-none"
          style={{ marginTop: "10%" }}
        >
          {/* <img
            width="100%"
            src={require("../../../../assets/img/Bg-mobile-ip3.png")}
            alt="bg-3"
          /> */}
          <Container>
            <Row>
              <Col>
              <div
              style={{
                marginRight:"-60px",
                marginTop: "42%"
              }}
              >
                <p
                  style={{
                    color: "#ffdb13",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t('LandingPage.whySkillanaPoint4sub1')} {" "}
                  {t('LandingPage.whySkillanaPoint4sub2')}
                </p>
                <p style={{ 
                  fontSize: "8px", 
                  color: "white",
                  marginRight: "0"
                  }}
                >
                  {t('LandingPage.whySkillanaPoint5sub1')}{" "}
                  {t('LandingPage.whySkillanaPoint5sub2')}
                </p>
                <Link
                  to="/promotion-video-cv"
                  className="py-2 px-3 text-white"
                  style={{
                    fontSize:"10px",
                    backgroundColor: "#FFA833",
                    border: "none",
                    borderRadius: "5px",
                    textDecoration: "none"
                  }}

                >
                  {t("General.more")}
                </Link>

              </div>
              </Col>
              <Col xs="7">
                <ScrollAnimation animateIn="fadeIn">
                  <img
                    style={{ marginTop: "20%", width: "100%" }}
                    src={
                      currentLang === "ID"
                      ? require("../../../../assets/img/iphone-2-landing-mobile.png")
                      : require("../../../../assets/img/iphone-2-landing-mobile-en.png")
                  }
                    alt="bg-4"
                  />
                </ScrollAnimation>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/* GAMBAR 3  */}
      <section>
        <div
          className="d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none mb-5"
          style={{ minHeight: "28vh", paddingTop: "5%" }}
        >
          <Row className="align-items-md-center" style={{ margin: "0" }}>
            <Col md="6">
              <ScrollAnimation animateIn="fadeIn">
                <img
                  src={
                    currentLang === "ID" 
                    ? require("../../../../assets/img/Bg-iphone3.png")
                    : require("../../../../assets/img/Bg-iphone3-en.png")
                  }
                  style={{ width: "130%" }}
                  alt="logo"
                />
              </ScrollAnimation>
            </Col>
            <Col md="6">
              <p style={{ fontSize: "25px", fontWeight: "bold" }}>
                {t('LandingPage.whySkillanaPoint6sub1')}<br /> 
                {t('LandingPage.whySkillanaPoint6sub2')}
              </p>
              <p style={{ fontSize: "14px" }}>
                {t('LandingPage.whySkillanaPoint7sub1')}<br />
                {t('LandingPage.whySkillanaPoint7sub2')}
              </p>
              <Link
                to="/jobplacement"
                className="py-2 px-3 text-white"
                style={{
                  backgroundColor: "#FFA833",
                  border: "none",
                  borderRadius: "5px",
                  textDecoration: "none"
                }}
              >
                {t("General.more")}
              </Link>
            </Col>
          </Row>
        </div>
      </section>
      <section>
        <div
          className="canvas-Lpmobile d-block d-sm-none || d-none d-sm-block d-md-none"
          style={{ minHeight: "28vh", marginTop: "5%" }}
        >
          <div>
            <Row>
              <Col>
                <ScrollAnimation animateIn="fadeIn">
                  <img
                    src={
                      currentLang === "ID"
                      ? require("../../../../assets/img/iphone-3-landing-mobile.png")
                      : require("../../../../assets/img/iphone-3-landing-mobile-en.png")
                  }
                    style={{ width: "150%", marginLeft: "-30px" }}
                    alt="logo"
                  />
                </ScrollAnimation>
              </Col>
              <Col xs="7">
                <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                  {t('LandingPage.whySkillanaPoint6sub1')}{" "}
                  {t('LandingPage.whySkillanaPoint6sub2')}
                </p>
                <p style={{ fontSize: "8px" }}>
                  {t('LandingPage.whySkillanaPoint7sub1')} {" "}
                  {t('LandingPage.whySkillanaPoint7sub2')}
                </p>
                <Link
                  to="/jobplacement"
                  className="py-2 px-3 text-white"
                  style={{
                    backgroundColor: "#FFA833",
                    border: "none",
                    borderRadius: "5px",
                    textDecoration: "none"
                  }}
                >
                  {t("General.more")}
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      {/* GAMBAR 4 BARU  */}
      {/* <section>
        <div
          className="backGroundLp d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none"
          style={{ minHeight: "90vh" }}
        >
          <Container>
            <Row>
              <Col style={{ marginTop: "13%" }}>
                <p
                  style={{
                    color: "#ffdb13",
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                >
                  Skillana memberikan layanan konsultasi <br /> karir dan
                  bisnis.
                </p>
                <p style={{ fontSize: "14px", color: "white" }}>
                  Kamu dimungkinkan untuk melakukan konsultasi karir dan bisnis
                  dengan mudah. Bisa kamu dapatkan bersama coach ahli dan
                  berpengalaman.
                </p>
              </Col>
              <Col>
                <ScrollAnimation animateIn="fadeIn">
                  <img
                    src={require("../../../../assets/img/liveChatImage.png")}
                    className="Bg-iphone2"
                    alt="bg-2"
                  />
                </ScrollAnimation>
              </Col>
            </Row>
          </Container>
        </div>
      </section> */}

      {/* <section>
        <div
          className="d-block d-sm-none || d-none d-sm-block d-md-none"
          style={{ minHeight: "28vh", marginTop: "10%" }}
        >
          <img
            width="100%"
            src={require("../../../../assets/img/Bg-mobile-ip3.png")}
            alt="bg-3"
          />
          <Container>
            <Row>
              <Col>
                <p
                  style={{
                    marginTop: "-96%",
                    fontSize: "11px",
                    color: "white",
                    marginRight: "-26%",
                  }}
                >
                  {t('LandingPage.whySkillanaMobilePoint4sub1')}<br />
                  {t('LandingPage.whySkillanaMobilePoint4sub2')}
                </p>
              </Col>
              <Col>
                <ScrollAnimation animateIn="fadeIn">
                  <img
                    style={{ marginTop: "-250px", width: "100%" }}
                    src={require("../../../../assets/img/mobileLivechat.png")}
                    alt="bg-4"
                  />
                </ScrollAnimation>
              </Col>
            </Row>
          </Container>
        </div>
      </section> */}
      {/* END  */}

      {/* GAMBAR 4  */}
      <div className="landingPage4">
        <div>
          <p className="title-landingpage2">{t('LandingPage.partnerClient')}</p>
          <hr className="line-horizontal" /> <br />
          <Container>
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              centerMode
              swipeable
              draggable={false}
              showDots={false}
              slidesToSlide={1}
              responsive={responsive}
              minimumTouchDrag={80}
              infinite
              autoPlay
              autoPlaySpeed={1500}
              keyBoardControl
              focusOnSelect={false}
              renderDotsOutside={false}
              renderButtonGroupOutside={false}
              itemClass="carousel-item-padding-40-px"
            > 
                <div className="px-md-4 mx-3">
                  <img
                    width="150px"
                    className="img-job-company"
                    src={require("../../../../assets/img/logoUgm.png")}
                    alt="Card img cap"
                  />
                </div>
                <div className="px-md-4 mx-3">
                  <img
                    width="150px"
                    className="img-job-company"
                    src={require("../../../../assets/img/logoUdinus.png")}
                    alt="Card img cap"
                  />
                </div>
                <div className="px-md-4 mx-3">
                  <img
                    width="150px"
                    className="img-job-company"
                    src={require("../../../../assets/img/logoPondok.png")}
                    alt="Card img cap"
                  />
                </div>
                <div className="px-md-4 mx-3">
                  <img
                    width="150px"
                    className="img-job-company"
                    src={require("../../../../assets/img/logoUmy.png")}
                    alt="Card img cap"
                  />
                </div>
                
            </Carousel>
            {/* <Row className="align-items-center" style={{ margin: "0" }}>
              <Col xs="0" sm="1" />
              <Col xs="2" className="mt-4 mt-md-0">
                <CardImg
                  className="img-job-company"
                  src={require("../../../../assets/img/logoGriyaton.png")}
                  alt="Card image cap"
                />
               
              </Col>
              <Col xs="2" className="mt-4 mt-md-0">
                <div>
                  <center>
                    <div>
                      <CardImg
                        className="img-job-company"
                        src={require("../../../../assets/img/logoPP.png")}
                        alt="Card image cap"
                      />
                    </div>
                  </center>
                </div>
              </Col>
              <Col xs="2" className="mt-4 mt-md-0">
                <div>
                  <center>
                    <div>
                      <CardImg
                        className="img-job-company"
                        src={require("../../../../assets/img/logoFrogs.png")}
                        alt="Card image cap"
                      />
                    </div>
                  </center>
                </div>
              </Col>
              <Col xs="2" className="mt-4 mt-md-0">
                <div>
                  <center>
                    <div>
                      <CardImg
                        className="img-job-company"
                        src={require("../../../../assets/img/logoAlfaBank.png")}
                        alt="Card image cap"
                      />
                    </div>
                  </center>
                </div>
              </Col>
              <Col xs="2" className="mt-4 mt-md-0">
                <div>
                  <center>
                    <div>
                      <CardImg
                        className="img-job-company"
                        src={require("../../../../assets/img/logoUdinus.png")}
                        alt="Card image cap"
                      />
                    </div>
                  </center>
                </div>
              </Col>
              <Col xs="0" sm="1" />
            </Row> */}
          </Container>
        </div>
      </div>

      {/* GAMBAR 5  */}
      <div style={{ minHeight: "90vh" }}>
        <div>
          <p className="title-landingpage3">
            {t('LandingPage.tryAssessment')}
          </p>
          <hr className="line-horizontal" style={{ marginBottom: "35px" }} />{" "}
          <br />
          <p style={{ textAlign: "center", marginBottom: "70px" }}>
            {t('LandingPage.variousAssessment')}
          </p>
          <center>
            <div className="container" style={{ marginBottom: "5%" }}>
              <Row>
                <Col sm="12">
                  <Carousel
                    partialVisible={true}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    arrows={false}
                    responsive={responsive}
                    ssr={true}
                    infinite={true}
                    autoPlaySpeed={2000}
                    autoPlay
                    keyBoardControl={true}
                    transitionDuration={2500}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    renderButtonGroupOutside
                    renderDotsOutside={false}
                  >
                    <Card body className="asesmenCard text-center shadow">
                      <CardTitle
                        tag="h5"
                        style={{ color: "#FCB50C", marginBottom: "45px" }}
                      >
                        {t('LandingPage.personalityTest')}
                      </CardTitle>
                      <CardText
                        className="paddingCard"
                        style={{ height: "150px" }}
                      >
                        {t('LandingPage.personalityTestDesc')}
                      </CardText>
                      <center>
                        <Button
                          size="md"
                          style={{
                            border: "none",
                            backgroundColor: "#fcb50c",
                            color: "white",
                            marginTop: "25%",
                          }}
                          className=" btn-tipe-asesmen"
                          onClick={() => setRedirect("/assessment-disc")}
                        >
                          <b>{t('LandingPage.tryNow')}</b>
                        </Button>
                      </center>
                    </Card>
                    <Card body className="asesmenCard text-center shadow">
                      <CardTitle
                        tag="h5"
                        style={{ color: "#FCB50C", marginBottom: "45px" }}
                      >
                        {t('LandingPage.workStyleTest')}
                      </CardTitle>
                      <CardText
                        className="paddingCard"
                        style={{ height: "150px" }}
                      >
                        {t('LandingPage.workStyleTestDesc')}
                      </CardText>
                      <center>
                        <Button
                          size="md"
                          style={{
                            border: "none",
                            backgroundColor: "#fcb50c",
                            color: "white",
                            marginTop: "25%",
                          }}
                          className=" btn-tipe-asesmen"
                          onClick={() => setRedirect("/assessment-papikostik")}
                        >
                          <b>{t('LandingPage.tryNow')}</b>
                        </Button>
                      </center>
                    </Card>
                    <Card body className="asesmenCard text-center shadow">
                      <CardTitle
                        tag="h5"
                        style={{ color: "#FCB50C", marginBottom: "45px" }}
                      >
                        {t('LandingPage.aptitudeTest')}
                      </CardTitle>
                      <CardText
                        className="paddingCard"
                        style={{ height: "150px" }}
                      >
                        {t('LandingPage.aptitudeTestDesc')}
                      </CardText>
                      <center>
                        <Button
                          size="md"
                          style={{
                            border: "none",
                            backgroundColor: "#fcb50c",
                            color: "white",
                            marginTop: "25%",
                          }}
                          className=" btn-tipe-asesmen"
                          onClick={() => setRedirect("/assessment-mbti")}
                        >
                          <b>{t('LandingPage.tryNow')}</b>
                        </Button>
                      </center>
                    </Card>
                    <Card body className="asesmenCard text-center shadow">
                      <CardTitle
                        tag="h5"
                        style={{ color: "#FCB50C", marginBottom: "45px" }}
                      >
                        {t('LandingPage.biometricTest')}
                      </CardTitle>
                      <CardText
                        className="paddingCard"
                        style={{ height: "150px" }}
                      >
                        {t('LandingPage.biometricTestDesc')}
                      </CardText>
                      <center>
                        <Button
                          size="md"
                          style={{
                            border: "none",
                            backgroundColor: "#fcb50c",
                            color: "white",
                            marginTop: "25%",
                          }}
                          className=" btn-tipe-asesmen"
                          onClick={() => setRedirect("/assessment-fingerprint")}
                        >
                          <b>{t('LandingPage.tryNow')}</b>
                        </Button>
                      </center>
                    </Card>
                    <Card body className="asesmenCard text-center shadow">
                      <CardTitle
                        tag="h5"
                        style={{ color: "#FCB50C", marginBottom: "45px" }}
                      >
                        {t('LandingPage.leadershipTest')}
                      </CardTitle>
                      <CardText
                        className="paddingCard"
                        style={{ height: "150px" }}
                      >
                        {t('LandingPage.leadershipTestDesc')}
                      </CardText>
                      <center>
                        <Button
                          size="md"
                          style={{
                            border: "none",
                            backgroundColor: "#fcb50c",
                            color: "white",
                            marginTop: "25%",
                          }}
                          className=" btn-tipe-asesmen"
                          onClick={() => setRedirect("/assessment-msdt")}
                        >
                          <b>{t('LandingPage.tryNow')}</b>
                        </Button>
                      </center>
                    </Card>
                    <Card body className="asesmenCard text-center shadow">
                      <CardTitle
                        tag="h5"
                        style={{ color: "#FCB50C", marginBottom: "45px" }}
                      >
                        {t('LandingPage.businessInsightTest')}
                      </CardTitle>
                      <CardText
                        className="paddingCard"
                        style={{ height: "150px" }}
                      >
                        {t('LandingPage.businessInsightTestDesc')}
                      </CardText>
                      <center>
                        <Button
                          size="md"
                          style={{
                            border: "none",
                            backgroundColor: "#fcb50c",
                            color: "white",
                            marginTop: "25%",
                          }}
                          className=" btn-tipe-asesmen"
                          onClick={() => setRedirect("/assessment-business-insight")}
                        >
                          <b>{t('LandingPage.tryNow')}</b>
                        </Button>
                      </center>
                    </Card>
                    <Card body className="asesmenCard text-center shadow mr-4">
                      <CardTitle
                        tag="h5"
                        style={{ 
                          color: "#FCB50C", 
                          marginBottom: "45px", 
                         
                        }}
                      >
                        {t('LandingPage.agilityTest')}
                      </CardTitle>
                      <CardText
                        className="paddingCard" 

                        style={{ height: "150px"}}
                      >
                        {t('LandingPage.agilityTestDesc')}
                      </CardText>
                      <center>
                        <Button
                          size="md"
                          style={{
                            border: "none",
                            backgroundColor: "#fcb50c",
                            color: "white",
                            marginTop: "25%",
                          }}
                          className=" btn-tipe-asesmen"
                          onClick={() => setRedirect("/assessment-agility")}
                        >
                          <b>{t('LandingPage.tryNow')}</b>
                        </Button>
                      </center>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
            </div>
          </center>
        </div>
      </div>
    </div>
  );
}

export default connect(({ user }) => ({ user }))(translate(QuoteLandingPage));

// baru
