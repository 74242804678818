import React, { Fragment } from "react";
import ContentPersonalityHeaderLandingPage from '../../../Templates/LandingPage/ContentPersonalityHeaderLandingPage';
import ContentPersonalityListLandingPage from '../../../Templates/LandingPage/ContentPersonalityListLandingPage';
const PersonalityMbti = (props) => {
  return (
    <Fragment>
        <div className="header-landing-page-old">
          <ContentPersonalityHeaderLandingPage />
          <ContentPersonalityListLandingPage /> 
        </div>
    </Fragment>
  )
}
export default PersonalityMbti;