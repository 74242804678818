import React, { useEffect, useState, Fragment } from "react";
import { ListGroup, ListGroupItem, Button, Row, Col } from "reactstrap";
import Color from "../../../../utils/Colors";
import requestGeneral from "../../../../utils/RequestGeneral";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { time_ago } from "../../../../utils/TimeFormat";
import companyLogo from "../../../../assets/img/icons8-company.png";
import noLogoCompany from "../../../../assets/img/company_icon.png";
import LoadingSkeleton from "../../SkeletonLoading/LandingPage/JobPlacement";
import Page from "../../../../components/atoms/Pagination";
import axios from "axios";
import { translate, t } from "react-switch-lang";
const ContentJobPlacement = (props) => {
  const [vacancies, setVacancies] = useState([]);
  const [loading, setLoading] = useState(true);
  const vacanciesPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const getVacancies = async () => {
    try {
      const res = await requestGeneral.get("/internal/recruitment/vacancies");
      const vacancies = res.data.data;
      setVacancies(vacancies);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  function kapital(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const indexOfLastPost = currentPage * vacanciesPerPage;
  const indexOfFirstPost = indexOfLastPost - vacanciesPerPage;
  const currentVacancies = vacancies.slice(indexOfFirstPost, indexOfLastPost);
  const getVacanciesNoLogin = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_DOMAIN}/api/internal/recruitment/vacancies`,
    })
      .then((res) => {
        const vacancies = res.data.data;
        setVacancies(vacancies);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (props.user?.id) {
      getVacancies(); // eslint-disable-next-line react-hooks/exhaustive-deps
    } else {
      getVacanciesNoLogin();
    } // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <h3 className="title-header">
        <span style={{ color: Color.primaryColor, fontSize: "28px" }}>
          {t("Dashboard.jobVacancy")}
        </span>
        <br />
      </h3>
      <br />
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <ListGroup
            id="list-job-placement"
            style={{ width: "100%" }}
            className="list-job-landingPage"
          >
            {currentVacancies.length > 0 ? (
              <div>
                {currentVacancies &&
                  currentVacancies.map((vacancy, id) => {
                    return (
                      <ListGroupItem
                        className="job-placement pt-4 pb-4 mb-2"
                        key={vacancy.id}
                        style={{ borderRadius: "15px" }}
                      >
                        <Row>
                          <Col sm="3 pb-3">
                            {vacancy.company.logo ? (
                              <img
                                src={
                                  vacancy.company.logo ===
                                  "https://hris-dev.widyaskilloka.com"
                                    ? noLogoCompany
                                    : vacancy.company.logo
                                }
                                alt="company-logo"
                                className="rounded"
                                style={{ width: "120px", marginLeft: "20px" }}
                              />
                            ) : (
                              <img
                                src={companyLogo}
                                alt="company-logo"
                                className="rounded"
                                style={{ width: "120px", marginLeft: "20px" }}
                              />
                            )}
                          </Col>
                          <Col sm="9">
                            <Row>
                              <Col lg="9">
                                <h1
                                  className="title-information-company"
                                  style={{ fontSize: "22px" }}
                                >
                                  <b>{vacancy.name}</b>
                                </h1>
                                <h5
                                  className="label-information"
                                  style={{ fontSize: "14px" }}
                                >
                                  {vacancy.company.name} -{" "}
                                  <span style={{ fontSize: "12px" }}>
                                    {t("Lists." + vacancy.type)}
                                  </span>
                                </h5>
                                <h5 style={{ fontSize: "14px" }}>
                                  <i className="fa fa-map-marker"></i>{" "}
                                  {vacancy.province.name
                                    ? kapital(vacancy.city.name)
                                      ? kapital(vacancy.province.name) +
                                        ", " +
                                        kapital(
                                          vacancy.city.name
                                            .replace("KABUPATEN ", "")
                                            .replace("KOTA ", "")
                                        )
                                      : kapital(vacancy.province.name)
                                    : t("JobVacancy.locationNoMention")}
                                </h5>
                              </Col>
                              <Col lg="3" className="job-placement-desc">
                                <h6
                                  className="label-information"
                                  style={{ fontSize: "14px" }}
                                >
                                  <i className="fa fa-clock-o"></i>{" "}
                                  {time_ago(vacancy.modifiedAt)}
                                </h6>
                                <Link
                                  to={`/jobs/${vacancy.id}/${vacancy.name
                                    .toString()
                                    .toLowerCase()
                                    .normalize("NFD")
                                    .trim()
                                    .replace(/\s+/g, "-") // eslint-disable-next-line
                                    .replace(/[^\w\-]+/g, "") // eslint-disable-next-line
                                    .replace(/\-\-+/g, "-")}`}
                                >
                                  <Button
                                    style={{
                                      backgroundColor: Color.primaryColor,
                                      color: Color.white,
                                    }}
                                    className="navtext button-job img-ps"
                                  >
                                    <span style={{ fontSize: "14px" }}>
                                      {t("General.more")}{" "}
                                      <i className="fa fa-arrow-right ml-2" />
                                    </span>
                                  </Button>
                                </Link>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem" }}>
                              <Col lg="5">
                                {props.user?.id ? (
                                  vacancy.showSalary ? (
                                    <h5
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      IDR{" "}
                                      {`${new Intl.NumberFormat([
                                        "ban",
                                        "id",
                                      ]).format(
                                        vacancy.minSalary
                                      )} - ${new Intl.NumberFormat([
                                        "ban",
                                        "id",
                                      ]).format(vacancy.maxSalary)}`}
                                    </h5>
                                  ) : (
                                    <h5
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {props.user?.expectedSalary ? (
                                        props.user?.expectedSalary <
                                        vacancy.minSalary ? (
                                          <>{t("JobVacancy.salaryAbove")}</>
                                        ) : props.user?.expectedSalary >
                                          vacancy.maxSalary ? (
                                          <>{t("JobVacancy.salaryBelow")}</>
                                        ) : (
                                          <>{t("JobVacancy.salaryExpected")}</>
                                        )
                                      ) : (
                                        <>{t("JobVacancy.salaryNoMention")}</>
                                      )}
                                    </h5>
                                  )
                                ) : (
                                  <Link
                                    to="/login"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <b>
                                      <u>{t("JobVacancy.loginToSeeSalary")}</u>
                                    </b>
                                  </Link>
                                )}
                              </Col>
                              <Col lg="4" />
                            </Row>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    );
                  })}
                <Row>
                  <Col sm="12" className="text-right">
                    <Page
                      vacanciesPerPage={vacanciesPerPage}
                      totalPost={vacancies.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <p>{t("JobVacancy.noVacancies")}</p>
            )}
          </ListGroup>
        </>
      )}
      <br />
    </Fragment>
  );
};
export default connect(({ user }) => ({ user }))(
  translate(ContentJobPlacement)
);
