import React from 'react'
import { t } from 'react-switch-lang'

export const SubmitButton = ({ nextPage, btnDisable, section, questions, page, addClass }) => {
  return (
    <div className={`mt-3 mt-md-5 text-center mx-auto ${addClass}`}>
      <button
        className='btn btn-primary vid-submit-button px-md-4 py-md-2'
        onClick={nextPage}
        disabled={btnDisable}
      >
        {section === "asking"
          ? questions[page].lastQuestion
            ? "Submit"
            : t("General.next")
          : t("General.next")}{" "}
        <i className="fa fa-arrow-right ml-2" />
      </button>
    </div>
  )
}