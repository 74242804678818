import React from "react";
import { Row, Modal, ModalBody, Button } from "reactstrap";

const AffirmationModal = (props) => {
  return (
    <Modal isOpen={props.isOpen}>
      <ModalBody>
        <div className="col-12 text-center">
          <h4 className="title-upgrade" style={{ color: "#93aad6" }}>
            {props.title}
          </h4>
        </div>
        <div className="col-12 text-justify">
          <p>{props.desc}</p>
        </div>
        <div className="col-12 text-center">
          <Row>
            <div className="col-12 text-center">
              <Button
                className="mr-2"
                color="netis-color"
                onClick={props.confirmation}
              >
                {props.titleButton}
              </Button>
            </div>
          </Row>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AffirmationModal;
