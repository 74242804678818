import React, { Fragment } from "react";
import ContentPrivacyPolicyLandingPage from '../../../Templates/LandingPage/ContentPrivacyPolicyLandingPage';
import {
    Container,
} from 'reactstrap';
const PrivacyPolicy = () => {
    return (
        <Fragment>
        <div className="header-landing-page-old">
            <Container className="wrap-content">
                <ContentPrivacyPolicyLandingPage />
            </Container>
            </div>
        </Fragment>
    )
}
export default PrivacyPolicy;