import React from 'react'
import {
  Row,
  Col,
  Container,
} from 'reactstrap';
// import Color from '../../../../utils/Colors';
import tentangKami from '../../../../assets/img/Tentang-Kami.png';
import {translate} from "react-switch-lang";
export default translate(function ContentPersonalityABoutLandingPage(props) {
  const {t} = props
  return (
    <div>
      <Container style={{ padding: "120px 0 90px 10px" }}>
        <Row>
          <Col sm="8">
            <h2 style={{ fontWeight: "700", marginBottom: "2rem", color: "#335877" }}>
             {t('LandingPage.didYouKnow')}
              </h2>
            <p>
              {t('LandingPage.didYouKnowPoint1Sub1')} <i> {t('LandingPage.didYouKnowPoint1Sub2')}</i> {t('LandingPage.didYouKnowPoint1Sub3')}
              <br />
              <br />
              <b style={{ color: "#335877" }}>{t('LandingPage.whatIsAptitudeTest')}</b>
              <br />
              {t('LandingPage.didYouKnowPoint2')}
              <br />
              <br />
              <b style={{ color: "#335877" }}>{t('LandingPage.whyItsImportant')}</b>
              <br />
              {t('LandingPage.didYouKnowPoint3')}
            </p>
          </Col>
          <Col sm="4">
            <img src={tentangKami} style={{ width: '80%' }} alt="kembangkan" />

          </Col>
        </Row>
      </Container>
      </div>
      )
})