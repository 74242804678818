import React, { Fragment } from 'react'
import {
  Row,
  Col,
} from 'reactstrap';
import Color from '../../../../utils/Colors';
import { Link } from 'react-router-dom';
export default function ContentPrivacyPolicyLandingPage() {
  return (
    <Fragment>
      <Row>
        <Col sm="12" className="text-justify">
          <h2 className="title-header">
            <span style={{ color: Color.primaryColor }}>Kebijakan Privasi</span><br />
          </h2>
          <br />
          <p className="text-justify">
            Kebijakan privasi ini memberitahu bagaimana cara PT Widya Indonesia Sejahtera mengelola
            dan menjaga data yang dimiliki oleh <i>user</i> atau pengguna layanan Skillana.
            <br />
            <ol className="none-list mt-3">
              <li>
                <b>1. Definisi</b>
                <ol type="a" className="sub-lists mt-3">
                  <li >
                    PT Widya Indonesia Sejahtera atau Skillana atau kami adalah perusahaan teknologi informasi yang mengembangkan layanan untuk menemukan dan memahami potensi dalam hal ini berperan sebagai penyedia layanan.
                  </li>
                  <li>
                    Layanan Skillana adalah sebagai <i>platform</i> analisa minat dan bakat untuk mengenali kepribadian dan menemukan potensi terbaik.
                  </li>
                  <li>
                    Portal <i>website</i> merupakan <i>website</i> Skillana yang dapat diakses melalui <u><Link to={{ pathname: "https://skillana.id" }} target="_blank"> www.skillana.id </Link></u>
                  </li>
                  <li>
                    <i>Mobile apps</i> merupakan aplikasi Skillana berbasis Android yang dapat diunduh pada <i>Play Store.</i>
                  </li>
                  <li>
                    <i>User</i> merupakan bagian yang terdiri namun tidak terbatas pada <i>user</i> yang menggunakan layanan Skillana.
                  </li>
                  <li>
                    Informasi pribadi atau data pribadi merupakan informasi mengenai <i>user</i> yang dapat diidentifikasi dan dikumpulkan melalui portal <i>website</i> yang dapat mengidentifikasi <i>user</i> yang sedang menggunakan <i>mobile apps</i>.
                  </li>
                </ol>
              </li>
            </ol>
            <br />
            <ol className="none-list mt-3">
              <li>
                <b>2. Pengolahan Data</b>
                <ol type="a" className="sub-lists mt-3">
                  <li >
                    Kami dapat mengolah, menganalisis, dan/atau menggunakan data pribadi yang kami dapatkan tentang <i>user</i> untuk tujuan sebagai berikut maupun tujuan lain yang diizinkan oleh peraturan perundang-undangan yang berlaku, diantaranya:
                    <ol type="i" className="sub-lists">
                      <li>Mengelola data <i>user.</i> </li>
                      <li> Mengelola data asesmen.</li>
                    </ol>
                  </li>
                  <li>
                    Adapun batasan pengelolaan data tersebut adalah:
                    <ol type="1" className="sub-lists">
                      <li>Skillana hanya akan memberikan akses terhadap informasi pribadi pengguna kepada <i>user</i> yang bersangkutan. </li>
                      <li>Skillana tidak akan menggunakan data tersebut untuk hal lain, selain untuk kepentingan asesmen pribadi.</li>
                      <li>Skillana tidak akan membagi, mengungkapkan dan/atau menyebarluaskan data pribadi tersebut kepada pihak ketiga manapun.</li>
                    </ol>
                  </li>
                  <li>
                    Skillana menjamin tidak ada penjualan, pengalihan, distribusi atau meminjamkan Informasi/Data Pribadi <i>user</i> atau pengguna kepada pihak ketiga, tanpa sepengetahuan dan seizin tertulis dari <i>user</i> atau pengguna.
                  </li>
                  <li>
                    Poin c di atas tidak berlaku dalam hal Skillana berkewajiban mengungkapkan dan/atau berbagi Data Pribadi <i>user</i> dalam upaya mematuhi kewajiban hukum yang berlaku.
                  </li>
                </ol>
              </li>
            </ol>
            <br />
            <ol className="none-list mt-3">
              <li>
                <b>III. Portal <i>Website</i></b>
                <ol type="a" className="sub-lists mt-3">
                  <li >
                    Portal <i>website</i> Skillana dilindungi oleh Hak kekayaan intelektual yang dimiliki oleh Skillana termasuk namun tidak terbatas pada Hak cipta dan merek dagang baik yang terdaftar maupun tidak.
                  </li>
                  <li>
                    Tindakan hukum akan dilakukan apabila ditemui tindakan percobaan, baik yang disengaja atau tidak disengaja, untuk mengubah atau merusak portal <i>website</i> Skillana dan/atau perangkat <i>server</i> yang termuat di dalamnya, tanpa izin tertulis yang diberikan oleh pengelola resmi dan sah Skillana.
                  </li>
                </ol>
              </li>
            </ol>
            <br />
            <ol className="none-list mt-3">
              <li>
                <b>IV. <i>Mobile Apps</i></b>
                <ol type="a" className="sub-lists mt-3">
                  <li >
                    Skillana dapat diakses melalui <i>mobile</i> ketika <i>user</i> telah menginstal melalui perangkat pribadi.
                  </li>
                  <li>
                    <i>User</i> dapat melakukan asesmen melalui <i>mobile</i> dan mendapatkan hasil asesmen yang telah dilakukan dari layanan <i>mobile apps</i> Skillana.
                  </li>
                </ol>
              </li>
            </ol>
            <br />
            <ol className="none-list mt-3">
              <li>
                <b>V. Penggunaan <i>Cookies</i></b>
                <br />
                <br />
                <p style={{ lineHeight: '2.1em' }}>
                  <i>Cookies</i> adalah teknologi yang digunakan untuk menyimpan potongan-potongan kecil informasi pada <i>hard drive user</i> atau pengguna untuk <i>browser</i>. Beberapa layanan <i>web browser</i> secara otomatis menerima <i>cookies</i>, tetapi <i>user</i> memiliki opsi untuk menerima atau menolak <i>cookies</i> dan tahu kapan <i>cookies</i> dikirim ke perangkat <i>User</i> atau Pengguna.
                </p>
              </li>
            </ol>
            <br />
            <ol className="none-list mt-3">
              <li>
                <b>VI. Keamanan</b>
                <br />
                <br />
                <p style={{ lineHeight: '2.1em' }}>
                  Kami menghargai kepercayaan <i>user</i> dalam memberikan informasi pribadi <i>user</i> kepada kami, sehingga kami berupaya untuk menggunakan cara yang dapat diterima secara komersial untuk melindunginya. Namun tidak ada metode transmisi melalui internet, atau metode penyimpanan elektronik yang 100% aman dan handal, sehingga kami tidak dapat menjamin keamanan absolutnya.
                </p>
              </li>
            </ol>
            <br />
            <ol className="none-list mt-3">
              <li>
                <b>VII. Perubahan Kebijakan Privasi</b>
                <br />
                <br />
                <p style={{ lineHeight: '2.1em' }}>
                  Kami dapat memperbarui kebijakan privasi kami dari waktu ke waktu. Maka dari itu, <i>user</i> disarankan untuk meninjau halaman ini secara berkala untuk setiap perubahan. Kami akan memberi tahu <i>user</i> tentang segala perubahan dengan memposting kebijakan privasi baru di halaman ini.
                </p>
              </li>
            </ol>
            <br />
            Melalui penggunaan Skillana, user atau pengguna mengakui bahwa user atau pengguna telah membaca, memahami, dan menyetujui kebijakan privasi ini.
          </p>
        </Col>
      </Row>
      </Fragment>
      )
}