import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Form,
  Label,
  FormGroup,
  CardBody,
  Input,
} from "reactstrap";
import { translate, t } from "react-switch-lang";
import request from "../../../../../utils/Request";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
import { useResultTestContext } from "../../Menu/AgilityTestContext";
import { toast } from "react-toastify";
import "./sacit.scss";
import moment from "../../../../../utils/Moment";
toast.configure();

function Sacit(props) {
  // eslint-disable-next-line
  const [result, setResult] = useResultTestContext({});
  const [answers, setAnswers] = useState({});
  const [answersNow, setAnswersNow] = useState({});
  const [time, setTime] = useState({});
  const [startTime, setStartTime] = useState(props.startTime);
  const [error, setError] = useState(false);
  const [notCompleteAnswer, setNotCompleteAnswer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [page, setPage] = useState(0);
  const [pageTitle, setPageTitle] = useState(1);
  const [completed, setCompleted] = useState(false);
  useEffect(() => {
    request
      .get("assessment/test/agility/sacit")
      .then((res) => {
        if (res.data?.data) {
          setLoading(false);
          setQuestions(res.data.data);
        }
      })
      .catch((err) => {
        setError(true);
      });
  }, []);

  // const groupedQuestions = arrayChunk(questions[page], 1)

  function isError() {
    if (error) {
      return (
        <AffirmationModal
          isOpen={error}
          title={"Error !"}
          desc={t("Agility.errorConnection")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setError(true);
          }}
          confirmation={() => {
            window.location.reload();
            setError(false);
          }}
        />
      );
    }
  }

  function isNotComplete() {
    if (notCompleteAnswer) {
      return (
        <AffirmationModal
          isOpen={notCompleteAnswer}
          title={"Oops !"}
          desc={t("Agility.notComplete")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setNotCompleteAnswer(true);
          }}
          confirmation={() => {
            setNotCompleteAnswer(false);
          }}
        />
      );
    }
  }
  function isComplete() {
    if (completed) {
      return (
        <AffirmationModal
          isOpen={completed}
          title={"Success !"}
          desc={t("Agility.completeSacit")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setCompleted(true);
          }}
          confirmation={() => {
            let totalTime = 0;
            Object.values(time).map((data) => {
              totalTime = totalTime + parseInt(data);
              return true;
            });
            setResult((result) => ({
              ...result,
              sacit: { answers, time, totalTime, step: props.page },
            }));
            window.scroll({ top: 0, behavior: "smooth" });
            props.setNextPage(false);
            toast.success("Success", {
              autoClose: 3000,
            });
          }}
        />
      );
    }
  }

  useEffect(() => {
    // eslint-disable-next-line
    if (Object.values(time).length > 0) {
      setStartTime(moment().format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [time]);

  function onAnswered(code, group) {
    setAnswersNow({ ...answersNow, [group]: code });
  }

  function nextPage() {
    window.scroll({ top: 0, behavior: "smooth" });
    if (answersNow["group1"] && answersNow["group2"]) {
      setTime((state) => ({
        ...state,
        [pageTitle]: moment(new Date(), "YYYY-MM-DD HH:mm:ss").diff(
          startTime,
          "seconds"
        ),
      }));
      if (questions[page].lastQuestion) {
        setAnswers({
          ...answers,
          [pageTitle]: [answersNow["group1"], answersNow["group2"]],
        });
        setCompleted(true);
      } else {
        setAnswers({
          ...answers,
          [pageTitle]: [answersNow["group1"], answersNow["group2"]],
        });
        setPage(page + 1);
        setPageTitle(pageTitle + 1);
      }
    } else {
      setNotCompleteAnswer(true);
    }
  }

  useEffect(() => {
    if (page > 0) {
      setAnswersNow({});
    }
    // eslint-disable-next-line
  }, [answers]);

  return (
    <Container>
      {isError()}
      {isNotComplete()}
      {isComplete()}
      <Row>
        <Col xs="0" md="1"></Col>
        <Col xs="12" md="10" className=" mt-4 mb-4">
          <h3>
            <b>
              {props.title} ( {pageTitle} / 5 )
            </b>
          </h3>
          <div
            className=" p-md-5 p-3"
            style={{
              boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
            }}
          >
            <Form>
              <Row>
                {loading ? (
                  <Col sm="12" className="text-center">
                    <Spinner size="lg" />
                  </Col>
                ) : questions ? (
                  questions[page] ? (
                    <>
                      <Col sm="12" className="mb-3">
                        <Container>
                          <FormGroup>
                            <p>{questions[page].question}</p>
                            <br />
                            <div className="d-flex flex-column justify-content-center">
                              <div
                                className="card p-3 border-0 shadow"
                                style={{ borderRadius: "12px" }}
                              >
                                {questions[page].options.map((data, idx) => (
                                  <>
                                    {data.group === "group1" ? (
                                      <Label
                                        className="card-body p-0 col-md-12 cursor-pointer"
                                        onClick={
                                          answersNow[data.group] === data.code
                                            ? null
                                            : () =>
                                                onAnswered(
                                                  data.code,
                                                  data.group
                                                )
                                        }
                                        key={idx}
                                      >
                                        <CardBody className="d-flex answers-group1">
                                          <Input
                                            type="radio"
                                            name={data.group}
                                            // value={data.code}
                                            className="mr-2"
                                            group={data.group}
                                            page={page}
                                          />
                                          {data.text}
                                        </CardBody>
                                      </Label>
                                    ) : null}
                                  </>
                                ))}
                              </div>
                              <div
                                className="card p-3 border-0 shadow"
                                style={{ borderRadius: "12px" }}
                              >
                                {questions[page].options.map((data, idx) => (
                                  <>
                                    {data.group === "group2" ? (
                                      <Label
                                        className="card-body p-0 col-md-12  cursor-pointer"
                                        onClick={
                                          answersNow[data.group] === data.code
                                            ? null
                                            : () =>
                                                onAnswered(
                                                  data.code,
                                                  data.group
                                                )
                                        }
                                        key={idx}
                                      >
                                        <CardBody className="d-flex answers-group2">
                                          <Input
                                            type="radio"
                                            name={data.group}
                                            // value={data.code}
                                            className="mr-2"
                                            group={data.group}
                                            page={page}
                                          />
                                          {data.text}
                                        </CardBody>
                                      </Label>
                                    ) : null}
                                  </>
                                ))}
                              </div>
                            </div>
                            <Col sm="12" className="mt-4 text-center">
                              <Button
                                color="primary"
                                type="reset"
                                className="btn btn-lg btn-primary"
                                onClick={nextPage}
                                disabled={
                                  !answersNow["group1"] || !answersNow["group2"]
                                }
                              >
                                {questions[page].lastQuestion
                                  ? "Submit"
                                  : t("General.next")}
                                <i className="fa fa-arrow-right ml-2"></i>
                              </Button>
                            </Col>
                          </FormGroup>
                        </Container>
                      </Col>
                    </>
                  ) : null
                ) : null}
              </Row>
            </Form>
          </div>
        </Col>
        <Col xs="0" md="1"></Col>
      </Row>
    </Container>
  );
}

export default translate(Sacit);
