import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Container, Spinner } from "reactstrap";
import request from "../../../../utils/Request";
import Colors from "../../../../utils/Colors";
import NoData from "../../../../assets/img/481.png";
import NoDataEn from "../../../../assets/img/no_dataEn.png";
import { Link } from "react-router-dom";
import { translate, t } from "react-switch-lang";
import langUtils from "../../../../utils/SettingLanguage/index";
export default translate(function ContentPersonalityDetailLandingPage(props) {
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [quotes, setQuotes] = useState("");
  const [desc, setDesc] = useState("");
  const [avaliable, setAvaliable] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getAssesment = async () => {
      try {
        const res = await request.get(
          "/assessment_mbti?type=" +
            props.props.match.params.id +
            "&lang=" +
            langUtils.getLanguage().toLocaleLowerCase()
        );
        setType(res.data.data.type);
        setName(res.data.data.name);
        setNickname(res.data.data.nickname);
        setQuotes(res.data.data.quotes);
        setDesc(res.data.data.desc);
        setAvaliable(true);
      } catch (err) {
        setAvaliable(false);
      } finally {
        setLoading(false);
      }
    };

    getAssesment();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <div className="header-landing-page-old">
        {loading ? (
          <>
            <Fragment>
              <Row>
                <Col sm="12" className="text-center pt-5">
                  <h1>
                    {" "}
                    <Spinner size="lg" className="mr-2" /> Loading
                  </h1>
                </Col>
              </Row>
            </Fragment>
          </>
        ) : (
          <Container className="wrap-content">
            {avaliable ? (
              <Row>
                <Col sm="2"></Col>
                <Col sm="8" className="text-center">
                  <h1
                    style={{
                      color: Colors.darkBlue,
                      textTransform: "uppercase",
                    }}
                  >
                    <b>{type}</b>
                  </h1>
                  <h5 style={{ color: Colors.yellowColor }}>
                    <b>{name}</b>
                  </h5>
                  <br />
                  <img
                    src={require("../../../../assets/img/16personalities/" +
                      props.props.match.params.id +
                      ".png")}
                    alt={type}
                    className="img-mbti"
                  />
                  <br />
                  <br />
                  <h4
                    style={{
                      color: Colors.yellowColor,
                      textTransform: "uppercase",
                    }}
                  >
                    <b>
                      <i>{nickname}</i>
                    </b>
                  </h4>
                  <p>{quotes}</p>
                  <br />
                  <br />
                  <h5
                    style={{
                      color: Colors.darkBlue,
                      textTransform: "uppercase",
                    }}
                    className="mt-3"
                  >
                    <b> {t("personalityTypes.shortDescription")}</b>
                  </h5>
                  <p>{desc}</p>
                </Col>
                <Col sm="2"></Col>
              </Row>
            ) : (
              <Row>
                <Col sm="12" className="text-center">
                  {langUtils.getLanguage() === "ID" ? (
                    <img src={NoData} alt="no-data" />
                  ) : (
                    <img src={NoDataEn} alt="no-data-en" />
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col sm="12" className="text-center">
                <Link to="/type-personality">
                  <button className="btn btn-netis-color">
                    <i className="fa fa-arrow-left mr-2"></i>
                    {t("personalityTypes.btnBack")}
                  </button>
                </Link>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </Fragment>
  );
});
