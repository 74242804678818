import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import { Link } from "react-router-dom";
const ModalWhenMobile = (props) => {
  const goToLink = (url) => {
    // window.location.replace(url);
    window.open(url, "_blank");
  };
  return (
    <Modal isOpen={props.isOpen}>
      <ModalBody>
        <div className="col-12 text-center">
          <h4 className="title-upgrade" style={{ color: "#93aad6" }}>
            {props.title}
          </h4>
        </div>
        <div className="col-12 text-justify">
          <p>{props.desc}</p>
          <Link
            onClick={() =>
              goToLink(
                "https://play.google.com/store/apps/details?id=com.widyaskilloka.skillana"
              )
            }
          >
            <img
              src={require("../../../../assets/img/playstore-image.png")}
              className="img-ps"
              alt="skillana-playstore"
              style={{ width: "120px" }}
            />
          </Link>
          <Link
            onClick={() =>
              goToLink("https://apps.apple.com/id/app/skillana/id1546184278")
            }
          >
            <img
              src={require("../../../../assets/img/app store.png")}
              className="img-ps ml-3"
              alt="skillana-playstore"
              style={{ width: "120px" }}
            />
          </Link>
        </div>
        <div className="col-12 text-center mt-3">
          <Button
            className="mr-2"
            color="netis-color"
            onClick={props.confirmation}
          >
            <i className="fa fa-arrow-left mr-3"></i>
            {props.titleButton}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalWhenMobile;
