import React, { useState } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import Color from "../../../../utils/Colors";
import { Redirect } from "react-router";
import { translate, t } from "react-switch-lang";
export default translate(function ContentPersonalityHeaderLandingPage() {
  const [redirect, setRedirect] = useState(false);
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <div>
      <Container className="wrap-content">
        <Button
          onClick={() => setRedirect("/type-asesmen")}
          className="navtext btn btn-sm btn-netis-color mb-3"
        >
          <i className="fa fa-arrow-left mr-1" />
          {t("personalityTypes.btnBack")}
        </Button>
        <Row className="mt-2">
          <Col sm="4">
            <img
              src={require("../../../../assets/img/Kembangkan2.svg")}
              style={{ width: "100%" }}
              alt="kembangkan"
            />
          </Col>
          <Col sm="8">
            <h2 className="title-header">
              <span style={{ color: Color.primaryColor }}>
                {t("personalityTypes.personalityTestTitle")}
              </span>
              <br />
              <span style={{ color: Color.yellowColor }} className="tagline">
                {t("LandingPage.startYourself")}
              </span>
            </h2>
            <br />
            <p className="text-justify">
              <b>{t("personalityTypes.16PersonalityTest")}</b>{" "}
              {t("personalityTypes.16PersonalityTestDesc")}
            </p>
            <ul>
              <li>{t("personalityTypes.16PersonalityTestDescPoint1")}</li>
              <li>{t("personalityTypes.16PersonalityTestDescPoint2")}</li>
              <li>{t("personalityTypes.16PersonalityTestDescPoint3")}</li>
              <li>{t("personalityTypes.16PersonalityTestDescPoint4")}</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
});
