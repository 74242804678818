import React, { Fragment } from "react";
import Webcam from "react-webcam";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import { t } from "react-switch-lang";
export default function PhotoWebcamFisiognomi(props) {
  return (
    <Fragment>
      <Webcam
        ref={props.webcamRefPhoto}
        screenshotFormat="image/jpeg"
        forceScreenshotSourceSize={true}
        mirrored={true}
        style={{ height: "100vh", width: "100vw" }}
        videoConstraints={props.videoConstraints}
      />
      <div className="box-control">
        <Fragment>
          {props.capturing ? null : (
            <>
              <Spinner type="grow" color="light" className="icon-spinner" />
              <UncontrolledTooltip placement="top" target="photo">
                {t("Interview.buttonPhoto")}
              </UncontrolledTooltip>
              <img
                id="photo"
                src={require("../../../../assets/img/icon-camere.png")}
                alt="play"
                onClick={props.takePicture}
                style={{ width: 70 }}
                className="icon-video-single"
              />
            </>
          )}
        </Fragment>
      </div>
    </Fragment>
  );
}
