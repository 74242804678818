import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  CardBody,
  Collapse,
  Container,
  Button,
  Alert,
} from "reactstrap";
import LoadingAnimation from "../../../components/atoms/LoadingAnimation";
import { useParams } from "react-router-dom";
import request from "../../../utils/Request";
import { translate, t } from "react-switch-lang";
function ShareableFingerprint() {
  const { resultCodes } = useParams();
  const [result, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    request
      .get("/assessment/test/fingerprint/share/" + resultCodes)
      .then((res) => {
        setResults(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [collapse, setCollapse] = useState(null);
  const toggleResult = (value) => {
    setCollapse(collapse === Number(value) ? null : Number(value));
  };
  return (
    <>
      <Container>
        {loading ? (
          <LoadingAnimation />
        ) : result.length !== 0 ? (
          <>
            <div
              className="row justify-content-center"
              style={{ paddingTop: "2rem" }}
            >
              <Col sm="4"></Col>
              <Col sm="4"></Col>
              <Col sm="4"></Col>
            </div>
            <div className="row text-center">
              <div className="col-sm-12">
                <br />
                <br />
                <br />
                <img
                  src={require("../../../assets/img/finger/result.png")}
                  alt="fingerprint-result"
                  style={{ width: "300px" }}
                />
                <br />
                <br />
                <br />
                <br />
                <br />
                <h3 className="text-center">
                  <b>{t("Assessment.biometric")}</b>
                </h3>
                <br />
              </div>
              <br />
              <div className="row">
                {result &&
                  result.data.map((objKategori, index) => {
                    return (
                      <>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-8 text-justify mb-4">
                          <Button
                            key={index}
                            onClick={() => toggleResult(index)}
                            data-event={index}
                            style={{
                              backgroundColor: "#E6F2FD",
                              paddingTop: 20,
                              width: "100%",
                              paddingBottom: 20,
                            }}
                          >
                            <Row>
                              <Col>
                                <h5
                                  style={{
                                    margin: 0,
                                    color: "#1B568D",
                                  }}
                                  className="text-left"
                                >
                                  <b>{objKategori.title}</b>
                                </h5>
                              </Col>
                              <Col className="text-right">
                                <i
                                  className="fa fa-angle-down"
                                  style={{
                                    fontSize: 20,
                                    color: "#1B568D",
                                    fontWeight: "bold",
                                    marginRight: 30,
                                  }}
                                ></i>
                              </Col>
                            </Row>
                          </Button>
                          <Collapse isOpen={collapse === index}>
                            <Card>
                              <CardBody className="navtext text-left">
                                <ul>
                                  {objKategori &&
                                    objKategori.list.map((objItem, idx) => {
                                      return (
                                        <li className="mb-2">{objItem}</li>
                                      );
                                    })}
                                </ul>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </div>
                        <div className="col-sm-2"></div>
                      </>
                    );
                  })}
              </div>
            </div>
          </>
        ) : (
          <Alert color="danger" className="text-center mt-3 mb-4">
            <h5 className="content-title mb-2">{t("Assessment.noData")}</h5>
          </Alert>
        )}
      </Container>
    </>
  );
}

export default translate(ShareableFingerprint);
