export default {
    primaryColor: '#1B568D',
    white: '#ffffff',
    lightGrey: '#edf2f5',
    red: '#d45142',
    black: '#555',
    redColor: '#FE2A55',
    yellowColor: '#FFA833',
    darkYellow: '#f6b532',
    darkOrange: '#F57844',
    darkBlue: '#283756'
};
