import React, { Fragment } from "react";
import {Container} from 'reactstrap';
import ContentArtikelLandingPage from "../../../Templates/LandingPage/ContentArtikelLandingPage";
const Artikel = (props) => {
  return (
    <Fragment>
      <div className="header-landing-page-old">
        <Container className="wrap-content">
          <ContentArtikelLandingPage />
        </Container>
      </div>
    </Fragment>
  );
};
export default Artikel;