import React, { Fragment } from "react";
import ContentJobPlacement from '../../../Templates/LandingPage/ContentJobPlacement';
import {
    Container,
} from 'reactstrap';
const JobPlacement = () => {
    return (
        <Fragment>
            <div className="header-landing-page-old">
                <Container className="wrap-content">
                    <ContentJobPlacement />
                </Container>
            </div>
        </Fragment>
    )
}
export default JobPlacement;