import React from "react";
import { Label, Input, FormGroup } from "reactstrap";
import ReactMarkdown from "react-markdown";
export default function Question(props) {
  const {
    code,
    onAnswered,
    checked,
    a,
    b,
    idx,
    refs,
    red,
    // answer = {}
  } = props;

  function changeOption(e) {
    onAnswered(code, e.target.value);
  }

  return (
    <div
      idx={idx}
      className={
        red === idx
          ? "card item-test mx-auto shadow-red border-0"
          : "card item-test mx-auto shadow border-0"
      }
      ref={(ref) => {
        refs.current[idx] = ref;
      }}
    >
      <div className="card-body">
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              name={code}
              value="a"
              onChange={changeOption}
              checked={checked === "a"}
            />
            &nbsp;
            {
              <span style={{ fontSize: "1.1em" }}>
                <ReactMarkdown source={a} renderers={{ paragraph: "span" }} />
              </span>
            }
          </Label>
        </FormGroup>
        <br />
        <FormGroup check>
          <Label check>
            <Input
              type="radio"
              name={code}
              value="b"
              onChange={changeOption}
              checked={checked === "b"}
            />
            &nbsp;
            {
              <span style={{ fontSize: "1.1em" }}>
                <ReactMarkdown source={b} renderers={{ paragraph: "span" }} />
              </span>
            }
          </Label>
        </FormGroup>
      </div>
    </div>
  );
}
