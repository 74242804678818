import React from "react";
import { connect } from "react-redux";
import { translate } from "react-switch-lang";
import { RibbonContainer } from "react-ribbons";
import {
  Row,
  Col,
  CardDeck,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import { Link } from "react-router-dom";
import Colors from "../../../../utils/Colors";
const Internal = (props) => {
  const { t } = props;
  const requirements = props.requirement
  const menu = [
    {
      name: "profile",
      title: t("Dashboard.myprofile"),
      desc: t("Dashboard.descMyProfile"),
      image: "profile.png",
      link: "/profile",
      marginTop: "-19px",
      step: "step1-home",
      internal: true,
    },
    {
      name: "assessment",
      title: t("Dashboard.myassessment"),
      desc: t("Dashboard.descMyAssessment"),
      image: "asesmenMbti.png",
      link: "/assessment",
      marginTop: "0px",
      step: "step2-home",
      internal: true,
    },
    {
      name: "video-cv",
      title: t("Dashboard.myVideoCv"),
      desc: t("Dashboard.descMyVideoCv"),
      image: "video.png",
      link: "/interview-online",
      marginTop: "0px",
      step: "step4-home",
      internal:
        props?.requirement?.filter((item) =>
          item?.toLowerCase().includes("video")
        ).length > 0,
    },
  ];


  const hasAssessment = 
    requirements.includes("msdt") ||
    requirements.includes("mbti") ||
    requirements.includes("spm") ||
    requirements.includes("papikostick") ||
    requirements.includes("disc") ||
    requirements.includes("business-insight") ||
    requirements.includes("agility")


  return (
    <CardDeck className="all-menu justify-content-center">
      <div style={{ display: "inherit" }}>
        <Row className="justify-content-center">
          {hasAssessment 
          ? menu?.map((data, idx) => {
              if (data?.internal) {
                return (
                  <InternalCardContainer
                  req={requirements}
                  data={data}
                  idx={idx}
                  step={data.step}
                  image={data.image}
                  link={data.link}
                  title={data.title}
                  desc={data.desc}
                  />
                );
              }
            })
          : menu?.filter(data => data.name !== "assessment")?.map((data, idx) => {
              if (data?.internal) {
                return (
                <InternalCardContainer
                  req={requirements}
                  data={data}
                  idx={idx}
                  step={data.step}
                  image={data.image}
                  link={data.link}
                  title={data.title}
                  desc={data.desc}
                />
                );
              }
            })
          }
        </Row>
      </div>
    </CardDeck>
  );
};


export function InternalCardContainer(props) {

  const data = props.data
  const step = props.step
  const image = props.image
  const idx = props.idx
  const link = props.link
  const title = props.title
  const desc = props.desc
  const requirement = props.req

  return (
    <Col
    lg={requirement.length === 1 
        ? requirement.includes("profile") 
          ? 6 
          : 4 
        : 4}
    md={6}
    sm={6}
    xs={6}
    className={"p-0 mt-4 mb-3 " + step}
    key={idx}
  >
    <Link
      to={link}
      className="card menu-item h-100 pb-2 ml-2"
      style={{ width: "90%" }}
    >
      <RibbonContainer className="custom-class">
        <CardImg
          top
          width="86%"
          src={require("../../../../assets/img/" + image)}
          alt="Card image cap"
          className="p-3"
        />

        <CardBody>
          <CardTitle
            className="mb-2 title-menu-company menu-title"
            style={{
              color: Colors.primaryColor,
              marginTop: data.marginTop,
            }}
          >
            <h4
              className="font-weight-bold"
              style={{ fontSize: "18px" }}
            >
              {title}
            </h4>
          </CardTitle>
          <CardText
            className="navtext d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none"
            style={{ fontSize: "13px" }}
          >
            {desc}
          </CardText>
        </CardBody>
      </RibbonContainer>
    </Link>
  </Col>
  )
}

export default connect(({ user }) => ({ user }))(translate(Internal));
