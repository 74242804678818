import React, { useState } from "react";
import UserGuide from "../UserGuide";
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { translate, t } from "react-switch-lang";
function PrivacyAggreement() {
  const [nextPage, setNextPage] = useState(false);
  const [checkBox1, setCheckBox1] = useState(true);
  const [checkBox2, setCheckBox2] = useState(true);
  const [checkBox3, setCheckBox3] = useState(true);
  if (nextPage) {
    return <UserGuide />;
  }
  const handleCheckBox = (e, id) => {
    if (e.target.checked === true) {
      if (id === "1") {
        setCheckBox1(false);
      } else if (id === "2") {
        setCheckBox2(false);
      } else if (id === "3") {
        setCheckBox3(false);
      }
    } else {
      if (id === "1") {
        setCheckBox1(true);
      } else if (id === "2") {
        setCheckBox2(true);
      } else if (id === "3") {
        setCheckBox3(true);
      }
    }
  };

  return (
    <Container>
      <Row>
        <Col sm="1"></Col>
        <Col
          sm="10 mt-4 mb-4"
          style={{
            boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
          }}
        >
          <h6 className="mt-4">{t("General.agreement")}</h6>
          <h2>
            <b>{t("General.confidentiality")}</b>
          </h2>
          <br />
          <hr />
          <br />
          <div
            style={{
              overflow: "scroll",
              width: "100%",
              height: "350px",
              overflowY: "scroll",
            }}
          >
            <div className="container">
              <h4
                style={{
                  textAlign: "center",
                  marginTop: "10px",
                  marginBottom: "30px",
                }}
              >
                <b>{t('Biometric.privacyPolicyConcent')}</b>
              </h4>
              <h4>
                <div>{t('Biometric.privacyFeaturesBiometric')}</div>
              </h4>
              <br />
              <p>
              {t('Biometric.privacyPolicy')}
              </p>
              <ol>
                <li>
                  <p>
                  {t('Biometric.definition')}
                    <ol type="a" className="sub-lists mt-3">
                      <li>
                      {t('Biometric.definitionPoint1')}
                      </li>
                      <li>
                      {t('Biometric.definitionPoint2')}
                      </li>
                      <li>
                      {t('Biometric.definitionPoint3')}{" "}
                        <u>
                          <Link
                            to={{ pathname: "https://skillana.id" }}
                            target="_blank"
                          >
                            {" "}
                            www.skillana.id{" "}
                          </Link>
                        </u>
                      </li>
                      <li>
                      {t('Biometric.definitionPoint4')}
                      </li>
                      <li>
                      {t('Biometric.definitionPoint5')}
                      </li>
                      <li>
                      {t('Biometric.definitionPoint6')}
                      </li>
                    </ol>
                  </p>
                </li>
                <li>
                  <p className="mt-3">
                  {t('Biometric.dataProcessing')}
                    <ol type="a" className="sub-lists mt-3">
                      <li>
                        <p>
                        {t('Biometric.dataProcessingPoint1')}{" "}
                        </p>
                        <ol type="i" className="sub-lists mt-3">
                          <li>{t('Biometric.dataProcessingPoint1Sub1')}</li>
                          <li>{t('Biometric.dataProcessingPoint1Sub2')}</li>
                        </ol>
                      </li>
                      <li>
                        <p>{t('Biometric.dataProcessingPoint2')} </p>
                        <ol type="i" className="sub-lists mt-3">
                          <li>
                          {t('Biometric.dataProcessingPoint2Sub1')}
                          </li>
                          <li>
                          {t('Biometric.dataProcessingPoint2Sub2')}
                          </li>
                          <li>
                          {t('Biometric.dataProcessingPoint2Sub3')}
                          </li>
                        </ol>
                      </li>
                      <li>
                        <p>
                        {t('Biometric.dataProcessingPoint3')}{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                        {t('Biometric.dataProcessingPoint4')}{" "}
                        </p>
                      </li>
                    </ol>
                  </p>
                </li>
                <li>
                  <p className="mt-3  ">
                  {t('Biometric.biometricTestDesc')} <br></br>
                    <ol type="a" className="sub-lists mt-3">
                      <li>
                      {t('Biometric.biometricTestDescPoint1')}
                      </li>
                      <li>
                      {t('Biometric.biometricTestDescPoint2')}
                      </li>
                      <li>
                      {t('Biometric.biometricTestDescPoint3')}
                      </li>
                      <li>
                      {t('Biometric.biometricTestDescPoint4')}
                      </li>
                    </ol>
                  </p>
                </li>
              </ol>
            </div>
            <br />
            <br />
          </div>
          <div className=" mt-5 mb-4">
            <p className="mt-3">
              <b>{t("General.consentStatement")}</b>
              <br />
              {t('Biometric.makeSurePrivacyPolicy')}{" "}
              <br></br>
            </p>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  onChange={(e) => handleCheckBox(e, "1")}
                />{" "}
                {t('Biometric.makeSurePrivacyPolicyPoint1')}
              </Label>
            </FormGroup>
            <br />
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  onChange={(e) => handleCheckBox(e, "2")}
                />{" "}
                {t('Biometric.makeSurePrivacyPolicyPoint2')}
              </Label>
            </FormGroup>
            <br />
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  onChange={(e) => handleCheckBox(e, "3")}
                />{" "}
                {t('Biometric.makeSurePrivacyPolicyPoint3')}
              </Label>
            </FormGroup>
            <br />
            <br />
            <div className="text-center">
              <Link to="/assessment">
                <Button
                  className="btn btn-info btn-shadow mr-3 "
                  style={{ backgroundColor: "#fff", color: "#5AADF7" }}
                >
                  <i className="fa fa-arrow-left mr-1"></i> {t("General.back")}
                </Button>
              </Link>
              <Button
                className="btn btn-info btn-shadow pr-4 pl-4 "
                style={{ backgroundColor: "#5AADF7", color: "#fff" }}
                disabled={checkBox1 || checkBox2 || checkBox3}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setNextPage(true);
                }}
              >
                {" "}
                {t("General.next")}
                <i className="fa fa-arrow-right ml-1"></i>
              </Button>
            </div>
          </div>
        </Col>
        <Col sm="1"></Col>
      </Row>
    </Container>
  );
}

export default translate(PrivacyAggreement);
