import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Spinner } from "reactstrap";
import { translate, t } from "react-switch-lang";
import request from "../../../../../utils/Request";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
import { useResultTestContext } from "../../Menu/AgilityTestContext";
import { toast } from "react-toastify";
import moment from "../../../../../utils/Moment";
import Pulse from "react-reveal/Pulse";

toast.configure();
function Sankarta(props) {
  // eslint-disable-next-line
  const [result, setResult] = useResultTestContext({});
  const [errorInput, setErrorInput] = useState(false);
  // const [showButton, setShowButton] = useState(true);
  const vidRef = useRef(null);
  const [answers, setAnswers] = useState({});
  const [time, setTime] = useState({});
  const [startTime, setStartTime] = useState();
  const [error, setError] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [section, setSection] = useState("topic");
  const [page, setPage] = useState(0);
  const [pageTitle, setPageTitle] = useState(1);
  const [completed, setCompleted] = useState(false);
  const [showBtnAnswer, setShowBtnAnswer] = useState(false);

  useEffect(() => {
    request
      .get("/assessment/test/agility/sankarta")
      .then((res) => {
        if (res.data?.data) {
          setLoading(false);
          setQuestions(res.data.data);
        }
      })
      .catch((err) => {
        setError(true);
      }); // eslint-disable-next-line
  }, []);

  function isError() {
    if (error) {
      return (
        <AffirmationModal
          isOpen={error}
          title={"Error !"}
          desc={t("Agility.errorConnection")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setError(true);
          }}
          confirmation={() => {
            window.location.reload();
            setError(false);
          }}
        />
      );
    }
  }

  function isErrorInput() {
    if (errorInput) {
      return (
        <AffirmationModal
          isOpen={errorInput}
          title={"Error !"}
          desc={t("Agility.hasDataSankerta")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setErrorInput(true);
          }}
          confirmation={() => {
            setErrorInput(false);
          }}
        />
      );
    }
  }

  function nextPage() {
    setLoading(true);
    window.scroll({ top: 0, behavior: "smooth" });
    if (section === "topic") {
      setStartTime(moment().format("YYYY-MM-DD HH:mm:ss"));
      setSection("asking");
      setBtnDisable(true);
      setLoading(false);
      // setShowButton(true);
      setShowBtnAnswer(false);
    } else {
      if (!answers[pageTitle]) {
        setLoading(false);
        setErrorInput(true);
        return false;
      } else {
        setTime((state) => ({
          ...state,
          [pageTitle]: moment(new Date(), "YYYY-MM-DD HH:mm:ss").diff(
            startTime,
            "seconds"
          ),
        }));
        if (questions[page].lastQuestion === true) {
          setCompleted(true);
          window.scroll({ top: 0, behavior: "smooth" });
          setLoading(false);
        } else {
          setPage(page + 1);
          setPageTitle(pageTitle + 1);
          setSection("topic");
          setBtnDisable(true);
          // setShowButton(true);
          setLoading(false);
        }
      }
    }
  }

  function chooseOption(option) {
    setAnswers((state) => ({
      ...state,
      [pageTitle]: option,
    }));
    setBtnDisable(false);
  }

  function isComplete() {
    if (completed) {
      return (
        <AffirmationModal
          isOpen={completed}
          title={"Success !"}
          desc={t("Agility.completeSankarta")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setCompleted(true);
          }}
          confirmation={() => {
            let totalTime = 0;
            Object.values(time).map((data) => {
              totalTime = totalTime + parseInt(data);
              return true;
            });
            setResult((result) => ({
              ...result,
              sankarta: { answers, time, totalTime, step: props.page },
            }));
            window.scroll({ top: 0, behavior: "smooth" });
            props.setNextPage(false);
            toast.success("Success", {
              autoClose: 3000,
            });
          }}
        />
      );
    }
  }

  return (
    <>
      {isError()}
      {isErrorInput()}
      {isComplete()}
      <div className="sankarta-container">
        <h3 style={{ position: "fixed", top: "5%", zIndex: 99 }}>
          <b>{props.title} ( {pageTitle} / 2 )</b>
        </h3>
        {loading ?
          <div sm="12" className=" mx-auto text-center my-auto">
            <Spinner size="lg" />
          </div>
          : questions && questions[page] &&
          <>
            <video
              ref={vidRef}
              className="vid-agility"
              style={{backgroundColor:"black"}}
              src={
                section === "topic"
                  ? process.env.REACT_APP_DOMAIN +
                  questions[page].videoTopic
                  : process.env.REACT_APP_DOMAIN +
                  questions[page].videoAsking
              }
              onContextMenu={(e) => e.preventDefault()}
              id="dataVid"
              controls={false}
              autoPlay={true}
              muted
              playsInline
              onPause={
                section === "topic"
                  ? () => nextPage()
                  : () => setShowBtnAnswer(true)
              }
            />
            {section === 'asking' &&
              <div className="button-sankarta">
                {pageTitle === 1 ?
                  <SectionButton
                    nextPage={nextPage}
                    btnDisable={btnDisable}
                    section={section}
                    xs="6" colClass="text-center"
                    rowClass="px-3 mt-3"
                    page={page}
                    questions={questions}
                    showBtnAnswer={showBtnAnswer}
                    answers={answers}
                    pageTitle={pageTitle}
                    chooseOption={chooseOption}
                  />
                  :
                  <SectionButton
                    nextPage={nextPage}
                    btnDisable={btnDisable}
                    section={section}
                    xs="12"
                    colClass="pl-0"
                    rowClass="fixedSection-2 mt-1 mt-md-4"
                    page={page}
                    questions={questions}
                    showBtnAnswer={showBtnAnswer}
                    answers={answers}
                    pageTitle={pageTitle}
                    chooseOption={chooseOption}
                  />
                }
              </div>
            }
          </>
        }
      </div>
    </>
  );
}

const SectionButton = ({ questions, showBtnAnswer, answers, pageTitle, chooseOption, xs, colClass, rowClass, page, nextPage, btnDisable, section }) => {
  return (
    <Row className={rowClass}>
      {questions[page].options.map((data, idx) => (
        <Col xs={xs} key={idx} className={colClass}>
          {showBtnAnswer &&
            <Pulse>
              <button type="button" id={`btnAnswer-${idx}`} name={"option" + page} onClick={() => chooseOption(data.code)}
                className={answers[pageTitle] === data.code
                  ? `options-sankarta options-sankarta-${pageTitle} options-sankarta-${pageTitle}-selected`
                  : `options-sankarta options-sankarta-${pageTitle}`
                }
              >
                {data.text}
              </button>
            </Pulse>
          }
        </Col>
      ))}
      {showBtnAnswer && <SubmitButton nextPage={nextPage} btnDisable={btnDisable} section={section} questions={questions} page={page} />}
    </Row>
  )
}

const SubmitButton = ({ nextPage, btnDisable, section, questions, page }) => {
  return (
    <div className='mt-1 mt-md-2 text-center mx-auto'>
      <button
        className='btn btn-primary vid-submit-button'
        onClick={nextPage}
        disabled={btnDisable}
      >
        {section === "asking"
          ? questions[page].lastQuestion
            ? "Submit"
            : t("General.next")
          : t("General.next")}{" "}
        <i className="fa fa-arrow-right ml-2" />
      </button>
    </div>
  )
}

export default translate(Sankarta);
