import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { translate } from "react-switch-lang";
import Education from "./Education";
import Experience from "./Experience";
import Organization from "./Organization";
import SkillHistory from "./Skill";
toast.configure();

const HistoryProfile = (props) => {
  return (
    <div className="animated fadeIn">
      <Education editProfilePro={props.editProfilePro} />
      <Organization editProfilePro={props.editProfilePro} />
      <Experience editProfilePro={props.editProfilePro} />
      <SkillHistory editProfilePro={props.editProfilePro} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sessionId: state.sessionId,
  };
};
export default connect(mapStateToProps)(translate(HistoryProfile));
