import React, { useState, Fragment } from "react";
import FooterLandingPage from "../../../Templates/LandingPage/FooterLandingPage";
import {
  Container,
  Card,
  CardTitle,
  CardText,
  CardImg,
  CardBody,
  Row,
  Col,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

function LiveChat() {
  const goToLink = (url) => {
    window.open(url, "_blank");
  };

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const responsive = {
    // desktop: {
    //   breakpoint: { max: 3000, min: 1024 },
    //   items: 5,
    //   slidesToSlide: 3, // optional, default to 1.
    // },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // optional, default to 1.
      partialVisibilityGutter: 50,
    },
  };
  return (
    <Fragment>
      <Container className="d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none">
        <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <Row>
            <Col lg="8">
              <CardText
                style={{
                  paddingTop: "100px",
                  marginLeft: "140px",
                }}
              >
                <p className="liveChat">
                  <b>Layanan Live Chat</b>
                </p>
                <p style={{ fontSize: "16px", marginTop: "-5px" }}>
                  Layanan interaktif dari konsultan psikologi untuk membantu
                  menyelesaikan masalahmu
                </p>
              </CardText>
            </Col>
            <Col lg="4">
              <CardImg
                width="70%"
                src={require("../../../../assets/img/Group520.png")}
                alt="Card image cap"
              />
            </Col>
          </Row>
        </div>
        <div>
          <p className="textLivechat">
            <b>Dengan Live Chat Via Skillana, Kamu bisa :</b>{" "}
          </p>
        </div>
        <div className="cardLivechat">
          <Row className="mt-4">
            <Col lg={2} />
            <Col>
              <Card
                style={{
                  padding: "22px",
                  width: "90%",
                  border: "none",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  borderRadius: "15px",
                }}
              >
                <CardImg
                  top
                  width="100%"
                  src={require("../../../../assets/img/CHAT_BISNIS.png")}
                  alt="Card image cap"
                />
                <CardBody>
                  <CardTitle tag="h5" style={{ textAlign: "center" }}>
                    <b>Konsultasi Bisnis</b>
                  </CardTitle>
                  <CardText>
                    Konsultasikan langkah bisnismu dengan coach berpengalaman
                    kami.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card
                style={{
                  padding: "22px",
                  width: "90%",
                  border: "none",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  borderRadius: "15px",
                }}
              >
                <CardImg
                  top
                  width="100%"
                  src={require("../../../../assets/img/CHAT_CAREER.png")}
                  alt="Card image cap"
                />
                <CardBody>
                  <CardTitle tag="h5" style={{ textAlign: "center" }}>
                    <b>Konsultasi Karir</b>
                  </CardTitle>
                  <CardText>
                    Diskusikan rencana kariermu bersama coach ahli terpilih
                    kami.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            <Col lg={2} />
          </Row>
        </div>
        <div>
          <p className="textLivechat" style={{ marginTop: "50px" }}>
            <b>Cara Menggunakan</b>{" "}
          </p>
        </div>

        <div>
          <Row>
            <Col>
              <div className="content-tabs">
                <div
                  className={
                    toggleState === 1
                      ? "content-chat  active-content"
                      : "content-chat"
                  }
                >
                  <CardImg
                    top
                    width="100%"
                    src={require("../../../../assets/img/CARA_1.png")}
                    alt="Card image cap"
                  />
                </div>

                <div
                  className={
                    toggleState === 2
                      ? "content-chat  active-content"
                      : "content-chat"
                  }
                >
                  <CardImg
                    top
                    width="100%"
                    src={require("../../../../assets/img/CARA_2.png")}
                    alt="Card image cap"
                  />
                </div>

                <div
                  className={
                    toggleState === 3
                      ? "content-Chat  active-content"
                      : "content-chat"
                  }
                >
                  <CardImg
                    top
                    width="100%"
                    src={require("../../../../assets/img/CARA_3.png")}
                    alt="Card image cap"
                  />
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <NavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleTab(1)}
                >
                  <Row>
                    <Col lg="1">
                      <div
                        className={
                          toggleState === 1 ? "tabs numberCircle" : "tabs"
                        }
                      >
                        1
                      </div>
                    </Col>
                    <Col
                      lg="10"
                      className={
                        toggleState === 1
                          ? "textActive ChatActive"
                          : "textActive"
                      }
                      style={{ letterSpacing: "1px", marginTop: "5px" }}
                    >
                      <b>Buka aplikasi Skillana, pilih menu Live Chat</b>
                    </Col>
                  </Row>
                </NavLink>
                <NavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleTab(2)}
                >
                  <Row>
                    <Col lg="1">
                      <div
                        className={
                          toggleState === 2 ? "tabs numberCircle" : "tabs"
                        }
                      >
                        2
                      </div>
                    </Col>
                    <Col
                      lg="10"
                      className={
                        toggleState === 2
                          ? "textActive ChatActive"
                          : "textActive"
                      }
                      style={{ letterSpacing: "1px", marginTop: "5px" }}
                    >
                      <b>Pilih Jenis Layanan Konsultasi</b>
                    </Col>
                  </Row>
                </NavLink>
                <NavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleTab(3)}
                >
                  <Row>
                    <Col lg="1">
                      <div
                        className={
                          toggleState === 3 ? "tabs numberCircle" : "tabs"
                        }
                      >
                        3
                      </div>
                    </Col>
                    <Col
                      lg="10"
                      className={
                        toggleState === 3
                          ? "textActive ChatActive"
                          : "textActive"
                      }
                      style={{ letterSpacing: "1px", marginTop: "5px" }}
                    >
                      <b>
                        Atur Jadwal Untuk Berkonsultasi Sesuai dengan Jadwal
                        yang Tersedia
                      </b>
                    </Col>
                  </Row>
                </NavLink>
              </div>
            </Col>
          </Row>
        </div>

        <div>
          <p className="textLivechat" style={{ marginTop: "80px" }}>
            <b>Download Skillana</b>{" "}
          </p>
        </div>
        <div style={{ marginBottom: "10%" }}>
          <Row>
            <Col lg={3} />
            <Col>
              <Link
                onClick={() =>
                  goToLink(
                    "https://play.google.com/store/apps/details?id=com.widyaskilloka.skillana"
                  )
                }
              >
                <center>
                  <img
                    src={require("../../../../assets/img/playstore-image.png")}
                    alt="playstore"
                    width="60%"
                    className="storeZoom"
                  />
                </center>
              </Link>
            </Col>
            <Col>
              <Link
                onClick={() =>
                  goToLink(
                    "https://apps.apple.com/id/app/skillana/id1546184278"
                  )
                }
              >
                <center>
                  <img
                    src={require("../../../../assets/img/apps store.png")}
                    alt="appstore"
                    width="60%"
                    className="storeZoom"
                  />
                </center>
              </Link>
            </Col>
            <Col lg={3} />
          </Row>
        </div>
      </Container>

      {/* ======MOBILE VIEW LIVE CHAT======= */}
      <div className="d-block d-sm-none || d-none d-sm-block d-md-none">
        <Container>
          <CardImg
            style={{ marginTop: "15px", marginBottom: "15px" }}
            width="80%"
            src={require("../../../../assets/img/ASSET_MOBILE_CHAT.png")}
            alt="Card image cap"
          />
        </Container>
        <div className="sectionSatu">
          <p style={{ fontSize: "25px", color: "#5AADF7" }}>
            <b>Layanan Live Chat</b>
          </p>
          <p
            style={{
              fontSize: "18px",
              marginTop: "-5px",
              paddingRight: "40px",
              paddingLeft: "40px",
            }}
          >
            Layanan interaktif dari konsultan psikologi untuk membantu
            menyelesaikan masalahmu.
          </p>
        </div>
        <Container>
          <p
            style={{
              fontSize: "20px",
              color: "#5AADF7",
              textAlign: "center",
              marginTop: "50px",
              marginBottom: "50px",
            }}
          >
            Dengan Live Chat via Skillana, kamu bisa melakukan konsultasi
            tentang:
          </p>
          <div>
            <center>
              <Card
                style={{
                  padding: "22px",
                  width: "80%",
                  border: "none",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  borderRadius: "10px",
                }}
              >
                <CardImg
                  top
                  width="100%"
                  src={require("../../../../assets/img/CHAT_BISNIS.png")}
                  alt="Card image cap"
                />
                <CardBody>
                  <CardTitle tag="h5" style={{ textAlign: "center" }}>
                    <b>Konsultasi Bisnis</b>
                  </CardTitle>
                  <CardText>
                    Konsultasikan langkah bisnismu dengan coach berpengalaman
                    kami.
                  </CardText>
                </CardBody>
              </Card>
            </center>
            <br />
            <center>
              <Card
                style={{
                  padding: "22px",
                  width: "80%",
                  border: "none",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  borderRadius: "10px",
                }}
              >
                <CardImg
                  top
                  width="100%"
                  src={require("../../../../assets/img/CHAT_CAREER.png")}
                  alt="Card image cap"
                />
                <CardBody>
                  <CardTitle tag="h5" style={{ textAlign: "center" }}>
                    <b>Konsultasi Karir</b>
                  </CardTitle>
                  <CardText>
                    Diskusikan rencana kariermu bersama coach ahli terpilih
                    kami.
                  </CardText>
                </CardBody>
              </Card>
            </center>
          </div>
        </Container>
        <Container>
          <div>
            <p
              style={{
                fontSize: "20px",
                color: "#5AADF7",
                textAlign: "center",
                marginTop: "50px",
                marginBottom: "50px",
              }}
            >
              Cara Menggunakan
            </p>
            <br />

            <Carousel
              partialVisible={true}
              swipeable={true}
              draggable={true}
              showDots={true}
              arrows={false}
              responsive={responsive}
              ssr={true}
              infinite={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              renderButtonGroupOutside
              renderDotsOutside={false}
            >
              <Col>
                <CardImg
                  className="mb-5"
                  top
                  width="100%"
                  src={require("../../../../assets/img/CARA_1.png")}
                  alt="Card image cap"
                />
                <div style={{ marginBottom: "5px" }}>
                  <Row>
                    <Col xs="1">
                      <div className="numberCircle">1</div>
                    </Col>
                    <Col
                      xs="10"
                      className="mt-2 ml-3 navtext"
                      style={{ color: "black", letterSpacing: "1px" }}
                    >
                      <b>Buka aplikasi Skillana, pilih menu Live Chat</b>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col>
                <CardImg
                  className="mb-5"
                  top
                  width="100%"
                  src={require("../../../../assets/img/CARA_2.png")}
                  alt="Card image cap"
                />
                <div style={{ marginBottom: "5px" }}>
                  <Row>
                    <Col xs="1">
                      <div className="numberCircle">2</div>
                    </Col>
                    <Col
                      xs="10"
                      className="mt-2 ml-3 navtext"
                      style={{ color: "black", letterSpacing: "1px" }}
                    >
                      <b>Pilih Jenis Layanan Konsultasi</b>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col>
                <CardImg
                  className="mb-5"
                  top
                  width="100%"
                  src={require("../../../../assets/img/CARA_3.png")}
                  alt="Card image cap"
                />
                <div style={{ marginBottom: "50px" }}>
                  <Row>
                    <Col xs="1">
                      <div className="numberCircle">3</div>
                    </Col>
                    <Col
                      xs="10"
                      className="mt-2 ml-3 navtext"
                      style={{ color: "black", letterSpacing: "1px" }}
                    >
                      <b>
                        Atur Jadwal Untuk Berkonsultasi Sesuai dengan Jadwal
                        yang Tersedia
                      </b>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Carousel>
          </div>
        </Container>
        <div>
          <p
            style={{
              fontSize: "20px",
              color: "#5AADF7",
              textAlign: "center",
              marginTop: "50px",
              marginBottom: "40px",
            }}
          >
            Download Skillana
          </p>
          <div className="mb-5">
            <Link
              onClick={() =>
                goToLink(
                  "https://play.google.com/store/apps/details?id=com.widyaskilloka.skillana"
                )
              }
            >
              <center>
                <img
                  src={require("../../../../assets/img/playstore-image.png")}
                  alt="playstore"
                  width="45%"
                  className="storeZoom"
                />
              </center>
            </Link>{" "}
            <br />
            <Link
              onClick={() =>
                goToLink("https://apps.apple.com/id/app/skillana/id1546184278")
              }
            >
              <center>
                <img
                  src={require("../../../../assets/img/apps store.png")}
                  alt="appstore"
                  width="45%"
                  className="storeZoom"
                />
              </center>
            </Link>
          </div>
        </div>
      </div>
      <FooterLandingPage />
    </Fragment>
  );
}

export default LiveChat;
