import React, { useState, useEffect, useRef } from "react";
import { Spinner } from "reactstrap";
import { translate, t } from "react-switch-lang";
import request from "../../../../../utils/Request";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
import { useResultTestContext } from "../../Menu/AgilityTestContext";
import { toast } from "react-toastify";
import moment from "../../../../../utils/Moment";
import { SectionOneFirst } from "./SectionButton/SectionOneFirst";
import { SectionTwoFirst } from "./SectionButton/SectionTwoFirst";
import { SectionDefault } from "./SectionButton/SectionDefault";

toast.configure();
function Vacana(props) {
  // eslint-disable-next-line
  const [result, setResult] = useResultTestContext({});
  const [errorInput, setErrorInput] = useState(false);
  const [time, setTime] = useState({});
  const [startTime, setStartTime] = useState(props.startTime);
  const vidRef = useRef(null);
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState(false);
  const [btnDisable, setBtnDisbale] = useState(true);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [section, setSection] = useState("topic");
  const [page, setPage] = useState(0);
  const [pageQuestion, setPageQuestion] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [showBtnAnswer, setShowBtnAnswer] = useState(false);

  useEffect(() => {
    request
      .get("/assessment/test/agility/vacana")
      .then((res) => {
        if (res.data?.data) {
          setLoading(false);
          setQuestions(res.data.data);
        }
      })
      .catch((err) => {
        setError(true);
      }); // eslint-disable-next-line
  }, []);
  function isError() {
    if (error) {
      return (
        <AffirmationModal
          isOpen={error}
          title={"Error !"}
          desc={t("Agility.errorConnection")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setError(true);
          }}
          confirmation={() => {
            window.location.reload();
            setError(false);
          }}
        />
      );
    }
  }
  function isErrorInput() {
    if (errorInput) {
      return (
        <AffirmationModal
          isOpen={errorInput}
          title={"Error !"}
          desc={t("Agility.hasDataVacana")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setErrorInput(true);
          }}
          confirmation={() => {
            setErrorInput(false);
          }}
        />
      );
    }
  }

  function isComplete() {
    if (completed) {
      return (
        <AffirmationModal
          isOpen={completed}
          title={"Success !"}
          desc={t("Agility.completeVacana")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setCompleted(true);
          }}
          confirmation={() => {
            let totalTime = 0;
            Object.values(time).map((data) => {
              totalTime = totalTime + parseInt(data);
              return true;
            });
            setResult((result) => ({
              ...result,
              vacana: { answers, time, totalTime, step: props.page },
            }));
            window.scroll({ top: 0, behavior: "smooth" });
            props.setNextPage(false);
            toast.success("Success", {
              autoClose: 3000,
            });
          }}
        />
      );
    }
  }
  // console.log(time);
  function chooseOption(option) {
    setAnswers((state) => ({
      ...state,
      [questions[page].section +
      "." +
      questions[page]?.questions[pageQuestion].codeQuestion]: option,
    }));
    setBtnDisbale(false);
  }

  useEffect(() => {
    // eslint-disable-next-line
    if (Object.values(time).length > 0) {
      if (
        questions[page]?.questions[pageQuestion].lastQuestion === true &&
        questions[page]?.lastSection === true
      ) {
        setStartTime(moment().format("YYYY-MM-DD HH:mm:ss"));
      } else if (
        questions[page]?.questions[pageQuestion].lastQuestion === true &&
        questions[page]?.lastSection === false
      ) {
      } else {
        setStartTime(moment().format("YYYY-MM-DD HH:mm:ss"));
      }
    }
    // eslint-disable-next-line
  }, [time]);

  // useEffect(() => {
  //   if (answers[page] !== null && section === "asking") {
  //     nextPage()
  //   }
  // }, [answers])

  function nextPage() {
    setLoading(true);
    window.scroll({ top: 0, behavior: "smooth" });
    if (section === "topic") {
      setStartTime(moment().format("YYYY-MM-DD HH:mm:ss"));
      setSection("asking");
      setBtnDisbale(true);
      setLoading(false);

      setShowBtnAnswer(false);
    } else {
      if (
        !answers[
          questions[page].section +
            "." +
            questions[page]?.questions[pageQuestion].codeQuestion
        ]
      ) {
        setLoading(false);
        setErrorInput(true);
        return false;
      } else {
        if (
          questions[page]?.questions[pageQuestion].lastQuestion === true &&
          questions[page]?.lastSection === true
        ) {
          setTime((state) => ({
            ...state,
            [questions[page].section +
            "." +
            questions[page]?.questions[pageQuestion].codeQuestion]: moment(
              new Date(),
              "YYYY-MM-DD HH:mm:ss"
            ).diff(startTime, "seconds"),
          }));
          setCompleted(true);
          setLoading(false);
        } else if (
          questions[page]?.questions[pageQuestion].lastQuestion === true &&
          questions[page]?.lastSection === false
        ) {
          setTime((state) => ({
            ...state,
            [questions[page].section +
            "." +
            questions[page]?.questions[pageQuestion].codeQuestion]: moment(
              new Date(),
              "YYYY-MM-DD HH:mm:ss"
            ).diff(startTime, "seconds"),
          }));

          setBtnDisbale(true);
          setSection("topic");
          setPage(page + 1);
          setShowBtnAnswer(false);
          setPageQuestion(0);
          setLoading(false);
        } else {
          setTime((state) => ({
            ...state,
            [questions[page].section +
            "." +
            questions[page]?.questions[pageQuestion].codeQuestion]: moment(
              new Date(),
              "YYYY-MM-DD HH:mm:ss"
            ).diff(startTime, "seconds"),
          }));

          setBtnDisbale(true);
          setPageQuestion(pageQuestion + 1);
          setShowBtnAnswer(false);
          setLoading(false);
        }
      }
    }
  }
  // console.log(answers);
  return (
    <>
      {isError()}
      {isErrorInput()}
      {isComplete()}
      <div className="sankarta-container">
        <h3 style={{ position: "fixed", top: "5%", zIndex: 99 }}>
          <b>
            {props.title}
            (&nbsp;{" "}
            {loading ? (
              <Spinner size="md" />
            ) : (
              questions && questions[page]?.section
            )}{" "}
            / 4 )
          </b>
        </h3>
        {loading ? (
          <div sm="12" className=" mx-auto text-center my-auto">
            <Spinner size="lg" />
          </div>
        ) : (
          questions &&
          questions[page] && (
            <>
              <video
                ref={vidRef}
                className="vid-agility"
                src={
                  section === "topic"
                    ? process.env.REACT_APP_DOMAIN + questions[page].videoTopic
                    : process.env.REACT_APP_DOMAIN +
                      questions[page].questions[pageQuestion].video
                }
                onContextMenu={(e) => e.preventDefault()}
                id="dataVid"
                // controls={process.env.REACT_APP_DOMAIN.includes('dev.skillana.id') ? true : false}
                controls={false}
                autoPlay={true}
                muted
                playsInline
                onPause={
                  section === "topic"
                    ? () => nextPage()
                    : () => setShowBtnAnswer(true)
                }
              />
              {section === "asking" && (
                <div
                  className={
                    questions[page].section === 2 &&
                    questions[page].questions[pageQuestion].codeQuestion === 1
                      ? "button-sankarta"
                      : "button-sankarta"
                  }
                >
                  {questions[page].section === 1 &&
                  questions[page].questions[pageQuestion].codeQuestion < 3 ? (
                    <SectionOneFirst
                      nextPage={nextPage}
                      btnDisable={btnDisable}
                      section={section}
                      xs={
                        12 /
                        questions[page]?.questions[pageQuestion].options.length
                      }
                      page={page}
                      questions={questions}
                      showBtnAnswer={showBtnAnswer}
                      answers={answers}
                      chooseOption={chooseOption}
                      pageQuestion={pageQuestion}
                    />
                  ) : questions[page].section === 2 &&
                    questions[page].questions[pageQuestion].codeQuestion ===
                      1 ? (
                    <SectionTwoFirst
                      nextPage={nextPage}
                      btnDisable={btnDisable}
                      section={section}
                      xs={
                        12 /
                        questions[page]?.questions[pageQuestion].options.length
                      }
                      page={page}
                      questions={questions}
                      showBtnAnswer={showBtnAnswer}
                      answers={answers}
                      chooseOption={chooseOption}
                      pageQuestion={pageQuestion}
                    />
                  ) : (
                    <SectionDefault
                      nextPage={nextPage}
                      btnDisable={btnDisable}
                      section={section}
                      xs={
                        12 /
                        questions[page]?.questions[pageQuestion].options.length
                      }
                      page={page}
                      questions={questions}
                      showBtnAnswer={showBtnAnswer}
                      answers={answers}
                      chooseOption={chooseOption}
                      pageQuestion={pageQuestion}
                    />
                  )}
                </div>
              )}
            </>
          )
        )}
      </div>
    </>
  );
}

export default translate(Vacana);
