import React, { useState } from "react";
import Fingerprint from "../index";
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { translate, t } from "react-switch-lang";
function UserGuide() {
  const [nextPage, setNextPage] = useState(false);
  const [checkBox, setCheckBox] = useState(true);
  if (nextPage) {
    return <Fingerprint />;
  }
  const handleCheckBox = (e) => {
    if (e.target.checked === true) {
      setCheckBox(false);
    } else {
      setCheckBox(true);
    }
  };
  return (
    <Container>
      <Row>
        <Col sm="1"></Col>
        <Col
          sm="10"
          className=" mt-4 mb-4 pt-3 pb-3"
          style={{
            boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
          }}
        >
          <h2>
            <b>{t('Biometric.userGuideLetsStart')}</b>
          </h2>
          <h6>{t('Biometric.beforeStarting')}</h6>
          <br />
          <Row>
            <Col sm="8">
              <ul>
                <li>
                  <p>
                  {t('Biometric.userGuideFingerprintPoint1')}
                  </p>
                </li>
                <li>
                  <p>
                  {t('Biometric.userGuideFingerprintPoint2')}
                  </p>
                </li>
                <li>
                  <p>
                  {t('Biometric.userGuideFingerprintPoint3')}
                  </p>
                </li>
                <li>
                  <p>
                  {t('Biometric.userGuideFingerprintPoint4')}
                  </p>
                </li>
                <li>
                  <p>
                  {t('Biometric.userGuideFingerprintPoint5')}
                  </p>
                </li>
                <li>
                  <p>
                  {t('Biometric.userGuideFingerprintPoint6')}
                  </p>
                </li>
              </ul>
            </Col>
            <Col sm="4" className="img-guide-web">
              <img
                src={require("../../../../assets/img/finger/guide.png")}
                alt="guide"
                className="text-center"
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm="8">
              <FormGroup check inline>
                <Label check>
                  <Input type="checkbox" onChange={(e) => handleCheckBox(e)} />{" "}
                  {t("Assessment.aggreeBiometric")}
                </Label>
              </FormGroup>
            </Col>
            <Col sm="4" className="text-right">
              <Button
                className="btn btn-info btn-shadow pr-4 pl-4 "
                style={{ backgroundColor: "#5AADF7", color: "#fff" }}
                disabled={checkBox}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setNextPage(true);
                }}
              >
                <b>
                  {t("General.next")} <i className="fa fa-arrow-right ml-1"></i>
                </b>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col sm="1"></Col>
      </Row>
    </Container>
  );
}

export default translate(UserGuide);
