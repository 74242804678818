import React, { Fragment } from "react";
import ContentFaqLandingPage from '../../../Templates/LandingPage/ContentFaqLandingPage';
import {
    Container,
} from 'reactstrap';
const Faq = () => {
    return (
        <Fragment>
        <div className="header-landing-page-old">
            <Container className="wrap-content">
                <ContentFaqLandingPage />
            </Container>
        </div>
        </Fragment>
    )
}
export default Faq;