import React, { Fragment } from "react";
const NotFound = (props) => {
  const toUp = window.scrollTo(0, 0);
  return (
    <Fragment>
      {toUp}
      <div className="row">
        <div className="col-sm-2"></div>
        <div className="col-sm-8 text-center pt-4">
          <img
            src={require("../../../assets/img/notFoundEn.png")}
            alt=""
            style={{ width: "100%" }}
          />
        </div>
        <div className="col-sm-2"></div>
      </div>
    </Fragment>
  );
};
export default NotFound;
