import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";
import Color from "../../../../utils/Colors";
import CardCollapse from "../../../../components/molecules/CardCollapse";
import { translate } from "react-switch-lang";
export default translate(function ContentFaqLandingPage(props) {
  const {t} = props
  return (
    <Fragment>
      <Row>
        <Col sm="12 mb-2" className="text-justify">
          <h2 className="title-header">
            <span style={{ color: Color.primaryColor }}>FAQ Skillana</span>
            <br />
          </h2>
          <br />
          <br />
          <h4>
            <span style={{ color: Color.black }}>
              <b>{t('LandingPage.skillanaApp')}</b>
            </span>
            <br />
          </h4>
        </Col>
        <CardCollapse
          id="q1"
          question={t('LandingPage.whatIsSkillana')}
          answer={t('LandingPage.whatIsSkillanaAnsw')}
        />
        <CardCollapse
          id="q2"
          question={t('LandingPage.howToRegister')}
          answer={t('LandingPage.howToRegisterAnsw')}
        />
        <CardCollapse
          id="q3"
          question={t('LandingPage.whyVerifyAccount')}
          answer={t('LandingPage.whyVerifyAccountAnsw')}
        />
        <CardCollapse
          id="q4"
          question={t('LandingPage.howToVerifyAccount')}
          answer={t('LandingPage.howToVerifyAccountAnsw')}
        />
        <CardCollapse
          id="q5"
          question={t('LandingPage.whatToPayAttention')}
          answer={t('LandingPage.whatToPayAttentionAnsw')}
        />
        <CardCollapse
          id="q6"
          question={t('LandingPage.howToGetSkillanaApp')}
          answer={t('LandingPage.howToGetSkillanaAppAnsw')}
        />
        <CardCollapse
          id="q7"
          question={t('LandingPage.howToChangeEmail')}
          answer={t('LandingPage.howToChangeEmailAnsw')}
        />
        <CardCollapse
          id="q8"
          question={t('LandingPage.whatIfForgetPassword')}
          answer={t('LandingPage.whatIfForgetPasswordAnsw')}
        />
        <CardCollapse
          id="q9"
          question={t('LandingPage.whyUseSkillanaApp')}
          answer={t('LandingPage.whyUseSkillanaAppAnsw')}
        />
        <CardCollapse
          id="q10"
          question={t('LandingPage.whatSkillanaFeatures')}
          answer={t('LandingPage.whatSkillanaFeaturesAnsw')}
        />
        <Col sm="12 mb-2 mt-5" className="text-justify">
          <h4>
            <span style={{ color: Color.black }}>
              <b>{t('LandingPage.profileMenu')}</b>
            </span>
            <br />
          </h4>
        </Col>
        <CardCollapse
          id="q11"
          question={t('LandingPage.whatIsProfileMenu')}
          answer={t('LandingPage.whatIsProfileMenuAnsw')}
        />
        <CardCollapse
          id="q12"
          question={t('LandingPage.howToFillOutProfile')}
          answer={t('LandingPage.howToFillOutProfileAnsw')}
        />
        <CardCollapse
          id="q13"
          question={t('LandingPage.whatIsRequiredToFillOutProfile')}
          answer={t('LandingPage.whatIsRequiredToFillOutProfileAnsw')}
        />
        <Col sm="12 mb-2 mt-5" className="text-justify">
          <h4>
            <span style={{ color: Color.black }}>
              <b>{t('LandingPage.assessmentMenu')}</b>
            </span>
            <br />
          </h4>
        </Col>
        <CardCollapse
          id="q14"
          question={t('LandingPage.whatIsAssessment')}
          answer={t('LandingPage.whatIsAssessmentAnsw')}
        />
        <CardCollapse
          id="q15"
          question={t('LandingPage.whatIsPsychotest')}
          answer={t('LandingPage.whatIsPsychotestAnsw')}
        />
        <CardCollapse
          id="q16"
          question={t('LandingPage.whyCanDoOneAssessment')}
          answer={t('LandingPage.whyCanDoOneAssessmentAnsw')}
        />
        <CardCollapse
          id="q17"
          question={t('LandingPage.howToTakeAssessment')}
          answer={t('LandingPage.howToTakeAssessmentAnsw')}
        />
        <CardCollapse
          id="q18"
          question={t('LandingPage.howToSeeAssessmentResult')}
          answer={t('LandingPage.howToSeeAssessmentResultAnsw')}
        />
        <Col sm="12 mb-2 mt-5" className="text-justify">
          <h4>
            <span style={{ color: Color.black }}>
              <b>{t('LandingPage.aptitudeTest')}</b>
            </span>
            <br />
          </h4>
        </Col>
        <CardCollapse
          id="q19"
          question={t('LandingPage.whatIsAptitudeTest2')}
          answer={t('LandingPage.whatIsAptitudeTest2Answ')}
        />
        <CardCollapse
          id="q20"
          question={t('LandingPage.howToTakeAptitudeAssessment')}
          answer={t('LandingPage.howToTakeAptitudeAssessmentAnsw')}
        />
        <CardCollapse
          id="q21"
          question={t('LandingPage.howToSeeAptitudeTestResults')}
          answer={t('LandingPage.howToSeeAptitudeTestResultsAnsw')}
        />
        <Col sm="12 mb-2 mt-5" className="text-justify">
          <h4>
            <span style={{ color: Color.black }}>
              <b>{t('LandingPage.workStyleTest')}</b>
            </span>
            <br />
          </h4>
        </Col>
        <CardCollapse
          id="q22"
          question={t('LandingPage.whatIsWorkStyleTest')}
          answer={t('LandingPage.whatIsWorkStyleTestAnsw')}
        />
        <CardCollapse
          id="q23"
          question={t('LandingPage.howToTakeWorkStyleTest')}
          answer={t('LandingPage.howToTakeWorkStyleTestAnsw')}
        />
        <CardCollapse
          id="q24"
          question={t('LandingPage.howToSeeWorkStyleTestResults')}
          answer={t('LandingPage.howToSeeWorkStyleTestResultsAnsw')}
        />
        <Col sm="12 mb-2 mt-5" className="text-justify">
          <h4>
            <span style={{ color: Color.black }}>
              <b>{t('LandingPage.personalityTest')}</b>
            </span>
            <br />
          </h4>
        </Col>
        <CardCollapse
          id="q25"
          question={t('LandingPage.whatIsPersonalityTest')}
          answer={t('LandingPage.whatIsPersonalityTestAnsw')}
        />
        <CardCollapse
          id="q26"
          question={t('LandingPage.howToTakePersonalityTest')}
          answer={t('LandingPage.howToTakePersonalityTestAnsw')}
        />
        <CardCollapse
          id="q27"
          question={t('LandingPage.howToSeePersonalityTestResults')}
          answer={t('LandingPage.howToSeePersonalityTestResultsAnsw')}
        />

        <Col sm="12 mb-2 mt-5" className="text-justify">
          <h4>
            <span style={{ color: Color.black }}>
              <b>{t('LandingPage.biometricTest')}</b>
            </span>
            <br />
          </h4>
        </Col>
        <CardCollapse
          id="q28"
          question={t('LandingPage.whatIsBiometricTest')}
          answer={t('LandingPage.whatIsBiometricTestAnsw')}
        />
        <CardCollapse
          id="q29"
          question={t('LandingPage.howToTakeBiometricTest')}
          answer={t('LandingPage.howToTakeBiometricTestAnsw')}
        />
        <CardCollapse
          id="q30"
          question={t('LandingPage.howToSeeBiometricTestResults')}
          answer={t('LandingPage.howToSeeBiometricTestResultsAnsw')}
        />

        <Col sm="12 mb-2 mt-5" className="text-justify">
          <h4>
            <span style={{ color: Color.black }}>
              <b>{t('LandingPage.leadershipTest')}</b>
            </span>
            <br />
          </h4>
        </Col>
        <CardCollapse
          id="q31"
          question={t('LandingPage.whatIsLeadershipTest')}
          answer={t('LandingPage.whatIsLeadershipTestAnsw')}
        />
        <CardCollapse
          id="q32"
          question={t('LandingPage.howToTakeLeadershipTest')}
          answer={t('LandingPage.howToTakeLeadershipTestAnsw')}
        />
        <CardCollapse
          id="q33"
          question={t('LandingPage.howToSeeLeadershipTestResults')}
          answer={t('LandingPage.howToSeeLeadershipTestResultsAnsw2')}
        />

        <Col sm="12 mb-2 mt-5" className="text-justify">
          <h4>
            <span style={{ color: Color.black }}>
              <b>{t('LandingPage.businessInsightTest')}</b>
            </span>
            <br />
          </h4>
        </Col>
        <CardCollapse
          id="q34"
          question={t('LandingPage.whatIsBusinessInsightTest')}
          answer={t('LandingPage.whatIsBusinessInsightTestAnsw')}
        />
        <CardCollapse
          id="q35"
          question={t('LandingPage.howToTakeBusinessInsightTest')}
          answer={t('LandingPage.howToTakeBusinessInsightTestAnsw')}
        />
        <CardCollapse
          id="q36"
          question={t('LandingPage.howToSeeBusinessInsightTestResults')}
          answer={t('LandingPage.howToSeeBusinessInsightTestResultsAnsw')}
        />

        {/* <Col sm="12 mb-2 mt-5" className="text-justify">
          <h4>
            <span style={{ color: Color.black }}>
              <b>Tes IQ</b>
            </span>
            <br />
          </h4>
        </Col>
        <CardCollapse
          id="q34"
          question="Apa itu tes IQ?"
          answer="Tes IQ merupakan salah satu tes nonverbal yang dikembangkan oleh John C. Raven pada tahun 1936. Tes ini biasanya digunakan dalam mengukur kecerdasan manusia secara umum dan penalaran abstrak dari pemecahan masalah individu. Kecerdasan umum yang diukur dalam tes ini terdapat dua komponen antara lain kemampuan untuk berpikir mengenai ide-ide kompleks dan kemampuan untuk menyimpan dan mengingat informasi. "
        />
        <CardCollapse
          id="q35"
          question="Bagaimana cara mengerjakan tes IQ?"
          answer="Hal utama yang perlu diperhatikan sebelum mengerjakan tes adalah kamu perlu melakukan tes ini dalam keadaan yang tenang agar hasilnya optimal. Pada setiap soal di tes IQ ini, kamu akan diberikan beberapa gambar dengan pola tertentu. Namun, pada gambar dan pola tersebut akan ada satu bagian kosong, yang perlu kamu lakukan adalah memilih salah satu gambar atau pola yang menurutmu melengkapi gambar dan pola tersebut melalui pilihan yang tersedia. Jika kamu sudah yakin dengan jawabanmu, kamu bisa klik submit untuk mengumpulkan jawabanmu."
        />
        <CardCollapse
          id="q36"
          question="Bagaimana cara melihat hasil tes IQ?"
          answer="Setelah selesai melakukan tes IQ, kamu bisa mendapatkan hasil tes secara langsung. Jika ingin melihatnya kembali kamu bisa akses langsung melalui menu 'Asesmenku' dan memilih tes IQ."
        /> */}
      </Row>
    </Fragment>
  );
})
