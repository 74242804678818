import React, { Fragment } from "react";
import { translate } from "react-switch-lang";
import { useState } from "react";
import { Formik, Field, Form } from "formik";
import FormikInput from "../../../../../../utils/FormikInput";
import { toast } from "react-toastify";
import request from "../../../../../../utils/Request";
import { useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Modal,
  ModalBody,
  Spinner,
  Label,
} from "reactstrap";

const Organization = (props) => {
  const { t } = props;
  const [modalTitle, setModalTitle] = useState("");
  const [nonFormals, setNonFormals] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDocumentChange, setIsDocumentChange] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [isDataNonFormal, setIsDataNonFormal] = useState(false);
  const [image, setImage] = useState({
    preview: "",
    raw: "",
  });

  const [valueLevelFormal, setValueLevelFormal] = useState({
    value: "",
    label: "",
  });

  const [addDataNonFormal, setAddDataNonFormal] = useState({
    id: null,
    yearStart: "",
    yearEnd: "",
    institution: "",
    number: "",
    certificate: null,
    title: props.title,
  });

  const getDataNonFormal = async () => {
    try {
      const res = await request.get("/users/resumes-training");
      const nonFormals = res.data.data;
      setNonFormals(nonFormals);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.errors.message || "Error", {
          autoClose: 2000,
        });
      }
    }
  };

  useEffect(() => {
    getDataNonFormal();
  }, []);

  const modalAddDataNonFormal = () => {
    setModalData(!modalData);
    setAddDataNonFormal({
      id: null,
      yearStart: "",
      yearEnd: "",
      institution: "",
      number: "",
      title: "",
      certificate: null,
    });
    setImage(image);
    setValueLevelFormal(valueLevelFormal);
    setModalTitle(t("Profile.organization"));
    setIsDataNonFormal(true);
    setIsUpdate(false);
    setIsDocumentChange(false);
  };

  const formValidate = (values) => {
    let errors = {};
    // let patternTextNumber = new RegExp(/^[0-9a-zA-Z-/.\b]+$/);
    // let patternText = new RegExp(/^[a-zA-Z\s]+$/);
    if (isDataNonFormal === true) {
      if (values.yearEnd !== null) {
        if (values.yearStart > values.yearEnd) {
          errors.yearStart = t("Profile.startYearCannotExceed");
        }
      }
      // if (values.number !== null) {
      //   if (!patternTextNumber.test(values.number)) {
      //     errors.number = "Nomor Sertifikat Kamu tidak valid";
      //   }
      // }

      // if (values.major !== null) {
      //   if (
      //     valueLevelFormal.value !== "sd" &&
      //     valueLevelFormal.value !== "smp"
      //     ) {
      //     if (!patternText.test(values.major)) {
      //       errors.major = "Nama Jurusan hanya boleh huruf";
      //     }
      //   }
      // }
      return errors;
    }
  };

  const cancelModal = (e) => {
    e.preventDefault();
    setModalData(false);
    setIsDataNonFormal(false);
    setIsUpdate(false);
    setIsDocumentChange(false);
    setImage({
      preview: "",
      raw: "",
    });
    setValueLevelFormal({
      value: "",
      label: "",
    });
  };

  const formSubmit = async (values, { setSubmitting, setErrors }) => {
    let formData = new FormData();
    if (isDocumentChange === true) {
      formData.append(
        "certificate",
        addDataNonFormal.certificate,
        addDataNonFormal.certificate.name
      );
    }

    //     if (values.institution === null || values.institution === "") {
    //       setSubmitting(false);
    //       return toast.error("wajib mengisi organisasi", { autoClose: 3000 });
    //     }
    //
    //     if (values.title === null || values.title === "") {
    //       setSubmitting(false);
    //       return toast.error("wajib mengisi bidang organisasi", {
    //         autoClose: 3000,
    //       });
    //     }

    // if (values.yearStart === null || values.yearStart === "") {
    //   setSubmitting(false);
    //   return toast.error("tahun mulai wajib diisi ya", { autoClose: 3000 });
    // }

    if (isUpdate === true) {
      formData.append("title", values.title);
      formData.append("number", values.number);
      formData.append("yearStart", values.yearStart);
      formData.append("yearEnd", values.yearEnd);
      formData.append("institution", values.institution);
      try {
        await request.post(
          "/users/resumes-training/" + addDataNonFormal.id,
          formData
        );
        setModalData(false);
        getDataNonFormal();
        toast.success(t("General.success"), { autoClose: 3000 });
      } catch (err) {
        if (err.response) {
          // toast.error("Error : " + JSON.stringify(err.response.data.errors), {
          if (err.response.data.errors.yearEnd) {
            toast.error(t("yearOnlyNumber"), {
              autoClose: 2000,
            });
          } else if (err.response.data.errors.yearStart) {
            toast.error(t("yearOnlyNumber"), {
              autoClose: 2000,
            });
          }
        }
      } finally {
        setLoadingButton(false);
      }
    }
    if (isUpdate === false) {
      formData.append("title", values.title);
      formData.append("number", values.number);
      formData.append("yearStart", values.yearStart);
      formData.append("yearEnd", values.yearEnd);
      formData.append("institution", values.institution);
      try {
        await request.post("/users/resumes-training", formData);
        setModalData(false);
        getDataNonFormal();
        toast.success(t("General.success"), { autoClose: 3000 });
      } catch (err) {
        if (err.response) {
          toast.error("Error : " + JSON.stringify(err.response.data.errors), {
            autoClose: 2000,
          });
        }
      } finally {
        setLoadingButton(false);
      }
    }
  };
  const deleteData = async (e, id) => {
    setDeleteButton(true);
    try {
      await request.delete("/users/resumes-training/" + id);
      setTimeout(() => {
        setModalData(!modalData);
        setAddDataNonFormal({
          id: null,
          yearStart: "",
          yearEnd: "",
          institution: "",
          number: "",
          title: "",
          certificate: null,
        });
        setImage({
          preview: "",
          raw: "",
        });
        setDeleteButton(false);
        setIsUpdate(false);
        setIsDataNonFormal(false);
        setIsDocumentChange(false);
        setLoadingButton(false);
        getDataNonFormal();
        toast.success(t("General.success"), { autoClose: 3000 });
      }, 500);
    } catch (err) {
      if (err.response) {
        setDeleteButton(false);
        toast.error("Error : " + JSON.stringify(err.response.data.errors), {
          autoClose: 2000,
        });
      }
    }
  };
  const modalEditDataNonFormal = (data) => {
    setAddDataNonFormal({
      id: data.id,
      yearStart: data.yearStart,
      yearEnd: data.yearEnd,
      institution: data.institution,
      number: data.number,
      title: data.title,
      certificate: data.certificate,
    });
    let addDataNew = { ...addDataNonFormal };
    addDataNew = data;
    if (addDataNew.yearStart === 0) {
      addDataNew.yearStart = "";
    }
    if (addDataNew.yearEnd === 0) {
      addDataNew.yearEnd = "";
    }
    setModalData(!modalData);
    setAddDataNonFormal(addDataNew);
    setModalTitle(t("Profile.organization"));
    setIsDataNonFormal(true);
    setImage({
      preview: "",
      raw: "",
    });
    setIsUpdate(true);
    setIsDocumentChange(false);
  };

  let institution;
  let title;
  let yearStart;
  let yearEnd;
  const itemNonFormals =
    nonFormals.length > 0
      ? nonFormals.map((data, idx) => {
          if (
            data.institution === undefined ||
            data.institution === null ||
            data.institution === "" ||
            data.institution === 0
          ) {
            institution = "-";
          } else {
            institution = data.institution;
          }

          if (
            data.title === undefined ||
            data.title === null ||
            data.title === "" ||
            data.title === 0
          ) {
            title = "-";
          } else {
            title = data.title;
          }

          if (
            data.yearStart === undefined ||
            data.yearStart === null ||
            data.yearStart === "" ||
            data.yearStart === 0
          ) {
            yearStart = "-";
          } else {
            yearStart = data.yearStart;
          }

          if (
            data.yearEnd === undefined ||
            data.yearEnd === null ||
            data.yearEnd === "" ||
            data.yearEnd === 0
          ) {
            yearEnd = "-";
          } else {
            yearEnd = data.yearEnd;
          }

          return (
            <tr key={idx}>
              <td className="text-center">{idx + 1}.</td>
              <td>{institution}</td>
              <td>{title}</td>
              <td className="text-center">{yearStart}</td>
              <td className="text-center">{yearEnd}</td>
              <td className="text-center">
                {props.editProfilePro === true ? (
                  <Button
                    color="secondary"
                    onClick={() => modalEditDataNonFormal(data)}
                    outline
                  >
                    <i className="fa fa-pencil mr-2"></i>
                    &nbsp;Edit
                  </Button>
                ) : null}
              </td>
            </tr>
          );
        })
      : null;
  return (
    <Fragment>
      <div className="content-body">
        <Row className="md-company-header mb-3">
          <Col className="d-flex justify-content-between align-items-center">
            <Label htmlFor="country" className="input-label">
              {t("Profile.organization")}
            </Label>
            {props.editProfilePro === true ? (
              <Button
                color="netis-color"
                onClick={() => modalAddDataNonFormal()}
                style={{ backgroundColor: "#5AADF7" }}
              >
                <i className="fa fa-plus mr-2"></i>
                {t("General.add")} Data
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="12">
            <Table responsive>
              <thead>
                <tr>
                  <th className="text-center w-5">#</th>
                  <th className="w-30">{t("Profile.nameOrganization")}</th>
                  <th className="w-25">{t("Profile.fieldOrganization")}</th>

                  <th className="text-center w-15">{t("Profile.startYear")}</th>
                  <th className="text-center w-15">{t("Profile.endYear")}</th>
                  <th className="text-center w-20">{t("General.action")}</th>
                </tr>
              </thead>
              <tbody>{itemNonFormals}</tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Modal isOpen={modalData} className={"w-100 modal-lg " + props.className}>
        <ModalBody>
          <h5 className="content-sub-title mb-4">{modalTitle}</h5>
          <Formik
            initialValues={addDataNonFormal}
            validate={formValidate}
            onSubmit={formSubmit}
            render={({ isSubmitting }) => (
              <Form>
                {isDataNonFormal === true ? (
                  <div className="col-md-12">
                    <Field
                      htmlFor="institution"
                      type="text"
                      label={t("Profile.nameOrganization")}
                      name="institution"
                      id="institution"
                      className="input-label"
                      value={addDataNonFormal.institution}
                      component={FormikInput}
                    />
                    <Field
                      htmlFor="title"
                      type="text"
                      label={t("Profile.fieldOrganization")}
                      name="title"
                      id="title"
                      className="input-label"
                      value={addDataNonFormal.title}
                      component={FormikInput}
                    />
                    <Field
                      htmlFor="yearStart"
                      label={t("Profile.startYear")}
                      type="text"
                      name="yearStart"
                      id="yearStart"
                      value={addDataNonFormal.yearStart}
                      className="input-label"
                      component={FormikInput}
                    />
                    <Field
                      htmlFor="yearEnd"
                      label={t("Profile.endYear")}
                      type="number"
                      name="yearEnd"
                      id="yearEnd"
                      min="1900"
                      max="2999"
                      value={addDataNonFormal.yearEnd}
                      className="input-label"
                      component={FormikInput}
                    />
                  </div>
                ) : null}
                <Row>
                  <div className="col-8 d-flex ">
                    {isUpdate === false ? null : isDataNonFormal === true ? (
                      <Button
                        className="navtext"
                        color="red-custom"
                        disabled={deleteButton}
                        onClick={(e) => deleteData(e, addDataNonFormal.id)}
                      >
                        {deleteButton ? (
                          <span>
                            <Spinner size="sm" className="mr-2" /> Loading
                          </span>
                        ) : (
                          t("General.delete") + " Data"
                        )}
                      </Button>
                    ) : null}
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    {loadingButton ? (
                      <Spinner color="dark" size="sm" />
                    ) : (
                      <Fragment>
                        <Button
                          className="navtext mr-2"
                          color="white"
                          onClick={cancelModal}
                        >
                          {t("General.cancel")}
                        </Button>
                        <Button
                          className="navtext"
                          type="submit"
                          color="netis-color"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <span>
                              <Spinner size="sm" className="mr-2" /> Loading
                            </span>
                          ) : (
                            t("General.save")
                          )}
                        </Button>
                      </Fragment>
                    )}
                  </div>
                </Row>
              </Form>
            )}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default translate(Organization);
