import React, { useState, Fragment } from "react";
import { Container, Alert, Spinner, Button, Row, Col } from "reactstrap";
import { toast } from "react-toastify";
import request from "../../../../utils/Request";
import { Formik, Field, Form } from "formik";
import { Link } from "react-router-dom";
import FormikInput from "../../../../utils/FormikInput";
import { translate } from "react-switch-lang";
import langUtils from "../../../../utils/SettingLanguage/index";
toast.configure();
const API_DOMAIN = process.env.REACT_APP_DOMAIN;

export default translate(function ForgotPassword(props) {
  const [success, setSuccess] = useState(false);
  const formValues = { email: "" };
  const { t } = props;
  const formValidate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = t("General.cannotEmpty");
    }

    return errors;
  };
  const formSubmit = (values, { setSubmitting, setErrors }) => {
    const { email } = values;
    request
      .post(`${API_DOMAIN}/api/auth/newpassword/request`, {
        email,
        lang: langUtils.getLanguage(),
      })
      .then((res) => {
        toast.success(t("General.checkEmail"));
        setSuccess(true);
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          setErrors(err.response.data.errors);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="navtext justify-content-center">
          <Col md="4" />
          <Col md="4">
            <Formik
              initialValues={formValues}
              validate={formValidate}
              onSubmit={formSubmit}
              render={({ isSubmitting }) => (
                <Form>
                  <div className="logo text-center">
                    <img
                      src={require("../../../../assets/img/logo.png")}
                      style={{ width: 180 }}
                      alt="logo"
                    />
                  </div>

                  <Alert color="success" className="navtext mt-3 text-center">
                    {t("ForgotPassword.title")}
                  </Alert>

                  {success ? (
                    <Alert color="info" className="text-center mt-3">
                      <strong>{t("General.success")} !</strong>
                      <br />
                      <p>{t("General.checkEmail")}</p>
                    </Alert>
                  ) : (
                    <Fragment>
                      <Field
                        type="email"
                        label="Email"
                        name="email"
                        id="email"
                        component={FormikInput}
                      />
                      <Button
                        type="submit"
                        className="navtext btn btn-warning login-submit"
                        disabled={isSubmitting}
                        style={{ border: "none", fontWeight: "bold" }}
                      >
                        {isSubmitting ? (
                          <span>
                            <Spinner size="sm" className="mr-2" /> Loading..
                          </span>
                        ) : (
                          t("ForgotPassword.button")
                        )}
                      </Button>
                    </Fragment>
                  )}
                  <div className="navtext mt-5">
                    <Link to="/login">
                      <i className=" mr-2 fa fa-chevron-left"></i>{" "}
                      {t("General.goToBack")} Login
                    </Link>
                  </div>
                </Form>
              )}
            />
          </Col>
          <Col md="4"></Col>
        </Row>
      </Container>
    </div>
  );
});
