import React, { Fragment } from "react";
import { translate } from "react-switch-lang";
import { useState } from "react";
import { Formik, Field, Form } from "formik";
import Select from "react-select";
import FormikInput from "../../../../../../utils/FormikInput";
import {
  Label,
  Modal,
  ModalBody,
  Row,
  FormGroup,
  Input,
  Col,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import request from "../../../../../../utils/Request";
import { useEffect } from "react";

const Education = (props) => {
  const { t } = props;
  const [modalTitle, setModalTitle] = useState("");
  const [formals, setFormals] = useState([]);
  const [isDataFormal, setIsDataFormal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDocumentChange, setIsDocumentChange] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [image, setImage] = useState({
    preview: "",
    raw: "",
  });
  const [valueLevelFormal, setValueLevelFormal] = useState({
    value: "",
    label: "",
  });
  const levelFormal = [
    { value: "sd", label: t("Lists.SD/MI (sederajat)") },
    { value: "smp", label: t("Lists.SMP/MTs (sederajat)") },
    { value: "sma", label: t("Lists.SMA/SMK/MA (sederajat)") },
    { value: "d1", label: t("Lists.D1") },
    { value: "d2", label: t("Lists.D2") },
    { value: "d3", label: t("Lists.D3") },
    { value: "d4", label: t("Lists.D4") },
    { value: "s1", label: t("Lists.S1") },
    { value: "s2", label: t("Lists.S2") },
    { value: "s3", label: t("Lists.S3") },
  ];
  const [addDataFormal, setAddDataFormal] = useState({
    id: null,
    LevelFormal: "",
    yearStart: "",
    yearEnd: "",
    institution: "",
    number: "",
    level: "",
    major: "",
    certificate: null,
  });

  const modalAddDataFormal = () => {
    const { t } = props;
    setModalData(!modalData);
    setAddDataFormal({
      id: null,
      LevelFormal: "",
      yearStart: "",
      yearEnd: "",
      institution: "",
      major: "",
      number: "",
      certificate: null,
    });
    setValueLevelFormal(false);
    setImage(image);
    setModalTitle(t("Profile.formalEducation"));
    setIsDataFormal(true);
    setIsUpdate(false);
    setIsDocumentChange(false);
  };

  const onChangeDropdown = (value) => {
    if (isDataFormal) {
      let addDataNew = { ...addDataFormal };
      addDataNew["level"] = value.value;
      setAddDataFormal(addDataNew);
      setValueLevelFormal({
        label: value.label,
        value: value.value,
      });
    }
  };
  const getDataFormal = async () => {
    try {
      const res = await request.get("/users/resumes-education");
      const formals = res.data.data;
      setFormals(formals);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.errors.message || "Error", {
          autoClose: 2000,
        });
      }
    }
  };

  useEffect(() => {
    getDataFormal();
  }, []);

  const handleChangeDocument = (e) => {
    if (e.target.files.length) {
      var preview = { ...image };
      preview["preview"] = URL.createObjectURL(e.target.files[0]);
      setAddDataFormal({
        ...addDataFormal,
        certificate: e.target.files[0],
      });
      setImage(preview);
      setIsDocumentChange(true);
    }
  };

  const formValidate = (values) => {
    let errors = {};
    // let patternTextNumber = new RegExp(/^[0-9a-zA-Z-/.\b]+$/);
    // let patternText = new RegExp(/^[a-zA-Z\s]+$/);
    if (isDataFormal === true) {
      if (values.yearEnd !== null) {
        if (values.yearStart > values.yearEnd) {
          errors.yearStart = t("Profile.startYearCannotExceed");
        }
      }
      // if (values.number !== null) {
      //   if (!patternTextNumber.test(values.number)) {
      //     errors.number = "Nomor Sertifikat Kamu tidak valid";
      //   }
      // }

      // if (values.major !== null) {
      //   if (
      //     valueLevelFormal.value !== "sd" &&
      //     valueLevelFormal.value !== "smp"
      //     ) {
      //     if (!patternText.test(values.major)) {
      //       errors.major = "Nama Jurusan hanya boleh huruf";
      //     }
      //   }
      // }
      return errors;
    }
  };

  const cancelModal = (e) => {
    e.preventDefault();
    setModalData(false);
    setIsDataFormal(false);
    setIsUpdate(false);
    setIsDocumentChange(false);
    setImage({
      preview: "",
      raw: "",
    });
    setValueLevelFormal({
      value: "",
      label: "",
    });
  };

  const formSubmit = async (values, { setSubmitting, setErrors }) => {
    let formData = new FormData();
    if (isDocumentChange === true) {
      formData.append(
        "certificate",
        addDataFormal.certificate,
        addDataFormal.certificate.name
      );
    }

    if (values.institution === null || values.institution === "") {
      setSubmitting(false);
      return toast.error("institusi wajib diisi ya", { autoClose: 3000 });
    }

    // if (values.major === null || values.major === "") {
    //   if (valueLevelFormal.value !== "sd" && valueLevelFormal.value !== "smp") {
    //     setSubmitting(false);
    //     return toast.error("Jurusan wajib diisi ya", { autoClose: 3000 });
    //   }
    // }

    // if (values.yearStart === null || values.yearStart === "") {
    //   setSubmitting(false);
    //   return toast.error("tahun mulai wajib diisi ya", { autoClose: 3000 });
    // }

    if (isUpdate === true) {
      formData.append("major", values.major);
      formData.append("level", valueLevelFormal.value);
      formData.append("number", values.number);
      formData.append("yearStart", values.yearStart);
      formData.append("yearEnd", values.yearEnd);
      formData.append("institution", values.institution);
      try {
        await request.post(
          "/users/resumes-education/" + addDataFormal.id,
          formData
        );
        setModalData(false);
        getDataFormal();
        toast.success(t("General.success"), { autoClose: 3000 });
      } catch (err) {
        if (err.response) {
          toast.error("Error : " + JSON.stringify(err.response.data.errors), {
            autoClose: 15000,
          });
        }
      } finally {
        setLoadingButton(setLoadingButton === true);
      }
    }
    if (isUpdate === false) {
      formData.append("major", values.major);
      formData.append("level", valueLevelFormal.value);
      formData.append("number", values.number);
      formData.append("yearStart", values.yearStart);
      formData.append("yearEnd", values.yearEnd);
      formData.append("institution", values.institution);
      try {
        await request.post("/users/resumes-education", formData);
        setModalData(false);
        getDataFormal();
        toast.success(t("General.success"), { autoClose: 3000 });
      } catch (err) {
        if (err.response) {
          toast.error(t("Profile.levelEducation") + t("General.cannotEmpty"), {
            autoClose: 3000,
          });
        }
      } finally {
        setLoadingButton(setLoadingButton === true);
      }
    }
  };

  const modalEditDataFormal = (data) => {
    setAddDataFormal({
      id: data.id,
      yearStart: data.yearStart,
      yearEnd: data.yearEnd,
      institution: data.institution,
      number: data.number,
      major:
        data.major !==
        (valueLevelFormal.value === "sd" || valueLevelFormal === "smp")
          ? data.major
          : null,
      certificate: data.certificate,
    });
    const { t } = props;
    let addDataNew = { ...addDataFormal };
    addDataNew = data;
    if (addDataNew.yearStart === 0 || addDataNew.yearStart === "") {
      addDataNew.yearStart = "";
    }
    if (addDataNew.yearEnd === 0 || addDataNew.yearEnd === "") {
      addDataNew.yearEnd = "";
    }
    setModalData(!modalData);
    setAddDataFormal(addDataNew);
    setModalTitle(t("Profile.formalEducation"));
    setIsDataFormal(true);
    setImage(image);
    setIsUpdate(true);
    setIsDocumentChange(false);
    setValueLevelFormal({
      value: data.level,
      label:
        data.level.toUpperCase() === "SD"
          ? t("Lists.SD/MI (sederajat)")
          : data.level.toUpperCase() && data.level.toUpperCase() === "SMP"
          ? t("Lists.SMP/MTs (sederajat)")
          : data.level.toUpperCase() && data.level.toUpperCase() === "SMA"
          ? t("Lists.SMA/SMK/MA (sederajat)")
          : t(data.level.toUpperCase()),
    });
  };

  const deleteData = async (e, id) => {
    setDeleteButton(true);
    try {
      await request.delete("/users/resumes-education/" + id);
      setTimeout(() => {
        setModalData(!modalData);
        setAddDataFormal({
          id: null,
          yearStart: "",
          yearEnd: "",
          institution: "",
          number: "",
          level: "",
          major: "",
          certificate: null,
        });
        setImage({
          preview: "",
          raw: "",
        });
        setDeleteButton(false);
        setIsUpdate(false);
        setIsDataFormal(false);
        setIsDocumentChange(false);
        setLoadingButton(false);
        getDataFormal();
        toast.success(t("General.success"), { autoClose: 3000 });
      }, 500);
    } catch (err) {
      if (err.response) {
        setDeleteButton(false);
        toast.error("Error : " + JSON.stringify(err.response.data.errors), {
          autoClose: 2000,
        });
      }
    }
  };

  let major;
  let yearStart;
  let yearEnd;
  const itemFormals =
    formals.length > 0
      ? formals.map((data, idx) => {
          if (
            data.major === undefined ||
            data.major === null ||
            data.major === "" ||
            data.major === 0 ||
            data.level === "sd" ||
            data.level === "smp"
          ) {
            major = "-";
          } else {
            major = data.major;
          }

          if (
            data.yearStart === undefined ||
            data.yearStart === null ||
            data.yearStart === "" ||
            data.yearStart === 0
          ) {
            yearStart = "-";
          } else {
            yearStart = data.yearStart;
          }

          if (
            data.yearEnd === undefined ||
            data.yearEnd === null ||
            data.yearEnd === "" ||
            data.yearEnd === 0
          ) {
            yearEnd = "-";
          } else {
            yearEnd = data.yearEnd;
          }
          return (
            <tr key={idx}>
              <td className="text-center">{idx + 1}.</td>
              <td>
                {data.level.toUpperCase() === "SD"
                  ? t("Lists.SD/MI (sederajat)")
                  : data.level.toUpperCase() &&
                    data.level.toUpperCase() === "SMP"
                  ? t("Lists.SMP/MTs (sederajat)")
                  : data.level.toUpperCase() &&
                    data.level.toUpperCase() === "SMA"
                  ? t("Lists.SMA/SMK/MA (sederajat)")
                  : t("Lists." + data.level.toUpperCase())}
              </td>
              <td>{data.institution}</td>
              <td>{major}</td>
              <td className="text-center">{yearStart}</td>
              <td className="text-center">{yearEnd}</td>
              <td className="text-center">
                {props.editProfilePro === true ? (
                  <Button
                    color="secondary"
                    onClick={() => modalEditDataFormal(data)}
                    outline
                  >
                    <i className="fa fa-pencil mr-2"></i>
                    &nbsp;Edit
                  </Button>
                ) : null}
              </td>
            </tr>
          );
        })
      : null;

  return (
    <Fragment>
      <div className="content-body">
        <Row className="md-company-header mb-3">
          <Col className="d-flex justify-content-between align-items-center">
            <Label htmlFor="country" className="input-label">
              {t("Profile.formalEducation")}
            </Label>
            {props.editProfilePro === true ? (
              <Button
                color="netis-color"
                onClick={() => modalAddDataFormal()}
                style={{ backgroundColor: "#5AADF7" }}
              >
                <i className="fa fa-plus mr-2"></i>
                {t("General.add")} Data
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="12">
            <Table responsive>
              <thead>
                <tr>
                  <th className="text-center w-5">#</th>

                  <th className="w-20">{t("Profile.educationLevel")}</th>
                  <th className="w-20">{t("Profile.institute")}</th>
                  <th className="w-15">{t("Profile.major")}</th>
                  <th className="text-center w-15">{t("Profile.startYear")}</th>
                  <th className="text-center w-15">{t("Profile.endYear")}</th>
                  <th className="text-center w-20">{t("General.action")}</th>
                </tr>
              </thead>
              <tbody>{itemFormals}</tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Modal isOpen={modalData} className={"modal-lg " + props.className}>
        <ModalBody>
          <h5 className="content-sub-title mb-4">{modalTitle}</h5>
          <Formik
            initialValues={addDataFormal}
            validate={formValidate}
            onSubmit={formSubmit}
            render={({ isSubmitting }) => (
              <Form>
                <Row className="mb-4">
                  <div className="col-md-6 d-flex justify-content-center align-items-center">
                    {addDataFormal.certificate === null ? (
                      null ? (
                        <Row>
                          <div className="col-md-12 d-flex justify-content-center align-items-center">
                            <p>
                              {t("Profile.uploadFileFormat")} .png, .jpg, .jpeg{" "}
                              {t("General.maximum")} 5 Mb
                            </p>
                          </div>
                          <div className="col-md-12 d-flex justify-content-center align-items-center">
                            <FormGroup>
                              <img
                                src={image.preview}
                                alt="preview"
                                width="200"
                                height="200"
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-12 d-flex justify-content-center align-items-center">
                            <FormGroup>
                              <Input
                                type="file"
                                id="certificate"
                                name="certificate"
                                onChange={handleChangeDocument}
                              />
                            </FormGroup>
                          </div>
                        </Row>
                      ) : (
                        <Row>
                          <div className="col-md-12 d-flex justify-content-center align-items-center">
                            <p>
                              {t("Profile.uploadFileFormat")} .png, .jpg, .jpeg{" "}
                              {t("General.maximum")} 5 Mb
                            </p>
                          </div>
                          <div className="col-md-12 d-flex justify-content-center align-items-center">
                            <div className="input-personnel-document d-flex justify-content-center align-items-center">
                              <Label
                                htmlFor="certificate"
                                className="input-label btn-circle"
                              >
                                <span className="fa fa-plus"></span>
                              </Label>
                              <Input
                                type="file"
                                id="certificate"
                                name="certificate"
                                className="d-none"
                                onChange={handleChangeDocument}
                              />
                            </div>
                          </div>
                        </Row>
                      )
                    ) : image.preview ? (
                      <Row>
                        <div className="col-md-12 d-flex justify-content-center align-items-center">
                          <p>
                            {t("Profile.uploadFileFormat")} .png, .jpg, .jpeg{" "}
                            {t("General.maximum")} 5 Mb
                          </p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-center align-items-center">
                          <FormGroup>
                            <img
                              src={image.preview}
                              alt="dummy"
                              width="200"
                              height="200"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-12 d-flex justify-content-center align-items-center">
                          <FormGroup>
                            <Input
                              type="file"
                              id="certificate"
                              name="certificate"
                              onChange={handleChangeDocument}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                    ) : (
                      <Row>
                        <div className="col-md-12 d-flex justify-content-center align-items-center">
                          <p>
                            {t("Profile.uploadFileFormat")} .png, .jpg, .jpeg{" "}
                            {t("General.maximum")} 5 Mb
                          </p>
                        </div>

                        <div className="col-md-12 d-flex justify-content-center align-items-center">
                          <FormGroup>
                            <img
                              src={
                                process.env.REACT_APP_DOMAIN +
                                "" +
                                addDataFormal.certificate
                              }
                              alt="dummy"
                              width="300"
                              height="300"
                            />
                          </FormGroup>
                        </div>
                        <br />
                        <div className="col-md-12 d-flex justify-content-center align-items-center">
                          <FormGroup>
                            <Input
                              type="file"
                              id="certificate"
                              name="certificate"
                              onChange={handleChangeDocument}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                    )}
                  </div>
                  <div className="col-md-6">
                    <Label htmlFor="yearEnd" className="input-label">
                      {t("Profile.levelEducation")}
                    </Label>
                    <Select
                      value={valueLevelFormal}
                      onChange={(value) => onChangeDropdown(value)}
                      options={levelFormal}
                    />
                    <Field
                      type="text"
                      label={t("Profile.institute")}
                      name="institution"
                      id="institution"
                      htmlFor="institution"
                      className="input-label"
                      placeholder={t("Profile.institute")}
                      value={addDataFormal.institution}
                      component={FormikInput}
                    />

                    {valueLevelFormal.value === "sd" ||
                    valueLevelFormal.value === "smp" ? null : (
                      <Field
                        type="text"
                        label={t("Profile.major")}
                        name="major"
                        id="major"
                        htmlFor="major"
                        className="input-label"
                        placeholder={t("Profile.major")}
                        value={addDataFormal.major}
                        component={FormikInput}
                      />
                    )}
                    <Field
                      type="number"
                      label={t("Profile.startYear")}
                      name="yearStart"
                      id="yearStart"
                      min="1900"
                      max="2999"
                      htmlFor="yearStart"
                      className="input-label"
                      placeholder={t("Profile.startYear")}
                      value={addDataFormal.yearStart}
                      component={FormikInput}
                    />
                    <Field
                      type="number"
                      label={t("Profile.endYear")}
                      name="yearEnd"
                      id="yearEnd"
                      min="1900"
                      max="2999"
                      htmlFor="yearEnd"
                      className="input-label"
                      placeholder={t("Profile.endYear")}
                      value={addDataFormal.yearEnd}
                      component={FormikInput}
                    />
                    <Field
                      type="text"
                      label={t("Profile.certificateSerialNumber")}
                      name="number"
                      id="number"
                      htmlFor="number"
                      className="input-label"
                      placeholder={t("Profile.certificateSerialNumber")}
                      value={addDataFormal.number}
                      component={FormikInput}
                    />
                  </div>
                  <Row className="button-group-modal">
                    <div className="col-8 d-flex ">
                      {isUpdate === false ? null : isDataFormal === true ? (
                        <Button
                          disabled={deleteButton}
                          className="navtext"
                          color="red-custom"
                          onClick={(e) => deleteData(e, addDataFormal.id)}
                        >
                          {deleteButton ? (
                            <span>
                              <Spinner size="sm" className="mr-2" /> Loading
                            </span>
                          ) : (
                            t("General.delete") + " Data"
                          )}
                        </Button>
                      ) : null}
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                      {loadingButton ? (
                        <Spinner color="dark" size="sm" />
                      ) : (
                        <Fragment>
                          <Button
                            className="navtext mr-2"
                            color="white"
                            onClick={cancelModal}
                          >
                            {t("General.cancel")}
                          </Button>
                          <Button
                            className="navtext"
                            type="submit"
                            color="netis-color"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <span>
                                <Spinner size="sm" className="mr-2" /> Loading
                              </span>
                            ) : (
                              t("General.save")
                            )}
                          </Button>
                        </Fragment>
                      )}
                    </div>
                  </Row>
                </Row>
              </Form>
            )}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default translate(Education);
