import React, { useState, useEffect, Fragment } from "react";
import FooterLandingPage from "../../../Templates/LandingPage/FooterLandingPage";
import {
  Row,
  Col,
  ListGroup,
  Label,
  Button,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { connect } from "react-redux";
import Color from "../../../../utils/Colors";
import { TabContent, TabPane } from "reactstrap";
import companyLogo from "../../../../assets/img/icons8-company.png";
import requestGeneral from "../../../../utils/RequestGeneral";
import moment from "../../../../utils/Moment";
import request from "../../../../utils/Request";
import LoadingAnimation from "../../../../components/atoms/LoadingAnimation";
import notFound from "../../../../assets/img/ops.png";
import notFoundEn from "../../../../assets/img/ops_en.png";
import noLogoCompany from "../../../../assets/img/company_icon.png";
import notFoundBookmark from "../../../../assets/img/ops_bookmark.png";
import notFoundBookmarkEn from "../../../../assets/img/ops_bookmark_en.jpeg";
import langUtils from "../../../../utils/SettingLanguage/index";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { HashLink } from "react-router-hash-link";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { translate, t } from "react-switch-lang";
import NotFound from "../../NotFound";
const AplikasiLamaran = (props) => {
  const [applicantStatus, setApplicantStatus] = useState([]);
  const [applicantBookmark, setApplicantBookmark] = useState([]);
  const [loading, setLoading] = useState(true);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);
  const accentColor = "#93aad6";
  const [isTourOpen, setIsTourOpen] = useState(false);
  moment.locale(langUtils.getLanguage());
  const getApplicantStatus = async () => {
    setLoading(true);
    try {
      const res = await requestGeneral.get(
        `/internal/recruitment/vacancies/status`
      );
      const applicantStatus = res.data.data;
      setApplicantStatus(applicantStatus);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const { t } = props;
  const getApplicantBookmark = async () => {
    setLoading(true);
    try {
      const res = await requestGeneral.get(
        `/internal/recruitment/vacancies/bookmark`
      );
      const applicantBookmark = res.data.data;
      setApplicantBookmark(applicantBookmark);
    } catch (err) {}
  };

  useEffect(() => {
    getApplicantStatus();
    getApplicantBookmark();
  }, []);
  useEffect(() => {
    if (props.user) {
      if (props.user.guideLamaran === 0) {
        setIsTourOpen(true);
      }
    }
  }, [props.user]); // eslint-disable-line react-hooks/exhaustive-deps
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const steps = [
    {
      selector: ".step-aplikasi-1",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.applicationJobList")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-12 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(1)}
                >
                  {t("UserGuide.next")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
    {
      selector: ".step-aplikasi-2",
      content: ({ goTo, inDOM }) => (
        <div>
          <p>{t("UserGuide.applicationSavedJobList")}</p>
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => goTo(0)}
                >
                  <i className="fa fa-arrow-left mr-2"></i>
                  {t("UserGuide.previous")}
                </Button>
              </div>
              <div className="col-6 text-center p-0">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={() => {
                    disableUserGuide();
                    enableBody();
                    setIsTourOpen(false);
                  }}
                >
                  {t("UserGuide.okUnderstand")}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      ),
    },
  ];
  function disableUserGuide() {
    request
      .put("/users/disable_user_guide_lamaran")
      .then((res) => {
        props.setUser({
          ...props.user,
          guideLamaran: 1,
        });
      })
      .catch((err) => {
        props.setUser({
          ...props.user,
          guideLamaran: 1,
        });
      });
  }
  if (localStorage.getItem("referal")) {
    return <NotFound />;
  }
  return (
    <div>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <Fragment>
          <Tour
            steps={steps}
            accentColor={accentColor}
            showButtons={false}
            rounded={5}
            isOpen={isTourOpen}
            disableFocusLock={false}
            onAfterOpen={disableBody}
            onBeforeClose={enableBody}
            onRequestClose={() => {
              disableUserGuide();
              setIsTourOpen(false);
            }}
          />

          <div className="aplikasi-lamaran">
            <Row
              style={{ margin: "1rem 0", display: "flex", flexWrap: "nowrap" }}
              className="title-aplikasi-lamaran"
            >
              <Col lg="2" sm="2">
                <Link to="/home">
                  <button className="navtext btn btn-netis-color">
                    <i className="fa fa-arrow-left mr-2"></i>
                    {t("General.back")}
                  </button>
                </Link>
                {/* <Button type="submit" color="netis-color" onClick={goBack} >Kembali</Button> */}
              </Col>
              <Col lg="8" sm="8" className="text-center">
                <h1 className="content-title">
                  {t("Applicant.myJobApplicant")}
                </h1>
              </Col>
              <Col lg="2" sm="2"></Col>
            </Row>
            <Nav className="navtext" tabs>
              {props.user.guideLamaran === 0 ? (
                <div style={{ display: "inherit" }}>
                  <NavItem>
                    <div
                      className="step-aplikasi-1"
                      style={{ display: "inherit" }}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                      >
                        {t("Applicant.myJobApplicant")}
                      </NavLink>
                    </div>
                  </NavItem>
                  <div
                    className="step-aplikasi-2"
                    style={{ display: "inherit" }}
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                      >
                        {t("Applicant.myJobApplicantSaved")}
                      </NavLink>
                    </NavItem>
                  </div>
                </div>
              ) : (
                <div style={{ display: "inherit" }}>
                  <NavItem>
                    <div
                      className="step-aplikasi-1"
                      style={{ display: "inherit" }}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        {t("Applicant.myJobApplicant")}
                      </NavLink>
                    </div>
                  </NavItem>
                  <div
                    className="step-aplikasi-2"
                    style={{ display: "inherit" }}
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        {t("Applicant.myJobApplicantSaved")}
                      </NavLink>
                    </NavItem>
                  </div>
                </div>
              )}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    {loading ? (
                      <LoadingAnimation />
                    ) : (
                      <Vacancy applicantStatus={applicantStatus} />
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    {loading ? (
                      <LoadingAnimation />
                    ) : (
                      <VacancyBookmark applicantStatus={applicantBookmark} />
                    )}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
          <FooterLandingPage />
        </Fragment>
      )}
    </div>
  );
};

const Vacancy = ({ applicantStatus }) => {
  return (
    <div className="app-list">
      <ListGroup style={{ width: "100%" }}>
        <Row>
          {applicantStatus.length > 0 ? (
            applicantStatus &&
            applicantStatus.map((vacancies) => {
              return (
                <Col lg="6" key={vacancies.id}>
                  <Link
                    to={`/jobs/${vacancies.id}/${vacancies.name
                      .toString()
                      .toLowerCase()
                      .normalize("NFD")
                      .trim()
                      .replace(/\s+/g, "-") // eslint-disable-next-line
                      .replace(/[^\w\-]+/g, "") // eslint-disable-next-line
                      .replace(/\-\-+/g, "-")}`}
                    style={{ textDecoration: "none" }}
                  >
                    <ListGroupItem
                      className="job-placement-lamaran mt-3"
                      id="go-to"
                    >
                      <Row>
                        <Col xs="2" className="mr-auto ml-auto">
                          {vacancies.company.logo ? (
                            <img
                              src={
                                vacancies.company.logo ===
                                "https://hris-dev.widyaskilloka.com"
                                  ? noLogoCompany
                                  : vacancies.company.logo
                              }
                              alt="company-logo"
                              className="rounded"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            <img
                              src={companyLogo}
                              alt="company-logo"
                              className="rounded"
                              style={{ width: "100%" }}
                            />
                          )}
                        </Col>
                        <Col sm="7">
                          <div className="job-placement-title">
                            <h1>{vacancies.name}</h1>
                            <h3>{vacancies.company.name}</h3>
                            {/* <h2>
                                      <i className="fa fa-map-marker"></i>{" "}
                                      {`${vacancies.company.address.province}, ${vacancies.company.address.country}`}
                                    </h2> */}
                          </div>
                          <div className="job-placement-type">
                            <h2>
                              {t("Applicant.hasAccepted") +
                                ` ${moment(vacancies.registerAt).format(
                                  `LLLL`
                                )}`}
                            </h2>
                          </div>
                        </Col>
                        <Col sm="3" className="text-center">
                          <Label
                            style={{
                              borderRadius: "8px",
                              color: "#FFFFFF",
                              fontSize: "0.9em",
                              padding: ".4rem .4rem",
                              fontWeight: 600,
                              backgroundColor:
                                vacancies.status === "accepted"
                                  ? "#52C41A"
                                  : vacancies.status === "rejected"
                                  ? "#F5222D"
                                  : "#FA8C16",
                            }}
                          >
                            {vacancies.status === "accepted"
                              ? t("General.registered")
                              : vacancies.status === "rejected"
                              ? t("General.rejected")
                              : t("General.waiting")}
                          </Label>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  </Link>
                </Col>
                // </Container>
              );
            })
          ) : (
            <Fragment>
              <div className="text-center col-sm-4"></div>
              <div className="text-center col-sm-4">
                {langUtils.getLanguage() === "ID" ? (
                  <img
                    style={{
                      width: "90%",
                      marginBottom: "2rem",
                      marginTop: "5rem",
                    }}
                    src={notFound}
                    alt="lowongan-belum-ada"
                  />
                ) : (
                  <img
                    style={{
                      width: "90%",
                      marginBottom: "2rem",
                      marginTop: "5rem",
                    }}
                    src={notFoundEn}
                    alt="lowongan-belum-ada"
                  />
                )}
                <br />
                <HashLink to="/home#list-job-placement">
                  <button className="navtext btn btn-netis-color">
                    {t("Applicant.applyNow")}
                  </button>
                </HashLink>
              </div>
              <div className="text-center col-sm-4"></div>
            </Fragment>
          )}
        </Row>
      </ListGroup>
    </div>
  );
};

const VacancyBookmark = ({ applicantStatus }) => {
  function kapital(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return (
    <div className="app-list">
      <ListGroup>
        <Row>
          {applicantStatus.length > 0 ? (
            applicantStatus &&
            applicantStatus.map((vacancies) => {
              return (
                <Col lg="6" key={vacancies.id}>
                  <ListGroupItem
                    id="go-to"
                    style={{ height: "85%" }}
                    className="job-placement-lamaran mt-3 mb-3"
                  >
                    <Row>
                      <Col xs="2" className="mr-auto ml-auto">
                        {vacancies.company.logo ? (
                          <img
                            src={
                              vacancies.company.logo ===
                              "https://hris-dev.widyaskilloka.com"
                                ? noLogoCompany
                                : vacancies.company.logo
                            }
                            alt="company-logo"
                            className="rounded"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <img
                            src={companyLogo}
                            alt="company-logo"
                            className="rounded"
                            style={{ width: "100%" }}
                          />
                        )}
                      </Col>
                      <Col sm="7">
                        <div
                          className="job-placement-title my-auto"
                          style={{ verticalAlign: "middle" }}
                        >
                          <h1>
                            {vacancies.name}&nbsp;
                            <small>({vacancies.type})</small>
                          </h1>
                          <h3>{vacancies.company.name}</h3>
                          <h3>
                            <i className="fa fa-map-marker"></i>&nbsp;
                            {kapital(vacancies.city.name)},{" "}
                            {kapital(vacancies.province.name)}
                          </h3>
                        </div>
                      </Col>
                      <Col sm="3" className="text-center">
                        <Link
                          to={`/jobs/${vacancies.id}/${vacancies.name
                            .toString()
                            .toLowerCase()
                            .normalize("NFD")
                            .trim()
                            .replace(/\s+/g, "-") // eslint-disable-next-line
                            .replace(/[^\w\-]+/g, "") // eslint-disable-next-line
                            .replace(/\-\-+/g, "-")}`}
                        >
                          <Button
                            style={{
                              borderRadius: "8px",
                              color: "#FFFFFF",
                              fontSize: "0.9em",
                              padding: ".4rem .4rem",
                              fontWeight: 600,
                              backgroundColor: Color.primaryColor,
                            }}
                            className="navtext button-detail pl-2"
                          >
                            {t("Dashboard.more")}
                            <i className="fa fa-arrow-right ml-2"></i>
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </Col>
                // </Container>
              );
            })
          ) : (
            <Fragment>
              <div className="text-center col-sm-4"></div>
              <div className="text-center col-sm-4">
                {langUtils.getLanguage() === "ID" ? (
                  <img
                    style={{
                      width: "100%",
                      marginBottom: "2rem",
                      marginTop: "4rem",
                    }}
                    src={notFoundBookmark}
                    alt="bookmark-belum-ada"
                  />
                ) : (
                  <img
                    style={{
                      width: "100%",
                      marginBottom: "2rem",
                      marginTop: "4rem",
                    }}
                    src={notFoundBookmarkEn}
                    alt="bookmark-belum-ada"
                  />
                )}
              </div>
              <div className="text-center col-sm-4"></div>
            </Fragment>
          )}
        </Row>
      </ListGroup>
    </div>
  );
};
const mapsToDispatch = (dispatch) => {
  return {
    setUser: (state) =>
      dispatch({
        type: "SET_USER",
        payload: state,
      }),
  };
};
export default connect(
  ({ user }) => ({ user }),
  mapsToDispatch
)(translate(AplikasiLamaran));
