import React, { useState, useEffect } from "react";
import { Row, Col, Progress, Container, Alert } from "reactstrap";
import { useParams } from "react-router-dom";
import Color from "../../../utils/Colors";
import ReactMarkdown from "react-markdown";
import request from "../../../utils/Request";
import LoadingAnimation from "../../../components/atoms/LoadingAnimation";
import { translate, t } from "react-switch-lang";

function ShareableMBTI() {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  const { resultCodes } = useParams();
  useEffect(() => {
    request
      .get("/tes/mbti_result/share/" + resultCodes)
      .then((res) => {
        setResult(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setResult(null);
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {loading ? (
        <LoadingAnimation />
      ) : result ? (
        <Container>
          <Row>
            <Col sm="2" />
            <Col sm="8">
              <div className="text-center">
                <h1
                  className="mb-2 mt-5 title-menu-company text-center"
                  style={{ color: Color.primaryColor }}
                >
                  <b>
                    <ReactMarkdown source={result.result.predicate} />
                  </b>
                </h1>

                <br />

                <img
                  src={require("../../../assets/img/16personalities/" +
                    result.type +
                    ".png")}
                  alt="logo"
                  style={{ width: 180 }}
                  className="mb-4"
                />
                <h2 className="mb-2 title-menu-company">
                  <b>
                    <ReactMarkdown source={result.type.toUpperCase()} />
                  </b>
                </h2>
                <h4 style={{ color: Color.yellowColor }}>
                  {" "}
                  {result.description}
                </h4>
                <br />
                <div className="text-center">
                  <div className="progress-group ">
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">Extrovert</span>
                    </div>
                    <div className="progress-group-bars">
                      <Progress multi>
                        <Progress
                          bar
                          className="barOrange"
                          value={result.dimensi.extrovert}
                        >
                          {result.dimensi.extrovert >=
                          result.dimensi.introvert ? (
                            <b>{result.dimensi.extrovert} %</b>
                          ) : (
                            result.dimensi.extrovert + "%"
                          )}{" "}
                        </Progress>
                        <Progress
                          bar
                          className="barYellow"
                          value={result.dimensi.introvert}
                        >
                          {result.dimensi.extrovert <=
                          result.dimensi.introvert ? (
                            <b>{result.dimensi.introvert} %</b>
                          ) : (
                            result.dimensi.introvert + "%"
                          )}{" "}
                        </Progress>
                      </Progress>
                    </div>
                    <div className="progress-group-prepend ml-3">
                      <span className="progress-group-text">Introvert</span>
                    </div>
                  </div>
                  <br />
                  <div className="progress-group ">
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">Sensing</span>
                    </div>
                    <div className="progress-group-bars">
                      <Progress multi>
                        <Progress
                          bar
                          className="barOrange"
                          value={result.dimensi.sensing}
                        >
                          {result.dimensi.intuition <=
                          result.dimensi.sensing ? (
                            <b>{result.dimensi.sensing} %</b>
                          ) : (
                            result.dimensi.sensing + "%"
                          )}
                        </Progress>
                        <Progress
                          bar
                          className="barYellow"
                          value={result.dimensi.intuition}
                        >
                          {result.dimensi.intuition >=
                          result.dimensi.sensing ? (
                            <b>{result.dimensi.intuition} %</b>
                          ) : (
                            result.dimensi.intuition + "%"
                          )}
                        </Progress>
                      </Progress>
                    </div>
                    <div className="progress-group-prepend ml-3">
                      <span className="progress-group-text">Intuition</span>
                    </div>
                  </div>
                  <br />
                  <div className="progress-group ">
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">Thinking</span>
                    </div>
                    <div className="progress-group-bars">
                      <Progress multi>
                        <Progress
                          bar
                          className="barOrange"
                          value={result.dimensi.thinking}
                        >
                          {result.dimensi.feeling <= result.dimensi.thinking ? (
                            <b>{result.dimensi.thinking} %</b>
                          ) : (
                            result.dimensi.thinking + "%"
                          )}
                        </Progress>
                        <Progress
                          bar
                          className="barYellow"
                          value={result.dimensi.feeling}
                        >
                          {result.dimensi.feeling >= result.dimensi.thinking ? (
                            <b>{result.dimensi.feeling} %</b>
                          ) : (
                            result.dimensi.feeling + "%"
                          )}
                        </Progress>
                      </Progress>
                    </div>
                    <div className="progress-group-prepend ml-3">
                      <span className="progress-group-text">Feeling</span>
                    </div>
                  </div>
                  <br />
                  <div className="progress-group ">
                    <div className="progress-group-prepend">
                      <span className="progress-group-text">Judging</span>
                    </div>
                    <div className="progress-group-bars">
                      <Progress multi>
                        <Progress
                          bar
                          className="barOrange"
                          value={result.dimensi.judging}
                        >
                          {result.dimensi.perceiving <=
                          result.dimensi.judging ? (
                            <b>{result.dimensi.judging} %</b>
                          ) : (
                            result.dimensi.judging + "%"
                          )}{" "}
                        </Progress>
                        <Progress
                          bar
                          className="barYellow"
                          value={result.dimensi.perceiving}
                        >
                          {result.dimensi.perceiving >=
                          result.dimensi.judging ? (
                            <b>{result.dimensi.perceiving} %</b>
                          ) : (
                            result.dimensi.perceiving + "%"
                          )}{" "}
                        </Progress>
                      </Progress>
                    </div>
                    <div className="progress-group-prepend ml-3">
                      <span className="progress-group-text">Perceiving</span>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <blockquote className="blockquote-quote">
                  <h2 style={{ lineHeight: "1.5em" }}>
                    <i style={{ fontSize: "0.8em" }}>
                      <ReactMarkdown
                        source={'"' + result.result.quotes + '"'}
                      />
                    </i>
                  </h2>
                </blockquote>
              </div>
              {/* <br />
                                                <br />
                                                <h3 style={{ color: Color.yellowColor }}>
                                                  <b>Karakteristik</b>
                                                </h3>
                                                <span style={{ fontSize: "1.1em", lineHeight: "1.8em" }}>
                                                  <ReactMarkdown
                                                    className="navtext"
                                                    source={result.result.characterisctics}
                                                  />
                                                </span>
                                                <br />
                                                <h3 style={{ color: Color.yellowColor }}>
                                                  <b>Fungsi Kognitif</b>
                                                </h3>
                                                <h5 style={{ color: Color.primaryColor }}>
                                                  <i>Kemampuan Berpikir</i>
                                                </h5>
                                                <Row>
                                                  <Col sm="6">
                                                    <div
                                                      className="card menu-item"
                                                      style={{ backgroundColor: "#fff" }}
                                                      >
                                                      <div className="card-body">
                                                        <div className="menu-title mb-2">
                                                          <h4
                                                            className="mb-2 title-menu-company"
                                                            style={{ color: Color.primaryColor }}
                                                            >
                                                            <b>Fungsi Dominan</b>
                                                          </h4>
                                                          <span style={{ fontSize: "1.1em", lineHeight: "1.8em" }}>
                                                            <ReactMarkdown
                                                              className="navtext"
                                                              source={result.result.dominan}
                                                            />
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Col>
                                                  <Col sm="6">
                                                    <div
                                                      className="card menu-item"
                                                      style={{ backgroundColor: "#fff" }}
                                                      >
                                                      <div className="card-body">
                                                        <div className="menu-title mb-2">
                                                          <h4
                                                            className="mb-2 title-menu-company"
                                                            style={{ color: Color.primaryColor }}
                                                            >
                                                            <b>Fungsi Sekunder</b>
                                                          </h4>
                                                          <span style={{ fontSize: "1.1em", lineHeight: "1.8em" }}>
                                                            <ReactMarkdown
                                                              className="navtext"
                                                              source={result.result.sekunder}
                                                            />
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Col>
                                                </Row>
                                                <br />
                                                <h3 style={{ color: Color.yellowColor }}>
                                                  <b>Profesi yang Cocok</b>
                                                </h3>
                                                <span style={{ fontSize: "1.1em", lineHeight: "1.8em" }}>
                                                  <ReactMarkdown source={result.result.profession} />
                                                </span>
                                                <br />
                                                <h3 style={{ color: Color.yellowColor }}>
                                                  <b>Partner Alami</b>
                                                </h3>

                                                <Row>
                                                  <Col sm="6" className="text-center">
                                                    <img
                                                      src={require("../../../assets/img/16personalities/" +
                                                        result.result.partner1 +
                                                        ".png")}
                                                      alt="logo"
                                                      style={{ width: 180 }}
                                                      className="mb-4"
                                                    />
                                                    <br />
                                                    <h5 style={{ color: Color.primaryColor }}>
                                                      <b>
                                                        <ReactMarkdown
                                                          source={result.result.partner1.toUpperCase()}
                                                        />
                                                      </b>
                                                    </h5>
                                                  </Col>
                                                  <Col sm="6" className="text-center">
                                                    <img
                                                      src={require("../../../assets/img/16personalities/" +
                                                        result.result.partner2 +
                                                        ".png")}
                                                      alt="logo"
                                                      style={{ width: 180 }}
                                                      className="mb-4"
                                                    />
                                                    <br />
                                                    <h5 style={{ color: Color.primaryColor }}>
                                                      <b>
                                                        <ReactMarkdown
                                                          source={result.result.partner2.toUpperCase()}
                                                        />
                                                      </b>
                                                    </h5>
                                                  </Col>
                                                </Row>
                                                <br />
                                                <h3 style={{ color: Color.yellowColor }}>
                                                  <b>Saran Pengembangan</b>
                                                </h3>
                                                <span style={{ fontSize: "1.1em", lineHeight: "1.8em" }}>
                                                  <ReactMarkdown
                                                    className="navtext"
                                                    source={result.result.suggestion}
                                                  />
                                                </span>

                                                <br />
                                                <br /> */}
            </Col>
            <Col sm="2" />
          </Row>
        </Container>
      ) : (
        <Alert color="danger" className="text-center mt-3 mb-4">
          <h5 className="content-title mb-2">{t("Assessment.noData")}</h5>
        </Alert>
      )}
    </div>
  );
}
export default translate(ShareableMBTI);
