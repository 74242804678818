import React, { Fragment } from "react";
import ContentPersonalityDetailLandingPage from '../../../Templates/LandingPage/ContentPersonalityDetailLandingPage';
const PersonalityMbtiDetail = (props) => {

    return (
        <Fragment>
          <div className="header-landing-page-old"> 
            <ContentPersonalityDetailLandingPage props={props} />
          </div>
        </Fragment>
    )
}
export default PersonalityMbtiDetail;