import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import { translate, t } from "react-switch-lang";
import request from "../../../../../utils/Request";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
import { useResultTestContext } from "../../Menu/AgilityTestContext";
import { toast } from "react-toastify";
import moment from "../../../../../utils/Moment";
toast.configure();
function Etung(props) {
  // eslint-disable-next-line
  const [result, setResult] = useResultTestContext({});
  const [answers, setAnswers] = useState({});
  const [answersNow, setAnswersNow] = useState({});
  const [time, setTime] = useState({});
  const [startTime, setStartTime] = useState(props.startTime);
  const [error, setError] = useState(false);
  const [errorInput, setErrorInput] = useState(null);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [page, setPage] = useState(0);
  const [pageTitle, setPageTitle] = useState(1);
  const [completed, setCompleted] = useState(false);
  useEffect(() => {
    request
      .get("/assessment/test/agility/etung")
      .then((res) => {
        if (res.data?.data) {
          setLoading(false);
          setQuestions(res.data.data);
        }
      })
      .catch((err) => {
        setError(true);
      }); // eslint-disable-next-line
  }, []);
  function isError() {
    if (error) {
      return (
        <AffirmationModal
          isOpen={error}
          title={"Error !"}
          desc={t("Agility.errorConnection")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setError(true);
          }}
          confirmation={() => {
            window.location.reload();
            setError(false);
          }}
        />
      );
    }
  }

  function isErrorInput() {
    if (errorInput) {
      toast.error(t("Agility.hasDataEtung"), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        onClose: setErrorInput(false),
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  }

  function chooseOption(name, answer, image, type) {
    const lastArray = Object.values(answersNow).pop();
    if (Object.values(answersNow).length > 0) {
      if (type === "operator") {
        if (!answersNow[name + "1"]) {
          name = name + "1";
        } else if (answersNow[name + "1"] && !answersNow[name + "2"]) {
          name = name + "2";
        } else if (answersNow[name + "1"] && answersNow[name + "2"]) {
          setErrorInput(true);
          return false;
        }
      }
      if (lastArray["type"] === "options" && type === "options") {
        setErrorInput(true);
      } else if (
        lastArray["type"] === "operator" &&
        type === "operator" &&
        lastArray["answer"] === "(" &&
        answer !== "("
      ) {
        setErrorInput(true);
      } else if (
        lastArray["type"] === "operator" &&
        type === "operator" &&
        lastArray["answer"] !== "(" &&
        lastArray["answer"] !== ")" &&
        answer !== "(" &&
        answer !== ")"
      ) {
        setErrorInput(true);
      } else if (
        lastArray["type"] === "operator" &&
        type === "options" &&
        lastArray["answer"] === ")"
      ) {
        setErrorInput(true);
      } else if (
        lastArray["type"] === "options" &&
        type === "operator" &&
        answer === "("
      ) {
        setErrorInput(true);
      } else if (
        lastArray["type"] === "operator" &&
        answer === ")" &&
        type === "operator"
      ) {
        setErrorInput(true);
      } else if (
        type === "operator" &&
        name === "close1" &&
        !answersNow["open1"]
      ) {
        setErrorInput(true);
      } else if (
        type === "operator" &&
        name === "close2" &&
        !answersNow["open2"]
      ) {
        setErrorInput(true);
      } else {
        setAnswersNow((state) => ({
          ...state,
          [name]: { image, type, answer, name },
        }));
      }
    } else {
      if (type === "operator" && name !== "open") {
        setErrorInput(true);
      } else {
        if (type === "operator") {
          if (!answersNow[name + "1"]) {
            name = name + "1";
          } else if (answersNow[name + "1"] && !answersNow[name + "2"]) {
            name = name + "2";
          } else if (answersNow[name + "1"] && answersNow[name + "2"]) {
            setErrorInput(true);
          }
        }
        setAnswersNow((state) => ({
          ...state,
          [name]: { image, type, answer, name },
        }));
      }
    }
  }
  function nextPage() {
    const lastArray = Object.values(answersNow).pop();
    if (Object.values(answersNow).length === 0) {
      setErrorInput(true);
    } else if (
      Object.values(answersNow).length === 1 &&
      lastArray["type"] === "operator"
    ) {
      setErrorInput(true);
    } else {
      if (lastArray["type"] === "operator" && lastArray["answer"] !== ")") {
        setErrorInput(true);
      } else if (answersNow["open1"] && !answersNow["close1"]) {
        setErrorInput(true);
      } else if (answersNow["open2"] && !answersNow["close2"]) {
        setErrorInput(true);
      } else {
        setLoading(true);
        let answer = [];
        Object.values(answersNow).map((data, idx) => {
          answer[idx] = data.answer;
          return true;
        });
        answer = answer.toString();
        let result = answer.replaceAll(",", "");
        setAnswers((state) => ({
          ...state,
          [pageTitle]: result,
        }));
      }
    }
  }

  useEffect(() => {
    if (Object.values(answersNow).length > 0) {
      setTime((state) => ({
        ...state,
        [pageTitle]: moment(new Date(), "YYYY-MM-DD HH:mm:ss").diff(
          startTime,
          "seconds"
        ),
      }));
      if (questions[page].lastQuestion === false) {
        setAnswersNow({});
        window.scroll({ top: 0, behavior: "smooth" });
        setPageTitle(pageTitle + 1);
        setPage(page + 1);
        setLoading(false);
      } else {
        setAnswersNow({});
        setCompleted(true);
        window.scroll({ top: 0, behavior: "smooth" });
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [answers]);

  useEffect(() => {
    // eslint-disable-next-line
    if (Object.values(time).length > 0) {
      setStartTime(moment().format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [time]);
  function isComplete() {
    if (completed) {
      return (
        <AffirmationModal
          isOpen={completed}
          title={"Success !"}
          desc={t("Agility.completeEtung")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setCompleted(true);
          }}
          confirmation={() => {
            let totalTime = 0;
            Object.values(time).map((data) => {
              totalTime = totalTime + parseInt(data);
              return true;
            });
            setResult((result) => ({
              ...result,
              etung: { answers, time, totalTime, step: props.page },
            }));
            window.scroll({ top: 0, behavior: "smooth" });
            props.setNextPage(false);
            toast.success("Success", {
              autoClose: 3000,
            });
          }}
        />
      );
    }
  }
  return (
    <Container>
      {isError()}
      {isErrorInput()}
      {isComplete()}
      <Row>
        <Col xs="0" md="1" />
        <Col xs="12" md="10" className=" mt-4 mb-4">
          <h3>
            <b>
              {props.title} ( {pageTitle} / 5 )
            </b>
          </h3>
          <br />
          <Row
            className="p-md-5 pb-3 pb-md-0 etung-wrapper"
            // style={{
            //   boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
            //   borderRadius: "12px",
            // }}
          >
            {loading ? (
              <Col sm="12" className="text-center">
                <Spinner size="lg" />
              </Col>
            ) : questions ? (
              questions[page] ? (
                <>
                  <Col
                    sm="12"
                    className="mt-4 mb-4"
                    style={{
                      paddingBottom: "15px",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <Row>
                      <Col sm="7">
                        <Row>
                          <Col sm="12" className="text-center">
                            {Object.values(answersNow).length > 0 ? (
                              Object.values(answersNow).map((data, id) => {
                                return (
                                  <>
                                    {data.type === "operator" ? (
                                      <div className="box-black-etung text-center mb-2 m-1">
                                        <img
                                          src={
                                            process.env.REACT_APP_DOMAIN +
                                            "/" +
                                            data.image
                                          }
                                          alt={data.text}
                                          width="31px"
                                          className="text-submit-agility"
                                        />
                                      </div>
                                    ) : (
                                      <div className="box-blue-etung text-center mb-2 m-1">
                                        <img
                                          src={
                                            process.env.REACT_APP_DOMAIN +
                                            "/" +
                                            data.image
                                          }
                                          alt={data.text}
                                          height="58px"
                                          className="text-submit-agility"
                                        />
                                      </div>
                                    )}
                                  </>
                                );
                              })
                            ) : (
                              <div
                                style={{
                                  width: "100%",
                                  borderBottom: "2px dotted #555",
                                  height: "97px",
                                }}
                              ></div>
                            )}
                            <Col
                              sm="12"
                              className="text-center mt-3"
                              style={{
                                float: "none",
                                clear: "both",
                              }}
                            >
                              {Object.values(answersNow).length > 0 ? (
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => {
                                    setAnswersNow({});
                                  }}
                                >
                                  Reset
                                </button>
                              ) : null}
                            </Col>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="2" className="text-center pt-4">
                        <img
                          src={require("../../../../../assets/img/agility/etung/SIMBOL_samadengan.png")}
                          alt="sama_dengan"
                          style={{ width: "50px" }}
                        />
                      </Col>
                      <Col sm="3" className="pt-3 etung-answer-image-wrapper">
                        <img
                          src={
                            process.env.REACT_APP_DOMAIN +
                            "/" +
                            questions[page].answer.image
                          }
                          alt={questions[page].answer.text}
                          height="53px"
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col sm="12">
                    <Row>
                      <Col sm="5">
                        <Row>
                          {questions[page].options.map((data, idx) => {
                            return (
                              <Col
                                xs="3"
                                sm="6"
                                md="6"
                                lg="4"
                                xl="3"
                                className="box-blue-etung-list-wrapper"
                                key={idx}
                              >
                                <div
                                  className={
                                    answersNow[data.name]
                                      ? "box-blue-etung-list text-center mb-2 disable-box"
                                      : "box-blue-etung-list text-center mb-2 cursor-pointer hover-transform"
                                  }
                                  onClick={() =>
                                    answersNow[data.name]
                                      ? null
                                      : chooseOption(
                                          data.name,
                                          data.text,
                                          data.image,
                                          "options"
                                        )
                                  }
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_DOMAIN +
                                      "/" +
                                      data.image
                                    }
                                    alt={data.text}
                                    height="50px"
                                    className="text-submit-agility"
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                      <Col sm="2"></Col>
                      <Col sm="5" className="mt-3 mt-sm-0">
                        <Row>
                          {questions[page].operator.map((data, idx) => {
                            return (
                              <Col
                                xs="4"
                                sm="6"
                                md="6"
                                lg="4"
                                xl="3"
                                className="col"
                                key={idx}
                              >
                                <div
                                  className={
                                    answersNow[data.name + "2"]
                                      ? "box-black-etung-list text-center mb-2 disable-box"
                                      : "box-black-etung-list text-center mb-2 cursor-pointer hover-transform"
                                  }
                                  onClick={
                                    answersNow[data.name + "2"]
                                      ? null
                                      : () =>
                                          chooseOption(
                                            data.name,
                                            data.text,
                                            data.image,
                                            "operator"
                                          )
                                  }
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_DOMAIN +
                                      "/" +
                                      data.image
                                    }
                                    alt={data.text}
                                    width="31px"
                                    className="text-submit-agility"
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="12" className="text-center mt-5 pt-3">
                    <button
                      className="btn btn-primary btn-lg"
                      onClick={nextPage}
                    >
                      {questions[page].lastQuestion
                        ? "Submit"
                        : t("General.next")}{" "}
                      <i className="fa fa-arrow-right ml-2"></i>
                    </button>
                  </Col>
                </>
              ) : null
            ) : null}
          </Row>
        </Col>
        <Col xs="0" md="1"></Col>
      </Row>
    </Container>
  );
}

export default translate(Etung);
