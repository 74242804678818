import React, { useEffect, useState, Fragment } from "react";
import { ListGroup, ListGroupItem, Button, Row, Col, Badge } from "reactstrap";
import Color from "../../../../utils/Colors";
import { connect } from "react-redux";
import requestGeneral from "../../../../utils/RequestGeneral";
import LoadingSkeleton from "../../../Templates/SkeletonLoading/Menu/JobPlacement";
import noLogoCompany from "../../../../assets/img/company_icon.png";
import { time_ago } from "../../../../utils/TimeFormat";
import Page from "../../../../components/atoms/Pagination";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { translate } from "react-switch-lang";
const JobPlacement = (props) => {
  const { t } = props;
  const [vacancies, setVacancies] = useState([]);
  const [loading, setLoading] = useState(true);
  const vacanciesPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getVacancies = async () => {
    try {
      const res = await requestGeneral.get(
        `/internal/recruitment/vacancies/list`
      );
      const vacancies = res.data.data;
      setVacancies(vacancies);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const indexOfLastPost = currentPage * vacanciesPerPage;
  const indexOfFirstPost = indexOfLastPost - vacanciesPerPage;
  const currentVacancies = vacancies.slice(indexOfFirstPost, indexOfLastPost);

  function kapital(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  useEffect(() => {
    getVacancies(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <ListGroup style={{ width: "100%" }} id="list-job-placement">
            {currentVacancies.length > 0 ? (
              <div>
                {currentVacancies &&
                  currentVacancies.map((vacancy, id) => {
                    return (
                      <ListGroupItem
                        className="job-placement pt-4 pb-4"
                        key={id}
                      >
                        <Row>
                          <Col lg="2 pb-3">
                            {vacancy.company.logo ? (
                              <img
                                src={
                                  vacancy.company.logo ===
                                  "https://hris-dev.widyaskilloka.com"
                                    ? noLogoCompany
                                    : vacancy.company.logo ===
                                      "https://widyaskilloka.com"
                                    ? noLogoCompany
                                    : vacancy.company.logo
                                }
                                alt="company-logo"
                                className="rounded"
                                style={{ width: "120px", marginLeft: "20px" }}
                              />
                            ) : (
                              <img
                                src={noLogoCompany}
                                alt="company-logo"
                                className="rounded"
                                style={{ width: "120px", marginLeft: "20px" }}
                              />
                            )}
                          </Col>
                          <Col lg="10">
                            <Row>
                              <Col lg="9" md="9">
                                <h1
                                  className="title-information-company"
                                  style={{
                                    fontSize: "22px",
                                    marginBottom: "3px",
                                  }}
                                >
                                  {vacancy.name}
                                  <span>
                                    {vacancy.status !== null ? (
                                      <Badge
                                        className="ml-2"
                                        color="info"
                                        style={{
                                          color: "#fff",
                                          fontSize: "0.4em",
                                          position: "relative",
                                          top: "-5px",
                                        }}
                                      >
                                        <b> {t("JobVacancy.applied")}</b>
                                      </Badge>
                                    ) : null}
                                  </span>
                                </h1>
                                <h2
                                  className="label-information"
                                  style={{ fontSize: "14px" }}
                                >
                                  {vacancy.company.name} -{" "}
                                  <span style={{ fontSize: "12px" }}>
                                    {t("Lists." + vacancy.type)}
                                  </span>
                                </h2>
                                <h5 style={{ fontSize: "14px" }}>
                                  <i className="fa fa-map-marker"></i>{" "}
                                  {vacancy.province.name
                                    ? kapital(vacancy.city.name)
                                      ? kapital(vacancy.province.name) +
                                        ", " +
                                        kapital(
                                          vacancy.city.name
                                            .replace("KABUPATEN ", "")
                                            .replace("KOTA ", "")
                                        )
                                      : kapital(vacancy.province.name)
                                    : t("JobVacancy.locationNoMention")}
                                </h5>
                              </Col>
                              <Col lg="3" md="3" className="job-placement-desc">
                                <h6
                                  className="label-information"
                                  style={{ fontSize: "14px" }}
                                >
                                  <i className="fa fa-clock-o"></i>{" "}
                                  {time_ago(vacancy.modifiedAt)}
                                </h6>
                                {props.user.guideHome === 0 ? (
                                  <Button
                                    className="navtext step-next-job"
                                    style={{
                                      backgroundColor: Color.primaryColor,
                                      color: Color.white,
                                    }}
                                  >
                                    <span style={{ fontSize: "12px" }}>
                                      {t("Dashboard.more")}
                                      <i className="fa fa-arrow-right ml-2" />
                                    </span>
                                  </Button>
                                ) : (
                                  <Link
                                    to={`/jobs/${vacancy.id}/${vacancy.name
                                      .toString()
                                      .toLowerCase()
                                      .normalize("NFD")
                                      .trim()
                                      .replace(/\s+/g, "-") // eslint-disable-next-line
                                      .replace(/[^\w\-]+/g, "") // eslint-disable-next-line
                                      .replace(/\-\-+/g, "-")}`}
                                  >
                                    <Button
                                      className="navtext step-next-job"
                                      style={{
                                        backgroundColor: Color.primaryColor,
                                        color: Color.white,
                                      }}
                                    >
                                      <span style={{ fontSize: "14px" }}>
                                        {t("Dashboard.more")}{" "}
                                        <i className="fa fa-arrow-right ml-2" />
                                      </span>
                                    </Button>
                                  </Link>
                                )}
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "1rem" }}>
                              <Col lg="5">
                                {vacancy.showSalary ? (
                                  <h5
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    IDR{" "}
                                    {`${new Intl.NumberFormat([
                                      "ban",
                                      "id",
                                    ]).format(
                                      vacancy.minSalary
                                    )} - ${new Intl.NumberFormat([
                                      "ban",
                                      "id",
                                    ]).format(vacancy.maxSalary)}`}
                                  </h5>
                                ) : (
                                  <h5
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {props.user.expectedSalary ? (
                                      props.user.expectedSalary <
                                      vacancy.minSalary ? (
                                        <>{t("JobVacancy.salaryAbove")}</>
                                      ) : props.user.expectedSalary >
                                        vacancy.maxSalary ? (
                                        <>{t("JobVacancy.salaryBelow")}</>
                                      ) : (
                                        <>{t("JobVacancy.salaryExpected")}</>
                                      )
                                    ) : (
                                      <>{t("JobVacancy.salaryNoMention")}</>
                                    )}
                                  </h5>
                                )}
                              </Col>
                              <Col lg="5" />
                            </Row>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    );
                  })}
                <Row>
                  <Col sm="12" className="text-right">
                    <HashLink to="/home#list-job-placement">
                      <Page
                        vacanciesPerPage={vacanciesPerPage}
                        totalPost={vacancies.length}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </HashLink>
                  </Col>
                </Row>
              </div>
            ) : (
              <p>{t("JobVacancy.noVacancy")}</p>
            )}
          </ListGroup>
        </>
      )}
    </Fragment>
  );
};
export default connect(({ user }) => ({ user }))(translate(JobPlacement));
