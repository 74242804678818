import React, { Fragment } from "react";
import ContentPromotionVideoCV from "../../../Templates/LandingPage/ContentPromotionVideoCV";
function PromotionVideoCV() {
  return (
    <Fragment>
      <div className="web-content">
        <ContentPromotionVideoCV />
      </div>
    </Fragment>
  );
}

export default PromotionVideoCV;
