import React, { Fragment } from "react";
import DefaultHeader from "../DefaultHeader";
import { Route } from "react-router";
import { AppHeader } from "@coreui/react";
import { connect } from "react-redux";
import WhatsAppButton from "../../../../Templates/LandingPage/WhatsAppButton";
const GeneralLayout = (props) => {
  const toUp = window.scrollTo(0, 0);
  return (
    <Fragment>
      {toUp}
      <div className="app" style={{ background: "#fff" }}>
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body-breadcrumb" style={{ paddingTop: 70 }}>
          <main style={{ width: "100%" }}>
            <Route {...props} />
          </main>
        </div>
        {/* <ShortGuideUser /> */}
        <WhatsAppButton />
      </div>
    </Fragment>
  );
};
// export default DefaultBreadcumb;
export default connect(({ user }) => ({ user }))(GeneralLayout);
