import React from 'react'
import { Row, Col } from 'reactstrap'
import Pulse from "react-reveal/Pulse";
import { SubmitButton } from './SubmitButton';

export const SectionOneFirst = ({ questions, pageQuestion, showBtnAnswer, answers, chooseOption, xs, colClass, page, nextPage, btnDisable, section }) => {
  return (
    <Row>
      {questions[page]?.questions[pageQuestion].options.map((data, idx) => (
        <Col xs={xs} key={idx} className="text-center">
          {showBtnAnswer &&
            <Pulse>
              <button type="button" id={`btnAnswer-${idx}`} name={"option" + page} onClick={() => chooseOption(data.name)}
                className={answers[`${questions[page].section}.${questions[page].questions[pageQuestion].codeQuestion}`] === data.name
                  ? `options-vacana options-vacana-selected`
                  : `options-vacana`
                }
              >
                {data.text}
              </button>
            </Pulse>
          }
        </Col>
      ))}
      {showBtnAnswer && <SubmitButton addClass='' nextPage={nextPage} btnDisable={btnDisable} section={section} questions={questions} page={page} />}
    </Row>
  )
}