import React, { Fragment } from "react";
import { translate } from "react-switch-lang";
import { useState } from "react";
import { Formik, Field, Form } from "formik";
import FormikInput from "../../../../../../utils/FormikInput";
import { toast } from "react-toastify";
import request from "../../../../../../utils/Request";
import { useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  Spinner,
  Label,
} from "reactstrap";

const Experience = (props) => {
  const { t } = props;
  const [modalTitle, setModalTitle] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDocumentChange, setIsDocumentChange] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [isDataExperience, setIsDataExperience] = useState(false);
  const [experiences, setExperiences] = useState([]);
  const [image, setImage] = useState({
    preview: "",
    raw: "",
  });

  const [addDataExperience, setAddDataExperience] = useState({
    id: null,
    yearStart: "",
    yearEnd: "",
    institution: "",
    description: "",
  });

  const [valueLevelFormal, setValueLevelFormal] = useState({
    value: "",
    label: "",
  });

  const getDataExperience = async () => {
    try {
      const res = await request.get("/users/resumes-working-history");
      const experiences = res.data.data;
      setExperiences(experiences);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.errors.message || "Error", {
          autoClose: 2000,
        });
      }
    }
  };

  useEffect(() => {
    getDataExperience();
  }, []);

  const modalEditDataExperience = (data) => {
    const { t } = props;
    setAddDataExperience({
      id: data.id,
      yearStart: data.yearStart,
      yearEnd: data.yearEnd,
      institution: data.institution,
      description: data.description,
    });
    let addDataNew = { ...addDataExperience };
    addDataNew = data;
    setModalData(!modalData);
    setAddDataExperience(addDataNew);
    setModalTitle(t("Profile.workExperience"));
    setIsDataExperience(true);
    setImage(image);
    setIsUpdate(true);
    setIsDocumentChange(isDocumentChange);
  };

  const cancelModal = (e) => {
    e.preventDefault();
    setModalData(false);
    setIsDataExperience(false);
    setIsUpdate(false);
    setIsDocumentChange(false);
    setImage({
      preview: "",
      raw: "",
    });
    setValueLevelFormal(valueLevelFormal);
  };

  const formValidate = (values) => {
    let errors = {};
    // let patternTextNumber = new RegExp(/^[0-9a-zA-Z-/.\b]+$/);
    // let patternText = new RegExp(/^[a-zA-Z\s]+$/);
    if (isDataExperience === true) {
      if (values.yearEnd !== null) {
        if (values.yearStart > values.yearEnd) {
          errors.yearStart = t("Profile.startYearCannotExceed");
        }
      }
      // if (values.number !== null) {
      //   if (!patternTextNumber.test(values.number)) {
      //     errors.number = "Nomor Sertifikat Kamu tidak valid";
      //   }
      // }

      // if (values.major !== null) {
      //   if (
      //     valueLevelFormal.value !== "sd" &&
      //     valueLevelFormal.value !== "smp"
      //     ) {
      //     if (!patternText.test(values.major)) {
      //       errors.major = "Nama Jurusan hanya boleh huruf";
      //     }
      //   }
      // }
      return errors;
    }
  };

  const modalAddDataExperience = () => {
    const { t } = props;
    setModalData(!modalData);
    setAddDataExperience({
      id: null,
      yearStart: "",
      yearEnd: "",
      institution: "",
      description: "",
    });
    setImage({
      preview: "",
      raw: "",
    });
    setModalTitle(t("Profile.workExperience"));
    setIsDataExperience(true);
    setIsUpdate(false);
    setIsDocumentChange(false);
  };

  const deleteData = async (e, id) => {
    setDeleteButton(true);
    try {
      await request.delete("/users/resumes-working-history/" + id);
      setTimeout(() => {
        setModalData(!modalData);
        setAddDataExperience({
          id: null,
          yearStart: "",
          yearEnd: "",
          institution: "",
          description: "",
        });
        setImage({
          preview: "",
          raw: "",
        });
        setDeleteButton(false);
        setIsUpdate(false);
        setIsDataExperience(false);
        setIsDocumentChange(false);
        setLoadingButton(false);
        getDataExperience();
        toast.success(t("General.success"), { autoClose: 3000 });
      }, 500);
    } catch (err) {
      if (err.response) {
        // toast.error("Error : " + JSON.stringify(err.response.data.errors), {
        //   autoClose: 2000,
        setDeleteButton(false);
        toast.error("Error", {
          autoClose: 2000,
        });
      }
    }
  };

  const formSubmit = async (values, { setSubmitting, setErrors }) => {
    let dataExperience = {};
    dataExperience = {
      description: values.description,
      yearStart: values.yearStart,
      yearEnd: values.yearEnd,
      institution: values.institution,
    };

    //     if (values.institution === null || values.institution === "") {
    //       setSubmitting(false);
    //       return toast.error("institusi wajib diisi ya", { autoClose: 3000 });
    //     }
    //
    //     if (values.description === null || values.description === "") {
    //       setSubmitting(false);
    //       return toast.error("posisi wajib diisi ya", { autoClose: 3000 });
    //     }

    // if (values.yearStart === null || values.yearStart === "") {
    //   setSubmitting(false);
    //   return toast.error("Tahun mulai wajib diisi ya", { autoClose: 3000 });
    // }

    // if (values.yearEnd === null || values.yearEnd === "") {
    //   setSubmitting(false);
    //   return toast.error("Tahun selesai wajib diisi ya", { autoClose: 3000 });
    // }

    if (isUpdate === true) {
      try {
        await request.put(
          "/users/resumes-working-history/" + addDataExperience.id,
          dataExperience
        );
        toast.success(t("General.success"), { autoClose: 3000 });
        setModalData(false);
        getDataExperience();
      } catch (err) {
        if (err.response) {
          toast.error("Error : " + JSON.stringify(err.response.data.errors), {
            autoClose: 2000,
          });
        }
      } finally {
        setLoadingButton(false);
      }
    }
    if (isUpdate === false) {
      try {
        await request.post("/users/resumes-working-history", dataExperience);
        toast.success(t("General.success"), { autoClose: 3000 });
        setModalData(false);
        getDataExperience();
      } catch (err) {
        if (err.response) {
          toast.error("Error : " + JSON.stringify(err.response.data.errors), {
            autoClose: 2000,
          });
        }
      } finally {
        setLoadingButton(false);
      }
    }
  };

  let institution;
  let description;
  let yearStart;
  let yearEnd;
  const itemExperiences =
    experiences.length > 0
      ? experiences.map((data, idx) => {
          if (
            data.institution === undefined ||
            data.institution === null ||
            data.institution === "" ||
            data.institution === 0
          ) {
            institution = "-";
          } else {
            institution = data.institution;
          }

          if (
            data.description === undefined ||
            data.description === null ||
            data.description === "" ||
            data.description === 0
          ) {
            description = "-";
          } else {
            description = data.description;
          }

          if (
            data.yearStart === undefined ||
            data.yearStart === null ||
            data.yearStart === "" ||
            data.yearStart === 0
          ) {
            yearStart = "-";
          } else {
            yearStart = data.yearStart;
          }

          if (
            data.yearEnd === undefined ||
            data.yearEnd === null ||
            data.yearEnd === "" ||
            data.yearEnd === 0
          ) {
            yearEnd = "-";
          } else {
            yearEnd = data.yearEnd;
          }

          return (
            <tr key={idx}>
              <td className="text-center">{idx + 1}.</td>
              <td>{institution}</td>
              <td>{description}</td>
              <td className="text-center">{yearStart}</td>
              <td className="text-center">{yearEnd}</td>
              <td className="text-center">
                {props.editProfilePro === true ? (
                  <Button
                    color="secondary"
                    onClick={() => modalEditDataExperience(data)}
                    outline
                  >
                    <i className="fa fa-pencil mr-2"></i>
                    &nbsp;Edit
                  </Button>
                ) : null}
              </td>
            </tr>
          );
        })
      : null;

  return (
    <Fragment>
      <div className="content-body">
        <Row className="md-company-header mb-3">
          <Col className="d-flex justify-content-between align-items-center">
            <Label htmlFor="country" className="input-label">
              {t("Profile.workExperience")}
            </Label>
            {props.editProfilePro === true ? (
              <Button
                color="netis-color"
                onClick={() => modalAddDataExperience()}
                style={{ backgroundColor: "#5AADF7" }}
              >
                <i className="fa fa-plus mr-2"></i>
                {t("General.add")} Data
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="12">
            <Table responsive>
              <thead>
                <tr>
                  <th className="text-center w-5">#</th>
                  <th className="w-30">{t("Profile.institute")}</th>
                  <th className="w-25">{t("Profile.position")}</th>

                  <th className="text-center w-15">{t("Profile.startYear")}</th>
                  <th className="text-center w-15">{t("Profile.endYear")}</th>
                  <th className="text-center w-20">{t("General.action")}</th>
                </tr>
              </thead>
              <tbody>{itemExperiences}</tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Modal isOpen={modalData} className={"w-100 modal-lg " + props.className}>
        <ModalBody>
          <h5 className="content-sub-title mb-4">{modalTitle}</h5>
          <Formik
            initialValues={addDataExperience}
            validate={formValidate}
            onSubmit={formSubmit}
            render={({ isSubmitting }) => (
              <Form>
                {isDataExperience === true ? (
                  <div className="col-md-12">
                    <Field
                      htmlFor="institution"
                      label={t("Profile.institute")}
                      type="text"
                      name="institution"
                      id="institution"
                      value={addDataExperience.institution}
                      className="input-label"
                      component={FormikInput}
                    />
                    <Field
                      htmlFor="yearStart"
                      label={t("Profile.startYear")}
                      type="number"
                      name="yearStart"
                      id="yearStart"
                      min="1900"
                      max="2999"
                      value={addDataExperience.yearStart}
                      component={FormikInput}
                    />
                    <Field
                      htmlFor="yearEnd"
                      label={t("Profile.endYear")}
                      type="number"
                      name="yearEnd"
                      id="yearEnd"
                      min="1900"
                      max="2999"
                      value={addDataExperience.yearEnd}
                      className="input-label"
                      component={FormikInput}
                    />
                    <Field
                      htmlFor="description"
                      label={t("Profile.position")}
                      type="text"
                      name="description"
                      id="description"
                      value={addDataExperience.description}
                      className="input-label"
                      component={FormikInput}
                    />
                  </div>
                ) : null}
                <Row>
                  <div className="col-8 d-flex ">
                    {isUpdate === false ? null : isDataExperience === true ? (
                      <Button
                        className="navtext"
                        color="red-custom"
                        disabled={deleteButton}
                        onClick={(e) => deleteData(e, addDataExperience.id)}
                      >
                        {deleteButton ? (
                          <span>
                            <Spinner size="sm" className="mr-2" /> Loading
                          </span>
                        ) : (
                          t("General.delete") + " Data"
                        )}
                      </Button>
                    ) : null}
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    {loadingButton ? (
                      <Spinner color="dark" size="sm" />
                    ) : (
                      <Fragment>
                        <Button
                          className="navtext mr-2"
                          color="white"
                          onClick={cancelModal}
                        >
                          {t("General.cancel")}
                        </Button>
                        <Button
                          className="navtext"
                          type="submit"
                          color="netis-color"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <span>
                              <Spinner size="sm" className="mr-2" /> Loading
                            </span>
                          ) : (
                            t("General.save")
                          )}
                        </Button>
                      </Fragment>
                    )}
                  </div>
                </Row>
              </Form>
            )}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default translate(Experience);
