import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Card,
  CardBody,
  CardGroup,
  Spinner,
  Alert,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";
import request from "../../../../utils/Request";
import { Link } from "react-router-dom";
import { translate } from "react-switch-lang";
import langUtils from "../../../../utils/SettingLanguage/index";
toast.configure();
const API_DOMAIN = process.env.REACT_APP_DOMAIN;
export default translate(function VerifRegister(props) {
  const { t } = props;
  const token = props.match.params.token;
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      await request
        .post(`${API_DOMAIN}/api/v1/users/create`, {
          token,
          lang: langUtils.getLanguage(),
        })
        .then((res) => {
          setSuccess(true);
          toast.success("Success Verif", { autoClose: 3000 });
        })
        .catch((err) => {
          if (err.response.status === 422) {
            setSuccess(false);
            toast.error("Error");
          } else {
            setSuccess(false);
            toast.error("Error Server");
          }
        })
        .finally(setLoading(false));
    };
    fetchData(token);
  }, [token]);

  return (
    <Fragment>
      <div
        className="app flex-row align-items-center"
        style={{ height: "auto", padding: 10 }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="3"></Col>
            <Col md={6}>
              <CardGroup style={{ height: "auto" }}>
                <Card className="card-login-form">
                  <CardBody style={{ padding: 0 }}>
                    {loading ? (
                      <>
                        <span>
                          {" "}
                          <Spinner size="lg" className="mr-2" /> Loading
                        </span>
                      </>
                    ) : (
                      <>
                        {success === true ? (
                          <Row>
                            <div className="col-sm-12 mb-2">
                              <div className=" text-center">
                                {langUtils.getLanguage() === "EN" ? (
                                  <img
                                    src={require("../../../../assets/img/vrifikasi_en.png")}
                                    alt="success"
                                    style={{ width: "100%" }}
                                  />
                                ) : (
                                  <img
                                    src={require("../../../../assets/img/verifikasi.png")}
                                    alt="success"
                                    style={{ width: "100%" }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-sm-12 mt-5">
                              <Link to="/login">
                                <i className="mr-2 fa fa-chevron-left"></i>{" "}
                                {t("General.goToBack")} Login
                              </Link>
                            </div>
                          </Row>
                        ) : success === false ? (
                          <Row>
                            <div className="col-sm-12 mb-2">
                              <Alert
                                color="danger"
                                className="text-center mt-3 mb-3"
                                style={{ width: "100%" }}
                              >
                                <h2>
                                  <strong>{t("General.failed")} !</strong>{" "}
                                </h2>
                              </Alert>
                            </div>
                            <div className="col-sm-12 mt-5">
                              <Link to="/login">
                                <i className="mr-2 fa fa-chevron-left"></i>{" "}
                                {t("General.goToBack")} Login
                              </Link>
                            </div>
                          </Row>
                        ) : (
                          <h2>
                            <Spinner size="lg" className="mr-2" /> Loading
                          </h2>
                        )}
                      </>
                    )}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
            <Col md="3"></Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
});
