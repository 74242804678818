import React, { useState, useEffect, Fragment } from "react";
import LoadingAnimation from "../../../../../components/atoms/LoadingAnimation";
import request from "../../../../../utils/Request";
import requestGeneral from "../../../../../utils/RequestGeneral";
import PrivacyAggreement from "../../../../Templates/Fingerprint/PrivacyAggreement";
import ResultFingerprint from "../../../../Templates/ResultAssessment/ResultAssessmentFingerprint";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
import ModalTakePhoto from "../../../../Templates/Fingerprint/ModalWhenMobile";
import NoComplete from "../NoCompleteProfile";
import NotFound from "../../../NotFound";
import { isMobile } from "react-device-detect";
import { translate, t } from "react-switch-lang";
function Fingerprint() {
  const [loading, setLoading] = useState(true);
  const [isNotComplete, setNotComplete] = useState(false);
  const [hasDone, setHasDone] = useState(false);
  const [exist, setExist] = useState(false);
  const [mobileView, setMobileView] = useState(isMobile);
  const [result, setResult] = useState([]);
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("referal")) {
      requestGeneral
        .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
        .then((res) => {
          if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("fingerprint")
            ).length === 0
          ) {
            setRedirect(true);
            setLoading(false);
          } else if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("profile")
            ).length > 0
          ) {
            validate();
          } else {
            getFinger();
          }
        })
        .catch((err) => {
          getFinger();
        });
    } else {
      getFinger();
    } // eslint-disable-next-line
  }, []);

  function getFinger() {
    request
      .get("/assessment/test/fingerprint/validate")
      .then((res) => {
        if (res.data?.type) {
          const type = res.data.type_done;
          const exist = res.data.type;

          if (type === "HAS_DONE") {
            request
              .get("/assessment/test/result_fingerprint")
              .then((res) => {
                setResult(res.data);
                setHasDone(true);
                setLoading(false);
              })
              .catch((err) => {
                setExist(true);
                setLoading(false);
                setHasDone(false);
              });
          } else if (type === "NOT_DONE") {
            if (exist === "DATA_EXIST") {
              setExist(true);
              setLoading(false);
              setHasDone(false);
            } else {
              setExist(false);
              setLoading(false);
              setHasDone(false);
            }
          }
        }
      })
      .catch((err) => {});
  }

  function validate() {
    requestGeneral
      .get(`/v2/users/validate-standart`)
      .then((res) => {
        getFinger();
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          setNotComplete(true);
          setLoading(false);
        } else {
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }

  function modalAttentionExistData() {
    if (mobileView) {
      return (
        <ModalTakePhoto
          isOpen={mobileView}
          title={t("General.sorry") + " !"}
          desc={t("Assessment.cannotAccessCameraBack")}
          titleButton={t("General.back")}
          toggleOpen={() => {
            setMobileView(true);
          }}
          confirmation={() => {
            window.location.replace("/assessment");
          }}
        />
      );
    } else {
      if (exist) {
        return (
          <AffirmationModal
            isOpen={exist}
            title={t("General.welcomeBack")}
            desc={t("Assessment.welcomeBackTextFinger")}
            titleButton={t("General.next")}
            toggleOpen={() => {
              setExist(true);
            }}
            confirmation={() => {
              setExist(false);
            }}
          />
        );
      }
    }
  }
  if (localStorage.getItem("referal")) {
    return <NotFound />;
  }
  return (
    <Fragment>
      {modalAttentionExistData()}
      {loading ? (
        <LoadingAnimation />
      ) : redirect ? (
        <NotFound />
      ) : isNotComplete ? (
        <NoComplete />
      ) : hasDone ? (
        <ResultFingerprint result={result} back={true} />
      ) : (
        <PrivacyAggreement />
      )}
    </Fragment>
  );
}

export default translate(Fingerprint);
