import React, { useEffect, useRef, useState, Fragment } from "react";
import { Radar } from "react-chartjs-2";
import { t, translate } from "react-switch-lang";
import "./papikostickresult.css";
import Star from "../../../components/molecules/Star";
import "chartjs-plugin-datalabels";
import request from "../../../utils/Request";
import langUtils from "../../../utils/SettingLanguage/index";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../../../components/atoms/LoadingAnimation";
import {
  CardBody,
  Card,
  Row,
  Container,
  Alert,
  Col,
  Collapse,
  Button,
} from "reactstrap";

function PapikostickResult() {
  const [result, setResult] = useState(null);
  const { resultCodes } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  useEffect(() => {
    request
      .get("/assessment/test/papikostick/share/" + resultCodes)
      .then((res) => {
        setResult(res.data.data);
        setLoading(false);
        setData({
          labels: "FWNGALPITVXSBORDCZEK".split(""),
          datasets: [
            {
              label: "RESULT",
              borderWidth: 1,
              pointRadius: 2,
              backgroundColor: "rgba(0,0,0,0.3)",
              borderColor: (option) => {
                // console.log(option);
                return colorsByIndex[option.dataIndex];
              },
              pointBackgroundColor: (option) => colorsByIndex[option.dataIndex],
              pointBorderColor: (option) => colorsByIndex[option.dataIndex],
              data: "FWNGALPITVXSBORDCZEK".split("").map((huruf) => {
                if (huruf === "Z") {
                  return 9 - res.data.data.scores[huruf];
                } else if (huruf === "K") {
                  return 9 - res.data.data.scores[huruf];
                } else {
                  return res.data.data.scores[huruf];
                }
              }),
            },
          ],
        });
      })
      .catch((err) => {
        setResult(null);
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const groupingLabel = {
    F: { category: "Followership", color: "#e53935" },
    W: { category: "Followership", color: "#e53935" },
    N: { category: "Work Direction", color: "#8e24aa" },
    G: { category: "Work Direction", color: "#8e24aa" },
    A: { category: "Work Direction", color: "#8e24aa" },
    L: { category: "Leadership", color: "#3949ab" },
    P: { category: "Leadership", color: "#3949ab" },
    I: { category: "Leadership", color: "#3949ab" },
    T: { category: "Activity", color: "#039be5" },
    V: { category: "Activity", color: "#039be5" },
    X: { category: "Social Nature", color: "#00897b" },
    S: { category: "Social Nature", color: "#00897b" },
    B: { category: "Social Nature", color: "#00897b" },
    O: { category: "Social Nature", color: "#00897b" },
    C: { category: "Work Style", color: "#7cb342" },
    D: { category: "Work Style", color: "#7cb342" },
    R: { category: "Work Style", color: "#7cb342" },
    Z: { category: "Temperament", color: "#fb8c00" },
    E: { category: "Temperament", color: "#fb8c00" },
    K: { category: "Temperament", color: "#fb8c00" },
  };
  const colorsByIndex = Object.keys(groupingLabel).map(
    (label) => groupingLabel[label].color
  );

  const options = {
    plugins: {
      datalabels: {
        display: false,
        backgroundColor: (option) => colorsByIndex[option.dataIndex],
        color: "#fff",
        font: {
          weight: "bold",
          size: 11,
        },
        borderColor: "#fff",
        borderWidth: 2,
        padding: {
          top: 6,
          bottom: 5,
          left: 8,
          right: 8,
        },
        borderRadius: 999,
      },
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          // console.log(tooltipItem, data);
          return (
            groupingLabel[data.labels[tooltipItem[0].index]].category +
            " (" +
            data.labels[tooltipItem[0].index] +
            ")"
          );
        },
        label: (tooltipItem) => parseInt(tooltipItem.value),
      },
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: t("Assessment.workStyle"),
    },
    scale: {
      gridLines: {
        display: false,
        circular: true,
      },
      angleLines: {
        // display: false,
      },
      ticks: {
        display: false,
        max: 9,
        min: 0,
        stepSize: 1,
        beginAtZero: true,
        showLabelBackdrop: true,
      },
      pointLabels: {
        display: false,
        fontStyle: "bold",
        fontSize: 12,
        fontColor: Object.keys(groupingLabel).map(
          (label) => groupingLabel[label].color
        ),
      },
    },
  };
  const chartRef = useRef(null);

  return (
    <>
      {loading ? (
        <LoadingAnimation />
      ) : result ? (
        <Container>
          <div className="p-5">
            <div
              className={
                langUtils.getLanguage() === "EN"
                  ? "mx-auto  grafik-papikostick-user-en"
                  : "mx-auto  grafik-papikostick-user"
              }
              style={{
                backgroundSize: "auto 95%",
                marginBottom: 110,
              }}
            >
              <Radar
                data={data}
                options={options}
                width={100}
                height={75}
                ref={chartRef}
              />
            </div>
            <div className="table">
              {result.result && <TablePapikostick result={result} />}
            </div>
          </div>
        </Container>
      ) : (
        <Alert color="danger" className="text-center mt-3 mb-4">
          <h5 className="content-title mb-2">{t("Assessment.noData")}</h5>
        </Alert>
      )}
    </>
  );
}

function titleColor(resultTitle) {
  switch (resultTitle) {
    case "Followership":
      return "#FCA39D";
    case "Work Direction":
      return "#91D4FE";
    case "Leadership":
      return "#87E8DD";
    case "Activity":
      return "#D3ADF6";
    case "Social Nature":
      return "#B6EB8F";
    case "Work Style":
      return "#FDD590";
    case "Temperament":
      return "#FBAED2";
    default:
      return "#FFFFFF";
  }
}

function TablePapikostick({ result }) {
  const template = {
    Followership: ["F", "W"],
    "Work Direction": ["N", "G", "A"],
    Leadership: ["L", "P", "I"],
    Activity: ["T", "V"],
    "Social Nature": ["X", "S", "B", "O"],
    "Work Style": ["C", "D", "R"],
    Temperament: ["Z", "E", "K"],
  };
  const groupingDesc = {
    F: t("Assessment.Keb Membantu Atasan"),
    W: t("Assessment.Keb Mengikuti Aturan dan Pengawasan"),
    N: t("Assessment.Keb dalam Menyelesaikan Tugas (Kemandirian)"),
    G: t("Assessment.Peran Pekerja Keras"),
    A: t("Assessment.Keb dalam Berprestasi"),
    L: t("Assessment.Peran Kepemimpinan"),
    P: t("Assessment.Keb Mengatur Orang Lain"),
    I: t("Assessment.Peran Dalam Membuat Keputusan"),
    T: t("Assessment.Peran Dalam Kesibukan Kerja"),
    V: t("Assessment.Peran Dalam Semangat Kerja"),
    X: t("Assessment.Keb untuk Diperhatikan"),
    S: t("Assessment.Peran Dalam Hubungan Sosial"),
    B: t("Assessment.Keb Diterima dalam Kelompok"),
    O: t("Assessment.Keb Kedekatan dan Kasih Sayang"),
    C: t("Assessment.Peran Dalam Mengatur"),
    D: t("Assessment.Peran Bekerja dengan Hal-hal Rinci"),
    R: t("Assessment.Peran Penalaran Teoritis"),
    Z: t("Assessment.Keb untuk Berubah"),
    E: t("Assessment.Peran Dalam Pengendalian Emosi"),
    K: t("Assessment.Keb untuk Agresif"),
  };

  const group =
    result.result &&
    Object.keys(result.result).map((category) => {
      const item =
        result.result &&
        template[category].map((code, idx) => ({
          code: code,
          scores: result.scores[code],
          description: result.result[category][idx],
        }));

      return {
        kategory: category,
        items: item,
      };
    });
  const [collapse, setCollapse] = useState(0);
  const toggle = (value) => {
    setCollapse(collapse === Number(value) ? 0 : Number(value));
  };

  return (
    <Fragment>
      {group &&
        group.map((objKategori, index) => {
          return (
            <div
              lg="12"
              className="text-justify"
              style={{ marginTop: "-20px" }}
            >
              <Button
                key={index}
                block
                rowSpan={objKategori.items.length}
                onClick={() => toggle(index)}
                data-event={index}
                style={{
                  backgroundColor: titleColor(objKategori.kategory),
                  color: "#FFFFFF",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <Row>
                  <Col>
                    <h5 style={{ margin: 0 }} className="text-left">
                      <b>{objKategori.kategory}</b>
                    </h5>
                  </Col>
                  <Col className="text-right">
                    <i
                      className="fa fa-angle-down"
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        marginRight: 30,
                      }}
                    ></i>
                  </Col>
                </Row>
              </Button>
              <Card>
                {objKategori &&
                  objKategori.items.map((objItem, idx) => {
                    return (
                      <Collapse isOpen={collapse === index}>
                        <CardBody className="navtext text-center">
                          <Star value={(objItem.scores + 1) / 2} />
                          <br />
                          <strong>{groupingDesc[objItem.code]}</strong>
                          <br />
                          {objItem.description}
                        </CardBody>
                      </Collapse>
                    );
                  })}
              </Card>
            </div>
          );
        })}
    </Fragment>
  );
}

export default translate(PapikostickResult);
