import React from "react";
import Skeleton from "react-loading-skeleton";
import { Row, Col, CardDeck, CardBody } from "reactstrap";

const Assesment = () => {
  return (
    <Row>
      <Col md="12" className="menu mb-5">
        <CardDeck className="all-menu">
          {Array(4)
            .fill()
            .map((item, index) => (
              <Col
                lg="3"
                md={6}
                sm={6}
                xs={6}
                key={index}
                className="p-0 mt-4 home-card "
              >
                <div className="card menu-item h-100 pb-2 ">
                  <div className="p-3">
                    <Skeleton height={240} width={"90%"} />
                  </div>
                  <CardBody>
                    <Skeleton height={30} width={140} />
                    <br />
                    <Skeleton width={150} />
                    <br />
                    <Skeleton width={130} />
                  </CardBody>
                </div>
              </Col>
            ))}
        </CardDeck>
      </Col>
    </Row>
  );
};
export default Assesment;
