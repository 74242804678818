import React, { useEffect, useState } from "react";
import requestGeneral from "../../../../../utils/RequestGeneral";
import LoadingAnimation from "../../../../../components/atoms/LoadingAnimation";
import { translate } from "react-switch-lang";
import Hint from "../../../../../components/atoms/Hint";
import CaptureLoading from "../../../../../components/atoms/CaptureLoading";
import GuidePhotoOnVideoAssessment from "../../../../Templates/GuidePhotoOnVideoAssessment";
import PhotoWebcamFisiognomi from "../../../../Templates/VideoAssesment/PhotoWebcamFisiognomi";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
function Fisiognomi(props) {
  let dataPhoto = null;
  const { t } = props;
  const webcamRefPhoto = React.useRef(null);
  const [notExistFisiognomi, setNotExistFisiognomi] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isHintPhoto, setIsHintPhoto] = useState(false);
  const [hintNumber, setHintNumberPhoto] = useState(1);
  const [isNotSupportBrowser, setIsNotSupportBrowser] = useState(false);
  const [isNotSupport, setIsNotSupport] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [isDetect, setIsDetect] = useState(false);
  const [successPhoto, setSuccessPhoto] = useState(false);
  const [notSuccessPhoto, setNotSuccessPhoto] = useState(false);
  const videoConstraints = {
    facingMode: "user",
  };

  useEffect(() => {
    requestGeneral
      .get("v1/assessment_video/validate_photo")
      .then((res) => {
        props.onComplete();
      })
      .catch((err) => {
        setIsHintPhoto(true);
        setNotExistFisiognomi(true);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //=================================================================================
  //=========================== function Loading Pop up ==============================
  //=================================================================================
  const showLoading = () => {
    if (loading) {
      return <CaptureLoading title="Loading" visible={true} />;
    }
  };

  function hintPhotoPopup() {
    if (isHintPhoto) {
      return (
        <GuidePhotoOnVideoAssessment
          setIsHintPhoto={setIsHintPhoto}
          isHintPhoto={isHintPhoto}
          hintNumber={hintNumber}
          setHintNumberPhoto={setHintNumberPhoto}
        />
      );
    }
  }

  //=================================================================================
  //=========================== function detect Device ==============================
  //=================================================================================
  // eslint-disable-next-line
  function detectDevice() {
    if (isDetect) {
      navigator.getUserMedia =
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        navigator.oGetUserMedia;
      if (navigator.getUserMedia) {
        // Request the camera.
        navigator.getUserMedia(
          // Constraints
          {
            video: true,
            audio: true,
          },
          // Success Callback
          function (localMediaStream) {
            setIsDetect(false);
            setIsNotSupport(false);
          },
          // Error Callback
          function (err) {
            setIsDetect(false);
            setIsNotSupport(true);
          }
        );
      } else if (navigator.mediaDevices.getUserMedia) {
        // Request the camera.
        navigator.mediaDevices.getUserMedia(
          // Constraints
          {
            video: true,
            audio: true,
          },
          // Success Callback
          function (localMediaStream) {
            setIsDetect(false);
            setIsNotSupport(false);
          },
          // Error Callback
          function (err) {
            setIsDetect(false);
            setIsNotSupport(true);
          }
        );
      } else {
        setIsDetect(false);
        setIsNotSupport(true);
      }
    }
  }

  //=================================================================================
  //=========================== function not support ==============================
  //=================================================================================
  function showNotSupport() {
    if (isNotSupport) {
      return (
        <Hint
          onClick={() => {
            setIsNotSupport(false);
            window.location.replace("/home");
          }}
          visible={isNotSupport}
          title="Error !"
          img="notif_cam_mic.png"
          desc={t("Interview.checkDevice")}
          actionTitle={t("General.back")}
        />
      );
    }
  }
  function notSupportBrowser() {
    if (isNotSupportBrowser) {
      return (
        <AffirmationModal
          isOpen={isNotSupportBrowser}
          title="Error !"
          desc={t("Interview.notSupport")}
          titleButton={t("General.back")}
          toggleOpen={() => {
            setIsNotSupportBrowser(true);
          }}
          confirmation={() => {
            setIsNotSupportBrowser(false);
            window.location.replace("/home");
          }}
        />
      );
    }
  }

  //=================================================================================
  //=============================== take picture ====================================
  //=================================================================================
  const takePicture = React.useCallback(() => {
    setLoading(true);
    setCapturing(true);
    // eslint-disable-next-line
    dataPhoto = webcamRefPhoto.current.getScreenshot();
    sendDataPhoto(dataPhoto);
    // eslint-disable-next-line
  }, [webcamRefPhoto]);

  //=================================================================================
  //============================= data uri to blob ==================================
  //=================================================================================
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  //=================================================================================
  //=============================== send picture ====================================
  //=================================================================================
  async function sendDataPhoto(dataPhoto) {
    let file = null;
    file = DataURIToBlob(dataPhoto);
    let formData = new FormData();
    formData.append(`photo`, file, "photo.jpeg");
    if (file !== null) {
      requestGeneral
        .post("v1/assessment_video/submit_photo", formData)
        .then((res) => {
          setSuccessPhoto(true);
          setLoading(false);
        })
        .catch((err) => {
          setNotSuccessPhoto(true);
          setLoading(false);
        });
    } else {
      setIsNotSupport(true);
      setLoading(false);
    }
  }

  //=================================================================================
  //======================= function pop up photo status ============================
  //=================================================================================
  function statusSuccessPhotoPopup() {
    if (successPhoto) {
      return (
        <AffirmationModal
          isOpen={successPhoto}
          title={t("Interview.photoSaved")}
          desc={t("Interview.photoSavedDesc")}
          titleButton={t("General.okey")}
          confirmation={() => {
            setSuccessPhoto(false);
            props.onComplete();
          }}
        />
      );
    }
  }

  function notSuccess() {
    if (notSuccessPhoto) {
      return (
        <AffirmationModal
          isOpen={notSuccessPhoto}
          title={t("General.failed") + " !"}
          desc={t("Interview.failedPhoto")}
          titleButton={t("General.okey")}
          confirmation={() => {
            setNotSuccessPhoto(false);
            setCapturing(false);
          }}
        />
      );
    }
  }

  return (
    <div>
      {hintPhotoPopup()}
      {showLoading()}
      {showNotSupport()}
      {notSupportBrowser()}
      {detectDevice()}
      {statusSuccessPhotoPopup()}
      {notSuccess()}
      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          {notExistFisiognomi ? (
            <div className="player-wrapper">
              <PhotoWebcamFisiognomi
                webcamRefPhoto={webcamRefPhoto}
                videoConstraints={videoConstraints}
                capturing={capturing}
                takePicture={takePicture}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
export default translate(Fisiognomi);
