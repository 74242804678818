import React, { Fragment, useState, useCallback } from "react";
import Webcam from "react-webcam";
import { toast } from "react-toastify";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import { isMobile } from "react-device-detect";
import LoadingAnimation from "../../../../components/atoms/CaptureLoading";
import AffirmationModal from "../../../../components/atoms/AffirmationModal";
import request from "../../../../utils/Request";
import { translate, t } from "react-switch-lang";
export default translate(function TakePhoto(props) {
  let dataPhoto = null;
  toast.configure();
  const webcamRefPhoto = React.useRef(null);
  const [blankWhite, setBlankWhite] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isErrorSubmit, setIsErrorSubmit] = useState(false);
  const videoConstraints = {
    facingMode: "user",
  };
  const videoConstraintsMobile = {
    facingMode: {
      exact: "environment",
    },
  };
  function loadingPopup() {
    if (loading) {
      return <LoadingAnimation title="Loading" visible={loading} />;
    }
  }
  function errorSubmit() {
    if (isErrorSubmit) {
      return (
        <AffirmationModal
          isOpen={isErrorSubmit}
          title={t("General.failed") + " !"}
          desc={t("Assessment.notRecordFinger") + " !"}
          titleButton={t("General.tryAgain")}
          toggleOpen={() => {
            setIsErrorSubmit(true);
          }}
          confirmation={() => {
            setLoading(false);
            setIsErrorSubmit(false);
          }}
        />
      );
    }
  }
  //=================================================================================
  //=============================== take picture ====================================
  //=================================================================================
  const takePicture = useCallback(() => {
    setLoading(true);
    setBlankWhite(true);
    setTimeout(() => {
      setBlankWhite(false);
    }, 250);
    // clearInterval(intervalId);
    // eslint-disable-next-line
    dataPhoto = webcamRefPhoto.current.getScreenshot();
    // // clearInterval(intervalId);
    sendDataPhoto(dataPhoto);
    // eslint-disable-next-line
  }, [webcamRefPhoto]);

  //=================================================================================
  //============================= data uri to blob ==================================
  //=================================================================================
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  //=================================================================================
  //=============================== send picture ====================================
  //=================================================================================
  async function sendDataPhoto(dataPhoto) {
    let type;
    if (isMobile) {
      type = "mobile";
    } else {
      type = "web";
    }
    let file = null;
    file = DataURIToBlob(dataPhoto);
    let formData = new FormData();
    formData.append(`finger`, props.dataFingers.finger);
    formData.append(`side`, props.dataFingers.side);
    formData.append(`image`, file, "photo.jpeg");
    if (file !== null) {
      request
        .post("assessment/test/fingerprint", formData, {
          headers: { type: type },
        })
        .then((res) => {
          setLoading(false);
          props.updateFingers(res.data.data);
          props.toggle();
          props.changeToTakePhoto("detailPhoto");
          toast.success(t("General.success"), { autoClose: 2000 });
        })
        .catch((err) => {
          setIsErrorSubmit(true);
          setLoading(false);
        });
    } else {
      setIsErrorSubmit(true);
      setLoading(false);
    }
  }
  return (
    <Fragment>
      {loadingPopup()}
      {errorSubmit()}
      {blankWhite ? (
        <div
          style={{
            backgroundColor: "#fff",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        ></div>
      ) : null}

      <div className="player-wrapper">
        <div className="bg-fingerprint"></div>
        <Webcam
          ref={webcamRefPhoto}
          screenshotFormat="image/jpeg"
          forceScreenshotSourceSize={true}
          mirrored={isMobile ? false : true}
          style={{ height: "100vh", width: "100vw", borderRadius: "12px" }}
          videoConstraints={
            isMobile ? videoConstraintsMobile : videoConstraints
          }
        />
        <div className="box-control">
          <Fragment>
            <>
              <Spinner type="grow" color="light" className="icon-spinner" />
              <UncontrolledTooltip placement="top" target="photo">
                {t("General.buttonPhoto")}
              </UncontrolledTooltip>
              <img
                id="photo"
                src={require("../../../../assets/img/icon-camere.png")}
                alt="play"
                onClick={takePicture}
                style={{ width: 70 }}
                className="icon-video-single"
              />
            </>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
});
