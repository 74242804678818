import React, { useState, Fragment } from "react";
import { useEffect } from "react";
import Hint from "../../../components/atoms/Hint";
// import request from "../../../utils/Request";
import requestGeneral from "../../../utils/RequestGeneral";
import AffirmationModal from "../../../components/atoms/AffirmationModal";
import ChoiceModal from "../../../components/atoms/ChoiceModal";
import GuidePhotoOnVideoAssessment from "../GuidePhotoOnVideoAssessment";
import VideoWebcam from "./VideoWebcam";
import ModalVideo from "./ModalVideo";
import CaptureLoading from "../../../components/atoms/CaptureLoading";
import moment from "../../../utils/Moment";
import ConfirmationModal from "../../../components/atoms/ConfirmationModal";
import { isMobile, isBrowser } from "react-device-detect";
import { translate, t } from "react-switch-lang";
export default translate(function VideoAssement(props) {
  let dataPhoto = null;

  const webcamRefPhoto = React.useRef(null);
  const webcamRefPalmistry = React.useRef(null);
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const time = React.useRef(null);

  const [loading, setLoading] = useState(true);
  const [isDetect, setIsDetect] = useState(false);
  const [isEndVideo, setIsEndVideo] = useState(false);
  const [isHintPhoto, setIsHintPhoto] = useState(false);
  const [isNotSupport, setIsNotSupport] = useState(false);
  const [isNotConnect, setIsNotConnect] = useState(false);
  const [isNotQuota, setIsNotQuota] = useState(false);
  const [isNotComplete, setIsNotComplete] = useState(false);
  const [isCanRepeat, setIsCanRepeat] = useState(false);
  const [hintNumber, setHintNumberPhoto] = useState(1);
  const [popUpPalmistry, setPopUpPalmistry] = useState(false);
  const [handPalmistryNotRecognize, setHandPalmistryNotRecognize] =
    useState(false);
  const [filpImages, setFilpImages] = useState(null);
  const [isFailedPalmistry, setIsFailedPalmistry] = useState(false);
  const [notSameType, setNotSameType] = useState(false);
  const [play, setPlay] = useState(false);
  const [sources, setSources] = useState([]);
  const [capturing, setCapturing] = useState(false); // eslint-disable-line
  const [successPhoto, setSuccessPhoto] = useState(null);
  const [successPhotoFace, setErrorPhotoFace] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [visibleControlPlayer, setVisibleControlPlayer] = useState(false);
  const [menuVideoPlayer, setMenuVideoPlayer] = useState(false);
  const [onRecord, setOnRecord] = useState(false);
  const [onStop, setOnStop] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const [loopVideo, setLoopVideo] = useState(false);
  const [count, setCount] = useState(0);
  const [section, setSection] = useState(0);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [startVideo, setStartVideo] = useState([]);
  const [endVideo, setEndVideo] = useState([]);
  const [record, setRecord] = useState([]);
  const [isModalReplay, setIsModalReplay] = useState(false);
  const [url, setUrl] = useState(null);
  const [popUpDominan, setPopUpDominan] = useState(false);
  const [dominanHand, setDominanHand] = useState(null);
  const [success, setSuccess] = useState(false);
  const [successVideo, setNotSuccessVideo] = useState(false);
  const [warning, setWarning] = useState(false);
  const [loop, setLoop] = useState(false);
  const [isNotSupportBrowser, setIsNotSupportBrowser] = useState(false);
  const [complete, setComplete] = useState(false);
  const [skip, setSkip] = useState(false);
  const [trying, setTrying] = useState(1);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingItem, setUploadingItem] = useState(0);
  const [slowConn, setSlowConn] = useState(false);
  
  const videoConstraints = {
    facingMode: "user",
  };

  useEffect(() => {
    let type;
    if (isMobile) {
      type = "mobile";
    } else if (isBrowser) {
      type = "web";
    }
    if (props.support) {
      setIsNotSupportBrowser(false);
      setIsDetect(true);
      requestGeneral
        .get("v1/assessment_video/questions?type=" + type)
        .then((res) => {
          const response = res?.data?.response_type;
          if (response === "QUESTIONS_SUCCESS") {
            const sources = res?.data.data;

            setIsVideo(true);
            setSources(sources);
            setIsNotComplete(false);
            setIsHintPhoto(false);
            setLoading(false);

            if (sessionStorage.getItem("video")) {
              setLoading(true);
              let datajson = JSON.parse(sessionStorage.getItem("video"));
              const blob = DataURIToBlob(datajson?.video);
              let formData = new FormData();
              formData.append(
                `video`,
                blob,
                "video" + datajson?.code + ".webm"
              );
              formData.append(`section`, datajson?.section);
              formData.append(`start_video`, datajson?.start_video);
              formData.append(`end_video`, datajson?.end_video);
              formData.append(`type`, type);
              requestGeneral
                .post("v1/assessment_video/submit", formData)
                .then((res) => {
                  setSkip(true);

                  setLoading(false);
                })
                .catch((err) => {
                  setIsNotSupportBrowser(true);
                  setLoading(false);
                });
            }
          } else if (response === "INCOMPLETE_TEST_CAN_REPEAT") {
            setLoading(false);
            setIsNotComplete(true);
            setIsCanRepeat(true);
          } else if (response === "INCOMPLETE_TEST_CANNOT_REPEAT") {
            setLoading(false);
            setIsNotComplete(true);
            setIsCanRepeat(false);
          }
        })
        .catch((err) => {
          if (err.response?.status === 422) {
            setLoading(false);
            setIsNotQuota(true);
          } else {
            setLoading(false);
            setIsNotConnect(true);
          }
        });
    } else {
      setIsNotSupportBrowser(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //=================================================================================
  //=========================== function Loading Pop up ==============================
  //=================================================================================
  const showLoading = () => {
    if (loading) {
      return <CaptureLoading title="Loading" visible={true} />;
    }
  };

  //=================================================================================
  //=========================== function detect Device ==============================
  //=================================================================================
  // eslint-disable-next-line
  function detectDevice() {
    if (isDetect) {
      navigator.getUserMedia =
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        navigator.oGetUserMedia;
      if (navigator.getUserMedia) {
        // Request the camera.
        navigator.getUserMedia(
          // Constraints
          {
            video: true,
            audio: true,
          },
          // Success Callback
          function (localMediaStream) {
            setIsDetect(false);
            setIsNotSupport(false);
          },
          // Error Callback
          function (err) {
            setIsDetect(false);
            setIsNotSupport(true);
          }
        );
      } else if (navigator.mediaDevices.getUserMedia) {
        // Request the camera.
        navigator.mediaDevices.getUserMedia(
          // Constraints
          {
            video: true,
            audio: true,
          },
          // Success Callback
          function (localMediaStream) {
            setIsDetect(false);
            setIsNotSupport(false);
          },
          // Error Callback
          function (err) {
            setIsDetect(false);
            setIsNotSupport(true);
          }
        );
      } else {
        setIsDetect(false);
        setIsNotSupport(true);
      }
    }
  }
  //=================================================================================
  //=========================== function not support ==============================
  //=================================================================================
  function showNotSupport() {
    if (isNotSupport) {
      return (
        <Hint
          onClick={() => {
            setIsNotSupport(false);
            window.location.replace("/home");
          }}
          visible={isNotSupport}
          title="Error !"
          img="notif_cam_mic.png"
          desc={t("Interview.checkDevice")}
          actionTitle={t("General.back")}
        />
      );
    }
  }
  function notSupportBrowser() {
    if (isNotSupportBrowser) {
      return (
        <AffirmationModal
          isOpen={isNotSupportBrowser}
          title="Error !"
          desc={t("Interview.notSupport")}
          titleButton={t("General.back")}
          toggleOpen={() => {
            setIsNotSupportBrowser(true);
          }}
          confirmation={() => {
            setIsNotSupportBrowser(false);
            window.location.replace("/home");
          }}
        />
      );
    }
  }
  //=================================================================================
  //=========================== function not quota ==============================
  //=================================================================================
  function showNotQuota() {
    if (isNotQuota) {
      return (
        <AffirmationModal
          isOpen={isNotQuota}
          title="Oops!"
          desc={t("Interview.noQuota")}
          titleButton={t("General.back")}
          toggleOpen={() => {
            setIsNotQuota(true);
          }}
          confirmation={() => {
            setIsNotQuota(false);
            window.location.replace("/home");
          }}
        />
      );
    }
  }

  //=================================================================================
  //=========================== function not Connection ==============================
  //=================================================================================
  function showNotConnection() {
    if (isNotConnect) {
      return (
        <AffirmationModal
          isOpen={isNotConnect}
          title="Oops!"
          desc={t("Interview.troubleInternet")}
          titleButton={t("General.back")}
          toggleOpen={() => {
            setIsNotConnect(true);
          }}
          confirmation={() => {
            setIsNotConnect(false);
            window.location.replace("/home");
          }}
        />
      );
    }
  }

  //=================================================================================
  //=========================== function not same type ==============================
  //=================================================================================
  function showNotSameType() {
    if (notSameType) {
      return (
        <ConfirmationModal
          isOpen={notSameType}
          title={t("Interview.notCompletePreviousDeviceTitle")}
          desc={t("Interview.notCompletePreviousDevice")}
          titleLeft={t("General.repeat")}
          titleRight={t("General.back")}
          onPressRight={() => window.location.replace("/home")}
          onPressLeft={() => goToResetVideo()}
        />
      );
    }
  }

  //=================================================================================
  //=========================== function Pop Up Continue ==============================
  //=================================================================================
  function popUpContinue() {
    if (isNotComplete) {
      if (isCanRepeat) {
        return (
          <ConfirmationModal
            isOpen={isNotComplete}
            title={t("General.warning") + " !"}
            desc={t("Interview.recreate")}
            titleLeft={t("General.repeat")}
            titleRight={t("General.continue")}
            onPressRight={() => goToContinueVideo()}
            onPressLeft={() => goToResetVideo()}
          />
        );
      } else {
        return (
          <AffirmationModal
            isOpen={isNotComplete}
            title={t("General.warning") + " !"}
            desc={t("Interview.recreate")}
            titleButton={t("General.repeat")}
            toggleOpen={() => {
              setIsNotComplete(true);
            }}
            confirmation={() => goToResetVideo()}
          />
        );
      }
    }
  }

  //=================================================================================
  //=========================== function Video Continue ==============================
  //=================================================================================
  const goToContinueVideo = () => {
    setLoading(true);
    let type;
    if (isMobile) {
      type = "mobile";
    } else if (isBrowser) {
      type = "web";
    }
    requestGeneral
      .get("v1/assessment_video/questions_continuation?type=" + type)
      .then((res) => {
        const sources = res?.data?.data;
        setSources(sources);
        if (sessionStorage.getItem("video")) {
          let datajson = JSON.parse(sessionStorage.getItem("video"));
          const blob = DataURIToBlob(datajson?.video);
          let formData = new FormData();
          formData.append(`video`, blob, "video" + datajson?.code + ".webm");
          formData.append(`section`, datajson?.section);
          formData.append(`start_video`, datajson?.start_video);
          formData.append(`end_video`, datajson?.end_video);
          formData.append(`type`, type);
          requestGeneral
            .post("v1/assessment_video/submit", formData)
            .then((res) => {
              setSkip(true);
            })
            .catch((err) => {
              setIsNotSupportBrowser(true);
            });
        }
        setIsHintPhoto(false);
        setIsNotComplete(false);
        setLoading(false);
        setIsVideo(true);
      })
      .catch((err) => {
        setIsNotComplete(false);
        const response = err.response.data.error_type;
        if (err.response?.status === 422 && response === "HAS_DONE_TEST") {
          setLoading(false);
          setIsNotQuota(true);
        } else if (
          err.response?.status === 422 &&
          response === "TYPE_NOT_SAME"
        ) {
          setLoading(false);
          setNotSameType(true);
        } else {
          setLoading(false);
          setIsNotConnect(true);
        }
      });
  };

  //=================================================================================
  //=========================== function Video Reset ================================
  //=================================================================================
  const goToResetVideo = () => {
    setLoading(true);
    setNotSameType(false);
    let type;
    if (isMobile) {
      type = "mobile";
    } else if (isBrowser) {
      type = "web";
    }
    requestGeneral
      .get("v1/assessment_video/questions_reset?type=" + type)
      .then((res) => {
        if (sessionStorage.getItem("video")) {
          let datajson = JSON.parse(sessionStorage.getItem("video"));
          const blob = DataURIToBlob(datajson?.video);
          let formData = new FormData();
          formData.append(`video`, blob, "video" + datajson?.code + ".webm");
          formData.append(`section`, datajson?.section);
          formData.append(`start_video`, datajson?.start_video);
          formData.append(`end_video`, datajson?.end_video);
          formData.append(`type`, type);
          requestGeneral
            .post("v1/assessment_video/submit", formData)
            .then((res) => {
              setSkip(true);
            })
            .catch((err) => {
              setIsNotSupportBrowser(true);
            });
        }
        const sources = res?.data?.data;

        setLoading(false);
        setIsVideo(true);
        setPlay(true);
        setSources(sources);
        setIsNotComplete(false);
        setIsHintPhoto(false);
      })
      .catch((err) => {
        setIsNotComplete(false);
        if (
          err.response?.status === 422 &&
          err.response.data.error_type === "HAS_DONE_TEST"
        ) {
          setLoading(false);
          setIsNotQuota(true);
        } else {
          setLoading(false);
          setIsNotConnect(true);
        }
      });
  };
  //=================================================================================
  //============================= pop up hint awal ==================================
  //=================================================================================
  function hintPhotoPopup() {
    if (isHintPhoto) {
      return (
        <GuidePhotoOnVideoAssessment
          setIsHintPhoto={setIsHintPhoto}
          isHintPhoto={isHintPhoto}
          hintNumber={hintNumber}
          setHintNumberPhoto={setHintNumberPhoto}
        />
      );
    }
  }
  //=================================================================================
  //========================= function pop up palmistry =============================
  //=================================================================================
  function popUpHintPalmistry() {
    if (popUpPalmistry) {
      return (
        <AffirmationModal
          isOpen={popUpPalmistry}
          title={t("Interview.lastStep")}
          desc={t("Interview.lastStepDesc")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setPopUpPalmistry(true);
          }}
          confirmation={() => {
            setPopUpPalmistry(false);
            setPopUpDominan(true);
          }}
        />
      );
    }
  }
  //=================================================================================
  //========================= function pop up palmistry =============================
  //=================================================================================
  function popUpDominanHand() {
    if (popUpDominan) {
      return (
        <ChoiceModal
          isOpen={popUpDominan}
          title={t("Interview.lastStep")}
          desc={t("Interview.lastStepDescComplete")}
          titleLeft={t("Interview.leftHand")}
          titleRight={t("Interview.rightHand")}
          onPressRight={() => {
            setDominanHand("kanan");
            setPopUpDominan(false);
          }}
          onPressLeft={() => {
            setDominanHand("kiri");
            setPopUpDominan(false);
          }}
        />
      );
    }
  }
  //=================================================================================
  //============================ pop up gagal palmistry =============================
  //=================================================================================
  function popUpFailedPalmistry() {
    if (isFailedPalmistry) {
      return (
        <AffirmationModal
          isOpen={isFailedPalmistry}
          title="Oops!"
          desc={t("Interview.failedTakePicture")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setIsFailedPalmistry(true);
          }}
          confirmation={() => {
            setIsFailedPalmistry(false);
          }}
        />
      );
    }
  }

  //=================================================================================
  //======================== pop up gagal tangan palmistry ==========================
  //=================================================================================
  function popUpFailedHandPalmistry() {
    if (handPalmistryNotRecognize) {
      return (
        <AffirmationModal
          isOpen={handPalmistryNotRecognize}
          title="Oops!"
          desc={t("Interview.failedTakePicture")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setHandPalmistryNotRecognize(true);
          }}
          confirmation={() => {
            setHandPalmistryNotRecognize(false);
          }}
        />
      );
    }
  }
  //=================================================================================
  //=============================== take picture ====================================
  //=================================================================================
  const takePicture = React.useCallback(() => { // eslint-disable-line
    setLoading(true);
    setCapturing(true);
    // eslint-disable-next-line
    dataPhoto = webcamRefPhoto.current.getScreenshot();
    sendDataPhoto(dataPhoto);
    // eslint-disable-next-line
  }, [webcamRefPhoto]);

  //=================================================================================
  //========================== take picture palmistry ================================
  //=================================================================================
  useEffect(() => {
    if (filpImages !== null) {
      sendDataPhotoPalmistry(filpImages);
    }
  }, [filpImages]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (sources.length > 0) {
      setPlay(true);
    }
  }, [sources]); // eslint-disable-line react-hooks/exhaustive-deps

  const takePicturePalmistry = React.useCallback(() => { // eslint-disable-line
    setLoading(true);
    setCapturing(true);
    // eslint-disable-next-line
    dataPhoto = webcamRefPalmistry.current.getScreenshot();
    if (dominanHand === "kanan") {
      filpImage(dataPhoto, (url) => {
        setFilpImages(url);
      });
    } else {
      setFilpImages(dataPhoto);
    }
    // eslint-disable-next-line
  }, [webcamRefPalmistry, dominanHand]);
  const filpImage = (imageBase64, cb) => {
    var img = new Image();
    img.src = imageBase64;
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      var ctx = canvas.getContext("2d");
      ctx.scale(-1, 1);
      ctx.drawImage(img, 0, 0, canvas.width * -1, img.height);
      cb(canvas.toDataURL("image/jpeg"));
    };
  };

  //=================================================================================
  //============================= data uri to blob ==================================
  //=================================================================================
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  //=================================================================================
  //=============================== send picture ====================================
  //=================================================================================
  async function sendDataPhoto(dataPhoto) {
    let file = null;
    setIsVideo(true);
    file = DataURIToBlob(dataPhoto);
    let formData = new FormData();
    formData.append(`photo`, file, "photo.jpeg");
    if (file !== null) {
      requestGeneral
        .post("v1/assessment_video/submit_photo", formData)
        .then((res) => {
          setSuccessPhoto(true);
          setLoading(false);
          setIsVideo(true);
          setErrorPhotoFace(false);
        })
        .catch((err) => {
          if (err) {
            if (err.response?.data?.face === true) {
              setLoading(false);
              setSuccessPhoto(false);
              setIsVideo(false);
              setErrorPhotoFace(false);
              setCapturing(false);
            } else if (err.response?.data?.face === false) {
              setIsVideo(false);
              setLoading(false);
              setSuccessPhoto(false);
              setErrorPhotoFace(true);
              setCapturing(false);
            } else {
              setIsNotConnect(true);
              setLoading(false);
            }
          } else {
            setIsNotConnect(true);
            setLoading(false);
          }
        });
    } else {
      setIsNotSupport(true);
    }
  }

  //=================================================================================
  //============================= send palmistry ====================================
  //=================================================================================
  async function sendDataPhotoPalmistry(dataPhoto) {
    setFilpImages(null);
    let file = null;
    file = DataURIToBlob(dataPhoto);
    let formData = new FormData();
    formData.append(`photo`, file, "photo.jpeg");
    formData.append(`try`, trying);
    if (file !== null) {
      requestGeneral
        .post("v1/assessment_video/submit_palmistry", formData)
        .then((res) => {
          setLoading(false);
          setIsFailedPalmistry(false);
          setHandPalmistryNotRecognize(false);
          if (sources.length !== 0) {
            setIsVideo(true);
            setPlay(true);
          } else {
            setComplete(true);
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response.data.hand === true) {
              setHandPalmistryNotRecognize(true);
              setLoading(false);
              setIsVideo(false);
              setIsFailedPalmistry(false);
              setCapturing(false);
            } else if (err.response.data.hand === false) {
              setTrying(trying + 1);
              setHandPalmistryNotRecognize(false);
              setIsVideo(false);
              setLoading(false);
              setIsFailedPalmistry(true);
              setCapturing(false);
            } else {
              setIsNotConnect(true);
              setLoading(false);
            }
          } else {
            setIsNotConnect(true);
            setLoading(false);
          }
        });
    } else {
      setIsNotSupport(true);
    }
  }

  //=================================================================================
  //======================= function pop up photo status ============================
  //=================================================================================
  function statusSuccessPhotoPopup() {
    if (successPhotoFace === false) {
      if (successPhoto === true) {
        return (
          <AffirmationModal
            isOpen={successPhoto}
            title={t("Interview.photoSaved")}
            desc={t("Interview.photoSavedDesc")}
            titleButton={t("General.okey")}
            confirmation={() => {
              setSuccessPhoto(null);
              setPlay(true);
            }}
          />
        );
      } else if (successPhoto === false) {
        return (
          <AffirmationModal
            isOpen={!successPhoto}
            title={t("General.failed") + " !"}
            desc={t("Interview.failedPhoto")}
            titleButton={t("General.okey")}
            confirmation={() => {
              setSuccessPhoto(null);
            }}
          />
        );
      }
    } else {
      return (
        <AffirmationModal
          isOpen={!successPhoto}
          title={t("Interview.noPhotoBefore")}
          desc={t("Interview.noPhotoBeforeDesc")}
          titleButton={t("General.okey")}
          confirmation={() => {
            setSuccessPhoto(null);
            setErrorPhotoFace(false);
          }}
        />
      );
    }
  }

  //=================================================================================
  //=========================== function on ended video =============================
  //=================================================================================
  function onEndedVideo() {
    if (count === 0) {
      window.scrollTo(0, 0);
      if (skip) {
        setCount(count + 3);
      } else {
        setCount(count + 1);
      }
    } else if (count > 0 && count < sources.length - 1) {
      if (sources[count].section === null) {
        setPlay(true);
        setLoopVideo(false);
      } else {
        setLoopVideo(true);
        setCount(count + 1);
        setPlay(true);
        setVisibleControlPlayer(true);
        if (!loop) {
          setOnRecord(true);
        }
      }
    } else if (count > 0 && count >= sources.length - 1) {
      if (count === sources.length - 1) {
        if (complete) {
          if (!success) {
            setLoading(false);
            setSuccess(true);
          }
        } else {
          setIsEndVideo(true);
        }
      }
    }
  }
  useEffect(() => {
    if (count > 0 && count >= sources.length - 1) {
      if (isVideo) {
        if (count === sources.length - 1) {
          if (isEndVideo) {
            if (complete) {
              if (!success) {
                setLoading(false);
                setSuccess(true);
              }
            }
          }
        }
      }
    }
  }, [complete]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (complete) {
      if (!success) {
        setLoading(false);
        setSuccess(true);
      }
    }
  }, [isEndVideo]); // eslint-disable-line react-hooks/exhaustive-deps
  //==================================================================================
  //=========================== function recorded video ==============================
  //==================================================================================
  const handleStartCaptureClick = React.useCallback(() => {
    setOnRecord(false);
    setCountdown(true);
    setMenuVideoPlayer(false);
    time.current = setTimeout(() => {
      setWarning(true);
    }, 840000);

    setStartVideo(moment().format("YYYY-MM-DD HH:mm:ss"));
    setTimeout(() => {
      setOnStop(true);
    }, 15000);
    // setOnStop(true)
    var options = {
      mimeType: "video/webm",
    };
    mediaRecorderRef.current = new MediaRecorder(
      webcamRef.current.stream,
      options
    );
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, mediaRecorderRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  //==================================================================================
  //============================= function stop video ================================
  //==================================================================================
  const handleStopCaptureClick = () => {
    mediaRecorderRef.current.stop();
    setRecordedChunks([]);
    setWarning(false);
    clearInterval(time.current);
    setTimeout(() => {
      setMenuVideoPlayer(true);
    }, 500);
    setOnStop(false);
    setEndVideo(moment().format("YYYY-MM-DD HH:mm:ss"));

    setCountdown(false);
    // setLoopVideo(false);
    // setLoop(false);
    // setMenuVideoPlayer(false);
    // setVisibleControlPlayer(false);
  }; // eslint-disable-line react-hooks/exhaustive-deps

  //==================================================================================
  //=========================== function Countdown video =============================
  //==================================================================================
  function renderer({ minutes, seconds, completed }) {
    if (completed) {
      // Render a completed state
      handleStopCaptureClick();
      setWarning(false);
      return "";
    } else if (minutes === 0 && seconds <= 59) {
      if (seconds < 10) {
        return (
          <span>
            {minutes}:{"0" + seconds}
          </span>
        );
      } else {
        return (
          <span>
            {minutes}:{seconds}
          </span>
        );
      }
    } else {
      // Render a countdown
      if (seconds < 10) {
        return (
          <div className="box-control-up">
            <h2
              style={{ color: "#fff", margin: "0px" }}
              className="countdown-video"
            >
              {minutes}:{"0" + seconds}
            </h2>
          </div>
        );
      } else {
        return (
          <div className="box-control-up">
            <h2
              style={{ color: "#fff", margin: "0px" }}
              className="countdown-video"
            >
              {minutes}:{seconds}
            </h2>
          </div>
        );
      }
    }
  }

  //=================================================================================
  //========================= function handle replay video ==========================
  //=================================================================================
  const handleReplayClick = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      setUrl(URL.createObjectURL(blob));
    }
  }, [recordedChunks]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (url !== null) {
      setIsModalReplay(true);
    }
  }, [url]); // eslint-disable-line react-hooks/exhaustive-deps
  const toggleReplay = () => setIsModalReplay(!isModalReplay);

  //=================================================================================
  //========================= function handle replay question =======================
  //=================================================================================
  function handleReplayQuestionClick() {
    setCount(count - 1);
    setPlay(true);
    setLoop(true);
    setLoopVideo(false);
    setMenuVideoPlayer(true);
    setVisibleControlPlayer(false);
  }
  //=================================================================================
  //========================== function modal replay video ==========================
  //=================================================================================
  function replayPopup() {
    if (isModalReplay) {
      return (
        <ModalVideo
          isModalReplay={isModalReplay}
          url={url}
          toggleReplay={toggleReplay}
          recordedChunks={recordedChunks}
        />
      );
    }
  }

  //=================================================================================
  //============================== function next video ==============================
  //=================================================================================
  const handleNextVideo = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      // const url = URL.createObjectURL(blob);
      let data = {
        video: blob,
        section: sources[count - 1]["section"],
        code: sources[count - 1]["code"],
        start_video: startVideo,
        end_video: endVideo,
      };
      setLoopVideo(false);
      setLoop(false);
      setMenuVideoPlayer(false);
      setVisibleControlPlayer(false);
      setRecord([...record, data]);
      setRecordedChunks([]);
    }
  }, [recordedChunks]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (count > 0) {
      setCount(count + 1);
      setPlay(true);
      setSection(section + 1);
      sendDataVideo();
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  //=================================================================================
  //================================= send video ====================================
  //=================================================================================
  useEffect(() => {
    sendDataVideo(uploadingItem);
  }, [uploadingItem]); // eslint-disable-line
  
  async function sendDataVideo(currentSection) {
    let type;
    if (isMobile) {
      type = "mobile";
    } else if (isBrowser) {
      type = "web";
    }

    if (currentSection === undefined)
      if (isUploading) return;
      else currentSection = uploadingItem;
    
    if (record[currentSection] === undefined)
      return setIsUploading(false);
    setIsUploading(true);
    
    let formData = new FormData();
    formData.append('section', record[currentSection]["section"]);
    formData.append('start_video', record[currentSection]["start_video"]);
    formData.append('end_video', record[currentSection]["end_video"]);
    formData.append('type', type);
    formData.append(
      `video`,
      record[currentSection]["video"],
      "video-" + record[currentSection]["code"] + ".webm"
    );
    window.scrollTo(0, 0);
    
    let start = Date.now();
    let dur = (new Date(record[currentSection]["start_video"])).getTime();
    dur = (new Date(record[currentSection]["end_video"])).getTime() - dur;
    let size = record[currentSection]["video"].size;
    
    requestGeneral
      .post("v1/assessment_video/submit", formData)
      .then((res) => {
        if (res?.data?.complete === true) {
          setComplete(true);
        }
        else setUploadingItem(currentSection + 1);
        
        if (slowConn === false)
        {
          let diff = Date.now() - start;
          let ratio = diff / dur;
          if (ratio > 1.5)
          {
            let bandwith = Math.round(size / diff);
            setSlowConn([bandwith,Math.ceil(ratio)]);
          }
        }
      })
      .catch((err) => {
        if (err.response?.data) {
          if (err.response?.status === 422 && err.response.data.error_type === "HAS_DONE_TEST") {
            setLoading(false);
            setSuccess(true);
          } else {
            setNotSuccessVideo(currentSection);
          }
        } else {
          setNotSuccessVideo(currentSection);
        }
      });
  }

  //=================================================================================
  //================================= send video reupload ====================================
  //=================================================================================
  /* async function sendDataVideoReupload(countNow) {
    let type;
    if (isMobile) {
      type = "mobile";
    } else if (isBrowser) {
      type = "web";
    }

    let formData = new FormData();
    formData.append(
      `video`,
      record[countNow]["video"],
      "video" + record[countNow]["code"] + ".webm"
    );
    formData.append(`section`, record[countNow]["section"]);
    formData.append(`start_video`, record[countNow]["start_video"]);
    formData.append(`end_video`, record[countNow]["end_video"]);
    formData.append(`type`, type);
    window.scrollTo(0, 0);
    requestGeneral
      .post("v1/assessment_video/submit", formData)
      .then((res) => {
        if (res?.data?.complete === true) {
          setSuccess(true);
        }
      })
      .catch((err) => {
        if (err.response.data) {
          const response = err.response.data.error_type;
          if (err.response?.status === 422 && response === "HAS_DONE_TEST") {
            setSuccess(true);
          } else {
            setNotSuccessVideo(true);
          }
        } else {
          setNotSuccessVideo(true);
        }
      });
  } */

  //=================================================================================
  //=================================== Success =====================================
  //=================================================================================
  function showOnSuccess() {
    if (success) {
      return (
        <Hint
          title={t("General.success") + " !"}
          onClick={() => {
            window.location.replace("/interview-online");
          }}
          backBtn={false}
          img="checked.png"
          visible={success}
          desc={t("Interview.successSaved")}
          actionTitle={t("General.okey")}
        />
      );
    }
  }

  //=================================================================================
  //============================== Pop up video error ===============================
  //=================================================================================
  function sendVideoError() {
    if (successVideo) {
      return (
        <AffirmationModal
          isOpen={successVideo}
          title="Oops !"
          desc={t("Interview.notStable")}
          titleButton={t("General.okey")}
          confirmation={() => {
            sendDataVideo(successVideo);
            setNotSuccessVideo(false);
          }}
        />
      );
    }
  }
  
  //=================================================================================
  //============================ Pop up Slow Connection =============================
  //=================================================================================
  function popUpSlowConn () {
    if (typeof slowConn === 'object') {
      return (
        <AffirmationModal
          isOpen={typeof slowConn === 'object'}
          title={t("General.warning")}
          desc={t("Interview.slowConnection")
            .replace(/{(\d+)}/g, (match, number) => {
              return slowConn[number] || match
            })
          }
          titleButton={t("General.okey")}
          confirmation={() => {
            setSlowConn(true);
          }}
        />
      );
    }
  }
  //=================================================================================
  //================================ Upload Banner ==================================
  //=================================================================================
  function UploadBanner() {
    if (isEndVideo && !complete)
    return <CaptureLoading title={"Uploading ("+parseInt(uploadingItem+1)+"/"+record.length+")"} visible={true} />;
  };
  
  return (
    <Fragment>
        <div className="player-wrapper" style={{width: '100vw', height: '100vh', overflow: 'hidden'}}>
        {showLoading()}
        {notSupportBrowser()}
        {detectDevice()}
        {showNotSupport()}
        {showNotQuota()}
        {showNotConnection()}
        {popUpContinue()}
        {hintPhotoPopup()}
        {UploadBanner()}
        {showOnSuccess()}
        {sendVideoError()}
        {popUpSlowConn()}
        {popUpHintPalmistry()}
        {popUpFailedPalmistry()}
        {popUpFailedHandPalmistry()}
        {replayPopup()}
        {statusSuccessPhotoPopup()}
        {showNotSameType()}
        {popUpDominanHand()}
        {!loading ? (
          <VideoWebcam
            webcamRef={webcamRef}
            videoConstraints={videoConstraints}
            play={play}
            loopVideo={loopVideo}
            visibleControlPlayer={visibleControlPlayer}
            sources={sources}
            onStop={onStop}
            onRecord={onRecord}
            onEndedVideo={onEndedVideo}
            count={count}
            warning={warning}
            setLoading={setLoading}
            handleStartCaptureClick={handleStartCaptureClick}
            renderer={renderer}
            countdown={countdown}
            handleStopCaptureClick={handleStopCaptureClick}
            menuVideoPlayer={menuVideoPlayer}
            handleReplayClick={handleReplayClick}
            handleNextVideo={handleNextVideo}
            handleReplayQuestionClick={handleReplayQuestionClick}
          />
        ) : null}
      </div>
    </Fragment>
  );
});
