import React from "react";
import { Row, Button, Modal, ModalBody } from "reactstrap";

export default function ChoiceModal(props) {
  return (
    <Modal isOpen={props.isOpen} className="top-50" autoFocus={true}>
      <ModalBody>
        <Row>
          <div className="col-12 text-center">
            <h4 className="title-upgrade" style={{ color: "#93aad6" }}>
              {props.title}
            </h4>
          </div>
          {props.center === true ? (
            <div className="col-12 text-center">
              <p>{props.desc}</p>
              </div>
              ) : (
              <div className="col-12 text-justify">
                <p>{props.desc}</p>
                </div>
                )}
                    <div className="col-12 text-center">
                      <Row>
                        <div className="col-6 text-right">
                          <Button
                            className="mt-2"
                            type="submit"
                            color="netis-color"
                            onClick={props.onPressLeft}
                            >
                            {props.titleLeft}
                          </Button>
                        </div>
                        <div className="col-6 text-left">
                          <Button
                            className="mt-2"
                            type="submit"
                            color="netis-color"
                            onClick={props.onPressRight}
                            >
                            {props.titleRight}
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </Row>
                </ModalBody>
                </Modal>
                );
}
