import React, { Fragment } from "react";
import ContentTermsConditionLandingPage from '../../../Templates/LandingPage/ContentTermsConditionLandingPage';
import {
    Container,
} from 'reactstrap';
const TermsCondition = () => {
    return (
        <Fragment>
            <div className="header-landing-page-old">
                <Container className="wrap-content">
                    <ContentTermsConditionLandingPage />
                </Container>
            </div>
        </Fragment>
    )
}
export default TermsCondition;