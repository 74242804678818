import React from 'react'
import { Row, Col } from 'reactstrap'
import Pulse from "react-reveal/Pulse";
import { SubmitButton } from './SubmitButton';

export const SectionDefault = ({ questions, pageQuestion, showBtnAnswer, answers, chooseOption, xs, colClass, page, nextPage, btnDisable, section }) => {
  return (
    <Row className="fixedSection-Vacana" style={
      questions[page].section === 4 ? { top: '51vh', right: '8vw' } :
        questions[page].section === 2 && questions[page].questions[pageQuestion].codeQuestion === 5 ? { top: '51vh', right: '7vw' } : { right: '8vw' }
    }>
      {questions[page]?.questions[pageQuestion].options.map((data, idx) => (
        <Col xs={xs} key={idx} className="d-flex justify-content-center">
          {showBtnAnswer &&
            <Pulse>
              <button type="button" id={`btnAnswer-${idx}`} name={"option" + page} onClick={() => chooseOption(data.name)}
                className={answers[`${questions[page].section}.${questions[page].questions[pageQuestion].codeQuestion}`] === data.name
                  ? `options-vacana options-vacana-selected`
                  : `options-vacana`
                }
              >
                {data.text}
              </button>
            </Pulse>
          }
        </Col>
      ))}
      {showBtnAnswer && <SubmitButton addClass='w-100' nextPage={nextPage} btnDisable={btnDisable} section={section} questions={questions} page={page} />}
    </Row>
  )
}