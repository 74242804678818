import React from 'react'
import { Container, Row ,Col} from "reactstrap";

export default function ContentArtikelLandingPage() {
  return (
    <div className="text-center">
      <Container style={{ padding: "150px 0 90px 10px" }}>
        <Row>
          <Col md="4"></Col>
          <Col md="4">
            <p>
              <img
                src={require("../../../../assets/img/comingsoon.png")}
                alt="coming-soon"
                style={{width:'100%'}}
              />
            </p>
          </Col>
          <Col md="4"></Col>
        </Row>
      </Container>
    </div>
  );
}