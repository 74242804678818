import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { translate, t } from "react-switch-lang";
import Sadana from "../Sadana";
import Etung from "../Etung";
import Sankarta from "../newSankarta";
import Sacit from "../Sacit";
import Citraleka from "../Citraleka";
import BedheAksara from "../BedheAksara";
import Pathway from "../Pathway";
import Vacana from "../newVacana";
import moment from "../../../../../utils/Moment";
function UserGuide(props) {
  const [nextPage, setNextPage] = useState(false);
  const nextPageButton = () => {
    setNextPage(true);
  };
  return (
    <Container>
      {nextPage ? (
        props?.name === "sadana" ? (
          <Sadana
            title={props.title}
            setNextPage={props.setNextPage}
            page={props.page}
            startTime={moment().format("YYYY-MM-DD HH:mm:ss")}
          />
        ) : props?.name === "etung" ? (
          <Etung
            title={props.title}
            setNextPage={props.setNextPage}
            page={props.page}
            startTime={moment().format("YYYY-MM-DD HH:mm:ss")}
          />
        ) : props?.name === "sankarta" ? (
          <Sankarta
            title={props.title}
            setNextPage={props.setNextPage}
            page={props.page}
          />
        ) : props?.name === "sacit" ? (
          <Sacit
            title={props.title}
            setNextPage={props.setNextPage}
            page={props.page}
            startTime={moment().format("YYYY-MM-DD HH:mm:ss")}
          />
        ) : props?.name === "citraleka" ? (
          <Citraleka
            title={props.title}
            setNextPage={props.setNextPage}
            page={props.page}
            startTime={moment().format("YYYY-MM-DD HH:mm:ss")}
          />
        ) : props?.name === "bedhe_aksara" ? (
          <BedheAksara
            title={props.title}
            setNextPage={props.setNextPage}
            page={props.page}
            startTime={moment().format("YYYY-MM-DD HH:mm:ss")}
          />
        ) : props?.name === "pathway_game" ? (
          <Pathway
            title={props.title}
            setNextPage={props.setNextPage}
            page={props.page}
            startTime={moment().format("YYYY-MM-DD HH:mm:ss")}
          />
        ) : props?.name === "vacana" ? (
          <Vacana
            title={props.title}
            setNextPage={props.setNextPage}
            page={props.page}
          />
        ) : null
      ) : (
        <Row>
          <Col xs="1"></Col>
          <Col
            xs="10"
            className=" mt-4 mb-4 pt-5 pb-5"
            style={{
              boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
            }}
          >
            <Row>
              <Col sm="12" className="mb-3">
                <div className="box-agility-detail">
                  <div className="box-image-detail text-center">
                    <img
                      src={props.image}
                      style={{
                        height: "170px",
                      }}
                      alt="icon_userGuide_agility"
                    />
                  </div>
                  <br />
                  <h2>
                    <b>{props?.title}</b>
                  </h2>
                  <p>
                    {props?.userGuide
                      ? props.userGuide.map((data, idx) => {
                        return (
                          <span key={idx}>
                            {++idx} . {data}
                            <br />
                          </span>
                        );
                      })
                      : null}
                  </p>
                </div>
              </Col>
              <Col sm="2"></Col>
              <Col sm="8">
                <p></p>
              </Col>
              <Col sm="2"></Col>
              <Col sm="12" className="text-center">
                <button
                  className="btn btn-primary mt-3"
                  onClick={nextPageButton}
                >
                  {t("General.next")} <i className="ml-2 fa fa-arrow-right"></i>
                </button>
              </Col>
            </Row>
          </Col>
          <Col xs="1"></Col>
        </Row>
      )}
    </Container>
  );
}

export default translate(UserGuide);
