import React, { Fragment } from 'react'
import {
  Row,
  Container
} from 'reactstrap';
import MbtiPersonalityList from '../MbtiPersonalityList'
import {translate, t } from "react-switch-lang"
export default translate(function ContentPersonalityListLandingPage() {

  return (
    <Fragment>
      <div sm="12" style={{ backgroundColor: 'rgb(247 247 247)', paddingBottom: '-190px' }}>
        <Container>
          <Row>
            <MbtiPersonalityList type="intj" name={t('personalityTypes.architect')} desc={t('personalityTypes.architectDesc')} />
            <MbtiPersonalityList type="intp" name={t('personalityTypes.planner')} desc={t('personalityTypes.plannerDesc')} />
            <MbtiPersonalityList type="entj" name={t('personalityTypes.commander')} desc={t('personalityTypes.commanderDesc')} />
            <MbtiPersonalityList type="entp" name={t('personalityTypes.debater')} desc={t('personalityTypes.debaterDesc')} />
            <MbtiPersonalityList type="infj" name={t('personalityTypes.advisor')} desc={t('personalityTypes.advisorDesc')} />
            <MbtiPersonalityList type="infp" name={t('personalityTypes.healer')} desc={t('personalityTypes.healerDesc')} />
            <MbtiPersonalityList type="enfj" name={t('personalityTypes.instructor')} desc={t('personalityTypes.instructorDesc')} />
            <MbtiPersonalityList type="enfp" name={t('personalityTypes.winner')} desc={t('personalityTypes.winnerDesc')} />
            <MbtiPersonalityList type="istj" name={t('personalityTypes.inspector')} desc={t('personalityTypes.inspectorDesc')} />
            <MbtiPersonalityList type="isfj" name={t('personalityTypes.protector')} desc={t('personalityTypes.protectorDesc')} />
            <MbtiPersonalityList type="estj" name={t('personalityTypes.supervisor')} desc={t('personalityTypes.supervisorDesc')} />
            <MbtiPersonalityList type="esfj" name={t('personalityTypes.provider')} desc={t('personalityTypes.providerDesc')} />
            <MbtiPersonalityList type="istp" name={t('personalityTypes.artisan')} desc={t('personalityTypes.artisanDesc')}/>
            <MbtiPersonalityList type="isfp" name={t('personalityTypes.composer')} desc={t('personalityTypes.composerDesc')} />
            <MbtiPersonalityList type="estp" name={t('personalityTypes.promoter')} desc={t('personalityTypes.promoterDesc')} />
            <MbtiPersonalityList type="esfp" name={t('personalityTypes.entertainer')} desc={t('personalityTypes.entertainerDesc')} />
          </Row>
        </Container>
      </div>
      </Fragment>
      )
})