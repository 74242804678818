import React, { useState, useEffect } from "react";
import { Row, Col, Container, Alert } from "reactstrap";
import { useParams } from "react-router-dom";
import request from "../../../utils/Request";
import LoadingAnimation from "../../../components/atoms/LoadingAnimation";
import { translate, t } from "react-switch-lang";

function ShareableMsdt() {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  const { resultCodes } = useParams();
  useEffect(() => {
    request
      .get("/assessment/test/msdt/share/" + resultCodes)
      .then((res) => {
        setResult(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setResult(null);
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  function title(title) {
    switch (title) {
      case "executive":
        return "Executive";
      case "compromiser":
        return "Compromiser";
      case "benevolent_autocrat":
        return "Benevolent Autocrat";
      case "autocrat":
        return "Autocrat";
      case "developer":
        return "Developer";
      case "missionary":
        return "Missionary";
      case "bureaucrat":
        return "Bureaucrat";
      case "deserter":
        return "Deserter";
      default:
        return "";
    }
  }
  return (
    <div>
      {loading ? (
        <LoadingAnimation />
      ) : result ? (
        <Container>
          <div
            className="justify-content-center"
            style={{ paddingTop: "2rem" }}
          >
            <Row>
              <Col sm="2" />
              <Col sm="8">
                <div className="text-center">
                  <Row>
                    <Col sm="2" />
                    <Col sm="8">
                      <Alert
                        color="info"
                        className="text-left"
                        style={{
                          width: "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "70px",
                          zIndex: 999,
                        }}
                      >
                        <h3 style={{ color: "#1B568D", fontWeight: "bold" }}>
                          {t("Assessment.leadershipType")} {title(result.type)}
                        </h3>
                        <p className="mb-2" style={{ color: "#555" }}>
                          {result.description}
                          <ul>
                            {result.lists.map((data, idx) => {
                              return <li key={idx}>{data}</li>;
                            })}
                          </ul>
                        </p>
                      </Alert>
                    </Col>
                    <Col sm="2" />
                    <Col sm="12">
                      <img
                        src={require("../../../assets/img/msdt/web/" +
                          result.type +
                          ".png")}
                        alt={result.type}
                        style={{ width: "100%", marginTop: "-100px" }}
                      />
                    </Col>
                  </Row>
                </div>
                <br />
              </Col>
              <Col sm="2" />
            </Row>
          </div>
        </Container>
      ) : (
        <Alert color="danger" className="text-center mt-3 mb-4">
          <h5 className="content-title mb-2">{t("Assessment.noData")}</h5>
        </Alert>
      )}
    </div>
  );
}
export default translate(ShareableMsdt);
