import React, { useState, Fragment } from "react";
import { useEffect } from "react";
import Hint from "../../../../components/atoms/Hint";
// import request from "../../../utils/Request";
import requestGeneral from "../../../../utils/RequestGeneral";
import AffirmationModal from "../../../../components/atoms/AffirmationModal";
import VideoWebcam from "../../VideoAssesment/VideoWebcam";
import ModalVideo from "../../VideoAssesment/ModalVideo";
import CaptureLoading from "../../../../components/atoms/CaptureLoading";
import moment from "../../../../utils/Moment";
import ConfirmationModal from "../../../../components/atoms/ConfirmationModal";
import { isMobile, isBrowser } from "react-device-detect";
import { translate, t } from "react-switch-lang";
import langUtils from "../../../../utils/SettingLanguage/index";
export default translate(function VideoWebCam(props) {
  const chooseLanguages = {
    ID: "id",
    EN: "en",
  };
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const time = React.useRef(null);

  const [loading, setLoading] = useState(true);
  const [isDetect, setIsDetect] = useState(false);
  const [isNotSupport, setIsNotSupport] = useState(false);
  const [isNotConnect, setIsNotConnect] = useState(false);
  const [play, setPlay] = useState(true);
  const [sources, setSources] = useState([]);
  const [visibleControlPlayer, setVisibleControlPlayer] = useState(false);
  const [menuVideoPlayer, setMenuVideoPlayer] = useState(false);
  const [onRecord, setOnRecord] = useState(false);
  const [onStop, setOnStop] = useState(false);
  const section = 0;
  const [countdown, setCountdown] = useState(false);
  const [loopVideo, setLoopVideo] = useState(false);
  const [count, setCount] = useState(0);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [startVideo, setStartVideo] = useState([]);
  const [endVideo, setEndVideo] = useState([]);
  const [record, setRecord] = useState([]);
  const [isModalReplay, setIsModalReplay] = useState(false);
  const [url, setUrl] = useState(null);
  const [success, setSuccess] = useState(false);
  const [successVideo, setNotSuccessVideo] = useState(false);
  const [warning, setWarning] = useState(false);
  const [loop, setLoop] = useState(false);
  const [isNotSupportBrowser, setIsNotSupportBrowser] = useState(false);
  const videoConstraints = {
    facingMode: "user",
  };

  useEffect(() => {
    let type;
    if (isMobile) {
      type = "mobile";
    } else if (isBrowser) {
      type = "web";
    }
    if (props.support) {
      setIsNotSupportBrowser(false);
      setIsDetect(true);
      requestGeneral
        .get(
          "v1/assessment_video/questions_landing_page?type=" +
            type +
            "&lang=" +
            chooseLanguages[langUtils.getLanguage()]
        )
        .then((res) => {
          const response = res?.data?.response_type;
          if (response === "QUESTIONS_SUCCESS") {
            const sources = res?.data.data;
            setSources(sources);
            setLoading(false);
          }
        })
        .catch((err) => {
          setIsNotConnect(true);
          setLoading(false);
        });
    } else {
      setIsNotSupportBrowser(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //=================================================================================
  //=========================== function Loading Pop up ==============================
  //=================================================================================
  const showLoading = () => {
    if (loading) {
      return <CaptureLoading title="Loading" visible={true} />;
    }
  };

  //=================================================================================
  //=========================== function detect Device ==============================
  //=================================================================================
  // eslint-disable-next-line
  function detectDevice() {
    if (isDetect) {
      navigator.getUserMedia =
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        navigator.oGetUserMedia;
      if (navigator.getUserMedia) {
        // Request the camera.
        navigator.getUserMedia(
          // Constraints
          {
            video: true,
            audio: true,
          },
          // Success Callback
          function (localMediaStream) {
            setIsDetect(false);
            setIsNotSupport(false);
          },
          // Error Callback
          function (err) {
            setIsDetect(false);
            setIsNotSupport(true);
          }
        );
      } else if (navigator.mediaDevices.getUserMedia) {
        // Request the camera.
        navigator.mediaDevices.getUserMedia(
          // Constraints
          {
            video: true,
            audio: true,
          },
          // Success Callback
          function (localMediaStream) {
            setIsDetect(false);
            setIsNotSupport(false);
          },
          // Error Callback
          function (err) {
            setIsDetect(false);
            setIsNotSupport(true);
          }
        );
      } else {
        setIsDetect(false);
        setIsNotSupport(true);
      }
    }
  }
  //=================================================================================
  //=========================== function not support ==============================
  //=================================================================================
  function showNotSupport() {
    if (isNotSupport) {
      return (
        <Hint
          onClick={() => {
            setIsNotSupport(false);
            window.location.replace("/home");
          }}
          visible={isNotSupport}
          title="Error !"
          img="notif_cam_mic.png"
          desc={t("Interview.checkDevice")}
          actionTitle={t("General.back")}
        />
      );
    }
  }
  function notSupportBrowser() {
    if (isNotSupportBrowser) {
      return (
        <AffirmationModal
          isOpen={isNotSupportBrowser}
          title="Error !"
          desc={t("Interview.notSupport")}
          titleButton={t("General.back")}
          toggleOpen={() => {
            setIsNotSupportBrowser(true);
          }}
          confirmation={() => {
            setIsNotSupportBrowser(false);
            window.location.replace("/home");
          }}
        />
      );
    }
  }

  //=================================================================================
  //=========================== function not Connection ==============================
  //=================================================================================
  function showNotConnection() {
    if (isNotConnect) {
      return (
        <AffirmationModal
          isOpen={isNotConnect}
          title="Oops!"
          desc={t("Interview.troubleInternet")}
          titleButton={t("General.back")}
          toggleOpen={() => {
            setIsNotConnect(true);
          }}
          confirmation={() => {
            setIsNotConnect(false);
            window.location.replace("/home");
          }}
        />
      );
    }
  }

  //=================================================================================
  //=========================== function on ended video =============================
  //=================================================================================
  function onEndedVideo() {
    if (count === 0) {
      window.scrollTo(0, 0);
      setCount(count + 1);
    } else if (count > 0 && count < sources.length - 1) {
      if (sources[count].section === null) {
        setPlay(true);
        setLoopVideo(false);
      } else {
        setLoopVideo(true);
        setCount(count + 1);
        setPlay(true);
        setVisibleControlPlayer(true);
        if (!loop) {
          setOnRecord(true);
        }
      }
    }
  }
  //==================================================================================
  //=========================== function recorded video ==============================
  //==================================================================================
  const handleStartCaptureClick = React.useCallback(() => {
    setOnRecord(false);
    setCountdown(true);
    setMenuVideoPlayer(false);
    time.current = setTimeout(() => {
      setWarning(true);
    }, 840000);

    setStartVideo(moment().format("YYYY-MM-DD HH:mm:ss"));
    setTimeout(() => {
      setOnStop(true);
    }, 15000);
    // setOnStop(true)
    var options = {
      mimeType: "video/webm",
    };
    mediaRecorderRef.current = new MediaRecorder(
      webcamRef.current.stream,
      options
    );
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, mediaRecorderRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  //==================================================================================
  //============================= function stop video ================================
  //==================================================================================
  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setRecordedChunks([]);
    setWarning(false);
    clearInterval(time.current);
    setTimeout(() => {
      setMenuVideoPlayer(true);
    }, 500);
    setOnStop(false);
    setEndVideo(moment().format("YYYY-MM-DD HH:mm:ss"));
    setCountdown(false);
  }, [mediaRecorderRef, webcamRef]); // eslint-disable-line react-hooks/exhaustive-deps

  //==================================================================================
  //=========================== function Countdown video =============================
  //==================================================================================
  function renderer({ minutes, seconds, completed }) {
    if (completed) {
      // Render a completed state
      handleStopCaptureClick();
      setWarning(false);
      return "";
    } else if (minutes === 0 && seconds <= 59) {
      if (seconds < 10) {
        return (
          <span>
            {minutes}:{"0" + seconds}
          </span>
        );
      } else {
        return (
          <span>
            {minutes}:{seconds}
          </span>
        );
      }
    } else {
      // Render a countdown
      if (seconds < 10) {
        return (
          <div className="box-control-up">
            <h2
              style={{ color: "#fff", margin: "0px" }}
              className="countdown-video"
            >
              {minutes}:{"0" + seconds}
            </h2>
          </div>
        );
      } else {
        return (
          <div className="box-control-up">
            <h2
              style={{ color: "#fff", margin: "0px" }}
              className="countdown-video"
            >
              {minutes}:{seconds}
            </h2>
          </div>
        );
      }
    }
  }

  //=================================================================================
  //========================= function handle replay video ==========================
  //=================================================================================
  const handleReplayClick = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      setUrl(URL.createObjectURL(blob));
    }
  }, [recordedChunks]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (url !== null) {
      setIsModalReplay(true);
    }
  }, [url]); // eslint-disable-line react-hooks/exhaustive-deps
  const toggleReplay = () => setIsModalReplay(!isModalReplay);

  //=================================================================================
  //========================= function handle replay question =======================
  //=================================================================================
  function handleReplayQuestionClick() {
    setCount(count - 1);
    setPlay(true);
    setLoop(true);
    setLoopVideo(false);
    setMenuVideoPlayer(true);
    setVisibleControlPlayer(false);
  }
  //=================================================================================
  //========================== function modal replay video ==========================
  //=================================================================================
  function replayPopup() {
    if (isModalReplay) {
      return (
        <ModalVideo
          isModalReplay={isModalReplay}
          url={url}
          toggleReplay={toggleReplay}
          recordedChunks={recordedChunks}
        />
      );
    }
  }

  //=================================================================================
  //============================== function next video ==============================
  //=================================================================================
  const handleNextVideo = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      // const url = URL.revokeObjectURL(blob);
      // console.log(url);
      let data = {
        video: blob,
        section: sources[count - 1]["section"],
        start_video: startVideo,
        end_video: endVideo,
      };
      setLoopVideo(false);
      setLoop(false);
      setMenuVideoPlayer(false);
      setVisibleControlPlayer(false);
      setRecord([...record, data]);
      setRecordedChunks([]);
    }
  }, [recordedChunks]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (count > 0) {
      sendDataVideo();
    }
  }, [record]); // eslint-disable-line react-hooks/exhaustive-deps

  //=================================================================================
  //================================= send video ====================================
  //=================================================================================
  async function sendDataVideo() {
    let type; // eslint-disable-line
    if (isMobile) {
      type = "mobile";
    } else if (isBrowser) {
      type = "web";
    }
    var reader = new FileReader();
    reader.readAsDataURL(record[section]["video"]);
    reader.onloadend = function () {
      var base64data = reader.result;
      const ss = {
        video: base64data,
        code: record[section]["code"],
        section: record[section]["section"],
        start_video: record[section]["start_video"],
        end_video: record[section]["end_video"],
      };
      sessionStorage.setItem("video", JSON.stringify(ss));
    };

    setSuccess(true);
    window.scrollTo(0, 0);
    // if (count === sources.length - 2) {
    //   setIsVideo(false);
    //   setCount(count + 1);
    // } else if (count < sources.length - 2) {
    //   setCount(count + 1);
    //   setPlay(true);
    // }
    // setSection(section + 1);

    // requestGeneral
    //   .post("v1/assessment_video/submit", formData)
    //   .then((res) => {
    //     if (res?.data?.complete === true) {
    //       setComplete(true);
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response.data) {
    //       const response = err.response.data.error_type;
    //       if (err.response?.status === 422 && response === "HAS_DONE_TEST") {
    //         setSuccess(true);
    //         setLoading(false);
    //       } else {
    //         setNotSuccessVideo(true);
    //       }
    //     } else {
    //       setNotSuccessVideo(true);
    //     }
    //   });
  }

  //=================================================================================
  //=================================== Success =====================================
  //=================================================================================
  function showOnSuccess() {
    if (success) {
      return (
        <ConfirmationModal
          isOpen={success}
          title={t("General.warning") + " !"}
          desc={t("Interview.continueVideo")}
          titleLeft={t("General.cancel")}
          titleRight={"Login"}
          onPressLeft={() => {
            window.location.replace("/video-cv");
          }}
          onPressRight={() => {
            window.location.replace("/login");
          }}
        />
      );
    }
  }

  //=================================================================================
  //============================== Pop up video error ===============================
  //=================================================================================
  function sendVideoError() {
    if (successVideo) {
      return (
        <AffirmationModal
          isOpen={successVideo}
          title="Oops !"
          desc={t("Interview.notStable")}
          titleButton={t("General.okey")}
          confirmation={() => {
            setNotSuccessVideo(false);
          }}
        />
      );
    }
  }
  return (
    <Fragment>
      <div className="player-wrapper">
        {notSupportBrowser()}
        {detectDevice()}
        {showNotSupport()}
        {showNotConnection()}
        {showLoading()}
        {showOnSuccess()}
        {sendVideoError()}
        {replayPopup()}
        <VideoWebcam
          webcamRef={webcamRef}
          videoConstraints={videoConstraints}
          play={play}
          loopVideo={loopVideo}
          visibleControlPlayer={visibleControlPlayer}
          sources={sources}
          onStop={onStop}
          onRecord={onRecord}
          onEndedVideo={onEndedVideo}
          count={count}
          warning={warning}
          setLoading={setLoading}
          handleStartCaptureClick={handleStartCaptureClick}
          renderer={renderer}
          countdown={countdown}
          handleStopCaptureClick={handleStopCaptureClick}
          menuVideoPlayer={menuVideoPlayer}
          handleReplayClick={handleReplayClick}
          handleNextVideo={handleNextVideo}
          handleReplayQuestionClick={handleReplayQuestionClick}
        />
      </div>
    </Fragment>
  );
});
