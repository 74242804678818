import React from "react";
import { Row, Button, Modal, ModalBody, CardImg } from "reactstrap";

export default function ConfirmationModal(props) {
  return (
    <Modal isOpen={props.isOpen} className="top-50" autoFocus={true}>
      <ModalBody>
        <Row>
          <div className="col-12 text-center">
            <h4 className="title-upgrade" style={{ color: "#93aad6" }}>
              {props.title}
            </h4>
          </div>
          {props.center === true ? (
            <div className="col-12 text-center">
              <p>{props.desc}</p>
            </div>
          ) : (
            <div className="col-12 text-justify">
              <p>{props.desc}</p>
            </div>
          )}

          {props.images === true ? (
            <div className="col-12 text-center">
              {props.guide === 1 ? (
                <CardImg
                  style={{ width: "280px" }}
                  src={require("../../../assets/img/guide/Asset_2.png")}
                  alt="Card image cap"
                />
              ) : props.guide === 2 ? (
                <CardImg
                  style={{ width: "280px" }}
                  src={require("../../../assets/img/guide/Asset_1.png")}
                  alt="Card image cap"
                />
              ) : props.guide === 3 ? (
                <CardImg
                  style={{ width: "310px" }}
                  src={require("../../../assets/img/guide/petunjuk_profil.png")}
                  alt="Card image cap"
                />
              ) : props.guide === 4 ? (
                <CardImg
                  style={{ width: "310px" }}
                  src={require("../../../assets/img/guide/petunjuk_psikologi.png")}
                  alt="Card image cap"
                />
              ) : props.guide === 5 ? (
                <CardImg
                  style={{ width: "310px" }}
                  src={require("../../../assets/img/guide/petunjuk_wawancara.png")}
                  alt="Card image cap"
                />
              ) : props.guide === 6 ? (
                <CardImg
                  style={{ width: "310px" }}
                  src={require("../../../assets/img/guide/petunjuk_lamaran.png")}
                  alt="Card image cap"
                />
              ) : null}
            </div>
          ) : null}
          <div className="col-12 text-center">
            <Row>
              <div className="col-6 text-right">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-danger"
                  onClick={props.onPressLeft}
                >
                  {props.titleLeft}
                </Button>
              </div>
              <div className="col-6 text-left">
                <Button
                  className="mt-2"
                  type="submit"
                  color="netis-color"
                  onClick={props.onPressRight}
                >
                  {props.titleRight}
                </Button>
              </div>
            </Row>
          </div>
        </Row>
      </ModalBody>
    </Modal>
  );
}
