import React, { useState, useEffect, Fragment } from "react";
import { Redirect } from "react-router-dom";
import LoadingAnimation from "../../../../components/atoms/LoadingAnimation";
import ConfirmationModal from "../../../../components/atoms/ConfirmationModal";
import VideoWebCam from "./index";
import { translate, t } from "react-switch-lang";
import { connect } from "react-redux";
const AuthWeb = (props) => {
  const [loading, setLoading] = useState(true);
  const [support, setSupport] = useState(false);
  useEffect(() => {
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    if (isSafari || isIE || isEdge) {
      setSupport(false);
    } else {
      setSupport(true);
    }
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (props.user?.id) {
    return <Redirect to="/interview-online" />;
  }
  return (
    <Fragment>
      {loading ? (
        <LoadingAnimation />
      ) : sessionStorage.getItem("video") ? (
        <ConfirmationModal
          isOpen={true}
          title={t("General.warning") + " !"}
          desc={t("Interview.continueVideo")}
          titleLeft={t("General.cancel")}
          titleRight={"Login"}
          onPressLeft={() => {
            window.location.replace("/video-cv");
          }}
          onPressRight={() => {
            window.location.replace("/login");
          }}
        />
      ) : (
        <VideoWebCam support={support} />
      )}
    </Fragment>
  );
};
export default connect(({ user }) => ({ user }))(translate(AuthWeb));
