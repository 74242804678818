import React from "react";
import Hint from "../../../components/atoms/Hint";
import { translate } from "react-switch-lang";
export default translate(function GuidePhotoOnVideoAssessment(props) {
  const { t } = props;
  if (props.isHintPhoto) {
    if (props.hintNumber === 1) {
      return (
        <Hint
          onClick={() => props.setHintNumberPhoto(2)}
          visible={props.isHintPhoto}
          title={t("Interview.verifyYourFace")}
          img={null}
          desc={t("Interview.verifyYourFacePoint1")}
          actionTitle={t("Interview.btnNext")}
        />
      );
    }
    if (props.hintNumber === 2) {
      return (
        <Hint
          onClick={() => props.setHintNumberPhoto(3)}
          backBtn={true}
          onBack={() => props.setHintNumberPhoto(1)}
          visible={props.isHintPhoto}
          img="hint1.png"
          desc={t("Interview.verifyYourFacePoint2")}
          actionTitle={t("Interview.btnNext")}
        />
      );
    }
    if (props.hintNumber === 3) {
      return (
        <Hint
          onClick={() => props.setHintNumberPhoto(4)}
          backBtn={true}
          onBack={() => props.setHintNumberPhoto(2)}
          visible={props.isHintPhoto}
          img="hint2.png"
          desc={t("Interview.verifyYourFacePoint3")}
          actionTitle={t("Interview.btnNext")}
        />
      );
    }
    if (props.hintNumber === 4) {
      return (
        <Hint
          onClick={() => props.setHintNumberPhoto(5)}
          backBtn={true}
          onBack={() => props.setHintNumberPhoto(3)}
          visible={props.isHintPhoto}
          img="hint3.png"
          desc={t("Interview.verifyYourFacePoint4")}
          actionTitle={t("Interview.btnNext")}
        />
      );
    }
    if (props.hintNumber === 5) {
      return (
        <Hint
          onClick={() => props.setHintNumberPhoto(6)}
          backBtn={true}
          onBack={() => props.setHintNumberPhoto(4)}
          visible={props.isHintPhoto}
          img="hint4.png"
          desc={t("Interview.verifyYourFacePoint5")}
          actionTitle={t("Interview.btnNext")}
        />
      );
    }
    if (props.hintNumber === 6) {
      return (
        <Hint
          onClick={() => {
            props.setIsHintPhoto(false);
          }}
          backBtn={true}
          onBack={() => props.setHintNumberPhoto(5)}
          visible={props.isHintPhoto}
          img="hint5.png"
          desc={t("Interview.verifyYourFacePoint6")}
          actionTitle={t("Interview.btnOk")}
        />
      );
    }
  }
});
