import React, { useEffect, useState, Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, Route } from "react-router";
import axios from "axios";
import LoadingAnimation from "../../components/atoms/LoadingAnimation";
const AuthRoute = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(true); // eslint-disable-line
  useEffect(() => {
    if (!localStorage.getItem("session")) {
      let data = null;
      dispatch({
        type: "SET_USER",
        payload: data,
      });
      setUser(data);
      setLoading(false);
    } else {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_DOMAIN}/api/auth/me`,
        headers: { Authorization: `Bearer ${localStorage.getItem("session")}` },
      })
        .then(({ data }) => {
          dispatch({
            type: "SET_USER",
            payload: data,
          });
          setUser(data);
          setLoading(false);
        })
        .catch((error) => {
          let data = null;
          dispatch({
            type: "SET_USER",
            payload: data,
          });
          setUser(data);
          setLoading(false);
        });
    }
  }, [dispatch]);
  const { isAuthUser, type } = props;
  if (loading) {
    return <Fragment>{loading ? <LoadingAnimation /> : null}</Fragment>;
  }
  if (type === "guest" && isAuthUser === true) {
    // const search = new URLSearchParams(location.search);
    // if (location.pathname === "/login") {
    //   if (search.get("to")) {
    //     return <Redirect to={search.get("to")} />;
    //   }
    // }
    return <Redirect to="/home" user />;
  } else if (type === "private" && isAuthUser === false) {
    // const searchParams = new URLSearchParams(location.search);
    // if (location.pathname !== "/login") {
    //   searchParams.set("to", location.pathname);
    // }
    // return <Redirect to={"/login?" + searchParams.toString()} user />;
    return <Redirect to="/login" user />;
  } else if (type === "general" && isAuthUser === true) {
    return <Route {...props} user />;
  } else {
    return <Route {...props} user />;
  }
};

const mapStateToProps = ({ isAuthUser }) => ({
  isAuthUser,
});

export default connect(mapStateToProps)(AuthRoute);
