import { apiRequestLogin, apiRequestLogout } from "../Actions/api.js";
import { LOGIN } from "../Actions/auth.js";
import { LOGOUT } from "../Actions/auth.js";

const SERVER_URL = process.env.REACT_APP_DOMAIN;

export const appMiddleware = () => next => action => {
    next(action);
    // console.log(action)
    switch (action.type) {
        case LOGIN:
            next(

                apiRequestLogin({
                    url: `${SERVER_URL}/api/auth/login`,
                    method: "POST",
                    data: action.payload
                })

            );
            break;

        case LOGOUT:
            next(
                apiRequestLogout({
                    url: `${SERVER_URL}/api/auth/logout`,
                    method: "POST",
                })
            );
            break;

        default:
            break;
    }
};