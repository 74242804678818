import { applyMiddleware, createStore } from "redux";
import { appMiddleware } from "../Middlewares/app";
import { authMiddleware } from "../Middlewares/auth";
import reducer from '../Reducer'

const createStoreWithMiddleware = applyMiddleware(
    appMiddleware,
    authMiddleware
)(createStore);

const store = createStoreWithMiddleware(reducer);

export default store;
