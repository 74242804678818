import React from "react";
import Skeleton from "react-loading-skeleton";
import { Row, Col } from "reactstrap";
import { isBrowser } from "react-device-detect";
const FingerprintLoading = () => {
  return (
    <Row>
      <Col sm="1"></Col>
      <Col
        sm="10 mt-4 mb-4 pt-4 pb-4"
        style={{
          boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
        }}
      >
        <div className="row">
          {isBrowser ? (
            <>
              {Array(10)
                .fill()
                .map((item, index) => (
                  <div className="col-sm-5ths text-center mb-4" key={index}>
                    <Skeleton height={150} width={150} />
                    <br />
                    <div className="text-center">
                      <Skeleton height={20} width={80} />
                    </div>
                    <Skeleton height={40} width={150} />
                  </div>
                ))}
            </>
          ) : (
            <>
              {Array(10)
                .fill()
                .map((item, index) => (
                  <div className="col-6 text-center mb-4" key={index}>
                    <Skeleton height={150} width={150} />
                    <br />
                    <div className="text-center">
                      <Skeleton height={20} width={80} />
                    </div>
                    <Skeleton height={40} width={150} />
                  </div>
                ))}
            </>
          )}
        </div>
        <div className="col-sm-12">
          <div className="text-center">
            <Skeleton height={30} width={140} />
          </div>
        </div>
      </Col>
      <Col sm="1"></Col>
    </Row>
  );
};
export default FingerprintLoading;
