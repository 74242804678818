import Axios from "axios";
import store from "../../config/Services/Store";
import { LOGOUT } from "../../config/Services/Actions/auth";

const request = Axios.create({
  baseURL: process.env.REACT_APP_DOMAIN + "/api/v1",
});

request.interceptors.request.use(
  (config) => {
    const session = localStorage.getItem("session");
    if (session) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Authorization"] = `Bearer ${session}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.response.status === 401) {
      store.dispatch({ type: LOGOUT });
    }

    return Promise.reject(err);
  }
);

export default request;
