import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { HashLink } from "react-router-hash-link";

const Page = ({ vacanciesPerPage, totalPost, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPost / vacanciesPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <div className="text-right mt-3">
      <Pagination
        aria-label="Page navigation example"
        className="text-right"
        style={{ float: "right" }}
      >
        {currentPage > 1 ? (
          <HashLink to="#lowongan-kerja" style={{ textDecoration: "none" }}>
            <PaginationItem className="item">
              <PaginationLink
                onClick={() => paginate(currentPage - 1)}
                previous
              />
            </PaginationItem>
          </HashLink>
        ) : null}
        {pageNumbers.map((number, id) => (
          <HashLink
            to="#lowongan-kerja"
            style={{ textDecoration: "none" }}
            key={id}
          >
            <PaginationItem key={number} className="item">
              <PaginationLink
                onClick={() => paginate(number)}
                className={
                  currentPage === number ? "link active-paginate" : "link"
                }
              >
                {number}
              </PaginationLink>
            </PaginationItem>
          </HashLink>
        ))}
        {currentPage !== pageNumbers.length ? (
          <HashLink to="#lowongan-kerja" style={{ textDecoration: "none" }}>
            <PaginationItem className="item">
              <PaginationLink onClick={() => paginate(currentPage + 1)} next />
            </PaginationItem>
          </HashLink>
        ) : null}
      </Pagination>
    </div>
  );
};

export default Page;
