import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import { translate, t } from "react-switch-lang";
import request from "../../../../../utils/Request";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
import { useResultTestContext } from "../../Menu/AgilityTestContext";
import { toast } from "react-toastify";
import moment from "../../../../../utils/Moment";

toast.configure();
function Citraleka(props) {
  // eslint-disable-next-line
  const [result, setResult] = useResultTestContext({});
  const [errorInput, setErrorInput] = useState(false);
  const [answers, setAnswers] = useState({});
  const [time, setTime] = useState({});
  const [startTime, setStartTime] = useState(props.startTime);
  const [error, setError] = useState(false);
  const [btnDisable, setBtnDisbale] = useState(true);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [page, setPage] = useState(0);
  const [pageTitle, setPageTitle] = useState(1);
  const [completed, setCompleted] = useState(false);
  useEffect(() => {
    request
      .get("/assessment/test/agility/citraleka")
      .then((res) => {
        if (res.data?.data) {
          setLoading(false);
          setQuestions(res.data.data);
        }
      })
      .catch((err) => {
        setError(true);
      }); // eslint-disable-next-line
  }, []);
  function isError() {
    if (error) {
      return (
        <AffirmationModal
          isOpen={error}
          title={"Error !"}
          desc={t("Agility.errorConnection")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setError(true);
          }}
          confirmation={() => {
            window.location.reload();
            setError(false);
          }}
        />
      );
    }
  }
  function isErrorInput() {
    if (errorInput) {
      return (
        <AffirmationModal
          isOpen={errorInput}
          title={"Error !"}
          desc={t("Agility.hasDataCitraleka")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setErrorInput(true);
          }}
          confirmation={() => {
            setErrorInput(false);
          }}
        />
      );
    }
  }

  function isComplete() {
    if (completed) {
      return (
        <AffirmationModal
          isOpen={completed}
          title={"Success !"}
          desc={t("Agility.completeCitraleka")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setCompleted(true);
          }}
          confirmation={() => {
            let totalTime = 0;
            Object.values(time).map((data) => {
              totalTime = totalTime + parseInt(data);
              return true;
            });
            setResult((result) => ({
              ...result,
              citraleka: { answers, time, totalTime, step: props.page },
            }));
            window.scroll({ top: 0, behavior: "smooth" });
            props.setNextPage(false);
            toast.success("Success", {
              autoClose: 3000,
            });
          }}
        />
      );
    }
  }
  function chooseOption(option) {
    setAnswers((state) => ({
      ...state,
      [pageTitle]: option,
    }));
    setBtnDisbale(false);
  }

  useEffect(() => {
    // eslint-disable-next-line
    if (Object.values(time).length > 0) {
      setStartTime(moment().format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [time]);

  function nextPage() {
    window.scroll({ top: 0, behavior: "smooth" });
    setLoading(true);
    setTimeout(() => {
      if (!answers[pageTitle]) {
        setLoading(false);
        setErrorInput(true);
        return false;
      } else {
        setTime((state) => ({
          ...state,
          [pageTitle]: moment(new Date(), "YYYY-MM-DD HH:mm:ss").diff(
            startTime,
            "seconds"
          ),
        }));
        if (questions[page].lastQuestion === true) {
          setCompleted(true);
          window.scroll({ top: 0, behavior: "smooth" });
          setLoading(false);
        } else {
          setPage(page + 1);
          setPageTitle(pageTitle + 1);
          setBtnDisbale(true);
          setLoading(false);
        }
      }
    }, 1000);
  }
  return (
    <Container>
      {isError()}
      {isErrorInput()}
      {isComplete()}
      <Row>
        <Col xs="0" md="1"></Col>
        <Col xs="12" md="10" className=" mt-4 mb-4">
          <h3>
            <b>
              {props.title} ( {pageTitle} / 4 )
            </b>
          </h3>
          <br />
          <Row className="citraleka-wrapper p-md-5 py-3">
            {loading ? (
              <Col sm="12" className="text-center">
                <Spinner size="lg" />
              </Col>
            ) : questions ? (
              questions[page] ? (
                <>
                  <Col
                    sm="12"
                    style={{
                      paddingBottom: "15px",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <Row>
                      {questions[page].questions.map((data, idx) => {
                        return (
                          <Col
                            key={idx}
                            sm={questions[page].questionsCol}
                            xs="4"
                            className="citraleka-question-card-wrapper mb-3 text-center"
                          >
                            <div className="box-citraleka">
                              <img
                                src={process.env.REACT_APP_DOMAIN + data}
                                alt={data}
                                height="70px"
                                className="citraleka-questions-img text-submit-agility"
                              />
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                  <Col sm="12">
                    <Row>
                      <Col sm="12" className="pt-3 pb-3">
                        <h4>
                          <b>{t("Agility.chooseAnswer")} =</b>
                        </h4>
                      </Col>
                      <Col sm="12" className="pt-3 pb-3">
                        <Row>
                          {questions[page].options.map((data, idx) => {
                            return (
                              <Col
                                sm="3"
                                xs="6"
                                className={"mb-3 text-center "}
                                key={idx}
                                onClick={
                                  answers[pageTitle] === data.name
                                    ? null
                                    : () => chooseOption(data.name)
                                }
                              >
                                <h4>{data.name.toUpperCase()}</h4>
                                <div
                                  className={
                                    answers[pageTitle] === data.name
                                      ? "box-citraleka box-citraleka-selected"
                                      : "box-citraleka cursor-pointer hover-transform"
                                  }
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_DOMAIN + data.image
                                    }
                                    alt={data.name}
                                    height="70px"
                                    className="text-submit-agility"
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                      <Col sm="12" className="text-center pt-3">
                        <button
                          className="btn btn-primary btn-lg"
                          onClick={nextPage}
                          disabled={btnDisable}
                        >
                          {questions[page].lastQuestion
                            ? "Submit"
                            : t("General.next")}{" "}
                          <i className="fa fa-arrow-right ml-2"></i>
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </>
              ) : null
            ) : null}
          </Row>
        </Col>
        <Col xs="0" md="1"></Col>
      </Row>
    </Container>
  );
}

export default translate(Citraleka);
