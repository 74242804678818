import React, { Fragment, useState } from "react";
import Webcam from "react-webcam";
import ReactPlayer from "react-player";
import Countdown from "react-countdown";
import { Spinner, UncontrolledTooltip, Alert } from "reactstrap";
import { BrowserView, MobileView } from "react-device-detect";
import CaptureLoading from "../../../../components/atoms/CaptureLoading";
import { t } from "react-switch-lang";
const Sources = React.memo((props) => {
  return (
    <ReactPlayer
      className="react-player"
      url={props.data}
      width="100vw"
      height="100vh"
      playing={props.play}
      onEnded={props.onEndedVideo}
      loop={props.loop}
      onReady={props.onReady}
    />
  );
}); // eslint-disable-line react-hooks/exhaustive-deps

export default function VideoWebcam(props) {
  const [temp, setTemp] = useState(-1);
  const loading = () => {
    if (temp < props.count) {
      return <CaptureLoading title="Loading" visible={true} />;
    }
  };

  const countdowns = React.useMemo(
    () => <Countdown date={Date.now() + 900000} renderer={props.renderer} />,
    [props.countdown] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return (
    <Fragment>
      {loading()}
      <BrowserView>
        <Webcam
          audio={true}
          ref={props.webcamRef}
          style={{
            height: "160px",
            margin: "10px",
            position: "absolute",
            right: "0%",
          }}
          videoConstraints={props.videoConstraints}
          mirrored={true}
        />
      </BrowserView>
      <MobileView>
        <Webcam
          audio={true}
          ref={props.webcamRef}
          style={{
            height: "224px",
            margin: "10px",
            position: "absolute",
            right: "0%",
            top: "0%",
          }}
          videoConstraints={props.videoConstraints}
          mirrored={true}
        />
      </MobileView>
      {props.warning ? (
        <Alert
          color="danger"
          className="warning-video text-center"
          style={{ width: "100%" }}
        >
          <p className="m-0">
            <b>
              {t("Interview.timerWarning")}{" "}
              {props.countdown ? countdowns : null} {t("Interview.secondAgo")}
            </b>
          </p>
        </Alert>
      ) : props.countdown ? (
        countdowns
      ) : null}
      {props.sources.map((value, index) => {
        if (index > temp + 1) return null;
        return (
          <div
            key={index}
            style={{ display: props.count === index ? "block" : "none" }}
          >
            <Sources
              data={value["video"]}
              play={props.play ? props.count === index : false}
              onEndedVideo={props.onEndedVideo}
              loop={props.loopVideo}
              onReady={()=>temp + 1 === index ? setTemp(index) : null}
            />
          </div>
        );
      })}
      {props.visibleControlPlayer ? (
        <Fragment>
          <div className="box-control">
            <Fragment>
              {props.onStop ? (
                <Fragment>
                  <UncontrolledTooltip placement="top" target="stop">
                    {t("Interview.buttonStop")}
                  </UncontrolledTooltip>
                    <Spinner
                      type="grow"
                      color="light"
                      className="icon-spinner"
                      style={{ left: 'calc(50% - 40px)' }}
                    />
                  <img
                    id="stop"
                    src={require("../../../../assets/img/stop.png")}
                    alt="stop"
                    onClick={props.handleStopCaptureClick}
                    style={{ width: 70, left: 'calc(50% - 35px)'}}
                    className="icon-video-single"
                  />
                </Fragment>
              ) : props.onRecord ? (
                <div>
                  <Fragment>
                    <UncontrolledTooltip placement="top" target="start-video">
                      {t("Interview.buttonStart")}
                    </UncontrolledTooltip>
                    <Spinner
                      type="grow"
                      color="light"
                      className="icon-spinner"
                      style={{ left: 'calc(50% - 40px)' }}
                    />
                    <img
                      id="start-video"
                      src={require("../../../../assets/img/records.png")}
                      alt="start-video"
                      onClick={props.handleStartCaptureClick}
                      style={{ width: 70, left: 'calc(50% - 35px)' }}
                      className="icon-video-single"
                    />
                  </Fragment>
                  <Fragment>
                    <UncontrolledTooltip
                      placement="top"
                      target="record-replay-question"
                    >
                      {t("Interview.replayQuestion")}
                    </UncontrolledTooltip>
                    <img
                      id="record-replay-question"
                      src={require("../../../../assets/img/replay-question.png")}
                      alt="record-replay-question"
                      onClick={props.handleReplayQuestionClick}
                      style={{ width: 70, left: 'calc(50% - 125px)' }}
                      className="icon-question record-replay-qustion"
                    />
                  </Fragment>
                </div>
              ) : props.menuVideoPlayer ? (
                <div>
                  <Fragment>
                    <UncontrolledTooltip
                      placement="top"
                      target="icon-replay-video"
                    >
                      {t("Interview.viewRecordButton")}
                    </UncontrolledTooltip>
                    <img
                      id="icon-replay-video"
                      src={require("../../../../assets/img/icon-replay.png")}
                      alt="icon-replay-video"
                      onClick={props.handleReplayClick}
                      style={{ width: 70 }}
                      className="icon-replay"
                    />
                  </Fragment>
                  <Fragment>
                    <UncontrolledTooltip
                      placement="top"
                      target="record-replay-question"
                    >
                      {t("Interview.replayQuestion")}
                    </UncontrolledTooltip>
                    <img
                      id="record-replay-question"
                      src={require("../../../../assets/img/replay-question.png")}
                      alt="record-replay-question"
                      onClick={props.handleReplayQuestionClick}
                      style={{ width: 70 }}
                      className="icon-question"
                    />
                  </Fragment>
                  <Fragment>
                    <UncontrolledTooltip
                      placement="top"
                      target="record-replay-video"
                    >
                      {t("Interview.recordAnswer")}
                    </UncontrolledTooltip>
                    <img
                      id="record-replay-video"
                      src={require("../../../../assets/img/record-replay.png")}
                      alt="record-replay-video"
                      onClick={props.handleStartCaptureClick}
                      style={{ width: 70 }}
                      className="icon-video"
                    />
                  </Fragment>
                  <Fragment>
                    <UncontrolledTooltip
                      placement="top"
                      target="icon-next-video"
                    >
                      {t("Interview.nextVideo")}
                    </UncontrolledTooltip>
                    <img
                      id="icon-next-video"
                      src={require("../../../../assets/img/icon-next.png")}
                      alt="icon-next-video"
                      style={{ width: 70 }}
                      onClick={props.handleNextVideo}
                      className="icon-next"
                    />
                  </Fragment>
                </div>
              ) : null}
            </Fragment>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
}
