import React, { Fragment } from "react";
import { Route } from "react-router";
import WhatsAppButton from "../../../Templates/LandingPage/WhatsAppButton";
const AuthContainer = (props) => {
  const toUp = window.scrollTo(0, 0);

  return (
    <Fragment>
      {toUp}
      <Fragment>
        <Route {...props} />
        <WhatsAppButton />
      </Fragment>
    </Fragment>
  );
};
export default AuthContainer;
