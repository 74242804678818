import React from "react";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";
import { translate, t } from "react-switch-lang";
const NoCompleteProfile = () => {
  return (
    <Alert color="danger" className="text-center mt-3 mb-4">
      <h5 className="content-title mb-2">
        {t("Assessment.mustCompleteProfile")}
      </h5>
      <Link to="/profile">
        <button className="btn btn-success">
          {t("General.completeProfile")}
        </button>
      </Link>
    </Alert>
  );
};
export default translate(NoCompleteProfile);
