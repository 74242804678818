import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner, Form, Input, Label } from "reactstrap";
import { translate, t } from "react-switch-lang";
import request from "../../../../../utils/Request";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
import { useResultTestContext } from "../../Menu/AgilityTestContext";
import { toast } from "react-toastify";
import moment from "../../../../../utils/Moment";

toast.configure();
function Pathway(props) {
  // eslint-disable-next-line
  const [result, setResult] = useResultTestContext({});
  const [errorInput, setErrorInput] = useState(false);
  const [answers, setAnswers] = useState({
    seeking: 0,
    accurate: 0,
  });
  const [time, setTime] = useState({});
  const [startTime, setStartTime] = useState(props.startTime);
  const [answersNow, setAnswersNow] = useState({});
  const [error, setError] = useState(false);
  const [btnDisable, setBtnDisbale] = useState(true);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState(null);
  const [page, setPage] = useState("generalQuestion");
  const [completed, setCompleted] = useState(false);
  useEffect(() => {
    request
      .get("/assessment/test/agility/pathway")
      .then((res) => {
        if (res.data?.data) {
          setLoading(false);
          setQuestions(res.data.data);
        }
      })
      .catch((err) => {
        setError(true);
      }); // eslint-disable-next-line
  }, []);
  function isError() {
    if (error) {
      return (
        <AffirmationModal
          isOpen={error}
          title={"Error !"}
          desc={t("Agility.errorConnection")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setError(true);
          }}
          confirmation={() => {
            window.location.reload();
            setError(false);
          }}
        />
      );
    }
  }
  function isErrorInput() {
    if (errorInput) {
      return (
        <AffirmationModal
          isOpen={errorInput}
          title={"Error !"}
          desc={t("Agility.hasDataPathway")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setErrorInput(true);
          }}
          confirmation={() => {
            setErrorInput(false);
          }}
        />
      );
    }
  }

  function isComplete() {
    if (completed) {
      return (
        <AffirmationModal
          isOpen={completed}
          title={"Success !"}
          desc={t("Agility.completePathway")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setCompleted(true);
          }}
          confirmation={() => {
            let totalTime = 0;
            Object.values(time).map((data) => {
              totalTime = totalTime + parseInt(data);
              return true;
            });
            setResult((result) => ({
              ...result,
              pathway_game: { answers, time, totalTime, step: props.page },
            }));
            window.scroll({ top: 0, behavior: "smooth" });
            props.setNextPage(false);
            toast.success("Success", {
              autoClose: 3000,
            });
          }}
        />
      );
    }
  }

  useEffect(() => {
    // eslint-disable-next-line
    if (Object.values(time).length > 0) {
      setStartTime(moment().format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [time]);

  function chooseOption(idx, seeking, accurate, nextQuestion) {
    setAnswersNow({ [page]: { seeking, accurate, nextQuestion, idx } });
    setBtnDisbale(false);
  }

  function nextPage() {
    setLoading(true);
    window.scroll({ top: 0, behavior: "smooth" });
    if (!answersNow[page]) {
      setLoading(false);
      setErrorInput(true);
      return false;
    } else {
      setAnswers({
        seeking: answers["seeking"] + answersNow[page].seeking,
        accurate: answers["accurate"] + answersNow[page].accurate,
      });
      setTime((state) => ({
        ...state,
        [page]: moment(new Date(), "YYYY-MM-DD HH:mm:ss").diff(
          startTime,
          "seconds"
        ),
      }));
      if (answersNow[page].nextQuestion === "done") {
        setAnswersNow({});
        setCompleted(true);
        window.scroll({ top: 0, behavior: "smooth" });
        setLoading(false);
      } else {
        setAnswersNow({});
        setPage(answersNow[page].nextQuestion);
        setBtnDisbale(true);
        setLoading(false);
      }
    }
  }
  return (
    <Container>
      {isError()}
      {isErrorInput()}
      {isComplete()}
      <Form>
        <Row>
          <Col xs="0" md="1"></Col>
          <Col xs="12" md="10" className=" mt-4 mb-4">
            <h3>
              <b>{props.title}</b>
            </h3>
            <br />
            <Row
              className="p-md-5"
              style={{
                boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
                borderRadius: "12px",
                padding: "2rem 0.5rem",
              }}
            >
              {loading ? (
                <Col sm="12" className="text-center">
                  <Spinner size="lg" />
                </Col>
              ) : questions ? (
                questions[page] ? (
                  <>
                    <Col sm="12" className="text-center mb-3">
                      <img
                        src={
                          process.env.REACT_APP_DOMAIN +
                          "/" +
                          questions[page].image
                        }
                        alt="guide"
                        className="pathway-question-image"
                      />
                    </Col>
                    <Col sm="12" className="text-justify mb-5">
                      <p>{questions[page].question}</p>
                    </Col>
                    <Col
                      sm="12"
                      className="d-flex flex-column p-3 mt-3"
                      style={{
                        boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
                        borderRadius: "12px",
                      }}
                    >
                      {questions[page].options.map((data, idx) => {
                        return (
                          <Label
                            check
                            className="options-pathway mb-3"
                            key={idx}
                            onClick={() =>
                              chooseOption(
                                idx,
                                data.seeking,
                                data.accurate,
                                data.nextQuestion
                              )
                            }
                          >
                            <Input
                              checked={answersNow[page]?.idx === idx}
                              id={"option" + page + idx}
                              type="radio"
                              name={"option" + page}
                              className="input-options-pathway"
                            />{" "}
                            {data.text}
                          </Label>
                        );
                      })}
                    </Col>
                    <Col lg="12" className="text-center pt-3">
                      <button
                        type="reset"
                        className="btn btn-primary btn-lg"
                        onClick={nextPage}
                        disabled={btnDisable}
                      >
                        {t("General.next")}{" "}
                        <i className="fa fa-arrow-right ml-2"></i>
                      </button>
                    </Col>
                  </>
                ) : null
              ) : null}
            </Row>
          </Col>
          <Col xs="0" md="1"></Col>
        </Row>
      </Form>
    </Container>
  );
}

export default translate(Pathway);
