import React, { useState, useEffect } from "react";
import { Row, Col, CardImg, CardImgOverlay } from "reactstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { translate } from "react-switch-lang";
export default translate(function HeaderLandingPage(props) {
  const { t } = props;
  const [isScroll] = useState(true);
  useEffect(() => {
    if (isScroll) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 0) {
          if (
            !document
              .getElementsByTagName("nav")[0]
              .classList.contains("onScroll")
          ) {
            document.getElementsByTagName("nav")[0].classList.add("onScroll");
          }
        } else {
          if (document.getElementsByTagName("nav")[0]) {
            if (
              document
                .getElementsByTagName("nav")[0]
                .classList.contains("onScroll")
            ) {
              document
                .getElementsByTagName("nav")[0]
                .classList.remove("onScroll");
            }
          }
        }
      });
    }
  }, [isScroll]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // optional, default to 1.
    },
  };
  const goToLink = (url) => {
    // window.location.replace(url);
    window.open(url, "_blank");
  };

  return (
    <Row className="pb-1 pt-4">
      <Col className="header-logo-mobile mb-5" sm="5" xs="12">
        <CardImg
          src={require("../../../../assets/img/Bg-Caraousel.png")}
          alt="Card image cap"
          style={{ width: "120%", marginLeft: "-12%" }}
        />
        <CardImgOverlay
          style={{ marginLeft: "11%", marginTop: "9%", width: "87%" }}
        >
          <Carousel
            partialVisible={false}
            swipeable={false}
            draggable={false}
            showDots={false}
            arrows={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            slidesToSlide={1}
            autoPlay={true}
            autoPlaySpeed={500}
            keyBoardControl={false}
            customTransition="all 1s linear"
            transitionDuration={1000}
            renderDotsOutside={false}
          >
            <img
              src={require("../../../../assets/img/Bg-Caraousel1.png")}
              alt="logo"
              style={{ width: "110%", marginLeft: "2px" }}
            />
            <img
              src={require("../../../../assets/img/Bg-imac.png")}
              alt="playstore-skillana"
              style={{ width: "110%", marginLeft: "2px" }}
            />
          </Carousel>
        </CardImgOverlay>
      </Col>
      {/* VIEW DESKTOP WEB */}
      <Col
        sm="5"
        xs="12"
        className="title-wrapper-header d-none d-xl-block || d-none d-lg-block d-xl-none || d-none d-md-block d-lg-none"
      >
        {/* <lottie-player
          src="https://assets9.lottiefiles.com/datafiles/MUp3wlMDGtoK5FK/data.json"
          background="transparent"
          speed="1"
          style={{ width: "300px", height: "300px" }}
          loop
          // controls
          autoplay
        ></lottie-player> */}
        <h2 className="title-header">
          <span style={{ color: "white" }}> {t("LandingPage.makeDream")} </span>
          <br />
          <span style={{ color: "#ffdb13", border: "none" }}>
            {t("LandingPage.startYourself")}
          </span>
        </h2>
        <p style={{ color: "white", letterSpacing: "1px" }}>
          <b>{t("LandingPage.tagLine2")}</b>
        </p>
        <Link
          onClick={() =>
            goToLink(
              "https://play.google.com/store/apps/details?id=com.widyaskilloka.skillana"
            )
          }
        >
          <img
            src={require("../../../../assets/img/playstore-image.png")}
            className="img-ps"
            alt="skillana-playstore"
            style={{ width: "140px" }}
          />
        </Link>
        <Link
          onClick={() =>
            goToLink("https://apps.apple.com/id/app/skillana/id1546184278")
          }
        >
          <img
            src={require("../../../../assets/img/app store.png")}
            className="img-ps ml-3"
            alt="skillana-playstore"
            style={{ width: "140px" }}
          />
        </Link>
      </Col>
      {/* VIEW MOBILE WEB */}
      <Col
        sm="5"
        xs="12"
        className="title-wrapper-header-mobileLp d-block d-sm-none || d-none d-sm-block d-md-none"
      >
        <h2 className="title-header">
          <span style={{ color: "white", fontSize: "30px" }}>
            {t("LandingPage.makeDream")}{" "}
          </span>
          <br />
          <span style={{ color: "yellow", fontSize: "30px" }}>
            {t("LandingPage.startYourself")}
          </span>
        </h2>
        <p style={{ color: "white", fontSize: "14px" }}>
          <b>{t("LandingPage.tagLine2")}</b>
        </p>
        <Link
          onClick={() =>
            goToLink(
              "https://play.google.com/store/apps/details?id=com.widyaskilloka.skillana"
            )
          }
        >
          <img
            src={require("../../../../assets/img/playstore-image.png")}
            className="img-ps"
            alt="skillana-playstore"
            style={{ width: "120px" }}
          />
        </Link>
        <Link
          onClick={() =>
            goToLink("https://apps.apple.com/id/app/skillana/id1546184278")
          }
        >
          <img
            src={require("../../../../assets/img/app store.png")}
            className="img-ps ml-3"
            alt="skillana-playstore"
            style={{ width: "120px" }}
          />
        </Link>
      </Col>
      <Col sm="7" xs="12" className="header-logo-web">
        <CardImg
          src={require("../../../../assets/img/Bg-Caraousel.png")}
          style={{ width: "88%" }}
          alt="Card image cap"
        />
        <CardImgOverlay
          style={{ marginLeft: "15%", marginTop: "8%", width: "68%" }}
        >
          <Carousel
            partialVisible={false}
            swipeable={false}
            draggable={false}
            showDots={false}
            arrows={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            slidesToSlide={1}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={false}
            customTransition="all 1s linear"
            transitionDuration={1000}
            renderDotsOutside={false}
          >
            <img
              src={require("../../../../assets/img/Bg-Caraousel1.png")}
              alt="logo"
              style={{ width: "100%" }}
            />
            <img
              src={require("../../../../assets/img/Bg-imac.png")}
              alt="playstore-skillana"
              style={{ width: "100%" }}
            />
          </Carousel>
        </CardImgOverlay>
      </Col>
    </Row>
  );
});
