import React, { Fragment } from "react";
import NavbarLandingPage from "../../../Templates/LandingPage/NavbarLandingPage";
import FooterLandingPage from "../../../Templates/LandingPage/FooterLandingPage";
import WhatsAppButton from "../../../Templates/LandingPage/WhatsAppButton";
import AuthRoute from "../../../../config/AuthRoute";
import { Route } from "react-router";
import { connect } from "react-redux";
const LayoutWithAuth = (props) => {
  const toUp = window.scrollTo(0, 0);
  return (
    <Fragment>
      {toUp}
      <Fragment>
        <NavbarLandingPage />
        <Route {...props} />
        <AuthRoute />
        <FooterLandingPage />
        <WhatsAppButton />
      </Fragment>
    </Fragment>
  );
};
export default connect(({ user }) => ({ user }))(LayoutWithAuth);
