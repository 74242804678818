import React, { Fragment } from "react";
import DefaultHeader from "./../DefaultHeader";
import { AppHeader } from "@coreui/react";
import { Route } from "react-router";
import { connect } from "react-redux";
import DefaultGeneralHeader from "../DefaultGeneralHeader";
// import ShortGuideUser from "../../../../Templates/ShortGuideUser";
import WhatsAppButton from "../../../../Templates/LandingPage/WhatsAppButton";
const DefaultBreadcumb = (props) => {
  const toUp = window.scrollTo(0, 0);
  return (
    <Fragment>
      {toUp}
      <div className="app" style={{ background: "#fff" }}>
        <AppHeader fixed>
          {!props.user?.id ? <DefaultGeneralHeader /> : <DefaultHeader />}
        </AppHeader>
        <div className="app-body-breadcrumb" style={{ paddingTop: 70 }}>
          <main style={{ width: "100%" }}>
            <Route {...props} />
          </main>
        </div>
        {/* {!props.user.id ? null : <ShortGuideUser />} */}
        <WhatsAppButton />
      </div>
    </Fragment>
  );
};
// export default DefaultBreadcumb;

export default connect(({ user }) => ({ user }))(DefaultBreadcumb);
