import React, { Fragment, useState, useCallback } from "react";
import {
  Modal,
  Row,
  ModalBody,
  ModalHeader,
  Spinner,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import Webcam from "react-webcam";
import request from "../../../../utils/Request";
import { toast } from "react-toastify";
import LoadingAnimation from "../../../../components/atoms/CaptureLoading";
import AffirmationModal from "../../../../components/atoms/AffirmationModal";
import { isMobile } from "react-device-detect";
import { translate, t } from "react-switch-lang";
const ModalTakePhoto = (props) => {
  let dataPhoto = null;
  toast.configure();
  const webcamRefPhoto = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [blankWhite, setBlankWhite] = useState(false);
  const [isErrorSubmit, setIsErrorSubmit] = useState(false);

  const videoConstraints = {
    facingMode: "user",
  };
  const videoConstraintsMobile = {
    facingMode: "environment",
  };

  function loadingPopup() {
    if (loading) {
      return <LoadingAnimation title="Loading" visible={loading} />;
    }
  }

  function errorSubmit() {
    if (isErrorSubmit) {
      return (
        <AffirmationModal
          isOpen={isErrorSubmit}
          title="Gagal !"
          desc="Maaf, sidik jari kamu masih belum terlihat. Coba lagi"
          titleButton="Coba Lagi"
          toggleOpen={() => {
            setIsErrorSubmit(true);
          }}
          confirmation={() => {
            setLoading(false);
            setIsErrorSubmit(false);
          }}
        />
      );
    }
  }
  //=================================================================================
  //=============================== take picture ====================================
  //=================================================================================
  const takePicture = useCallback(() => {
    setLoading(true);
    setBlankWhite(true);
    setTimeout(() => {
      setBlankWhite(false);
    }, 250);
    // clearInterval(intervalId);
    // eslint-disable-next-line
    dataPhoto = webcamRefPhoto.current.getScreenshot();
    // // clearInterval(intervalId);
    sendDataPhoto(dataPhoto);
    // eslint-disable-next-line
  }, [webcamRefPhoto]);

  //=================================================================================
  //============================= data uri to blob ==================================
  //=================================================================================
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  //=================================================================================
  //=============================== send picture ====================================
  //=================================================================================
  async function sendDataPhoto(dataPhoto) {
    let type;
    if (isMobile) {
      type = "mobile";
    } else {
      type = "web";
    }
    let file = null;
    file = DataURIToBlob(dataPhoto);
    let formData = new FormData();
    formData.append(`finger`, props.dataFingers.finger);
    formData.append(`side`, props.dataFingers.side);
    formData.append(`image`, file, "photo.jpeg");
    if (file !== null) {
      request
        .post("assessment/test/fingerprint", formData, {
          headers: { type: type },
        })
        .then((res) => {
          setLoading(false);
          props.updateFingers(res.data.data);
          props.toggle();
          toast.success("Sidik Jari Berhasil", { autoClose: 2000 });
        })
        .catch((err) => {
          setIsErrorSubmit(true);
          setLoading(false);
        });
    } else {
      setIsErrorSubmit(true);
      setLoading(false);
    }
  }
  return (
    <Fragment>
      {loadingPopup()}
      {errorSubmit()}
      {blankWhite ? (
        <div
          className={blankWhite ? " modal fade show" : "modal fade"}
          role="dialog"
          tabindex="-1"
          style={{ display: "block", zIndex: 2000 }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          ></div>
        </div>
      ) : null}
      <Modal isOpen={props.isOpen} size="lg">
        <ModalHeader
          toggle={() => {
            props.toggle();
          }}
        >
          {props.statusModal === "getPhoto" ? (
            <>{props.title}</>
          ) : (
            <>Detail {props.title}</>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <div className="col-12 text-center">
              {props.statusModal === "getPhoto" ? (
                <>
                  <div className="bg-fingerprint"></div>
                  <Webcam
                    ref={webcamRefPhoto}
                    screenshotFormat="image/jpeg"
                    forceScreenshotSourceSize={true}
                    mirrored={isMobile ? false : true}
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "12px",
                    }}
                    videoConstraints={
                      isMobile ? videoConstraintsMobile : videoConstraints
                    }
                  />
                </>
              ) : (
                <>
                  <img
                    src={props.dataFingers.image.raw}
                    alt={props.title}
                    style={{ width: "300px", borderRadius: "12px" }}
                  />
                </>
              )}
              {loading ? null : props.statusModal === "getPhoto" ? (
                <>
                  <Spinner
                    type="grow"
                    color="light"
                    className="icon-spinner-finger"
                  />
                  <UncontrolledTooltip placement="top" target="photo">
                    Tombol Foto
                  </UncontrolledTooltip>
                  <img
                    id="photo"
                    src={require("../../../../assets/img/icon-camere.png")}
                    alt="capture"
                    onClick={takePicture}
                    style={{ width: 70 }}
                    className="icon-photo-finger"
                  />
                </>
              ) : (
                <>
                  <br />
                  <br />
                  <Button
                    color="netis-color btn-shadow "
                    onClick={() => props.changeToTakePhoto("getPhoto")}
                    className="mt-3"
                  >
                    <i className="fa fa-exchange mr-2"></i>
                    {t("Assessment.changeFingerprint")}
                  </Button>
                </>
              )}
            </div>
            <hr />
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default translate(ModalTakePhoto);
