import React, { Fragment } from 'react'
import {
  Row,
  Col,
  UncontrolledCollapse,
  Card,
  CardBody
} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
export default function CardColapse(props) {
  return (
    <Fragment>
      <Col lg="12" className="text-justify mt-2">
        <Card className="card-collapse" id={props.id}>
          <CardBody>
            <Row>
              <Col lg="10" md="10" sm="10" xs="10">
                <h5 style={{ margin: 0 }}><b>{props.question}</b></h5>
              </Col>
              <Col lg="2" md="2" sm="2" xs="2" className="text-right">
                <i className="fa fa-angle-down" style={{ fontSize: 20, fontWeight: 'bold' }}></i>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <UncontrolledCollapse toggler={"#" + props.id}>
          <Card>
            <CardBody>
              <ReactMarkdown source={props.answer} />
            </CardBody>
          </Card>
        </UncontrolledCollapse>
      </Col>
      </Fragment>
      )
}