import React, { createContext } from "react";
import { useState } from "react";
import { useContext } from "react";

const resultTestContext = createContext();
const setResultTestContext = createContext();

export default function AgilityTestProvider(props) {
  const [result, setResult] = useState({
    sadana: null,
    etung: null,
    sankarta: null,
    sacit: null,
    citraleka: null,
    bedhe_aksara: null,
    pathway_game: null,
    vacana: null,
  });

  return (
    <setResultTestContext.Provider value={setResult}>
      <resultTestContext.Provider value={result}>
        {props.children}
      </resultTestContext.Provider>
    </setResultTestContext.Provider>
  );
}

export const useResultTestContext = () => {
  return [useContext(resultTestContext), useContext(setResultTestContext)];
};
