import React, { Fragment } from "react";
import {Container} from 'reactstrap';
import ContentAboutLandingPage from "../../../Templates/LandingPage/ContentAboutLandingPage";
const About = (props) => {
  return (
    <Fragment>
      <div className="header-landing-page-old">
        <Container className="wrap-content">
            <ContentAboutLandingPage />
        </Container>
      </div>
    </Fragment>
  );
};
export default About;
