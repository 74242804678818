import React, { useEffect, useState } from "react";
import requestGeneral from "../../../../../utils/RequestGeneral";
import LoadingAnimation from "../../../../../components/atoms/LoadingAnimation";
import { translate } from "react-switch-lang";
import Hint from "../../../../../components/atoms/Hint";
import ChoiceModal from "../../../../../components/atoms/ChoiceModal";
import CaptureLoading from "../../../../../components/atoms/CaptureLoading";
import PhotoWebcamPalmistry from "../../../../Templates/VideoAssesment/PhotoWebcamPalmistry";
import AffirmationModal from "../../../../../components/atoms/AffirmationModal";
function Palmistry(props) {
  let dataPhoto = null;
  const { t } = props;
  const webcamRefPalmistry = React.useRef(null);
  const [notExistPalmistry, setNotExistPalmistry] = useState(false);
  const [loading, setLoading] = useState(true);
  const [handPalmistryNotRecognize, setHandPalmistryNotRecognize] =
    useState(false);
  const [filpImages, setFilpImages] = useState(null);
  const [isFailedPalmistry, setIsFailedPalmistry] = useState(false);
  const [trying, setTrying] = useState(1);
  const [popUpDominan, setPopUpDominan] = useState(false);
  const [dominanHand, setDominanHand] = useState(null);
  const [isNotSupportBrowser, setIsNotSupportBrowser] = useState(false);
  const [isNotSupport, setIsNotSupport] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [isDetect, setIsDetect] = useState(false);
  const [successPhoto, setSuccessPhoto] = useState(false);
  const [notSuccessPhoto, setNotSuccessPhoto] = useState(false);
  const videoConstraints = {
    facingMode: "user",
  };

  useEffect(() => {
    requestGeneral
      .get("v1/assessment_video/validate_palmistry")
      .then((res) => {
        props.onComplete();
      })
      .catch((err) => {
        setPopUpDominan(true);
        setNotExistPalmistry(true);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //=================================================================================
  //=========================== function Loading Pop up ==============================
  //=================================================================================
  const showLoading = () => {
    if (loading) {
      return <CaptureLoading title="Loading" visible={true} />;
    }
  };
  //=================================================================================
  //========================= function pop up palmistry =============================
  //=================================================================================
  function popUpDominanHand() {
    if (popUpDominan) {
      return (
        <ChoiceModal
          isOpen={popUpDominan}
          title={t("Interview.lastStep")}
          desc={t("Interview.lastStepDescComplete")}
          titleLeft={t("Interview.leftHand")}
          titleRight={t("Interview.rightHand")}
          onPressRight={() => {
            setDominanHand("kanan");
            setPopUpDominan(false);
          }}
          onPressLeft={() => {
            setDominanHand("kiri");
            setPopUpDominan(false);
          }}
        />
      );
    }
  }

  //=================================================================================
  //=========================== function detect Device ==============================
  //=================================================================================
  // eslint-disable-next-line
  function detectDevice() {
    if (isDetect) {
      navigator.getUserMedia =
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        navigator.oGetUserMedia;
      if (navigator.getUserMedia) {
        // Request the camera.
        navigator.getUserMedia(
          // Constraints
          {
            video: true,
            audio: true,
          },
          // Success Callback
          function (localMediaStream) {
            setIsDetect(false);
            setIsNotSupport(false);
          },
          // Error Callback
          function (err) {
            setIsDetect(false);
            setIsNotSupport(true);
          }
        );
      } else if (navigator.mediaDevices.getUserMedia) {
        // Request the camera.
        navigator.mediaDevices.getUserMedia(
          // Constraints
          {
            video: true,
            audio: true,
          },
          // Success Callback
          function (localMediaStream) {
            setIsDetect(false);
            setIsNotSupport(false);
          },
          // Error Callback
          function (err) {
            setIsDetect(false);
            setIsNotSupport(true);
          }
        );
      } else {
        setIsDetect(false);
        setIsNotSupport(true);
      }
    }
  }

  //=================================================================================
  //=========================== function not support ==============================
  //=================================================================================
  function showNotSupport() {
    if (isNotSupport) {
      return (
        <Hint
          onClick={() => {
            setIsNotSupport(false);
            window.location.replace("/home");
          }}
          visible={isNotSupport}
          title="Error !"
          img="notif_cam_mic.png"
          desc={t("Interview.checkDevice")}
          actionTitle={t("General.back")}
        />
      );
    }
  }
  function notSupportBrowser() {
    if (isNotSupportBrowser) {
      return (
        <AffirmationModal
          isOpen={isNotSupportBrowser}
          title="Error !"
          desc={t("Interview.notSupport")}
          titleButton={t("General.back")}
          toggleOpen={() => {
            setIsNotSupportBrowser(true);
          }}
          confirmation={() => {
            setIsNotSupportBrowser(false);
            window.location.replace("/home");
          }}
        />
      );
    }
  }

  //=================================================================================
  //============================= data uri to blob ==================================
  //=================================================================================
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  //=================================================================================
  //======================= function pop up photo status ============================
  //=================================================================================
  function statusSuccessPhotoPopup() {
    if (successPhoto) {
      return (
        <AffirmationModal
          isOpen={successPhoto}
          title={t("Interview.photoSavedPalmistry")}
          desc={t("Interview.photoSavedPalmistryDesc")}
          titleButton={t("General.okey")}
          confirmation={() => {
            setSuccessPhoto(false);
            props.onComplete();
          }}
        />
      );
    }
  }

  function notSuccess() {
    if (notSuccessPhoto) {
      return (
        <AffirmationModal
          isOpen={notSuccessPhoto}
          title={t("General.failed") + " !"}
          desc={t("Interview.failedPhoto")}
          titleButton={t("General.okey")}
          confirmation={() => {
            setNotSuccessPhoto(false);
          }}
        />
      );
    }
  }

  const takePicturePalmistry = React.useCallback(() => {
    setLoading(true);
    setCapturing(true);
    // eslint-disable-next-line
    dataPhoto = webcamRefPalmistry.current.getScreenshot();
    if (dominanHand === "kanan") {
      filpImage(dataPhoto, (url) => {
        setFilpImages(url);
      });
    } else {
      setFilpImages(dataPhoto);
    }
    // eslint-disable-next-line
  }, [webcamRefPalmistry, dominanHand]);

  //=================================================================================
  //========================== take picture palmistry ================================
  //=================================================================================
  useEffect(() => {
    if (filpImages !== null) {
      sendDataPhotoPalmistry(filpImages);
    }
  }, [filpImages]); // eslint-disable-line react-hooks/exhaustive-deps

  //=================================================================================
  //============================= send palmistry ====================================
  //=================================================================================
  async function sendDataPhotoPalmistry(dataPhoto) {
    setFilpImages(null);
    let file = null;
    file = DataURIToBlob(dataPhoto);
    let formData = new FormData();
    formData.append(`photo`, file, "photo.jpeg");
    formData.append(`try`, trying);
    if (file !== null) {
      requestGeneral
        .post("v1/assessment_video/submit_palmistry", formData)
        .then((res) => {
          setSuccessPhoto(true);
          setLoading(false);
        })
        .catch((err) => {
          setTrying(trying + 1);
          setIsFailedPalmistry(true);
          setCapturing(false);
          setLoading(false);
        });
    } else {
      setIsNotSupport(true);
    }
  }

  const filpImage = (imageBase64, cb) => {
    var img = new Image();
    img.src = imageBase64;
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      var ctx = canvas.getContext("2d");
      ctx.scale(-1, 1);
      ctx.drawImage(img, 0, 0, canvas.width * -1, img.height);
      cb(canvas.toDataURL("image/jpeg"));
    };
  };

  //=================================================================================
  //============================ pop up gagal palmistry =============================
  //=================================================================================
  function popUpFailedPalmistry() {
    if (isFailedPalmistry) {
      return (
        <AffirmationModal
          isOpen={isFailedPalmistry}
          title="Oops!"
          desc={t("Interview.failedTakePicture")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setIsFailedPalmistry(true);
          }}
          confirmation={() => {
            setIsFailedPalmistry(false);
          }}
        />
      );
    }
  }

  //=================================================================================
  //======================== pop up gagal tangan palmistry ==========================
  //=================================================================================
  function popUpFailedHandPalmistry() {
    if (handPalmistryNotRecognize) {
      return (
        <AffirmationModal
          isOpen={handPalmistryNotRecognize}
          title="Oops!"
          desc={t("Interview.failedTakePicture")}
          titleButton={t("General.okey")}
          toggleOpen={() => {
            setHandPalmistryNotRecognize(true);
          }}
          confirmation={() => {
            setHandPalmistryNotRecognize(false);
          }}
        />
      );
    }
  }
  return (
    <div>
      {showLoading()}
      {showNotSupport()}
      {notSupportBrowser()}
      {detectDevice()}
      {statusSuccessPhotoPopup()}
      {notSuccess()}
      {popUpDominanHand()}
      {popUpFailedHandPalmistry()}
      {popUpFailedPalmistry()}
      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          {notExistPalmistry ? (
            <div className="player-wrapper">
              <PhotoWebcamPalmistry
                dominanHand={dominanHand}
                webcamRefPhoto={webcamRefPalmistry}
                videoConstraints={videoConstraints}
                setPopUpDominan={setPopUpDominan}
                capturing={capturing}
                takePicture={takePicturePalmistry}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
export default translate(Palmistry);
