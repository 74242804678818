// input YYYY-MM-DD HH:MM:SS

import { dateWithSlashReverse } from "../DateFormat";
import { t } from "react-switch-lang";

// output Miliseconds
const toSecond = (time) => {
  const seconds = time.split(
    /([0-9]{4})-([0-1][0-9])-([0-3][0-9])\s([0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9])/
  );
  const times = seconds.filter((item) => {
    return item !== "";
  });
  const years = times[0] * 31536000;
  const month = times[1] * 2628000;
  const day = times[2] * 86400;
  const hours = times[3] * 3600;
  const minutes = times[4] * 60;
  const second = times[5] * 1;

  const totalSeconds = years + month + day + hours + minutes + second;
  return totalSeconds / 1000;
};

// input miliseconds
// output {time}
// history time converter 1
const timeSince = (date) => {
  if (typeof date !== "object") {
    date = new Date(date);
  }

  let seconds = Math.floor((new Date() - date) / 1000);
  let intervalType;

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = "year";
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = "month";
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = "day";
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = "hour";
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = "minute";
          } else {
            interval = seconds;
            intervalType = "second";
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += "s";
  }

  return interval + " " + intervalType;
};

// input miliSecond
// output {time} ago
// history time converter 2
function time_ago(times) {
  const arr = times.split(" ");
  const arrDate = arr.splice(0, 1);
  const dates = dateWithSlashReverse(arrDate[0]);
  const arrTime = arr[0];
  const date = dates + " " + arrTime;
  let time = new Date(date);
  switch (typeof time) {
    case "number":
      break;
    case "string":
      time = +new Date(time);
      break;
    case "object":
      if (time.constructor === Date) {
        time = time.getTime();
      }
      break;
    default:
      time = +new Date();
  }
  let time_formats = [
    [60, t("JobVacancy.second"), 1], // 60
    [120, t("JobVacancy.1minuteAgo"), "1 menit lagi"], // 60*2
    [3600, t("JobVacancy.minute"), 60], // 60*60, 60
    [7200, t("JobVacancy.1hourAgo"), "1 jam lagi"], // 60*60*2
    [86400, t("JobVacancy.hour"), 3600], // 60*60*24, 60*60
    [172800, t("JobVacancy.yesterday"), "besok"], // 60*60*24*2
    [604800, t("JobVacancy.day"), 86400], // 60*60*24*7, 60*60*24
    [1209600, t("JobVacancy.1weekAgo"), "minggu depan"], // 60*60*24*7*4*2
    [2419200, t("JobVacancy.week"), 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, t("JobVacancy.1monthAgo"), "bulan depan"], // 60*60*24*7*4*2
    [29030400, t("JobVacancy.month"), 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, t("JobVacancy.1yearAgo"), "tahun depan"], // 60*60*24*7*4*12*2
    [2903040000, t("JobVacancy.year"), 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, t("JobVacancy.1centuryAgo"), "abad depan"], // 60*60*24*7*4*12*100*2
    [58060800000, t("JobVacancy.centuries"), 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];
  let seconds = (+new Date() - time) / 1000,
    token = t("JobVacancy.ago"),
    list_choice = 1;

  if (seconds === 0) {
    return t("JobVacancy.justNow");
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = "dari sekarang";
    list_choice = 2;
  }
  let i = 0,
    format;
  while ((format = time_formats[i++])) {
    if (seconds < format[0]) {
      if (typeof format[2] === "string") {
        return format[list_choice];
      } else {
        return Math.floor(seconds / format[2]) + " " + format[1] + " " + token;
      }
    }
  }
  return time;
}

export { toSecond, time_ago, timeSince };
