import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import Color from "../../../../utils/Colors";
import FooterLandingPage from "../../../Templates/LandingPage/FooterLandingPage";
import { connect } from "react-redux";
import Carousel from "react-multi-carousel";
import requestGeneral from "../../../../utils/RequestGeneral";
import LoadingAnimation from "../../../../components/atoms/LoadingAnimation";
import { useParams } from "react-router-dom";
import noLogoCompany from "../../../../assets/img/company_icon.png";
import NotFound from "../../NotFound";
import { translate, t } from "react-switch-lang";
const CompanyProfile = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // optional, default to 1.
    },
  };
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState(null);
  const { id, slug } = useParams();
  useEffect(() => {
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCompany = async () => {
    try {
      const res = await requestGeneral.get(
        `/internal/recruitment/company/detail/${id}/${slug}`
      );
      setCompany(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      {loading ? (
        <LoadingAnimation />
      ) : company ? (
        <div className="job-detail-page">
          <Fragment>
            <div className="container">
              <div className="job-description" style={{ marginTop: "2rem" }}>
                <div className="job-description-title">
                  <h1>{t("Company.description")}</h1>
                </div>
                <div
                  className="job-desc-list"
                  style={{
                    borderRadius: "0 0 10px 10px",
                    paddingLeft: "2.5rem",
                  }}
                >
                  <div
                    className="container-flluid job-description-company"
                    style={{ marginTop: "2rem" }}
                  >
                    {/* {t('Company.information')} */}
                    <div className="job-desc-list-company">
                      <div className="job-description-title-company">
                        <div className="ml-3">{t("Company.information")}</div>
                      </div>
                      <div
                        style={{
                          marginTop: "12px",
                          paddingLeft: "2.5rem",
                          paddingRight: "2.5rem",
                        }}
                      >
                        <Row>
                          <Col lg={3}>
                            <center>
                              <div className="mb-4">
                                {company.company.logo ? (
                                  <CardImg
                                    className="pl-3 pr-3 mt-5"
                                    src={
                                      company.company.logo ===
                                      "https://hris-dev.widyaskilloka.com"
                                        ? noLogoCompany
                                        : company.company.logo
                                    }
                                    alt={company.company.name}
                                  />
                                ) : (
                                  <CardImg
                                    className="pl-3 pr-3 mt-5"
                                    src={noLogoCompany}
                                    alt={company.company.name}
                                  />
                                )}
                              </div>
                            </center>
                          </Col>
                          <Col lg={9} style={{ marginTop: "50px" }}>
                            <h2
                              style={{
                                fontSize: "36px",
                                lineHeight: "54px",
                                fontWeight: 400,
                              }}
                            >
                              {company.company.name
                                ? company.company.name
                                : "-"}
                            </h2>
                            <p
                              style={{
                                fontSize: "18px",
                                lineHeight: "27px",
                                fontWeight: 400,
                              }}
                            >
                              {company.company.type
                                ? company.company.type
                                : "-"}
                            </p>
                          </Col>
                        </Row>

                        <div className="description-company">
                          <h5>
                            <strong>{t("Company.description")}</strong>
                          </h5>{" "}
                          <br />
                          <p
                            className="text-justify"
                            style={{ fontWeight: 400, marginBottom: "60px" }}
                          >
                            {company.company.description
                              ? company.company.description
                              : "-"}
                          </p>
                        </div>
                        <Row>
                          <Col>
                            <Row>
                              <Col lg="2" sm="2" xs="2">
                                <Card
                                  style={{ backgroundColor: "#4595D8" }}
                                  className="sosmedCard"
                                >
                                  <i
                                    className="fa fa-globe fa-2x m-auto"
                                    style={{ color: "white" }}
                                  />
                                </Card>
                              </Col>
                              <Col lg="10" sm="10" xs="10">
                                <div className="visitMe ml-3">
                                  <CardText>
                                    <h5>
                                      <b>Website</b>
                                    </h5>
                                    <p>
                                      {" "}
                                      {company.company.website
                                        ? company.company.website
                                        : "-"}
                                    </p>
                                  </CardText>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="2" sm="2" xs="2">
                                <Card
                                  style={{ backgroundColor: "#FFA833" }}
                                  className="sosmedCard"
                                >
                                  <i
                                    className="fa fa-envelope fa-2x m-auto"
                                    style={{ color: "white" }}
                                  />
                                </Card>
                              </Col>
                              <Col lg="10" sm="10" xs="10">
                                <div className="visitMe ml-3">
                                  <h5>
                                    <b>Email</b>
                                  </h5>
                                  <p>
                                    {company.company.email
                                      ? company.company.email
                                      : "-"}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <Col lg="2" sm="2" xs="2">
                                <Card
                                  style={{ backgroundColor: "#01C875" }}
                                  className="sosmedCard"
                                >
                                  <i
                                    className="fa fa-phone fa-2x m-auto"
                                    style={{ color: "white" }}
                                  />
                                </Card>
                              </Col>
                              <Col lg="10" sm="10" xs="10">
                                <div className="visitMe ml-3">
                                  <h5 style={{ textTransform: "bold" }}>
                                    <b>{t("General.phone")}</b>
                                  </h5>
                                  <p>
                                    {company.company.phone
                                      ? company.company.phone
                                      : "-"}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="2" sm="2" xs="2">
                                <Card
                                  style={{ backgroundColor: "#E2445C" }}
                                  className="sosmedCard"
                                >
                                  <i
                                    className="fa fa-map-marker fa-2x m-auto"
                                    style={{ color: "white" }}
                                  />
                                </Card>
                              </Col>
                              <Col lg="10" sm="10" xs="10">
                                <div className="visitMe ml-3 ">
                                  <CardText>
                                    <h5 style={{ textTransform: "bold" }}>
                                      <b>{t("Profile.address")}</b>
                                    </h5>
                                    <p style={{ lineHeight: "1.3em" }}>
                                      {company.company.address
                                        ? company.company.address
                                        : "-"}
                                    </p>
                                  </CardText>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {/* {t('Company.infoJob')} PERUSAHAAN */}
                    <div className="job-desc-list-company">
                      <div className="job-description-title-company">
                        <div className="ml-3">{t("Company.infoJob")}</div>
                      </div>
                      <div
                        style={{
                          marginTop: "12px",
                          paddingLeft: "2.5rem",
                          paddingRight: "2.5rem",
                        }}
                      >
                        <Row>
                          <Col sm="12">
                            <Carousel
                              partialVisible={true}
                              swipeable={true}
                              draggable={true}
                              showDots={false}
                              arrows={true}
                              responsive={responsive}
                              ssr={true}
                              infinite={false}
                              autoPlaySpeed={1000}
                              keyBoardControl={true}
                              customTransition="all .5"
                              transitionDuration={500}
                              containerClass="carousel-container"
                              dotListClass="custom-dot-list-style"
                              itemClass="carousel-item-padding-40-px"
                              renderButtonGroupOutside
                              renderDotsOutside={false}
                            >
                              {company.job &&
                                company.job.map((data, id) => {
                                  return (
                                    <Card id="card-company" key={id}>
                                      <center>
                                        <div className="jobs-logo">
                                          {company.company.logo ? (
                                            <CardImg
                                              className="img-job-compan"
                                              src={
                                                company.company.logo ===
                                                "https://hris-dev.widyaskilloka.com"
                                                  ? noLogoCompany
                                                  : company.company.logo
                                              }
                                              alt={data.name}
                                            />
                                          ) : (
                                            <CardImg
                                              className="img-job-compan"
                                              src={noLogoCompany}
                                              alt={data.name}
                                            />
                                          )}
                                        </div>
                                      </center>
                                      <CardBody>
                                        <center>
                                          <CardTitle
                                            tag="h5"
                                            className="title-information-companyProfile"
                                          >
                                            {data.name ? data.name : "-"}
                                          </CardTitle>
                                          <CardSubtitle
                                            tag="h6"
                                            className="subtitle-companyProfile mb-2 text-muted"
                                          >
                                            {data.type ? data.type : "-"}
                                          </CardSubtitle>

                                          <CardText>
                                            <i className="fa fa-building fa-md  m-auto" />{" "}
                                            {company.company.name
                                              ? company.company.name
                                              : "-"}{" "}
                                            <br />
                                            <i className="fa fa-map-marker fa-lg m-auto" />{" "}
                                            {data.province.name
                                              ? data.province.name
                                              : "-"}
                                          </CardText>
                                        </center>
                                        <center>
                                          <Link
                                            to={`/jobs/${data.id}/${data.name
                                              .toString()
                                              .toLowerCase()
                                              .normalize("NFD")
                                              .trim()
                                              .replace(/\s+/g, "-") // eslint-disable-next-line
                                              .replace(/[^\w\-]+/g, "") // eslint-disable-next-line
                                              .replace(/\-\-+/g, "-")}`}
                                          >
                                            <Button
                                              className="navtext step-next-job mt-3"
                                              style={{
                                                backgroundColor:
                                                  Color.primaryColor,
                                                color: Color.white,
                                              }}
                                            >
                                              <span
                                                style={{ fontSize: "12px" }}
                                              >
                                                {t("Dashboard.more")}{" "}
                                                <i className="fa fa-arrow-right ml-2" />
                                              </span>
                                            </Button>
                                          </Link>
                                        </center>
                                      </CardBody>
                                    </Card>
                                  );
                                })}
                            </Carousel>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FooterLandingPage />
          </Fragment>
        </div>
      ) : (
        <NotFound />
      )}
    </Fragment>
  );
};

export default connect(({ user }) => ({ user }))(translate(CompanyProfile));
