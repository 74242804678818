import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";
import Color from "../../../../utils/Colors";
export default function ContentTermsConditionLandingPage() {
  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <h2 className="title-header text-left">
            <span style={{ color: Color.primaryColor }}>
              Syarat dan Ketentuan
            </span>
            <br />
          </h2>
          <br />
          <p className="text-justify">
            Terima kasih telah mengunduh dan mengunjungi aplikasi Skillana.
            Sebelum mengakses dan/atau menggunakan layanan yang ada di dalam
            situs dan/atau <i>mobile application</i> Skillana pastikan Anda
            membaca dengan cermat dan hati-hati mengenai syarat dan ketentuan
            penggunaan situs dan <i>mobile application</i> Skillana (“syarat dan
            Ketentuan”) yang ada di halaman ini. Melalui pengunduhan dan/atau
            menggunakan aplikasi Skillana, Anda setuju bahwa Anda telah membaca,
            memahami, menerima dan menyetujui serta terikat secara hukum pada
            syarat dan ketentuan ini dan dokumen-dokumen lain sehubungan dengan
            itu. Jika Anda tidak menyetujui syarat dan ketentuan ini, mohon
            tidak mengakses situs dan menggunakan layanan Skillana. Anda harus
            membaca syarat dan ketentuan dengan hati-hati dan tidak harus
            menerima syarat dan ketentuan atau mendaftar, mengakses atau
            menggunakan layanan kecuali Anda setuju dengan syarat dan ketentuan
            ini.
            <br />
            <ol className="none-list mt-3">
              <li>
                <b>I. Informasi Umum</b>
                <ol type="1" className="sub-lists mt-3">
                  <li>
                    penyedia layanan adalah PT Widya Indonesia Sejahtera, sebuah
                    perusahaan yang didirikan berdasarkan hukum Republik
                    Indonesia, sebagai perusahaan pemilik dan penyedia aplikasi
                    berbasis cloud untuk mengelola Asesmen, yaitu Skillana, yang
                    dapat diakses melalui https://www.skillana.id/ (“situs”) dan
                    diunduh melalui <i>Play Store.</i>
                  </li>
                  <li>
                    Layanan adalah layanan yang tersedia dalam aplikasi Skillana
                    yang terdiri dari pengelolaan data pribadi dan pengelolaan
                    asesmen.
                  </li>
                  <li>
                    <i>User</i> atau Anda merupakan pengguna Layanan dan
                    aplikasi Skillana yang sesuai dengan konteksnya terdiri
                    dari:
                    <ol type="a" className="sub-lists">
                      <li>
                        Setiap orang yang mengakses situs atau mengunduh
                        aplikasi Skillana;
                      </li>
                      <li>
                        Perusahaan, yaitu merupakan badan usaha yang
                        mendaftarkan badan usahanya pada sistem Skillana; atau
                      </li>
                      <li>
                        Karyawan, yaitu tenaga kerja yang didaftarkan oleh
                        perusahaan untuk diikutsertakan dalam Skillana.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Hak kekayaan intelektual termasuk namun tidak terbatas pada
                    hak cipta dan merek, baik yang terdaftar maupun tidak.
                  </li>
                  <li>
                    Waktu kerja adalah hari saat penyedia layanan melakukan
                    kegiatan operasionalnya, yaitu hari Senin hingga Jumat,
                    kecuali libur nasional/publik, pukul 08.00- 17.00 WIB.
                  </li>
                  <li>
                    Data adalah informasi-informasi penting yang harus diberikan
                    oleh perusahaan kepada Skillana.
                  </li>
                </ol>
              </li>
              <br />
              <li>
                <b>II. Persetujuan</b>
                <ol type="1" className="sub-lists mt-3">
                  <li>
                    Anda dengan ini menyatakan dan menjamin bahwa:
                    <ol type="a" className="sub-lists">
                      <li>
                        Anda telah membaca dan menyetujui syarat dan Ketentuan,
                        Kebijakan Privasi;
                      </li>
                      <li>
                        Anda akan menggunakan dan/atau mengakses situs, Layanan,
                        Konten Pengguna Kami hanya untuk tujuan yang sah;
                      </li>
                      <li>
                        Tidak ada materi apapun yang disampaikan melalui akun
                        Anda atau yang diunggah atau dibagikan oleh Anda melalui
                        situs, Layanan, dan/atau interkom akan melanggar atau
                        menyalahi hak pihak ketiga manapun, termasuk hak cipta,
                        merek dagang, privasi, publisitas atau hak kepemilikan
                        atau pribadi lainnya; atau mengandung fitnah, pencemaran
                        nama baik atau materi yang melanggar hukum;
                      </li>
                      <li>
                        Semua informasi yang Anda berikan kepada penyedia
                        Layanan (termasuk namun tidak terbatas pada informasi
                        data pribadi dan kontak) adalah akurat dan lengkap;
                      </li>
                      <li>
                        penyedia Layanan berhak untuk mengubah, memodifikasi,
                        menunda atau menghentikan semua atau sebagian dari
                        situs, Layanan atau aplikasi Skillana setiap saat.
                        penyedia Layanan juga dapat menentukan batas pada
                        fitur-fitur tertentu atau membatasi akses Anda
                        berdasarkan keputusan internal penyedia Layanan atau
                        peraturan terkait dengan penyelenggaraan Layanan dan
                        aplikasi Skillana.
                      </li>
                      <li>
                        penyedia Layanan dari waktu ke waktu, tanpa memberikan
                        alasan atau pemberitahuan apapun sebelumnya, dapat
                        memperbarui, mengubah, menunda, menghentikan dan/atau
                        mengakhiri semua konten pada situs dan/atau aplikasi
                        Skillana, secara keseluruhan atau sebagian, termasuk
                        namun tidak terbatas pada desain, teks, gambar grafis,
                        foto, gambar, citra, video, aplikasi lunak, musik, suara
                        dan file lain, tarif, biaya, kuotasi, data historis,
                        grafik, statistik, artikel, informasi kontak kami,
                        setiap informasi lain, dan pemilihan serta
                        pengaturannya.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Anda dengan ini menyatakan dan menjamin bahwa:
                    <ol type="a" className="sub-lists">
                      <li>
                        Anda bertanggung jawab untuk membuat semua pengaturan
                        yang diperlukan agar Anda memiliki akses ke Skillana.
                        Anda juga bertanggung jawab untuk memastikan bahwa semua
                        orang yang mengakses Skillana melalui koneksi internet
                        Anda mengetahui dan mematuhi syarat dan Ketentuan ini
                        serta ketentuan lain yang berlaku.
                      </li>
                      <li>
                        Internet dapat mengalami gangguan, pemadaman transmisi,
                        penundaan transmisi karena lalu lintas internet atau
                        transmisi data yang salah sebagaimana hal-hal tersebut
                        melekat pada internet yang bersifat terbuka bagi publik.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <br />
              <li>
                <b>III. Penggunaan Layanan dan aplikasi</b>
                <br />
                Dengan Anda melanjutkan penggunaan atau pengaksesan Skillana,
                berarti Anda telah menyatakan dan menjamin kepada kami bahwa:
                <ol type="1" className="sub-lists mt-3">
                  <li>
                    Anda hanya diperkenankan untuk mengakses dan/atau
                    menggunakan Skillana untuk keperluan diri pribadi,
                    perusahaan dan non-komersil, yang berarti bahwa situs ini
                    hanya boleh diakses dan digunakan secara langsung oleh diri
                    sendiri atau perusahaan yang sedang mencari produk atau
                    Layanan untuk mengelola potensi diri. Akses dan penggunaan
                    situs di luar keperluan perusahaan atau non-komersil
                    dilarang, dan melanggar syarat dan ketentuan ini.
                  </li>
                  <li>
                    Anda tidak diperkenankan menggunakan Skillana untuk hal
                    sebagai berikut:
                    <ol type="a" className="sub-lists">
                      <li>
                        Anda bertanggung jawab untuk membuat semua pengaturan
                        yang diperlukan agar Anda memiliki akses ke Skillana.
                        Anda juga bertanggung jawab untuk memastikan bahwa semua
                        orang yang mengakses Skillana melalui koneksi internet
                        Anda mengetahui dan mematuhi syarat dan Ketentuan ini
                        serta ketentuan lain yang berlaku.
                      </li>
                      <li>
                        Untuk menyakiti, menyiksa, mempermalukan, memfitnah,
                        mencemarkan nama baik, mengancam, mengintimidasi atau
                        mengganggu orang atau bisnis lain, atau apapun yang
                        melanggar privasi atau yang penyedia Layanan anggap
                        penuh kebencian, tidak senonoh, tidak patut, tidak
                        pantas, atau diskriminatif;
                      </li>
                      <li>
                        Dengan cara yang melawan hukum, menipu, atau tindakan
                        komersil;
                      </li>
                      <li>
                        Dengan melanggar atau menyalahi hak orang lain, termasuk
                        tanpa terkecuali hak paten, merek dagang, hak cipta,
                        rahasia dagang, publisitas, dan hak milik lainnya;
                      </li>
                      <li>
                        Untuk membuat, memeriksa, memperbarui, mengubah atau
                        memperbaiki <i>database</i>, rekaman, atau direktori
                        Anda ataupun orang lain;
                      </li>
                      <li>
                        menggunakan kode komputer otomatis, proses atau sistem{" "}
                        <i>“screen scraping”</i>, program, robot,{" "}
                        <i>net crawler, spider</i>, pemrosesan data,{" "}
                        <i>trawling</i> atau kode komputer; dan/atau
                      </li>
                      <li>
                        melanggar syarat dan ketentuan, atau petunjuk lainnya
                        yang ada pada Skillana.
                      </li>
                    </ol>
                  </li>
                  <li>
                    penyedia Layanan tidak bertanggung jawab atas kehilangan
                    akibat kegagalan dalam mengakses Skillana atau metode
                    penggunaan Skillana yang di luar kendali kami.
                  </li>
                  <li>
                    penyedia Layanan tidak bertanggung jawab atau dapat
                    disalahkah atas kehilangan atau kerusakan yang di luar
                    perkiraan saat Anda mengakses atau menggunakan Skillana.
                    Kehilangan termasuk kehilangan penghematan biaya yang
                    diharapkan, kehilangan bisnis atau kesempatan bisnis,
                    kehilangan pemasukan atau keuntungan, atau kehilangan atau
                    kerusakan apapun yang Anda alami akibat penggunaan Skillana.
                  </li>
                </ol>
              </li>
              <br />
              <li>
                <b>IV. Ketentuan Lain - lain</b>

                <ol type="1" className="sub-lists mt-3">
                  <li>
                    <i>Disclaimer</i>
                    <ol type="a" className="sub-lists">
                      <li>
                        penyedia Layanan dalam hal ini tidak bertanggung jawab
                        terhadap segala macam bentuk kerugian yang diakibatkan
                        oleh kelalaian yang dilakukan oleh <i>User</i>.
                      </li>
                      <li>
                        Melalui penggunaan layanan Skillana <i>User</i> secara
                        otomatis mengikuti sistem yang terdapat pada fitur-fitur
                        Layanan Skillana.
                      </li>
                      <li>
                        <i>User</i> bertanggung jawab untuk memastikan
                        kebenaran, keabsahan dan kejelasan dokumen-dokumen untuk
                        pendaftaran Skillana, dan dengan ini <i>User</i>{" "}
                        membebaskan penyedia Layanan dari segala gugatan,
                        tuntutan dan atau ganti rugi dari pihak manapun
                        sehubungan dengan ketidakbenaran informasi, data,
                        keterangan, kewenangan dan atau kuasa yang diberikan
                        oleh <i>User</i>.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Perubahan
                    <br />
                    Melalui pemberitahuan sebelumnya kepada <i>User</i>, sesuai
                    dengan ketentuan yang berlaku, <i>User</i> dengan ini setuju
                    bahwa setiap saat Skillana berhak mengubah maupun
                    memperbaiki, menambah atau mengurangi ketentuan dalam syarat
                    dan Ketentuan terikat pada seluruh perubahan yang dilakukan
                    oleh penyedia Layanan.
                  </li>
                  <li>
                    Komunikasi:
                    <br />
                    User dapat menghubungi pihak penyedia Layanan melalui:
                    <ul type="none" className="sub-lists">
                      <li>
                        E-mail: <u>info@widyaskilloka.com</u>
                      </li>
                      <li>Telepon: (+62)81326754339</li>
                      <li>
                        PT Widya Indonesia Sejahtera: Jatirejo, Sendangadi, Kec.
                        Mlati, Kabupaten Sleman, Daerah Istimewa Yogyakarta
                        55285
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <br />
              <li>
                <b>V. Dokumen Lain yang Berlaku</b>

                <ol type="1" className="sub-lists mt-3">
                  <li>
                    Sebagai tambahan dari syarat dan Ketentuan ini, hal-hal
                    berikut juga berlaku terhadap penggunaan Layanan dan
                    aplikasi Skillana oleh <i>User</i>: Kebijakan Privasi, yang
                    menetapkan ketentuan-ketentuan yang berlaku ketika penyedia
                    Layanan mengolah setiap Data yang dikumpulkan dari{" "}
                    <i>User</i>, atau yang <i>User</i> berikan kepada penyedia
                    Layanan. Dengan menggunakan Skillana, Anda setuju dengan
                    pengumpulan, penggunaan, pengungkapan Data Anda dan Anda
                    menjamin bahwa semua Data yang Anda berikat adalah akurat;
                  </li>
                  <li>
                    Apabila terdapat perbedaan pada syarat dan Ketentuan ini
                    dengan perjanjian penggunaan layanan, maka
                    ketentuan-ketentuan dalam syarat dan Ketentuan inilah yang
                    berlaku.
                  </li>
                </ol>
              </li>
            </ol>
            Bersamaan dengan penggunaan Skillana, Anda mengakui bahwa Anda telah
            membaca, memahami, dan menyetujui syarat dan Ketentuan ini.
          </p>
        </Col>
      </Row>
    </Fragment>
  );
}
