import React from "react";
import Skeleton from "react-loading-skeleton";
import { ListGroupItem, Row, ListGroup, Col } from "reactstrap";

const JobPlacement = () => {
  return (
    <ListGroup style={{ width: "100%" }}>
      {Array(5)
        .fill()
        .map((item, index) => (
          <ListGroupItem key={index} className="job-placement pt-4 pb-4">
            <Row>
              <Col sm="2 pb-3">
                <Skeleton height={`60%`} width={`100%`} />
              </Col>
              <Col sm="10">
                <Row>
                  <Col lg="9">
                    <Skeleton height={30} width={150} />
                    <br />
                    <Skeleton width={190} />
                    <br />
                    <Skeleton width={180} />
                    <br />
                    <br />
                    <Skeleton width={200} />
                  </Col>
                  <Col lg="3">
                    <Skeleton width={100} />
                    <br />
                    <Skeleton height={30} width={130} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </ListGroupItem>
        ))}
    </ListGroup>
  );
};
export default JobPlacement;
