import React, { Fragment } from "react";
import { Row, Modal, ModalBody } from "reactstrap";

export default function ModalTitle(props) {
  return (
    <Fragment>
      <Modal
        isOpen={props.visible}
        size="sm"
        className="modal-center"
        style={{ zIndex: 10000000 }}
      >
        <ModalBody>
          <Row>
            <div className="col-12 text-center">
              <h1 className="title-upgrade" style={{ color: "#5aadf7" }}>
                {props.title}
              </h1>
            </div>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
