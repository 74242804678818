import React, { useState, useEffect } from 'react'
import {
    Col,
} from 'reactstrap';
import Color from '../../../../utils/Colors';
import { Link } from 'react-router-dom';
export default function MbtiPersonalityList(props) {
    const [isScroll, setIsScroll] = useState(true);
    useEffect(() => {
        if (isScroll) {
            window.addEventListener("scroll", () => {
                if (window.scrollY > 0) {
                    if (!document.getElementsByTagName('nav')[0].classList.contains('onScroll')) {
                        document.getElementsByTagName('nav')[0].classList.add('onScroll')
                    }
                } else {
                    if (document.getElementsByTagName('nav')[0].classList.contains('onScroll')) {
                        document.getElementsByTagName('nav')[0].classList.remove('onScroll')
                    }
                }
            })
        }
    }, [isScroll]);
    const goToLink = () => {
        setIsScroll(false)
    }
    return (
        <Col md="3" xs="6" className="text-center pt-5 pb-5" style={{margin: "1rem 0"}}>
            <Link to={"/type-personality/" + props.type} onClick={() => goToLink()} className="link-mbti">
                <img src={require("../../../../assets/img/16personalitiesLanding/" + props.type + ".png")} alt={props.type} className="img-mbti" />
                <h4 style={{ color: '#283756', textTransform: 'uppercase' }}><b>{props.type}</b></h4>
                <h6 style={{ color: Color.yellowColor, textTransform: 'uppercase' }}><b>{props.name}</b></h6>
                <p style={{ color: '#959292' }}>{props.desc}</p>
            </Link>
        </Col>
    )

}