import React, { useState } from "react";
import Menu from "../Menu";
import { Container, Row, Col } from "reactstrap";
import { translate, t } from "react-switch-lang";
import AgilityTestProvider from "../Menu/AgilityTestContext";
function UserGuide(props) {
  const [nextPage, setNextPage] = useState(false);
  const nextPageButton = () => {
    setNextPage(true);
  };
  return (
    <Container>
      {nextPage ? (
        <AgilityTestProvider>
          <Menu setComplete={props.setComplete} />
        </AgilityTestProvider>
      ) : (
        <Row>
          <Col xs="1"></Col>
          <Col
            xs="10"
            className=" mt-4 mb-4 pt-5 pb-5"
            style={{
              boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
            }}
          >
            <Row>
              <Col sm="12" className="text-center">
                <img
                  src={require("../../../../assets/img/info-circle.png")}
                  alt="guide"
                  style={{ width: "50px" }}
                />
                <br />
                <br />
                <h5>{t("Agility.descUserGuide")}</h5>
                <br />
              </Col>
              <Col sm="1"></Col>
              <Col sm="9">
                <p>
                  1. {t("Agility.descUserGuide1")}
                  <br />
                  2. {t("Agility.descUserGuide2")}
                  <br />
                  3. {t("Agility.descUserGuide3")}
                  <br />
                  4. {t("Agility.descUserGuide4")}
                  <br />
                  5. {t("Agility.descUserGuide5")}
                  <br />
                  6. <strong>{t("Agility.descUserGuide6")}</strong>
                  <br />
                  7. <strong>{t("Agility.descUserGuide7")}</strong>
                  <br />
                  8. {t("Agility.descUserGuide8")}
                  <br />
                  9. {t("Agility.descUserGuide9")}
                  <br />
                </p>
              </Col>
              <Col sm="1"></Col>
              <Col sm="12" className="text-center">
                <button
                  className="btn btn-primary mt-3"
                  onClick={nextPageButton}
                >
                  {t("General.next")} <i className="ml-2 fa fa-arrow-right"></i>
                </button>
              </Col>
            </Row>
          </Col>
          <Col xs="1"></Col>
        </Row>
      )}
    </Container>
  );
}

export default translate(UserGuide);
