import React, { Fragment } from "react";
import { Route } from "react-router";
const DefaultFullScreen = (props) => {
  const toUp = window.scrollTo(0, 0);
  return (
    <Fragment>
      {toUp}
      <Route {...props} />
    </Fragment>
  );
};
export default DefaultFullScreen;
