import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { translate, t } from "react-switch-lang";

const InternalModal = ({ isOpen, job, company, onLeft, onRight }) => {
  return (
    <Modal
      isOpen={isOpen}
      className="modal-md"
      backdropClassName="back-home"
      style={{ marginTop: "10vh" }}
    >
      <ModalBody>
        <div className="row justify-content-center mt-2">
          <div className="col-12 mb-4">
            <div className="text-center" style={{ borderRadius: "5px" }}>
              <i className="fa fa-3x fa-exclamation-triangle mb-2 text-danger" />
              <br />
              <h5 className="my-3" style={{ lineHeight: "1.4em" }}>
                {t("Internal.modal1")} <b>{job}</b> {t("Internal.modal2")}{" "}
                <b>{company}</b>?
              </h5>
            </div>
          </div>
          <div className="col-6 text-center">
            <Button className="button-netis-outline px-3" onClick={onLeft}>
              {t("General.cancel")}
            </Button>
          </div>
          <div className="col-6 text-center">
            <Button className="button-netis-color px-3" onClick={onRight}>
              <b>{t("Internal.yes")}</b>
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default translate(InternalModal);
