import React from "react";
import { Row, Button, Modal, ModalBody } from "reactstrap";
import { t } from "react-switch-lang";
export default function Hint(props) {
  return (
    <Modal isOpen={props.visible} className="top-50" autoFocus={true}>
      <ModalBody>
        <Row>
          <div className="col-12 text-center">
            <h4 className="title-upgrade" style={{ color: "#93aad6" }}>
              {props.title}
            </h4>
          </div>
          {props.img !== null ? (
            <div className="col-12 text-center">
              <img
                src={require(`../../../assets/img/hintPhoto/${props.img}`)}
                alt=""
                width="140px"
              />
            </div>
          ) : null}
          <div className="col-12 text-justify">
            <p>{props.desc}</p>
          </div>
          <div className="col-12 text-justify">
            {props.backBtn ? (
              <Row>
                <div className="col-6 text-right">
                  <Button
                    type="submit"
                    color="netis-color"
                    onClick={props.onBack}
                  >
                    {t("General.back")}
                  </Button>
                </div>
                <div className="col-6 text-left">
                  <Button
                    type="submit"
                    color="netis-color"
                    onClick={props.onClick}
                  >
                    {props.actionTitle}
                  </Button>
                </div>
              </Row>
            ) : (
              <Row>
                <div className="col-12 text-center">
                  <Button
                    type="submit"
                    color="netis-color"
                    onClick={props.onClick}
                  >
                    {props.actionTitle}
                  </Button>
                </div>
              </Row>
            )}
          </div>
        </Row>
      </ModalBody>
    </Modal>
  );
}
