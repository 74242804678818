import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { translate, t } from "react-switch-lang";
import Test from "../Test";
function UserGuide(props) {
  const [nextPage, setNextPage] = useState(false);
  const nextPageButton = () => {
    setNextPage(true);
  };
  return (
    <Container>
      {nextPage ? (
        <Test />
      ) : (
        <Row>
          <Col xs="1"></Col>
          <Col
            xs="10"
            className="mt-4 mb-4 pt-5 pb-4"
            style={{
              boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
            }}
          >
            <Row>
              <Col sm="12" className="text-center">
                <img
                  src={require("../../../../assets/img/info-circle.png")}
                  alt="guide"
                  style={{ width: "50px" }}
                />

                <br />
                <br />
                <h5>{t("Assessment.businessInsightUserGuide")}</h5>
                <br />
              </Col>
              <Col sm="1"></Col>
              <Col sm="9">
                <p>
                  1. {t("Assessment.businessInsightDescUserGuide1")}
                  <br />
                  2. {t("Assessment.businessInsightDescUserGuide2")}
                  <br />
                  3. {t("Assessment.businessInsightDescUserGuide3")}
                  <br />
                </p>

                <ol type="a" className="business-userGuide-point-3">
                  <li>{t("Assessment.businessInsightStronglyAgree")}</li>
                  <li>{t("Assessment.businessInsightAgree")}</li>
                  <li>{t("Assessment.businessInsightFairlyAgree")}</li>
                  <li>{t("Assessment.businessInsightNeutral")}</li>
                  <li>{t("Assessment.businessInsightFairlyDisagree")}</li>
                  <li>{t("Assessment.businessInsightDisagree")}</li>
                  <li>{t("Assessment.businessInsightStronglyDisagree")}</li>
                </ol>
                <p>
                  4. {t("Assessment.businessInsightDescUserGuide4")}
                  <br />
                  5. {t("Assessment.businessInsightDescUserGuide5")}
                  <br />
                  6. {t("Assessment.businessInsightDescUserGuide6")}
                  <br />
                  7. {t("Assessment.businessInsightDescUserGuide7")}
                  <br />
                  8. {t("Assessment.businessInsightDescUserGuide8")}
                  <br />
                </p>
                <p>{t("Assessment.goodLuck")}</p>
              </Col>
              <Col sm="1"></Col>
              <Col sm="12" className="text-center">
                <button
                  className="btn btn-primary mt-2"
                  onClick={nextPageButton}
                >
                  {t("General.next")} <i className="ml-2 fa fa-arrow-right"></i>
                </button>
              </Col>
              <Col xs="1"></Col>
            </Row>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default translate(UserGuide);
