import React, { useState, useEffect, Fragment } from "react";
import { Alert } from "reactstrap";
import LoadingAnimation from "../../../../../components/atoms/LoadingAnimation";
import request from "../../../../../utils/Request";
import requestGeneral from "../../../../../utils/RequestGeneral";
import GuideVideoAssessment from "../../../../Templates/GuideVideoAssessment";
import { translate, t } from "react-switch-lang";
import NoCompleteProfile from "../../Assessment/NoCompleteProfile";
import { Redirect } from "react-router-dom";
const AuthInterview = () => {
  const [loading, setLoading] = useState(true);
  const [quota, setQuota] = useState(false);
  const [support, setSupport] = useState(false);
  const [isNotComplete, setNotComplete] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [spm, setSpm] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("referal")) {
      requestGeneral
        .get("internal/recruitment/internal/" + localStorage.getItem("referal"))
        .then((res) => {
          if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("video")
            ).length === 0
          ) {
            setRedirect("/home");
            setLoading(false);
          } else if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("spm")
            ).length > 0 &&
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("profile")
            ).length > 0
          ) {
            setSpm(true);
            validate_profile();
          } else if (
            res?.data?.data?.requirements?.filter((item) =>
              item?.toLowerCase().includes("spm")
            ).length > 0
          ) {
            setSpm(true);
            validate_video();
          } else {
            validate_video();
          }
        })
        .catch((err) => {
          validate_video();
        });
    } else {
      validate_video();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  function validate_profile() {
    requestGeneral
      .get(`/v2/users/validate-standart`)
      .then((res) => {
        validate_video();
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          setNotComplete(true);
          setLoading(false);
        } else {
          setLoading(false);
          return Promise.reject(err);
        }
      });
  }
  function validate_video() {
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    request
      .get("/assessment_video/validate_video")
      .then((res) => {
        if (isSafari || isIE || isEdge) {
          setSupport(false);
          setQuota(true);
        } else {
          setSupport(true);
          setQuota(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          request
            .get("/assessment_video/validate_photo")
            .then((res) => {
              request
                .get("/assessment_video/validate_palmistry")
                .then((res) => {
                  setQuota(false);
                  setLoading(false);
                })
                .catch((err) => {
                  if (err.response?.status === 422) {
                    if (isSafari || isIE || isEdge) {
                      setSupport(false);
                      setQuota(true);
                    } else {
                      setSupport(true);
                      setQuota(true);
                    }
                    setLoading(false);
                  }
                });
            })
            .catch((err) => {
              if (err) {
                if (err.response?.status === 422) {
                  if (isSafari || isIE || isEdge) {
                    setSupport(false);
                    setQuota(true);
                  } else {
                    setSupport(true);
                    setQuota(true);
                  }
                  setLoading(false);
                }
              }
            });
        }
      });
  }
  return redirect !== false ? (
    <Redirect to={redirect} />
  ) : (
    <Fragment>
      {loading ? (
        <LoadingAnimation />
      ) : isNotComplete ? (
        <NoCompleteProfile />
      ) : quota ? (
        <GuideVideoAssessment
          applied={false}
          support={support}
          spmExist={spm}
        />
      ) : (
        <Alert color="danger" className="text-center mt-3 mb-4">
          <h5 className="content-title mb-2">{t("Interview.noQuota")}</h5>
        </Alert>
      )}
    </Fragment>
  );
};
export default translate(AuthInterview);
