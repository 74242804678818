import React from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { translate } from "react-switch-lang";
export default connect(({ user }) => ({ user }))(
  translate(function ContentVideoCV(props) {
    const { t } = props;
    const titleVideo = [
      t("Interview.Perkenalan Diri"),
      t("Interview.Motivasi"),
      t("Interview.Sistematika Kerja"),
      t("Interview.Daya Tahan Terhadap Tugas"),
      t("Interview.Berpikir Analitis"),
      t("Interview.Kepemimpinan"),
      t("Interview.Kerjasama"),
      t("Interview.Ketrampilan"),
      t("Interview.Pengembangan Diri"),
      t("Interview.Komitmen"),
      t("Interview.Kejelasan Visi"),
      t("Interview.Kemampuan Adaptasi"),
    ];

    if (props.user?.id) {
      return <Redirect to="/interview-online" />;
    }
    const changePageLogin = () => {
      window.location.replace("/promotion-video-cv");
    };
    return (
      <div>
        <Container style={{ padding: "120px 0 90px 10px" }}>
          <Row className="mb-4 mt-4">
            <Col sm="4" xs="3">
              <button
                className="navtext btn btn-netis-color"
                onClick={changePageLogin}
              >
                <i className="fa fa-arrow-left mr-2"></i>
                {t("General.back")}
              </button>
            </Col>
            <Col sm="4" xs="5" className="text-center">
              <h3 className="content-title">{t("Interview.CVVideo")}</h3>
            </Col>
            <Col sm="4" xs="4" className="text-right">
              <Link to="/video-webcam-cv">
                <button className="navtext btn btn-success">
                  <i className="fa fa-video-camera mr-2"></i>
                  {t("General.create")} {t("Interview.CVVideo")}
                </button>
              </Link>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col lg="6" md="8">
                  {sessionStorage.getItem("video") ? (
                    <>
                      <video
                        className="vid"
                        src={JSON.parse(sessionStorage.getItem("video"))?.video}
                        style={{
                          // position: "fixed",
                          width: "100%",
                          // left: 0,
                          // top: 0,
                        }}
                        id="dataVid"
                        controls={true}
                        autoPlay={false}
                        muted
                        playsInline
                      />
                    </>
                  ) : (
                    <img
                      src={require("../../../../assets/img/widya_robot.png")}
                      alt="widya_robot"
                      style={{ width: "100%" }}
                      className="mb-2"
                    />
                  )}
                </Col>
                <Col lg="6" md="4">
                  {titleVideo &&
                    titleVideo.map((video, idx) => (
                      <ListGroup key={idx}>
                        {idx === 0 && sessionStorage.getItem("video") ? (
                          <ListGroupItem
                            className="d-flex align-items-center"
                            tag="button"
                            action
                            style={{ backgroundColor: "#e7e7e7" }}
                          >
                            <i className="fa fa-video-camera mr-2" />
                            {video}
                          </ListGroupItem>
                        ) : (
                          <ListGroupItem
                            className="d-flex align-items-center"
                            tag="button"
                            action
                          >
                            <i className="fa fa-video-camera mr-2" />
                            {video}
                          </ListGroupItem>
                        )}
                      </ListGroup>
                    ))}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    );
  })
);
