// import {useSelector} from 'react-redux';

// input : "yyyy-mm-dd"
// output: "dd/mm/yyyy"
const dateWithSlash = (date) => {
  let splits = date.split(/[ -]/);
  return `${splits[2]}/${splits[1]}/${splits[0]}`;
};

const dateWithSlashReverse = (date) => {
  let splits = date.split(/[ -]/);
  return `${splits[0]}/${splits[1]}/${splits[2]}`;
};

// input : ISO String format
// output: "yyyy-mm-dd"
const dateWithDashReverse = (date) => {
  let splits = date.split(/[ T]/);
  return splits[0];
};

// input: "yyyy-mm-dd"
// output: "dd month yyyy"
const dateFullname = (dateInput) => {
  // Belum ada Language reducer
  // const Language = store.getState().languageReducer.messages;
  // const Language = useSelector(state => state.languageReducer).messages;

  let listMonth = {
    "01": "Januari",
    "02": "Februari",
    "03": "Maret",
    "04": "April",
    "05": "Mei",
    "06": "Juni",
    "07": "Juli",
    "08": "Agustus",
    "09": "September",
    "10": "Oktober",
    "11": "November",
    "12": "Desember",
  };

  let splits = dateInput.split(/[ -]/);

  let date = splits[2];
  let month = splits[1];
  let year = splits[0];

  month = listMonth[month];

  return `${date} ${month} ${year}`;
};

// input date: date ISO string format
// input time: time Locale time string
// output: 'yyyy-mm-dd HH:MM:SS'
const getFullDateTime = (date, time) => {
  let dateNow = dateWithDashReverse(date);
  return `${dateNow} ${time}`;
};

export {
  dateWithSlash,
  dateWithDashReverse,
  dateFullname,
  dateWithSlashReverse,
  getFullDateTime,
};
