import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import {
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  Container,
  Alert,
} from "reactstrap";
import LoadingAnimation from "../../../components/atoms/LoadingAnimation";
import { translate, t } from "react-switch-lang";
import { useParams } from "react-router-dom";
import request from "../../../utils/Request";
function ShareableDisc() {
  const { resultCodes } = useParams();
  const [results, setResults] = useState([]);
  const [kekuatan, setKekuatan] = useState([]);
  const [karakter, setKarakter] = useState([]);
  const [perkembangan, setPerkembangan] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    request
      .get("/assessment/test/disc/share/" + resultCodes)
      .then((res) => {
        const resultType = res.data.data.type_description[0];
        setResults(resultType[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (results.length !== 0) {
      const arrKekuatan = results.kekuatan.split("\n");
      if (arrKekuatan.length >= 10) {
        const chunkLength = Math.max(arrKekuatan.length / 2, 1);
        let chunks = [];
        for (var i = 0; i < 2; i++) {
          if (chunkLength * (i + 1) <= arrKekuatan.length)
            chunks.push(
              arrKekuatan.slice(chunkLength * i, chunkLength * (i + 1))
            );
        }
        setKekuatan(chunks);
      }
    }
  }, [results.kekuatan]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (results.length !== 0) {
      const arrKarakter = results.karakteristik.split("\n");
      if (arrKarakter.length >= 10) {
        const chunkLength = Math.max(arrKarakter.length / 2, 1);
        let chunks = [];
        for (var i = 0; i < 2; i++) {
          if (chunkLength * (i + 1) <= arrKarakter.length)
            chunks.push(
              arrKarakter.slice(chunkLength * i, chunkLength * (i + 1))
            );
        }
        setKarakter(chunks);
      }
    }
  }, [results.karakteristik]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (results.length !== 0) {
      const arrPerkembangan = results.area_perkembangan.split("\n");
      if (arrPerkembangan.length >= 10) {
        const chunkLength = Math.max(arrPerkembangan.length / 2, 1);
        let chunks = [];
        for (var i = 0; i < 2; i++) {
          if (chunkLength * (i + 1) <= arrPerkembangan.length)
            chunks.push(
              arrPerkembangan.slice(chunkLength * i, chunkLength * (i + 1))
            );
        }

        setPerkembangan(chunks);
      }
    }
  }, [results.area_perkembangan]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Container>
        {loading ? (
          <LoadingAnimation />
        ) : results.length !== 0 ? (
          <>
            <div
              className="row justify-content-center"
              style={{ paddingTop: "2rem" }}
            >
              <Col sm="4"></Col>
              <Col sm="4"></Col>
              <Col sm="4"></Col>
            </div>

            <div className="text-center">
              <img
                src={require("../../../assets/img/disc/" +
                  results.type +
                  ".png")}
                alt="logo"
                style={{ height: 300 }}
                className="my-4"
              />
              <h2 className="mb-3 h4 title-menu-company text-uppercase">
                <b>
                  <ReactMarkdown source={results.nama.split(" (")[0]} />
                </b>
              </h2>
              <Row>
                <Col lg="7" md="7" sm="12" className="p-2 mx-auto">
                  <Card
                    className="shadow border-0 mx-auto mt-4 mb-3"
                    style={{ borderRadius: "12px" }}
                  >
                    <CardHeader
                      style={{
                        backgroundColor: "#ffb5cc",
                        borderRadius: "12px 12px 0px 0px",
                      }}
                    >
                      <h4 style={{ color: "#000" }}>
                        {t("Assessment.characteristics")}
                      </h4>
                    </CardHeader>
                    <CardBody
                      className="text-left"
                      style={{
                        backgroundColor: "#FEF8FA",
                      }}
                    >
                      {results.karakteristik.split("\n").length > 10 ? (
                        <>
                          <Row>
                            <Col>
                              <ul>
                                {karakter &&
                                  karakter[0]?.map((karakterFirst) => {
                                    return (
                                      <li>{karakterFirst.replace("-", "")}</li>
                                    );
                                  })}
                              </ul>
                            </Col>

                            <Col>
                              <ul>
                                {karakter &&
                                  karakter[1]?.map((karakterSecond) => {
                                    return (
                                      <li>{karakterSecond.replace("-", "")}</li>
                                    );
                                  })}
                              </ul>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <ReactMarkdown source={results.karakteristik} />
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="7" md="7" sm="12" className="p-2 mx-auto">
                  <Card
                    className="shadow border-0 mx-auto mt-4 mb-3"
                    style={{ borderRadius: "12px" }}
                  >
                    <CardHeader
                      style={{
                        backgroundColor: "#ffc9ad",
                        borderRadius: "12px 12px 0px 0px",
                      }}
                    >
                      <h4 style={{ color: "#000" }}>{t("Assessment.power")}</h4>
                    </CardHeader>
                    <CardBody
                      className="text-left"
                      style={{
                        backgroundColor: "#FEF6F1",
                      }}
                    >
                      {results.kekuatan.split("\n").length > 10 ? (
                        <>
                          <Row>
                            <Col>
                              <ul>
                                {kekuatan &&
                                  kekuatan[0]?.map((kekuatanFirst) => {
                                    return (
                                      <li>{kekuatanFirst.replace("-", "")}</li>
                                    );
                                  })}
                              </ul>
                            </Col>

                            <Col>
                              <ul>
                                {kekuatan &&
                                  kekuatan[1]?.map((kekuatanSecond) => {
                                    return (
                                      <li>{kekuatanSecond.replace("-", "")}</li>
                                    );
                                  })}
                              </ul>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <ReactMarkdown source={results.kekuatan} />
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="7" md="7" sm="12" className="p-2 mx-auto">
                  <Card
                    className="shadow border-0 mx-auto mt-4 mb-3"
                    style={{ borderRadius: "12px" }}
                  >
                    <CardHeader
                      style={{
                        backgroundColor: "#a990e4",
                        borderRadius: "12px 12px 0px 0px",
                      }}
                    >
                      <h4 style={{ color: "#000" }}>{t("Assessment.area")}</h4>
                    </CardHeader>
                    <CardBody
                      className="text-left"
                      style={{
                        backgroundColor: "#EEE9FB",
                      }}
                    >
                      {results.area_perkembangan.split("\n").length > 10 ? (
                        <>
                          <Row>
                            <Col>
                              <ul>
                                {perkembangan &&
                                  perkembangan[0]?.map((perkembanganFirst) => {
                                    return (
                                      <li>
                                        {perkembanganFirst.replace("-", "")}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </Col>

                            <Col>
                              <ul>
                                {perkembangan &&
                                  perkembangan[1]?.map((perkembanganSecond) => {
                                    return (
                                      <li>
                                        {perkembanganSecond.replace("-", "")}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <ReactMarkdown source={results.area_perkembangan} />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <Alert color="danger" className="text-center mt-3 mb-4">
            <h5 className="content-title mb-2">{t("Assessment.noData")}</h5>
          </Alert>
        )}
      </Container>
    </>
  );
}

export default translate(ShareableDisc);
