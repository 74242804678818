import React, { Fragment } from "react";
import NavbarLandingPage from "../../Templates/LandingPage/NavbarLandingPage";
import FooterLandingPage from "../../Templates/LandingPage/FooterLandingPage";
import WhatsAppButton from "../../Templates/LandingPage/WhatsAppButton";
import { Route } from "react-router";
import { connect } from "react-redux";
const LandingPage = (props) => {
  const toUp = window.scrollTo(0, 0);

  return (
    <Fragment>
      {toUp}
      <NavbarLandingPage {...props} />
      <Route {...props} />
      <FooterLandingPage />
      <WhatsAppButton />
    </Fragment>
  );
};
const mapsToDispatch = (dispatch) => {
  return {
    setUser: (state) =>
      dispatch({
        type: "SET_USER",
        payload: state,
      }),
  };
};
export default connect(({ user }) => ({ user }), mapsToDispatch)(LandingPage);
